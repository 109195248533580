import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { IUEvent } from '../../lib/event';
import { EventDetails } from '../../components/EventForm/Common';
import LocationMarker from '../../components/icons/LocationMarker';
import {
  convertTimestampToDateString,
  convertTimestampToDateTimeString,
  generateTimeString,
  isMultidayEvent
} from '../../lib/time';
import AddToCalendar from '../../components/EventForm/AddToCalendar';
import { getAddressString } from '../../lib/location';
import { useUserContext } from '../../contexts/UserContext';

import { DeviceQueries } from 'Common/src/components/styled';

const EventDetailsModule = ({ event, readOnly, isAttending, forSampleInvite }: { event: IUEvent, readOnly?: boolean, isAttending: boolean, forSampleInvite?: boolean }) => {
  const userContext = useUserContext();

  let fullDateTime: ReactNode;
  if (isMultidayEvent(event)) {
    fullDateTime = <div>{convertTimestampToDateTimeString(event.startTime, event.timeZone, true,
      false)} - {convertTimestampToDateTimeString(
      event.endTime, event.timeZone, true, false)}</div>;
  } else {
    fullDateTime = <>
      <div>{convertTimestampToDateString(event.startTime, event.timeZone, true)}</div>
      <div>{generateTimeString(event)}</div>
    </>
  }

  const addressString = event.location.address ? getAddressString(event.location.address, true) : event.location.displayName;
  const addressComponents = addressString.split(',');

  let locationCell: ReactNode;
  if (userContext.isLoggedIn() || isAttending) {
    locationCell = <LocationLink
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        event.location.displayName)}`}
      target="_blank" rel="noreferrer noopener"
      $disabled={readOnly}>
      <div>{event.location.placeName}</div>
      {addressComponents.map(str =>
        <div key={str}>{str.trim()}</div>
      )}
      <ViewMap>
        <LocationMarker/>
        View map
      </ViewMap>
    </LocationLink>;
  } else {
    locationCell = <em>
      Please log in or RSVP to view the location
    </em>
  }

  return <EventDetailsContainer $forSampleInvite={forSampleInvite}>
    <EventLogisticsCell>
      <CellContent>
        {locationCell}
      </CellContent>
    </EventLogisticsCell>
    <EventLogisticsCell>
      <CellContent>
        {fullDateTime}
        <AddToCalendar event={event} readOnly={readOnly}/>
      </CellContent>
    </EventLogisticsCell>
  </EventDetailsContainer>;
};

const LocationLink = styled.a<{ $disabled?: boolean }>`
  text-decoration: none;
  color: currentColor;

  &:visited {
    color: currentColor;
  }

  ${({ $disabled }) => $disabled && 'pointer-events: none;'};
`;

const EventDetailsContainer = styled(EventDetails)<{ $forSampleInvite?: boolean }>`
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
  ${({ $forSampleInvite }) => $forSampleInvite && 'font-size: 16px;'};

  @media (${DeviceQueries.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`

const EventLogisticsCell = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
`

const CellContent = styled.div`
  display: flex;
  flex-direction: column;

  @media not (${DeviceQueries.mobile}) {
    width: 80%;
  }
`

const ViewMap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media (${DeviceQueries.mobile}) {
    font-size: 14px;
  }
`

export default EventDetailsModule;
