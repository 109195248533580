import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { getNormalizedPhotoUrl } from '../lib/images';
import LeftPaginationArrow from './icons/LeftPaginationArrow';
import RightPaginationArrow from './icons/RightPaginationArrow';
import Dot from './icons/Dot';
import { DesktopShow } from './styled/Common';

import Carousel, { CarouselItem } from './Carousel';

interface Props {
  title: string;
  imageUrls: string[];
  onImageClick: (id: string) => void;
  onAllClick: () => void;
}

const PER_PAGE = 6;
const MAX_PAGES = 8;

const PaginatedImages = ({ title, imageUrls, onImageClick, onAllClick }: Props) => {
  const pageCount = Math.min(Math.ceil(imageUrls.length / PER_PAGE), MAX_PAGES);

  const carouselItems: CarouselItem[] = [];
  for (let i = 0; i < pageCount; i++) {
    carouselItems.push( {
      contents: <ImagePage>
        {imageUrls.slice(i * PER_PAGE, (i + 1) * PER_PAGE).map((url) => <ImageContainer
          key={url} onClick={() => onImageClick(url)}>
          <Image src={getNormalizedPhotoUrl(url)} width="100" height="100"/>
        </ImageContainer>)}
      </ImagePage>
    });
  }

  return <Container>
    <TitleContainer>
      <Title>{title}</Title>
      {imageUrls.length > 6 && <ViewAll onClick={onAllClick}>View all</ViewAll>}
    </TitleContainer>
    <Carousel items={carouselItems} width={360}/>
  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.label`
  font-family: Plus Jakarta Sans, sans-serif;
  color: BLACK;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
`;

const ViewAll = styled.div`
  color: var(--shine-tint-color);
  cursor: pointer;
`;

const ImagePage = styled.div`
  display: inline-grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 340px;
  min-width: 340px;
`;

const ImageContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(var(--sp-text-rgb), 0.cheese-10);
  height: 100px;
  cursor: pointer;
`;

const Image = styled.img`
  border-radius: 10px;
`;

const DotWrapper = styled.span`
  cursor: pointer;
  display: flex;
`;

export default PaginatedImages;
