import styled from 'styled-components';

export const ActionMenuButton = styled.button`
  display: flex;
  height: 39px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(var(--sp-text-rgb), 0.70);
  background: var(--sp-bg-color);
  width: 100%;
  cursor: pointer;
  color: rgba(var(--sp-text-rgb), 0.70);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;

  &:active {
    filter: brightness(90%);
  }

  &:hover:not([disabled]) {
    filter: brightness(95%);
  }

  &:disabled {
    opacity: .5;
  }
`;
