import React, { ReactNode, useEffect, useState } from 'react';
import { Location, useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import md5 from 'md5';

import InviteForm from '../components/invite/InviteForm';
import InviteTable from '../components/invite/InviteTable';
import { useEventCacheContext } from '../contexts/EventCacheContext';
import { useBackgroundContext } from '../contexts/BackgroundContext';
import { useUserContext } from '../contexts/UserContext';
import {
  deletePendingInvites,
  editInvitee,
  queueInvites, reviewAttendee,
  sendInvites,
  sendTemplateEmail,
  updateAttendance
} from '../api/ElkEventService';
import { useModalContext } from '../contexts/ModalContext';
import InviteUploadModal from '../components/modals/InviteUploadModal';
import { BasePageContent } from './BasePage';
import BasePageHeader, { UPageType } from './BasePageHeader';
import BulkEmailInviteModal from '../components/modals/BulkEmailInviteModal';
import { filterInvitees, FilterType, removeDuplicateInvitees, separateInvitees, sortInvitees } from '../util/attendee';
import Modal from '../components/modals/Modal';
import { UButterBarLevel, useButterBarContext } from '../contexts/ButterBarContext';
import EditInviteeModal from '../components/modals/EditInviteeModal';
import AddList from '../components/icons/AddList';
import FramedPage, { FrameBody, FrameFooter, FrameHeader, FrameHeaderLeft, FrameHeaderRight } from './FramedPage';
import { MobileWideWhiteButton, WhiteButton } from '../components/buttons/WhiteButton';
import LeftArrow from '../components/icons/LeftArrow';
import ChartSquareBarOutline from '../components/icons/ChartSquareBarOutline';
import LinkIcon from '../components/icons/LinkIcon';
import SystemButton from '../components/buttons/SystemButton';
import UserEmailModal from '../components/invite/UserEmailModal';
import DownloadCSVModal from '../components/invite/DownloadCSVModal';
import { IUInvitee } from '../lib/event';
import Download from '../components/icons/Download';

import { DeviceQueries } from 'Common/src/components/styled';
import KebabMenu, { KebabMenuOption } from 'Common/src/components/KebabMenu';
import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent } from 'Common/src/api/SumoLogicApi';

import { TAppElkInviteStatus, TAppElkReviewStatus } from 'TProtocol/prototypes/events/messages';
import { shareEvent, ShareResult } from '../util/share';
import { Tooltip } from 'react-tooltip';
import { ShareTooltipContainer } from '../components/styled/CopyLinkTooltip';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../core/libs/Firebase';
import { DropdownContainer } from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';
import InviteFilters from '../components/invite/InviteFilters';
import { MobileWideOutlineButton, OutlineButton } from '../components/buttons/OutlineButton';

interface InvitePageState {
  fromCreate?: boolean;
}

const InvitePage = () => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const backgroundContext = useBackgroundContext();
  const modalContext = useModalContext();
  const butterBarContext = useButterBarContext();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation() as Location<InvitePageState>;
  const blocker = useBlocker(() => {
    return unsentInvites > 0;
  });

  const [invitees, setInvitees] = useState<IUInvitee[]>([]);
  const [inviteesByType, setInviteesByType] = useState(separateInvitees([]));
  const [filteredInvitees, setFilteredInvitees] = useState<IUInvitee[]>([]);
  const [unsentInvites, setUnsentInvites] = useState(0);
  const [showUserEmailModal, setShowUserEmailModal] = useState(false);
  const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Set<FilterType>>(new Set([FilterType.All]));
  const [showCopiedLinkTooltip, setShowCopiedLinkTooltip] = useState<boolean>(false);

  const event = eventId !== undefined ? eventCacheContext.getEvent(eventId) : undefined;

  useEffect(() => {
    return () => {
      modalContext.hide();
    }
  }, []);

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine Parties - Manage Invites - ${event.title}`;
      logEvent(firebaseAnalytics, 'page_view', {
        page_title: 'Manage Invites - Analytics'
      });

      backgroundContext.setColors(
        {
          colors: event?.colors,
          animationUrl: event.backgroundAnimationUrl,
          animationDisabled: event.videoBackgroundDisabled,
          subdued: true
        }
      );
      const newInvitees = sortInvitees(event.attendees);
      setInvitees(newInvitees);
      const pendingInvitees = newInvitees.filter(
        (invitee) => invitee.inviteStatus === TAppElkInviteStatus.PENDING
      ).length;
      setUnsentInvites(pendingInvitees);
    }
  }, [event]);

  useEffect(() => {
    if (eventId !== undefined) {
      void fetchEvent(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    setInviteesByType(separateInvitees(invitees));
    setFilteredInvitees(filterInvitees(invitees, activeFilters));
  }, [invitees, activeFilters]);

  useEffect(() => {
    if (showCopiedLinkTooltip) {
      const timerId = setTimeout(() => {
        setShowCopiedLinkTooltip(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopiedLinkTooltip]);

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[InvitePage] ${action}`
    });
  };

  const fetchEvent = async (eventId: string) => {
    const event = await eventCacheContext.fetchEvent({ eventId });
    if (event === undefined || !event.isHost) {
      navigate(`/event/${eventId}`);
    }
  };

  const createInvite = async (invitee: IUInvitee) => {
    if (event !== undefined) {
      logUserAction(`Create invite, email = ${invitee.email && md5(invitee.email)}`);

      invitees.unshift(invitee);
      setInvitees([...invitees]);
      event.attendees = invitees;
      eventCacheContext.setEvent(event);

      await queueInvites(userContext, event.id, [invitee.toTAppElkInvitee()]);

      butterBarContext.show({
        contents: `${invitee.name ?? 'Invitee'} added.`
      });
    }
  };

  const onUploadClick = () => {
    if (eventId) {
      modalContext.show({
        contents: <InviteUploadModal eventId={eventId} close={() => modalContext.hide()}
                                     onComplete={() => modalContext.hide()}/>
      });
    }
  };

  const onPasteClick = () => {
    modalContext.show({
      contents: <BulkEmailInviteModal onSubmit={onBulkEmailInviteSubmit} close={modalContext.hide}/>
    });
  };

  const onBulkEmailInviteSubmit = async (invitees: IUInvitee[]) => {
    if (event !== undefined) {
      const beforeCount = invitees.length;
      const newInvitees = removeDuplicateInvitees(invitees, event);
      logUserAction(`bulk email invite: ${newInvitees.length} (${beforeCount} before dedupe)`);

      if (newInvitees.length === 0) {
        butterBarContext.show({
          level: UButterBarLevel.WARN,
          contents: 'No new invites found in pasted text.'
        });
      } else {
        await queueInvites(userContext, event.id, newInvitees.map((invitee) => invitee.toTAppElkInvitee()));
      }
    }
  };

  const onShareClick = async () => {
    if (event !== undefined) {
      logUserAction('Share click');

      const shareResult = await shareEvent(userContext, event);
      if (shareResult === ShareResult.Clipboard) {
        setShowCopiedLinkTooltip(true);
      }
    }
  };

  const onChange = (invitee: IUInvitee) => {
    if (event) {
      void updateAttendance(userContext, event.id, invitee.toTAppElkInvitee(), undefined, undefined, undefined, false);
    }
  };

  const onDelete = (invitee: IUInvitee) => {
    modalContext.show({
      contents: <Modal title="Delete Invitee"
                       subtitle={`Are you sure you want to remove ${invitee.name ?? invitee.email} from the party?`}
                       primaryButtonLabel="Yes" secondaryButtonLabel="No"
                       onPrimaryClick={() => deleteInvitee(invitee.inviteeId)}
                       onSecondaryClick={modalContext.hide}
                       useSystemColors={true}/>
    });
  };

  const deleteInvitee = async (inviteId: string) => {
    if (event) {
      logUserAction(`Delete invitee: ${inviteId}`);
      const newInvitees = invitees.filter((attendee) => attendee.inviteeId !== inviteId);
      event.attendees = invitees;
      setInvitees(newInvitees);
      eventCacheContext.setEvent(event);

      modalContext.hide();

      await deletePendingInvites(userContext, event.id, [inviteId]);
    }
  };

  const onEdit = async (inviteeId: string) => {
    const invitee = invitees.find((invitee) => invitee.inviteeId === inviteeId);

    if (event && invitee) {
      modalContext.show({
        contents: <EditInviteeModal
          close={modalContext.hide}
          onSave={handleUpdate}
          event={event}
          invitee={invitee}
        />
      });
    }
  };

  const onSend = async (inviteeId: string) => {
    logUserAction(`Send invite: ${inviteeId}`);

    setSending(true);
    await sendInvites(userContext, eventId ?? '', [inviteeId]);
    butterBarContext.show({
      contents: 'Guest invited successfully.'
    });
    setSending(false);
  };

  const handleUpdate = async (invitee: IUInvitee) => {
    if (eventId) {
      invitees.forEach((inv) => {
        if (inv.inviteeId === invitee.inviteeId) {
          inv.name = invitee.name;
          inv.email = invitee.email;
          inv.rsvpStatus = invitee.rsvpStatus;
          inv.inviteStatus = invitee.inviteStatus;
        }
      });
      setInvitees([...invitees]);

      modalContext.hide();
      await editInvitee(userContext, eventId, invitee.toTAppElkInvitee());
    }
  };

  const handleSend = async () => {
    const inviteeIds: string[] = [];

    invitees.forEach((invitee) => {
      if (invitee.inviteStatus === TAppElkInviteStatus.PENDING) {
        inviteeIds.push(invitee.inviteeId);
        invitee.inviteStatus = TAppElkInviteStatus.TRIGGERED;
      }
    });

    logUserAction(`Send invites, count = ${inviteeIds.length}`);

    setInvitees([...invitees]);
    await sendInvites(userContext, eventId ?? '', inviteeIds);
    butterBarContext.show({
      contents: `${inviteeIds.length} guest${inviteeIds.length !== 1 ? 's' : ''} invited successfully.`
    });
  };

  const handleSendTest = async () => {
    setShowUserEmailModal(true);
  };

  const submitUserEmail = async (email: string) => {
    if (!eventId) {
      return;
    }
    setSending(true);

    logUserAction(`Sending template email to ${md5(email)}`);

    await sendTemplateEmail(userContext, eventId, email);
    butterBarContext.show({
      contents: `Invite sent to ${email}.`
    });

    setSending(false);
    setShowUserEmailModal(false);
  };

  const onBackClick = () => {
    logUserAction('Back click');

    navigate(`/event/${eventId}`);
  };

  const onDownload = async () => {
    if (event !== undefined) {
      setShowDownloadCSVModal(true);
    }
  };

  const onApprovalChange = async (invitee: IUInvitee, value?: TAppElkReviewStatus) => {
    if (event?.id && value !== undefined) {
      invitee.waitlistStatus = value;
      setInvitees([...invitees]);
      await reviewAttendee(userContext, event?.id, invitee.inviteeId, value, false);
    }
  };

  const onFilterChange = (filterType: FilterType, status: boolean) => {
    if (filterType === FilterType.All) {
      setActiveFilters(new Set([filterType]));
      return;
    } else if (status) {
      activeFilters.delete(FilterType.All);
      activeFilters.add(filterType);
    } else {
      activeFilters.delete(filterType);
      if (activeFilters.size === 0) {
        activeFilters.add(FilterType.All);
      }
    }
    setActiveFilters(new Set(activeFilters));
  };

  const onMenuClick = (id: string) => {
    if (id === 'import') {
      onUploadClick();
    } else if (id === 'paste') {
      onPasteClick();
    } else if (id === 'copyLink') {
      void onShareClick();
    } else if (id === 'download') {
      void onDownload();
    }
  };

  const menuOptions: KebabMenuOption[] = [
    {
      id: 'import',
      label: 'Import CSV',
      icon: <ChartSquareBarOutline size={20}/>
    },
    {
      id: 'paste',
      label: 'Paste list',
      icon: <AddList size={20}/>
    },
    {
      id: 'download',
      label: 'Download list',
      icon: <Download size={20}/>
    },
    {
      id: 'copyLink',
      label: 'Copy link',
      icon: <LinkIcon size={20}/>
    }
  ];


  let sendText: ReactNode;
  if (unsentInvites > 0) {
    sendText = <>Send to {unsentInvites} pending guest{unsentInvites === 1 ? '' : 's'}</>;
  } else {
    sendText = <>No pending invites</>;
  }

  let contactsContent: ReactNode;
  if ((userContext.contacts?.length ?? 0) > 0) {
    contactsContent = <ContactsMessage>
      Your {userContext.contacts?.length} contacts from Shine are available to search!
    </ContactsMessage>;
  } else {
    contactsContent = <ContactsMessage>
      To add your contacts, get <AppStoreA href="https://shi.ne/photos" target="_blank" rel="noreferrer">
      Shine</AppStoreA> on iOS or Android!
    </ContactsMessage>;
  }

  return <>
    {blocker.state === 'blocked' ? (
      <Modal title={`You have ${unsentInvites} unsent invite${unsentInvites !== 1 ? 's' : ''}`}
             subtitle={'Are you sure you want to leave?'}
             primaryButtonLabel={'Leave anyway'} secondaryButtonLabel={'Back to send'}
             onPrimaryClick={() => blocker.proceed()} onSecondaryClick={() => blocker.reset()}
             useSystemColors={true}/>
    ) : null}
    {showUserEmailModal ? <UserEmailModal
      onCancel={() => setShowUserEmailModal(false)}
      onSubmit={submitUserEmail}
      saving={sending}
    /> : null}
    {(showDownloadCSVModal && event !== undefined) ? <DownloadCSVModal
      event={event}
      onCancel={() => setShowDownloadCSVModal(false)}
    /> : null}
    <BasePageHeader page={UPageType.Invite}/>
    <BasePageContent $wide={true} $full={true} $centered={true}>
      <FramedPage>
        <FrameHeader>
          <FrameHeaderLeft>
            <BackArrowContainer onClick={onBackClick}>
              <LeftArrow/>
            </BackArrowContainer>
            <span>
              {invitees.length} total invited
            </span>
          </FrameHeaderLeft>
          <FrameHeaderRight>
            <TopActionContainer>
              <OutlineButton onClick={onUploadClick} $noMin={true}>
                <ChartSquareBarOutline/> Import CSV
              </OutlineButton>
              <OutlineButton onClick={onPasteClick} $noMin={true}>
                <AddList/> Paste list
              </OutlineButton>
              <OutlineButton onClick={onDownload} $noMin={true}>
                <Download/> Download list
              </OutlineButton>
              <ShareTooltipContainer>
                <Tooltip anchorSelect="#inviteCopyLink" place="top" className="copiedTooltip"
                         noArrow={true} isOpen={showCopiedLinkTooltip}>
                  Copied link!
                </Tooltip>
                <OutlineButton onClick={onShareClick} id={'inviteCopyLink'} $noMin={true}>
                  <LinkIcon size={20}/> Copy link
                </OutlineButton>
              </ShareTooltipContainer>
            </TopActionContainer>
            <TopMobileMenu>
              <KebabMenu options={menuOptions} onClick={onMenuClick}/>
            </TopMobileMenu>
          </FrameHeaderRight>
        </FrameHeader>

        <FrameBody>
          <DropdownContainer>
            <ContainerRow>
              <InviteFilters
                inviteesByType={inviteesByType}
                activeFilters={activeFilters}
                onFilterChange={onFilterChange}
              />
              <InviteForm
                placeholder="Invite guests by name or email"
                event={event}
                onCreate={createInvite}
              />
            </ContainerRow>
            <ContactsContent>
              {contactsContent}
            </ContactsContent>
          </DropdownContainer>

          <InviteTable
            event={event}
            invites={filteredInvitees}
            onChange={onChange}
            onDelete={onDelete}
            onEdit={onEdit}
            onSend={onSend}
            onApprovalChange={onApprovalChange}
            questions={event?.questions ?? []}
            sending={sending}
            fromCreate={!!location.state?.fromCreate}
            onSkip={onBackClick}
          />
        </FrameBody>
        <FrameFooter>
          <FooterButtonContainer>
            <MobileWideOutlineButton onClick={handleSendTest}>Send preview</MobileWideOutlineButton>
            <MobileWideWhiteButton $invert={true} onClick={handleSend} disabled={unsentInvites === 0 || sending}>{sendText}</MobileWideWhiteButton>
          </FooterButtonContainer>
        </FrameFooter>
      </FramedPage>
    </BasePageContent>
  </>;
};

export const BackArrowContainer = styled.div`
  cursor: pointer;
  width: 25px;
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

const TopActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

const TopMobileMenu = styled.div`
  display: none;

  @media (${DeviceQueries.mobile}) {
    display: flex;
  }
`;

const ContainerRow = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;

  @media (${DeviceQueries.mobile}) {
    display: block;
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
  }
`;

const ContactsContent = styled.div`
  margin: 10px 0;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
`;

const AppStoreA = styled.a`
  color: currentColor;
`;

const ContactsMessage = styled.div`
  color: rgba(var(--sp-text-rgb), 0.6);
`;

export default InvitePage;
