import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { formatStatus } from '../../util/attendee';
import SmallActionButton from '../buttons/SmallActionButton';
import { useUserContext } from '../../contexts/UserContext';
import AttendeeHostReply from './AttendeeHostReply';
import AttendeeCircle from './AttendeeCircle';
import Reply from '../icons/Reply';
import { IUInvitee } from '../../lib/event';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkMessage } from 'TProtocol/prototypes/events/messages';

const AttendeeCircleRow = ({
                             attendee,
                             responseMessages,
                             isInvited,
                             amIUnverified,
                             amIHost,
                             onVerifyClick,
                             onReplyClick,
                             readOnly
                           }: {
  attendee?: IUInvitee;
  responseMessages?: TAppElkMessage[];
  isInvited: boolean;
  amIUnverified?: boolean;
  amIHost: boolean;
  onVerifyClick: () => void;
  onReplyClick: (inviteeId: string) => void;
  readOnly: boolean;
}) => {
  const userContext = useUserContext();

  if (attendee === undefined) {
    return null;
  }

  const isMe = userContext.id === attendee.userId;

  let primaryContent: ReactNode = null;
  let secondaryContent: ReactNode = null;
  let tertiaryContent: ReactNode = null;

  if (isInvited) {
    tertiaryContent = formatStatus(attendee);

    primaryContent = <>
      {attendee.name || attendee.email}
    </>;
    secondaryContent = attendee.email && attendee.name ? <Message>{attendee.email}</Message> : null;
  } else {
    primaryContent = <>
      {attendee.name}
      {attendee?.role === TAppElkAttendeeRole.ORGANIZER ? ' (Host)' : ''}
      {attendee?.additionalGuestCount !== 0 && attendee?.rsvpStatus === TAppElkAttendeeStatus.YES ?
        ' (+' + attendee?.additionalGuestCount.toString() + ')' : ''}
    </>;
    secondaryContent = attendee?.rsvpMessage !== undefined && attendee?.rsvpMessage !== '' ?
      <Message>{attendee.rsvpMessage}</Message> : null;

    if (!readOnly) {
      if (amIHost && attendee.userId !== userContext.id) {
        tertiaryContent = <ReplyButton onClick={() => onReplyClick(attendee.inviteeId)}><Reply/></ReplyButton>;
      } else if (amIUnverified) {
        tertiaryContent = <SmallActionButton onClick={onVerifyClick} wide={true}>Verify my account</SmallActionButton>;
      }
    }
  }

  return <Row>
    <Container>
      <AttendeeContainer>
        <AttendeeCircle attendee={attendee} small={true} size={32}/>
        <Name>
          {
            secondaryContent !== null ? <>
              <PrimaryContainer>{primaryContent}</PrimaryContainer>
              <SecondaryContainer>{secondaryContent}</SecondaryContainer>
            </> : primaryContent
          }
        </Name>
        <StatusContainer>
          {tertiaryContent}
        </StatusContainer>
      </AttendeeContainer>
    </Container>
    {
      (responseMessages?.length ?? 0) > 0 ? <MessagesContainer>
        {responseMessages?.map((message) =>
          <AttendeeHostReply
            key={message.sentTime.toNumber()}
            message={message}
          />)}
        {
          amIHost && !isMe && !readOnly && <div onClick={() => onReplyClick(attendee.inviteeId)}>
            Reply to {attendee.name} (Hidden to other guests)
          </div>
        }
      </MessagesContainer> : null
    }
  </Row>;
};

const Row = styled.div`
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusContainer = styled.div`
  font-size: 16px;
  text-align: right;
`;

const AttendeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-wrap: anywhere;
  width: 100%;
  gap: 8px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: anywhere;
  //flex-grow: 1;
  color: rgba(var(--sp-text-rgb), 0.90);
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  font-style: italic;
`;

const MessagesContainer = styled.div`
  border-left: 3px solid rgba(var(--sp-text-rgb), 0.40);
  padding-left: 20px;
  margin: 3px 0 0 15px;
  box-sizing: border-box;
`;

const ReplyButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const PrimaryContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const SecondaryContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export default AttendeeCircleRow;
