import React from 'react';

const Dot = ({ color = 'currentColor', size = 8, opacity = 1 }: { color?: string, size?: number, opacity?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <circle cx="4.14284" cy="3.49888" r="3.42857" fill={color}/>
    </g>
  </svg>
;
};

export default Dot;
