import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { ExternalLabel } from '../forms/Input';

const AdditionalGuestsPicker = ({ additionalGuestCount, setGuestCount }: {
  additionalGuestCount: number,
  setGuestCount: (num: number) => void
}) => {

  const maxAdditionalGuestCount = 8; // Max guests attending = 9

  const handleIncrease = (e: MouseEvent) => {
    e.preventDefault();
    if (additionalGuestCount < maxAdditionalGuestCount) {
      setGuestCount(additionalGuestCount + 1);
    }
  };

  const handleDecrease = (e: MouseEvent) => {
    e.preventDefault();
    if (additionalGuestCount > 0) {
      setGuestCount(additionalGuestCount - 1);
    }
  };

  return (
    <ButtonWrapper>
      <ExternalLabel>Guests attending</ExternalLabel>
      <CounterWrapper>
        <CountActionButton onClick={handleDecrease} disabled={additionalGuestCount === 0}>&minus;</CountActionButton>
        <CountDisplay>{additionalGuestCount + 1}</CountDisplay>
        <CountActionButton onClick={handleIncrease} disabled={additionalGuestCount >= 9}>+</CountActionButton>
      </CounterWrapper>
    </ButtonWrapper>
  );
};

const CountActionButton = styled.button`
  border-radius: 12px;
  background: rgba(var(--sp-bg-rgb), 0.10);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
  border: none;
  height: 30px;
  width: 30px;
  padding: 5px 10px;
  cursor: pointer;
  color: currentColor;

  &:hover:not([disabled]) {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: .5;
  }
`;

const CountDisplay = styled.span`
  display: flex;
  width: 21px;
  height: 33px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(var(--sp-bg-rgb), 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
`;

const ButtonWrapper = styled.div`
  color: var(--sp-text-color);
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default AdditionalGuestsPicker;
