import React from 'react';
import styled from 'styled-components';

const FooterLinks = () => {
  return <FooterContainer>
    <div>Sunshine © All rights reserved</div>
    <LinkContainer>
      <span>
      <FooterLink href="https://about.sunshine.com/privacy-policy/" target="_blank"
                  rel="noreferrer">Privacy Policy</FooterLink>
    </span>
      <span>•</span>
      <span>
      <FooterLink href="https://about.sunshine.com/terms-of-service/" target="_blank"
                  rel="noreferrer">Terms of Service</FooterLink>
    </span>
      <span>•</span>
      <span>
      <FooterLink href="mailto:support@sunshine.com">Contact Support</FooterLink>
    </span>
    </LinkContainer>
  </FooterContainer>;
};

const FooterContainer = styled.div`
  font-size: 14px;
  padding: 50px 20px 20px 20px;
  text-align: center;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.5em;
`;

const FooterLink = styled.a`
  color: currentColor;
  text-decoration: none;
`;

export default FooterLinks;
