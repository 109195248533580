import React, { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { useEditEventContext } from '../../contexts/EditEventContext';
import { ModalContainer, Overlay } from './CommonModal';
import { IUEvent } from '../../lib/event';
import { getNormalizedPhotoUrl } from '../../lib/images';
import { getFilteredInspirationsByType, InspirationIdea, InspirationTheme } from '../../lib/inspiration';
import XCircle from '../icons/XCircle';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  close: () => void;
  event: IUEvent;
}

const InspirationGalleryModal = ({ close, event }: Props) => {
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();

  const [inspirationsMap, setInspirationsMap] = useState<Map<InspirationTheme, InspirationIdea[]>>(new Map());

  useEffect(() => {
    void fetchInspirations();
  }, [event.suggestionTags]);

  const fetchInspirations = async () => {
    if (event.suggestionTags === undefined) {
      setInspirationsMap(new Map());
    } else {
      setInspirationsMap(await getFilteredInspirationsByType(event.suggestionTags));
    }
  }

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onImageClick = (imageUrl: string) => {
    close();
    editEventContext.setPhotoUrl(imageUrl);
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[InspirationGalleryModal] Closed modal'
    });

    close();
  };

  return <Overlay onMouseDown={onClose}>
    <ModalContainer onMouseDown={onModalClick} $wide={true} $maxWidth="800px">
      <Header>
        <HeaderText>
          Inspiration Gallery
        </HeaderText>
        <CloseIconWrapper onClick={onClose}>
          <XCircle/>
        </CloseIconWrapper>
      </Header>

      <EventImagesContainer>
        {
          Array.from(inspirationsMap.keys()).map((category) => {
            return <div key={category}>
              <CategoryTitle key={`${category}-title`}>{category}</CategoryTitle>
              <CategoryContainer key={`${category}-images`}>
              {
                inspirationsMap.get(category)?.map((inspiration) => <EventImageContainer
                  key={inspiration.id} onClick={() => onImageClick(inspiration.photoUrl)}
                >
                  <EventImage src={getNormalizedPhotoUrl(inspiration.photoUrl)} width="150" height="150"/>
                </EventImageContainer>)
              }
            </CategoryContainer>
            </div>;
          })
        }
      </EventImagesContainer>
    </ModalContainer>
  </Overlay>;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  color: rgba(var(--sp-text-rgb), 0.70);
  font-size: 20px;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  @media (${DeviceQueries.mobile}) {
    gap: 12px;
  }
`;

const CategoryTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const EventImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
`;

const EventImageContainer = styled.div`
  border-radius: 7px;
  border: 1px solid #000;
  height: 150px;
`;

const EventImage = styled.img`
  border-radius: 6px;
`;

export default InspirationGalleryModal;
