import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CodeType } from '../../lib/login';
import { useUserContext } from '../../contexts/UserContext';

import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent } from 'Common/src/api/SumoLogicApi';

interface Props {
  disabled: boolean;
  error: string;
  codeType: CodeType;
  onConfirm: (code: string, type: CodeType) => void;
  onResend: () => void;
}

const ConfirmCode = ({ disabled, error, codeType, onConfirm, onResend }: Props) => {
  const userContext = useUserContext();
  const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);
  const [codeTimer, setCodeTimer] = useState<number | undefined>();
  const [codeResendTimestamp, setCodeResendTimestamp] = useState<number | undefined>(undefined);
  const [codeSendCount, setCodeSendCount] = useState(1);
  const interval = useRef<number | null>(null);

  useEffect(() => {
    if (interval.current !== null) {
      window.clearInterval(interval.current);
    }
    if (codeResendTimestamp !== undefined) {
      const calculateCodeTimer = () => {
        const diff = Math.floor((Date.now() - codeResendTimestamp) / 1000);
        if (diff < 30) {
          setCodeTimer(30 - diff);
        } else {
          setCodeTimer(undefined);
        }
      };
      interval.current = window.setInterval(calculateCodeTimer, 1000);
      calculateCodeTimer();
    }

    return () => {
      if (interval.current !== null) {
        window.clearInterval(interval.current);
      }
    };
  }, [codeResendTimestamp]);

  const codeInputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[EventForm] ${action}`
    });
  };

  const handleConfirm = () => {
    logUserAction('Confirm code');

    onConfirm(code.join(''), codeType);
  };

  const handleResend = () => {
    logUserAction('Resend code');

    codeInputRefs.forEach((ref) => {
      const ele = ref.current;
      if (ele) {
        ele.value = '';
      }
    });
    setCode(['', '', '', '', '', '']);

    if (codeTimer === undefined) {
      setCodeSendCount(codeSendCount + 1);
      setCodeResendTimestamp(Date.now());
      onResend();
    }
  };

  useEffect(() => {
    codeInputRefs.forEach((ref, index) => {
      const ele = ref.current;

      if (ele) {
        ele.addEventListener('keydown', (e: KeyboardEvent) => {
          if (e.key === 'Backspace' && ele.value === '') {
            codeInputRefs[Math.max(0, index - 1)].current?.focus();
          }
        });

        ele.addEventListener('input', (e: Event) => {
          const [first, ...rest] = (e.currentTarget as HTMLInputElement)?.value || '';
          code[index] = ele.value = first ?? '';
          setCode([...code]);

          const lastInputBox = index === 5;
          const didInsertContent = first !== undefined;
          if (didInsertContent) {
            if (lastInputBox) {
              handleConfirm();
            } else {
              const nextEle = codeInputRefs[index + 1].current;
              if (nextEle) {
                nextEle.focus();
                nextEle.value = rest.join('');
                nextEle.dispatchEvent(new Event('input'));
              }
            }
          }
        });

        ele.addEventListener('focus', () => {
          for (let i = 0; i < index; i++) {
            if (codeInputRefs[i].current?.value === '') {
              codeInputRefs[i].current?.focus();
              break;
            }
          }
        });
      }
    });

    codeInputRefs[0].current?.focus();

    // Don't make the dependency list [codeInputRefs]. Really. Don't do it. It'll blow up.
  }, [codeInputRefs[0], codeInputRefs[1], codeInputRefs[2], codeInputRefs[3], codeInputRefs[4], codeInputRefs[5]]);

  return <>
    <LoginCodeInput>
      <CodeBox name="code" autoComplete="one-time-code" required ref={codeInputRefs[0]} disabled={disabled}/>
      <CodeBox name="code" required ref={codeInputRefs[1]} disabled={disabled}/>
      <CodeBox name="code" required ref={codeInputRefs[2]} disabled={disabled}/>
      <CodeBox name="code" required ref={codeInputRefs[3]} disabled={disabled}/>
      <CodeBox name="code" required ref={codeInputRefs[4]} disabled={disabled}/>
      <CodeBox name="code" required ref={codeInputRefs[5]} disabled={disabled}/>
    </LoginCodeInput>
    {
      error && <ErrorContainer>
        <span>{error}</span>
      </ErrorContainer>
    }
    <LoginSecondaryButtonText $disabled={codeTimer !== undefined} onClick={handleResend}>
      {codeTimer === undefined ? 'Resend Code' : `Resend Code in ${('' + codeTimer).padStart(2, '0')}s`}
    </LoginSecondaryButtonText>
    {/* <SingleFieldError error={error}/> */}
  </>;
};

const CodeBox = styled.input.attrs({
  type: 'text', // Change the input type to text
  inputMode: 'numeric', // Set the input mode to numeric
})`
  padding: 6px 10px;
  font-size: 16px;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  height: 39px;
  width: 39px;
  border: 0.5px solid rgba(var(--sp-text-rgb), 0.40);

  &:focus {
    outline: 0.5px solid #000;
    border: 0.5px solid #000;
  }
`;

const LoginCodeInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  justify-content: space-between;
  max-width: 276px;
`;

const LoginSecondaryButtonText = styled.div<{ $disabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: var(--sp-text-color);
  margin-top: 8px;
  ${({ $disabled }) => $disabled ? 'opacity: 0.65;' : 'cursor: pointer;'}
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: 6px;
  color: #B3251E;
  font-size: 12px;
  align-items: center;
`;

export default ConfirmCode;
