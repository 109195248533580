import React, { ChangeEvent, MouseEvent, useState } from 'react';
import styled from 'styled-components';

import { ModalHeader, ModalTitle, Overlay, SystemModalContainer } from './CommonModal';
import { parseBulkEmails } from '../../util/email';
import { useUserContext } from '../../contexts/UserContext';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import { Textarea } from '../forms/Input';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';
import { IUInvitee } from '../../lib/event';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { WideWhiteButton } from '../buttons/WhiteButton';

interface Props {
  close: () => void;
  onSubmit: (invitees: IUInvitee[]) => void;
}

const BulkEmailInviteModal = ({ close, onSubmit }: Props) => {
  const userContext = useUserContext();

  const [bulkEmails, setBulkEmails] = useState('');
  const [invalidEmails, setInvalidEmails] = useState(false);

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onSubmitClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[BulkEmailInviteModal] Bulk inviting ${bulkEmails.length} emails`
    });

    const invitees = parseBulkEmails(bulkEmails);
    if (invitees === null) {
      setInvalidEmails(true);
      return;
    }

    onSubmit(invitees);
    close();
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[BulkEmailInviteModal] Closed modal'
    });

    close();
  };

  return <Overlay onMouseDown={onClose}>
    <SystemModalContainer onMouseDown={onModalClick}>
      <ModalHeader>
        <BackArrowContainer onClick={onClose}>
          <BackArrow/>
        </BackArrowContainer>
        <ModalTitle>Add email addresses</ModalTitle>
      </ModalHeader>

      <form>
        <Textarea rows={5} value={bulkEmails} $invalid={invalidEmails}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setBulkEmails(e.target.value)}
                  placeholder={'Comma separated list of emails, e.g. stella@sunshine.com, Luna Eclipse <luna@sunshine.com>'}/>
        {invalidEmails && <EmailError>
            Please enter valid email addresses.
        </EmailError>
        }

        <Footer>
          <WideWhiteButton $invert={true} onClick={onSubmitClick}>Confirm</WideWhiteButton>
        </Footer>
      </form>
    </SystemModalContainer>
  </Overlay>;
};

const EmailError = styled.div`
  background-color: black;
  padding: 5px;
  border-radius: 6px;
  color: #cc6666;
`;

const Footer = styled.div`
  margin-top: 30px;

  ${ButtonInternal} {
    width: 100%;
  }
`;

export default BulkEmailInviteModal;
