import React from 'react';

const LinkIcon = ({ color = 'currentColor', size = 25 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 24.4922C10.8516 24.4922 9.30469 24.1797 7.85938 23.5547C6.41406 22.9375 5.14453 22.082 4.05078 20.9883C2.95703 19.8867 2.09766 18.6172 1.47266 17.1797C0.855469 15.7344 0.546875 14.1875 0.546875 12.5391C0.546875 10.8906 0.855469 9.34375 1.47266 7.89844C2.09766 6.45312 2.95703 5.18359 4.05078 4.08984C5.14453 2.99609 6.41406 2.14063 7.85938 1.52344C9.30469 0.898438 10.8516 0.585938 12.5 0.585938C14.1484 0.585938 15.6953 0.898438 17.1406 1.52344C18.5859 2.14063 19.8555 2.99609 20.9492 4.08984C22.043 5.18359 22.8984 6.45312 23.5156 7.89844C24.1406 9.34375 24.4531 10.8906 24.4531 12.5391C24.4531 14.1875 24.1406 15.7344 23.5156 17.1797C22.8984 18.6172 22.043 19.8867 20.9492 20.9883C19.8555 22.082 18.5859 22.9375 17.1406 23.5547C15.6953 24.1797 14.1484 24.4922 12.5 24.4922ZM8.5625 17.4609C8.84375 17.4609 9.08203 17.3672 9.27734 17.1797L12.5117 13.9219L15.7578 17.1797C15.9375 17.3672 16.168 17.4609 16.4492 17.4609C16.7227 17.4609 16.9531 17.3672 17.1406 17.1797C17.3281 16.9844 17.4219 16.7539 17.4219 16.4883C17.4219 16.1992 17.3281 15.9688 17.1406 15.7969L13.8828 12.5508L17.1523 9.29297C17.3398 9.09766 17.4336 8.86719 17.4336 8.60156C17.4336 8.33594 17.3398 8.10938 17.1523 7.92188C16.9648 7.73438 16.7383 7.64062 16.4727 7.64062C16.2148 7.64062 15.9922 7.73438 15.8047 7.92188L12.5117 11.1914L9.24219 7.93359C9.04688 7.75391 8.82031 7.66406 8.5625 7.66406C8.29688 7.66406 8.07031 7.75781 7.88281 7.94531C7.69531 8.125 7.60156 8.35156 7.60156 8.625C7.60156 8.88281 7.69531 9.10938 7.88281 9.30469L11.1406 12.5508L7.88281 15.8086C7.69531 15.9961 7.60156 16.2227 7.60156 16.4883C7.60156 16.7539 7.69531 16.9844 7.88281 17.1797C8.07031 17.3672 8.29688 17.4609 8.5625 17.4609Z"
      fill={color}/>
  </svg>;
};

export default LinkIcon;
