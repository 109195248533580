import React from 'react';

const LinkIcon = ({ color = 'currentColor', size = 25 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.0398 4.37179C18.5754 2.90732 16.201 2.90732 14.7365 4.37179L10.2365 8.87179C8.77207 10.3363 8.77207 12.7106 10.2365 14.1751C10.5474 14.486 10.898 14.73 11.2712 14.9083C11.645 15.0868 11.8032 15.5345 11.6247 15.9083C11.4461 16.2821 10.9984 16.4403 10.6246 16.2618C10.0999 16.0111 9.60881 15.6687 9.17587 15.2357C7.12562 13.1855 7.12562 9.86138 9.17587 7.81113L13.6759 3.31113C15.7261 1.26087 19.0502 1.26087 21.1005 3.31113C23.1507 5.36138 23.1507 8.6855 21.1005 10.7357L19.3435 12.4927C19.0506 12.7856 18.5758 12.7856 18.2829 12.4927C17.99 12.1998 17.99 11.725 18.2829 11.4321L20.0398 9.67509C21.5043 8.21062 21.5043 5.83625 20.0398 4.37179ZM12.6517 8.63857C12.8302 8.26481 13.278 8.10656 13.6517 8.28511C14.1765 8.53579 14.6676 8.87819 15.1005 9.31113C17.1507 11.3614 17.1507 14.6855 15.1005 16.7357L10.6005 21.2357C8.55024 23.286 5.22613 23.286 3.17587 21.2357C1.12562 19.1855 1.12562 15.8614 3.17587 13.8111L4.93284 12.0542C5.22574 11.7613 5.70061 11.7613 5.9935 12.0542C6.2864 12.347 6.2864 12.8219 5.9935 13.1148L4.23653 14.8718C2.77207 16.3363 2.77207 18.7106 4.23653 20.1751C5.701 21.6396 8.07537 21.6396 9.53983 20.1751L14.0398 15.6751C15.5043 14.2106 15.5043 11.8363 14.0398 10.3718C13.729 10.0609 13.3784 9.81691 13.0052 9.63861C12.6314 9.46006 12.4732 9.01233 12.6517 8.63857Z"
          fill={color}/>
  </svg>;
};

export default LinkIcon;
