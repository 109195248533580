import { Int64 } from 'thrift';
import {v4 as uuidv4} from 'uuid';

import Net, { createUnauthHeader } from 'Common/src/lib/Net';
import {augmentService, NetworkErrorException, RateLimitException} from 'Common/src/lib/ThriftServiceAugmentor';

import {TAuthenticationService} from 'TProtocol/authentication';
import {
  TAppPii,
  TAuthenticateRequest,
  TAuthenticateResponse, TRefetchAccessTokenRequest, TRefetchAccessTokenResponse,
  TSendEmailSignInCodeRequest,
  TSendEmailSignInCodeResponse,
  TSendPhoneSignInCodeRequest,
  TSendPhoneSignInCodeResponse,
  TSendPiiVerificationCodeRequest,
  TSignInWithEmailAndCodeRequest, TSignInWithPhoneAndCodeRequest, TVerifyWithPiiCodeRequest
} from 'TProtocol/authentication/messages';
import {TAppProduct, TAuthHeader} from 'TProtocol/common/models';
import {TAppPlatform} from 'TProtocol/appversion';
import {TUpdateUserMetadataRequest, TUpdateUserMetadataResponse} from 'TProtocol/user/messages';
import {TUserService} from 'TProtocol/user';
import {TAuthFailed} from 'TProtocol/common/exceptions';


const createService = (): TAuthenticationService.Client => {
  return augmentService(Net.createService(TAuthenticationService, '/json/AuthenticationService'));
};

const createUserService = (): TUserService.Client => {
  return augmentService(Net.createService(TUserService, '/json/UserService'));
};

interface AuthenticateProps {
  deviceId: string;
  verifiedPhoneNumber?: string;
  verifiedEmailAddress?: string;
  firebaseIDToken: string;
  product: TAppProduct;
  requestId?: string;
}

export const authenticate = async ({
  deviceId,
  verifiedPhoneNumber,
  verifiedEmailAddress,
  firebaseIDToken,
  product,
  requestId = uuidv4()
  }: AuthenticateProps): Promise<TAuthenticateResponse> => {
  const request = new TAuthenticateRequest({
    deviceId,
    verifiedPhoneNumber,
    verifiedEmailAddress,
    firebaseIDToken,
    timestamp: new Int64(Date.now()),
    product,
    platform: TAppPlatform.WEB
  })

  // `${isBlocking === false ? 'nonblocking-' : 'blocking-'}${activeEvent?.requestId}`,
  return createService().authenticate(request, createUnauthHeader(product, requestId));
}

export const refetchAccessToken = async (
  userId: string,
  deviceId: string,
  refreshToken: string
): Promise<TRefetchAccessTokenResponse> => {
  const request = new TRefetchAccessTokenRequest({
    userId,
    deviceId,
    refreshToken,
    timestamp: new Int64(Date.now())
  });
  return createService().refetchAccessToken(request);
}

export const updateUserMetadata = async (
  header: TAuthHeader,
  notificationToken: string,
): Promise<TUpdateUserMetadataResponse> => {
  const request = new TUpdateUserMetadataRequest({notificationToken});
  return createUserService().updateUserMetadata(header, request);
}

export const sendEmailSignInCode = async (
  product: TAppProduct,
  emailAddress: string
) => {
  const request = new TSendEmailSignInCodeRequest({emailAddress: emailAddress})
  return createService().sendEmailSignInCode(createUnauthHeader(product), request).catch((e) => {
    if (e instanceof NetworkErrorException || e instanceof RateLimitException) {
      return new TSendEmailSignInCodeResponse({success: false, errorMessage: e.getErrorMessage()})
    } else if (e instanceof TAuthFailed) {
      return new TSendEmailSignInCodeResponse({success: false, errorMessage: e.reason})
    } else {
      return new TSendEmailSignInCodeResponse({success: false, errorMessage: 'Unknown error'})
    }
  });
}

export const signInWithEmailAndCode = async (
  product: TAppProduct,
  emailAddress: string,
  code: string
) => {
  const request = new TSignInWithEmailAndCodeRequest({
    emailAddress: emailAddress,
    code: code
  })
  return createService().signInWithEmailAndCode(createUnauthHeader(product), request);
}

export const sendPhoneSignInCode = async (
  product: TAppProduct,
  phoneNumber: string
): Promise<TSendPhoneSignInCodeResponse> => {
  const request = new TSendPhoneSignInCodeRequest({phoneNumber: phoneNumber})
  return createService().sendPhoneSignInCode(createUnauthHeader(product), request).catch((e) => {
    if (e instanceof NetworkErrorException || e instanceof RateLimitException) {
      return new TSendPhoneSignInCodeResponse({success: false, errorMessage: e.getErrorMessage()})
    } else if (e instanceof TAuthFailed) {
      return new TSendPhoneSignInCodeResponse({success: false, errorMessage: e.reason})
    } else {
      return new TSendPhoneSignInCodeResponse({success: false, errorMessage: 'Unknown error'})
    }
  });
}

export const signInWithPhoneAndCode = async (
  product: TAppProduct,
  phoneNumber: string,
  code: string
) => {
  const request = new TSignInWithPhoneAndCodeRequest({
    phoneNumber: phoneNumber,
    code: code
  })
  return createService().signInWithPhoneAndCode(createUnauthHeader(product), request);
}

export const sendPiiVerificationCode = async (
  authHeader: TAuthHeader,
  pii: TAppPii
) => {
  const request = new TSendPiiVerificationCodeRequest({
    pii
  });
  return createService().sendPiiVerificationCode(authHeader, request);
};

export const verifyWithPiiAndCode = async (
  authHeader: TAuthHeader,
  pii: TAppPii,
  code: string
) => {
  const request = new TVerifyWithPiiCodeRequest({
    pii,
    code
  });
  return createService().verifyWithPiiAndCode(authHeader, request);
};
