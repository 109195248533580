import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import md5 from 'md5';

import { ModalContent, ModalHeader, ModalTitle, Overlay, SystemModalContainer } from './CommonModal';
import EventFormInput from '../EventForm/EventFormInput';
import { emailExists, validateEmail } from '../../util/email';
import { IUEvent, IUInvitee } from '../../lib/event';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';
import Dropdown, { DropdownItem } from '../Dropdown';
import { TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';
import { Container, ExternalLabel } from '../forms/Input';
import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../../contexts/UserContext';

enum Option {
  Unsent = 1,
  Sent,
  Yes,
  No
}

const Options: { id: Option, rsvpStatus: TAppElkAttendeeStatus, inviteStatus?: TAppElkInviteStatus }[] = [
  {
    id: Option.Unsent,
    rsvpStatus: TAppElkAttendeeStatus.INVITED
  },
  {
    id: Option.Sent,
    rsvpStatus: TAppElkAttendeeStatus.INVITED
  },
  {
    id: Option.Yes,
    rsvpStatus: TAppElkAttendeeStatus.YES
  },
  {
    id: Option.No,
    rsvpStatus: TAppElkAttendeeStatus.NO
  }
];

const defaultOption = (invitee: IUInvitee): Option => {
  if (invitee.rsvpStatus === TAppElkAttendeeStatus.YES) {
    return Option.Yes;
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.NO) {
    return Option.No;
  } else if (invitee.inviteStatus === TAppElkInviteStatus.PENDING) {
    return Option.Unsent;
  }
  return Option.Sent;
};

const STATUS_VALUES: DropdownItem<Option>[] = [
  {
    id: Option.Unsent,
    label: 'Unsent',
    displayOnly: true
  },
  // Not working at the moment.  Setting to Sent turns into Unsent in the backend.  Leave as display Only.
  {
    id: Option.Sent,
    label: 'Sent',
    displayOnly: true
  },
  {
    id: Option.Yes,
    label: 'Yes'
  },
  {
    id: Option.No,
    label: 'No'
  }
];

interface Props {
  close: () => void;
  onSave: (invitee: IUInvitee) => void;
  event: IUEvent;
  invitee: IUInvitee;
  emailRequired?: boolean;
}

const EditInviteeModal = ({ close, onSave, event, invitee, emailRequired = true }: Props) => {
  const userContext = useUserContext();
  const [name, setName] = useState(invitee.name);
  const [email, setEmail] = useState(invitee.email);
  const [option, setOption] = useState<Option>(defaultOption(invitee));
  const [error, setError] = useState('');

  const showEmail = emailRequired && invitee.userId === undefined;

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[EditInviteeModal] ${action}`
    });
  };

  const onUpdateClick = () => {
    if (!name) {
      logUserAction('Invalid name');
      setError('Please enter a valid name.');
      return;
    }

    if (showEmail && (!email || !validateEmail(email))) {
      logUserAction('Invalid email');
      setError('Please enter a valid email.');
      return;
    }

    if (email !== invitee.email && emailExists(email, event)) {
      logUserAction(`Email exists: ${email && md5(email)}`);
      setError('Email has already been invited.');
      return;
    }
    logUserAction('Update valid -- adding');

    const optionConfig = Options.find((o) => o.id === option);
    if (optionConfig === undefined) {
      return;
    }

    onSave(new IUInvitee({
      ...invitee,
      name,
      email,
      rsvpStatus: optionConfig.rsvpStatus
    }));
  };

  return (
    <Overlay onMouseDown={close}>
      <ModalContainer onMouseDown={onModalClick}>
        <ModalContent>
          <ModalHeader>
            <BackArrowContainer onClick={close}>
              <BackArrow/>
            </BackArrowContainer>
            <ModalTitle>Edit invitee</ModalTitle>
          </ModalHeader>
          <InviteeForm>
            <EventFormInput
              label="Guest name"
              value={name ?? ''}
              onChange={setName}
              maxLength={128}
              inModal={true}
              isLabelOutside={true}
              excludeBackground={true}
              noVertMargin={true}
            />
            {emailRequired &&
              <EventFormInput
                label="Email"
                value={showEmail ? (email ?? '') : 'Connected through Shine'}
                disabled={!showEmail}
                onChange={setEmail}
                maxLength={128}
                inModal={true}
                isLabelOutside={true}
                excludeBackground={true}
                noVertMargin={true}
              />
            }
            <Container $excludeBackground={true} $noVertMargin={true}>
              <ExternalLabel>RSVP Status</ExternalLabel>
              <Dropdown
                values={STATUS_VALUES}
                value={option}
                onChange={setOption}
              />
            </Container>
            {error === '' ? null : <InviteError>{error}</InviteError>}
          </InviteeForm>
        </ModalContent>

        <Footer>
          <SystemButton secondary={true} onClick={close}>Cancel</SystemButton>
          <SystemButton onClick={onUpdateClick}>Save</SystemButton>
        </Footer>
      </ModalContainer>
    </Overlay>
  );
};

const ModalContainer = styled(SystemModalContainer)`
  overflow-y: visible;
`;

const InviteeForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InviteError = styled.div`
  color: var(--shine-system-error-color);
  padding: 5px 10px;
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;

  ${ButtonInternal} {
    width: 100%;
  }
`;

const ConnectedThrough = styled.span`
  color: rgba(var(--sp-text-rgb), 0.30);
`;


export default EditInviteeModal;

