import logRpc from '../util/logRpc';

import Net from 'Common/src/lib/Net';
import { augmentService } from 'Common/src/lib/ThriftServiceAugmentor';
import { TAppPii } from 'TProtocol/authentication/messages';

import { TAuthHeader } from 'TProtocol/common/models';
import { TUserService } from 'TProtocol/user';
import { TConfirmUserMergeRequest, TOptOutRequest, TOptOutResponse } from 'TProtocol/user/messages';

const createUserService = (): TUserService.Client => {
  return augmentService(Net.createService(TUserService, '/json/UserService'));
};

export const optOut = async (header: TAuthHeader): Promise<TOptOutResponse> => {
  logRpc(null, 'optOut');
  const request: TOptOutRequest = new TOptOutRequest();
  const client: TUserService.Client = createUserService();
  const response = client.optOut(header, request);
  logRpc(null, 'optOut', true);
  return response;
};

export const confirmUserMerge = async (
  authHeader: TAuthHeader,
  pii: TAppPii[]
) => {
  logRpc(null, 'confirmUserMerge');
  const request = new TConfirmUserMergeRequest({
    pii
  });
  const response = await createUserService().confirmUserMerge(authHeader, request);
  logRpc(null, 'confirmUserMerge', true);
  return response;
};
