import styled from 'styled-components';

export const ShareTooltipContainer = styled.div`
  display: contents;
  word-break: normal;

  .copiedTooltip {
    border-radius: 10px;
    background-color: rgba(var(--sp-text-rgb), 0.90);
    color: #000;
    font-size: 16px;
    font-weight: 400;
    z-index: 200;
  }
`;
