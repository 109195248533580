import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronUp from '../icons/ChevronUp';
import ChevronDown from '../icons/ChevronDown';

export interface HeaderDropdownItem {
  value: string;
  display: string;
}

const HeaderDropdown = ({ value, items, onSelect }: {
  value: string,
  items: HeaderDropdownItem[],
  onSelect: (key: string) => void
}) => {
  const [open, setOpen] = useState(false);

  const currentItem = items.find((item) => item.value === value);
  const otherItems = items.filter(item => item.value !== value);

  if (currentItem === undefined) {
    return <div/>;
  }

  const onItemClick = (item: HeaderDropdownItem) => {
    setOpen(false);
    onSelect(item.value);
  };

  return <Wrapper>
    {open && <Dropdown>
      {
        otherItems.map((item) => <DropdownItem key={item.value} onClick={() => onItemClick(item)} title={item.display}>
          {item.display}
        </DropdownItem>)
      }
    </Dropdown>}
    <ItemName>
      {currentItem.display}
    </ItemName>
    <More onClick={() => setOpen(!open)}>{open ? <>More <ChevronUp/></> : <>More <ChevronDown/></>}</More>
  </Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
`;

const ItemName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 45px;
  left: -3px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: normal;

  border-radius: 10px;
  border: 1px solid rgba(var(--sp-text-rgb), 0.10);
  background: var(--sp-bg-color);
  box-shadow: 0 0 10px 0 rgba(var(--sp-text-rgb), 0.10);
`;

const DropdownItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
`;

const More = styled.span`
  color: rgba(var(--sp-text-rgb), 0.40);
  margin-left: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export default HeaderDropdown;
