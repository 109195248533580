import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkMessage } from 'TProtocol/prototypes/events/messages';
import MessageText from './MessageText';
import { calculateColor, parseName } from '../../util/attendee';
import { IUInvitee } from '../../lib/event';
import { DeviceQueries } from 'Common/src/components/styled';


const dateFormat = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' });
const timeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

const MessageRow = ({ message }: { message?: TAppElkMessage }) => {

  if (message === undefined) {
    return null;
  }

  const [firstName, lastName] = parseName(message.senderProfile.name);

  const color = calculateColor(new IUInvitee(
    {
      userId: message.senderProfile.userId,
      name: message.senderProfile.name,
      rsvpStatus: TAppElkAttendeeStatus.YES,
      role: TAppElkAttendeeRole.ORGANIZER,
      additionalGuestCount: 0,
      inviteeId: ''
    }));

  let circle: ReactNode;
  const photoUrl = message.senderProfile.photoUrl;
  if (photoUrl) {
    circle = <Circle $color={color}>
      <img src={photoUrl} alt={`Profile of ${message.senderProfile.name}`}/>
    </Circle>;
  } else {
    circle = <Circle $color={color}>
      {firstName?.[0]?.toUpperCase()}{lastName?.[0]?.toUpperCase()}
    </Circle>;
  }

  const sentTime = message?.sentTime.toNumber();
  return <Container>
    <Header>
      {circle}
      <div>
        <Name>
          {message?.senderProfile.name}{' (Host)'}
        </Name>
        <SentTime>
          sent on {dateFormat.format(sentTime)} at {timeFormat.format(sentTime)}
        </SentTime>
      </div>
    </Header>
    <Message>
      <MessageText message={message?.message}/>
    </Message>
  </Container>;
};

const Container = styled.div`
  padding: 15px 0;
  border-top: 1px solid #fff;

  &:first-of-type {
    border-top: 0;
  }
`;

const Header = styled.div`
  display: flex;
  margin: 10px 0;
`;

const Circle = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 45px;
  height: 45px;
  border-radius: 22.5px;

  font-size: 24px;
  font-weight: 500;
  line-height: 1.95;
  text-align: center;
  color: #fff;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const Name = styled.div`
  margin-top: 5px;
  font-size: 18px;

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
  }
`;

const SentTime = styled.div`
  font-size: 14px;
  opacity: .5;
`;

const Message = styled.div`
`;

export default MessageRow;
