import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { WhiteButton } from '../buttons/WhiteButton';
import PlusCircleOutline from '../icons/PlusCircleOutline';

import { DeviceQueries } from 'Common/src/components/styled';

const CreateEventPromo = () => {
  const navigate = useNavigate();

  const onCreateClick = () => {
    navigate('/instant');
  };

  return <>
    <Container>
      <ContentContainer>
        <TextContainer>
          You have no upcoming parties. Host one!
        </TextContainer>
        <WhiteButton onClick={onCreateClick}>
          <PlusCircleOutline/>
          <CreateText>Create party</CreateText>
        </WhiteButton>
      </ContentContainer>
    </Container>
  </>;
};


export default CreateEventPromo;

const Container = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 311px;
  height: 311px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 10px;
  border: 2px solid rgba(var(--sp-text-rgb), 0.20);
  box-shadow: 0 0 10px 0 rgba(var(--sp-text-rgb), 0.10);
  background: linear-gradient(0deg, rgba(var(--sp-text-rgb), 0.60) 0%, rgba(var(--sp-text-rgb), 0.60) 100%);

  @media (${DeviceQueries.mobile}) {
    width: 242px;
    height: 242px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const TextContainer = styled.div`
  color: #FFFFFF;
  text-align: center;
  font-size: 22px;
  font-weight: 500;

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
  }
`;

const CreateText = styled.div`
  color: var(--sp-text-color);
  font-size: 18px;
  font-weight: 400;

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
  }
`;
