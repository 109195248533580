import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Dot from './icons/Dot';
import LeftPaginationArrow from './icons/LeftPaginationArrow';
import RightPaginationArrow from './icons/RightPaginationArrow';
import { DesktopShow, MobileShow } from './styled/Common';

import { DeviceQueries } from 'Common/src/components/styled';

export interface SlideshowDatum {
  content: React.ReactNode;
  delay: number;
}

const Slideshow = ({ slidesData }: { slidesData: SlideshowDatum[] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slidesData.length;
  const slideTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    startSlideshow();
    return () => clearInterval(slideTimeout.current); // Clean up on unmount
  }, [currentSlide]);

  const startSlideshow = () => {
    clearInterval(slideTimeout.current); // Clear previous interval
    slideTimeout.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides); // Loop through slides
    }, slidesData[currentSlide].delay);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  return (
    <SlideshowContainer>
      <SlideshowWrapper>
        {slidesData.map((slide, index) => (
          <Slide
            key={index}
            $active={index === currentSlide}
            $exitLeft={index === currentSlide - 1 || (currentSlide === 0 && index === totalSlides - 1)}
            $enterRight={index === (currentSlide + 1) % totalSlides}
          >
            {slide.content}
          </Slide>
        ))}
      </SlideshowWrapper>

      <SlideshowNavigation>
        <ArrowButton onClick={goToPrevSlide}>
          <DesktopShow><LeftPaginationArrow size={19}/></DesktopShow>
          <MobileShow><LeftPaginationArrow size={15}/></MobileShow>
        </ArrowButton>
        <DotsContainer>
          {slidesData.map((slide, index) => {
            const isActive = index === currentSlide;
            return <DotWrapper key={index} onClick={() => setCurrentSlide(index)}>
              <DesktopShow><Dot size={isActive ? 13 : 10} color={isActive ? 'var(--sp-text-color)' : '#9FA6B7'}/></DesktopShow>
              <MobileShow><Dot size={isActive ? 11 : 7} color={isActive ? 'var(--sp-text-color)' : '#9FA6B7'}/></MobileShow>
            </DotWrapper>
          })}
        </DotsContainer>
        <ArrowButton onClick={goToNextSlide}>
          <DesktopShow><RightPaginationArrow size={19}/></DesktopShow>
          <MobileShow><RightPaginationArrow size={15}/></MobileShow>
        </ArrowButton>
      </SlideshowNavigation>
    </SlideshowContainer>
  );
};

const SlideshowContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SlideshowWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (${DeviceQueries.mobile}) {
    height: 950px; //TODO: will probs need to change, not reflexive
  }

  @media (min-width: 768px) and (max-width: 950px) {
    height: 770px; //TODO: will probs need to change, not reflexive
  }

  @media (min-width: 951px) {
    height: 580px; //TODO: hardcoded right now
  }
`

const Slide = styled.div <{ $active: boolean, $exitLeft: boolean, $enterRight: boolean }>`
  width: 100%;
  height: 100%;
  //opacity: 0;
  //transform: translateX(100%); /* Start off-screen to the right */
  transition: opacity 0.5s ease, transform 0.5s ease;
  padding: 40px;
  overflow: clip;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  transform: translateX(100%);

  ${({ $active }) => $active && 'opacity: 1; transform: translateX(0);'};
  ${({ $exitLeft }) => $exitLeft && 'transform: translateX(-100%);'};
    // ${({ $enterRight }) => $enterRight && 'transform: translateX(100%);'};

  @media (${DeviceQueries.mobile}) {
    padding: 15px;
  }
`

const SlideshowNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9.94px;

  @media (${DeviceQueries.mobile}) {
    gap: 8.23px;
  }
`

const ArrowButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: var(--sp-text-color);
  height: 19px;

  &:disabled {
    opacity: .5;
    cursor: auto;
  }

  @media (${DeviceQueries.mobile}) {
    height: 15px;
  }
`

const DotsContainer = styled.div`
  display: flex;
  padding: 6.487px;
  align-items: center;
  gap: 8.108px;

  @media (${DeviceQueries.mobile}) {
    padding: 5.053px;
    gap: 6.316px;
  }
`

const DotWrapper = styled.span`
  display: flex;
  cursor: pointer;
`;

export default Slideshow;
