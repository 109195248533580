import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEditEventContext } from '../contexts/EditEventContext';
import useSpotify from '../lib/spotify';
import useLocalStorage from 'Common/src/hooks/useLocalStorage';
import { IUEvent } from '../lib/event';
import styled from 'styled-components';
import SpotifyIcon from './icons/SpotifyIcon';
import { TAppElkEventQuestionType } from 'TProtocol/prototypes/events/messages';
import Questions from './questions/Questions';
import { ContentTitle, ContentTitleWrapper } from './EventForm/ActionMenuCommon';
import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../contexts/UserContext';

const SpotifyQuestionsWidget = ({ event }: {
  event: IUEvent;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const spotify = useSpotify();
  const location = useLocation();
  const [eventPageUrl, setEventPageUrl] = useLocalStorage<string>('eventPageUrl', '');

  const songRequests = event.questions.filter(q => q.questionType === TAppElkEventQuestionType.SONG);

  const logManagePlaylistClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[SpotifyQuestionsWidget] Clicked on manage playlist link'
    });
  };

  const connectSpotify = async () => {
    const autosaveParam = location.state?.autoSaveId ? `?autosave=${location.state.autoSaveId}` : '';
    setEventPageUrl(location.pathname + autosaveParam);
    console.log('event page url: ', location.pathname + autosaveParam);
    await spotify.spotifyOAuthSignIn();
  };

  const createSpotifyPlaylist = async () => {
    if (!spotify.isConnected() && !location.state?.spotifySuccess) {
      await connectSpotify();
    } else {
      const playlistName = event.title.trim() === '' ? 'Untitled Party' : event.title;
      const newPlaylistId = await spotify.createPlaylist(playlistName);
      editEventContext.setPlaylistId(newPlaylistId);
    }
  };

  useEffect(() => {
    localStorage.removeItem('eventPageUrl');
  }, []);

  useEffect(() => {
    if (location.state?.spotifySuccess && !editEventContext.event?.playlistId) {
      void createSpotifyPlaylist();
    }
  }, [location.state]);

  const createPlaylistSection = <ButtonContainer>
    <ButtonLabel>
      Connect to Spotify to create a party playlist and let guests add songs!
    </ButtonLabel>
    <SpotifyButton onClick={createSpotifyPlaylist}>
      <SpotifyIcon/>
      Connect to Spotify
    </SpotifyButton>
  </ButtonContainer>;

  const songRequestSection = event.playlistId
    ? <Questions
      event={event}
      questions={songRequests}
      questionType={TAppElkEventQuestionType.SONG}
      handleAddQuestion={() => editEventContext.addSongRequestQuestion()}
    />
    : null;

  return <Container>
    <SpotifyContentTitleWrapper $withMarginBottom={true}>
      <SpotifySectionTitle>
        <SpotifyIcon/>
        <SpotifyContentTitle>Spotify</SpotifyContentTitle>
      </SpotifySectionTitle>
      {event.playlistId && <SpotifyLink to={spotify.constructPlaylistLinkWithId(event.playlistId)} target="_blank"
                                        rel="noreferrer noopener" onClick={logManagePlaylistClick}>
        Manage Playlist
      </SpotifyLink>}
    </SpotifyContentTitleWrapper>
    {event.playlistId ? songRequestSection : createPlaylistSection}
  </Container>;
};

const Container = styled.div`
  font-size: 14px;
  margin: 20px 0;
`;

const SpotifyContentTitleWrapper = styled(ContentTitleWrapper)`
  align-items: center;

  @media (${DeviceQueries.mobile}) {
    height: 40px;
  }
`;

const SpotifyContentTitle = styled(ContentTitle)`
  height: auto;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 10px;
`;

const SpotifyButton = styled.button`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 9px;
  background: #1DB954;
  box-shadow: 0 4px 4px 0 rgba(var(--sp-text-rgb), 0.25);
  border: none;
  color: #FFFFFF;
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 24px;
  width: fit-content;
  cursor: pointer;


  &:hover:not([disabled]) {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: .5;
  }
`;

const ButtonLabel = styled.div`
  color: var(--sp-text-color);
`;

const SpotifyLink = styled(Link)`
  color: var(--sp-text-color);
`;

const SpotifySectionTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export default SpotifyQuestionsWidget;
