import React, { ReactNode, Suspense, useEffect, useRef, useState } from 'react';
import { Location, useBlocker, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { useUserContext } from '../../contexts/UserContext';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { BasePageContent } from '../BasePage';
import { getAttendeeIndex, getRSVPedInvitee, hasUserRSVPed } from '../../lib/attendance';
import { endUserEvent } from '../../lib/performance';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { useModalContext } from '../../contexts/ModalContext';
import ChangeRSVPModal from '../../components/modals/ChangeRSVPModal';
import { updateAttendance, updateAttendanceThroughEmail } from '../../api/ElkEventService';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import BasePageHeader, { UPageType } from '../BasePageHeader';
import { b64tos, stob64 } from '../../util/stringutils';
import AttendeesList from './AttendeesList';
import AlbumDetails from './AlbumDetails';
import EventCreatedModal from '../../components/modals/EventCreatedModal';
import TextBlast from '../../components/icons/TextBlast';
import FooterLinks from '../../components/FooterLinks';
import MessageGroup from '../../components/messages/MessageGroup';
import PhotoViewer from '../../components/PhotoViewer';
import RSVPButton from '../../components/buttons/RSVPButton';
import GuestManagementModal from '../../components/modals/GuestManagementModal';
import PostRsvpModal from '../Login/PostRsvpModal';
import { SmallWhiteButton, WhiteButton } from '../../components/buttons/WhiteButton';
import MailOutline from '../../components/icons/MailOutline';
import PencilAlt from '../../components/icons/PencilAlt';
import { ActionRow, EventModules, StickyFooter, StickyFooterWhiteButton } from '../../components/EventForm/Common';
import TopModule from './TopModule';
import Duplicate from '../../components/icons/Duplicate';
import TrashOutline from '../../components/icons/TrashOutline';
import DeleteEventModal from '../../components/modals/DeleteEventModal';
import CancelEventModal from '../../components/modals/CancelEventModal';
import Share from '../../components/icons/Share';
import RSVPYesModal from '../../components/modals/RSVPYesModal';
import RSVPNoModal from '../../components/modals/RSVPNoModal';
import SendMessageModal from '../../components/modals/SendMessageModal';
import { findCurrentInvitee, inviteeHasNewPiiForUser } from '../../util/attendee';
import { IUInvitee } from '../../lib/event';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import KebabMenu, { KebabMenuOption } from 'Common/src/components/KebabMenu';
import { DeviceQueries } from 'Common/src/components/styled';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkCohostRequestStatus,
  TAppElkMessage,
  TAppElkReviewStatus
} from 'TProtocol/prototypes/events/messages';
import { shareEvent, ShareResult } from '../../util/share';
import { Tooltip } from 'react-tooltip';
import { ShareTooltipContainer } from '../../components/styled/CopyLinkTooltip';
import { DesktopShow, MobileShow } from '../../components/styled/Common';
import { getIsMacOS, getIsWindows } from 'Common/src/utils/Browser';
import LinkIcon from '../../components/icons/LinkIcon';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../../core/libs/Firebase';
import { useEditEventContext } from '../../contexts/EditEventContext';
import { useToastContext } from '../../contexts/ToastContext';
import { isPast } from '../../lib/time';
import MergeModal from '../../components/modals/MergeModal';
import UserGroup from '../../components/icons/UserGroup';
import { OutlineButton, SmallOutlineButton } from '../../components/buttons/OutlineButton';

export interface EventPageState {
  showPhotosPromo: boolean;
  showEventCreated: boolean;
  showRSVP: boolean;
  showGuestOptions: boolean;
  finishingRSVP: boolean; // For usage by UpdateAttendance actions
  fromLogin?: boolean;
  inviteeName?: string;
  previousPath?: string;
  nonUpdateRSVPThroughEmail?: boolean;
}

interface IUSeparatedMessages {
  blasts: TAppElkMessage[];
  byInviteeId: Map<string, TAppElkMessage[]>;
}

declare const ELK_WEBAPP_URL: string;

const EventPage = ({ eventIdProp, readOnly, showPreview, setShowPreview }: {
  eventIdProp?: string,
  readOnly?: boolean,
  showPreview?: boolean,
  setShowPreview?: (showPreview: boolean) => void
}) => {
  const userContext = useUserContext();
  const backgroundContext = useBackgroundContext();
  const eventCacheContext = useEventCacheContext();
  const editEventContext = useEditEventContext();
  const toastContext = useToastContext();
  const modalContext = useModalContext();
  const { getInviteeUuid, getToken, setToken, getPhone, getEmail } = useInviteeUuidStorage();

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { eventIdParam } = useParams();
  const location = useLocation() as Location<EventPageState>;
  useBlocker(() => {
    modalContext.hide();
    return false; // Never actually blocks
  });
  const isLoggedIn = userContext.isLoggedIn();

  const eventId = eventIdProp ?? eventIdParam;
  const eventPageAsBackground = eventIdProp !== undefined;
  const event = showPreview ? editEventContext.event : eventId !== undefined ? eventCacheContext.getEvent(eventId) : undefined;
  const [hasRSVPed, setHasRSVPed] = useState<boolean>(false);
  const [showCopied, setShowCopied] = useState(false);
  const [userSeen, setUserSeen] = useState<boolean>(false);
  const [showPhotoViewer, setShowPhotoViewer] = useState<boolean>(false);
  const [showCopiedLinkTooltip, setShowCopiedLinkTooltip] = useState<boolean>(false);

  const encodedEmailAddress = queryParams.get('ea');
  const encodedPhone = queryParams.get('ep');
  const token = queryParams.get('token') ?? getToken(eventId);
  const rsvpResponse = queryParams.get('r');
  const fromEmailLink = queryParams.get('fe') === '1';
  const inviteeUuid = b64tos(queryParams.get('iu') ?? '') || getInviteeUuid(eventId);
  const [separatedMessages, setSeparatedMessages] = useState<IUSeparatedMessages>({
    blasts: [],
    byInviteeId: new Map()
  });

  const pageScrollRef = useRef<HTMLDivElement | null>(null);

  const showCreatedModal = location.state?.showEventCreated && event?.albumDetails !== undefined;
  const showGuestOptionsModal = location.state?.showGuestOptions;
  const showRSVP = location.state?.showRSVP;
  const fromLogin = location.state?.fromLogin;
  const inviteeName = location.state?.inviteeName;
  const nonUpdateRSVPThroughEmail = location.state?.nonUpdateRSVPThroughEmail;

  useEffect(() => {
    if (event?.messages) {
      const separatedMessages: IUSeparatedMessages = {
        blasts: [],
        byInviteeId: new Map()
      };

      for (const message of event.messages) {
        if (message.textBlast) {
          separatedMessages.blasts.push(message);
        } else if (message.receiverInviteeUuid !== undefined) {
          const messagesById = separatedMessages.byInviteeId.get(message.receiverInviteeUuid);

          if (messagesById) {
            messagesById.unshift(message);
          } else {
            separatedMessages.byInviteeId.set(message.receiverInviteeUuid, [message]);
          }
        }
      }

      setSeparatedMessages(separatedMessages);
    }
  }, [event?.messages]);

  useEffect(() => {
    if (showCopiedLinkTooltip) {
      const timerId = setTimeout(() => {
        setShowCopiedLinkTooltip(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopiedLinkTooltip]);

  const handleTextBlast = () => {
    modalContext.hide();
    modalContext.show({
      contents: <SendMessageModal close={modalContext.hide} event={event}/>
    });
  };

  const onShare = async () => {
    modalContext.hide();
    if (event?.isHost) {
      onManageInviteClick();
    } else {
      if (event !== undefined) {
        await shareEvent(userContext, event);
      }
    }
  };

  const onCohost = async () => {
    if (eventId !== undefined) {
      navigate(`/event/cohost/${eventId}`);
    }
  }

  const onShareLink = async () => {
    if (event !== undefined) {
      const shareResult = await shareEvent(userContext, event);
      if (shareResult === ShareResult.Clipboard) {
        setShowCopiedLinkTooltip(true);
      }
    }
  };

  const handleChangeRSVP = () => {
    modalContext.show({
      contents: <ChangeRSVPModal close={modalContext.hide} event={event} eventId={eventId}
                                 clickedChangeResponse={true}/>
    });
  };

  const handleRSVPYes = () => {
    modalContext.show({
      contents: <RSVPYesModal close={modalContext.hide} event={event} eventId={eventId}
                              showUpdateRSVP={showRSVP} nonUpdateRSVPThroughEmail={nonUpdateRSVPThroughEmail}/>
    });
  };

  const handleRSVPNo = () => {
    modalContext.show({
      contents: <RSVPNoModal close={modalContext.hide} event={event} eventId={eventId}
                             nonUpdateRSVPThroughEmail={nonUpdateRSVPThroughEmail}/>
    });
  };

  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Reset to the start of the day

  const showInviteBanner = event?.isHost && event.attendees.length <= 1 && !isPast(event) && !event.cancelledTimestamp && !showPreview;

  useEffect(() => {
    if (encodedEmailAddress) {
      userContext.setEmail(b64tos(encodedEmailAddress));
    }
  }, [encodedEmailAddress]);

  useEffect(() => {
    if (encodedPhone) {
      userContext.setPhoneNumber(b64tos(encodedPhone));
    }
  }, [encodedPhone]);

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine Parties - ${event.title}`;
      logEvent(firebaseAnalytics, 'page_view', {
        page_title: 'Event Page - Analytics'
      });

      endUserEvent(userContext);
      backgroundContext.setColors(
        {
          colors: event?.colors,
          animationUrl: event.backgroundAnimationUrl,
          animationDisabled: event.videoBackgroundDisabled
        }
      );
    }
  }, [event]);

  useEffect(() => {
    if (showInviteBanner) {
      toastContext.show({
        contents: 'Is your invitation ready?',
        actionText: 'Invite guests now',
        onActionClick: onManageInviteClick,
        positionTop: true,
        useEventColor: true
      })
    }

    return () => {
      toastContext.hide();
    };
  }, [event?.colors]);

  useEffect(() => {
    if (eventId !== undefined) {
      if (!showPreview) {
        void eventCacheContext.fetchEvent({ eventId, inviteeUuid, isPreview: !isLoggedIn, token });
      }
      if (isLoggedIn) {
        // void fetchUserData();
      }
    } else if (!showPreview) {
      // User came directly to /event without an id, but no event is in context (reload or bookmark)
      navigate('/event/create');
    }
  }, [event?.id, eventId]);

  useEffect(() => {
    if (event) {
      const phone = getPhone();
      const email = getEmail();

      setHasRSVPed(hasUserRSVPed(event, userContext.id ?? '', inviteeUuid,
        userContext.phones ?? (phone ? [phone] : undefined), userContext.emails ?? (email ? [email] : undefined)));
      let inviteeId: string | undefined = inviteeUuid;
      if (userContext.id) {
        inviteeId = getRSVPedInvitee(event, userContext.id)?.inviteeId;
      }
      if ((userContext.id || inviteeUuid) && !event.messagesArePersonal) {
        void eventCacheContext.refreshMessages(event.id, inviteeId, token);
      }
      if (event.albumDetails === undefined) {
        void eventCacheContext.refreshAlbumDetails(event.id, inviteeUuid, token);
      }
    }
  }, [event?.id, userContext.id]);

  useEffect(() => {
    if (hasRSVPed && event && event.albumDetails?.albumCode === undefined && inviteeUuid !== undefined) {
      void eventCacheContext.refreshAlbumDetails(event.id, inviteeUuid);
    }
  }, [hasRSVPed]);

  useEffect(() => {
    // Don't update to SEEN if it's in the background of another page
    if (!readOnly && eventIdProp === undefined) {
      void updateSeen();

      const myInvitee = getMyInvitee();
      if (myInvitee?.cohostStatus === TAppElkCohostRequestStatus.PENDING) {
        navigate(`/event/cohost/add/${eventId}`);
      }
    }
  }, [event?.attendees, userContext.id, inviteeUuid]);

  useEffect(() => {
    if (showCopied === true) {
      const timerId = setTimeout(() => {
        setShowCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopied]);

  useEffect(() => {
    if (location.state?.showPhotosPromo && event && !eventPageAsBackground) {
      const newPii = inviteeHasNewPiiForUser(getMyInvitee(), userContext.generateElkUser());
      if (newPii) {
        modalContext.show({
          contents: <Suspense fallback={<div/>}>
            <MergeModal
              fromRsvp={true}
              close={onPhotoPromoClose}
              defaultPhone={newPii.phone ? newPii.phone : undefined}
              defaultEmail={newPii.email ? newPii.email : undefined}
            />
          </Suspense>
        });
      } else {
        modalContext.show({
          contents: <PostRsvpModal event={event} close={onPhotoPromoClose} afterLogin={fromLogin}/>
        });
      }
    }
  }, [location.state?.showPhotosPromo, event]);

  useEffect(() => {
    if (event && showCreatedModal && !eventPageAsBackground) {
      modalContext.show({
        contents: <EventCreatedModal
          event={event} close={onEventCreatedModalClose} onLinkClick={onShareLink} onEmailClick={onShare}/>
      });
    }
  }, [showCreatedModal]);

  useEffect(() => {
    if (event && showGuestOptionsModal && !eventPageAsBackground) {
      modalContext.show({
        contents: <GuestManagementModal key="guest-management-modal" close={modalContext.hide}/>
      });
    }
  }, [event, showGuestOptionsModal]);

  useEffect(() => {
    if (eventId && token) {
      setToken(eventId, token);
    }
  }, [eventId, token]);

  useEffect(() => {
    if (event && showRSVP && !eventPageAsBackground) {
      if (rsvpResponse === 'yes') {
        handleRSVPYes();
      } else if (rsvpResponse === 'no') {
        handleRSVPNo();
      }
    }
  }, [event, rsvpResponse, showRSVP]);

  const findInvitee = () => {
    const phone = getPhone();
    const email = getEmail();

    return findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
      userContext.phones ?? (phone ? [phone] : undefined), userContext.emails ?? (email ? [email] : undefined));
  };

  const findRsvpedInvitee = () => {
    const phone = getPhone();
    const email = getEmail();

    return getRSVPedInvitee(event, userContext.id ?? '', inviteeUuid,
      userContext.phones ?? (phone ? [phone] : undefined), userContext.emails ?? (email ? [email] : undefined));
  }

  const onEventCreatedModalClose = () => {
    navigate(`/event/${eventId}`, { replace: true, state: { showEventCreated: false } });
    modalContext.hide();
  };

  const onPhotoPromoClose = () => {
    navigate(`/event/${eventId}`, { state: { showPhotosPromo: false }, replace: true });
    modalContext.hide();
  };

  const handleEditClick = () => {
    modalContext.hide();
    if (event === undefined || !event.isHost) {
      return;
    }
    navigate('/event/edit/' + event.id);
  };

  const cloneEvent = () => {
    modalContext.hide();
    if (event?.id) {
      navigate(`/event/create/from/${event.id}`);
    }
  };

  const onManageInviteClick = () => {
    modalContext.hide();
    if (eventId !== undefined) {
      navigate(`/event/invite/${eventId}`);
    }
  };


  const findAttendeeIndex = () => {
    const phone = getPhone();
    const email = getEmail();

    return getAttendeeIndex(
      event?.attendees, userContext.id, inviteeUuid, userContext.phones ?? (phone ? [phone] : undefined),
      userContext.emails ?? (email ? [email] : undefined))
  }

  const getMyInvitee = (): IUInvitee | undefined  => {
    return event?.attendees[findAttendeeIndex()];
  };

  const exitPreview = () => {
    if (setShowPreview) {
      logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: 'Exiting preview'
      });

      setShowPreview(false);
    }
  }


  const updateSeen = async () => {
    if (eventId === undefined || userSeen || !event || event.attendees.length === 0) {
      return;
    }

    const userId = userContext.id;

    let myInvitee = getMyInvitee();
    if (userContext.isLoggedIn()) {
      if (myInvitee !== undefined && myInvitee.rsvpStatus !== TAppElkAttendeeStatus.INVITED) {
        // User already is attending.
        return;
      }

      const userName = userContext.name;

      if (myInvitee === undefined && userName !== undefined && userName !== null) {
        myInvitee = new IUInvitee({
          inviteeId: uuidv4(),
          rsvpStatus: TAppElkAttendeeStatus.SEEN,
          role: TAppElkAttendeeRole.UNDEFINED,
          additionalGuestCount: 0,
          userId,
          name: inviteeName ?? userName,
          isNew: true
        });
      }
    } else if (inviteeUuid) {
      myInvitee = event?.attendees
        .filter(attendee => {
            return attendee?.inviteeId === inviteeUuid;
          }
        )?.[0];

      if (myInvitee !== undefined && myInvitee.rsvpStatus !== TAppElkAttendeeStatus.INVITED) {
        // User already is attending.
        return;
      }
    }

    if (myInvitee) {
      myInvitee.rsvpStatus = TAppElkAttendeeStatus.SEEN;
      myInvitee.userId = userId;
      if (!myInvitee.phone || myInvitee.phone.trim() === '') {
        myInvitee.phone = userContext.getPhoneNumber();
      }
      if (!myInvitee.email || myInvitee.email.trim() === '') {
        myInvitee.email = userContext.getEmail();
      }
      setUserSeen(true);
      if (userContext.isLoggedIn()) {
        void updateAttendance(userContext, eventId, myInvitee.toTAppElkInvitee(), undefined, undefined, undefined,
          false);
      } else if (token) {
        void updateAttendanceThroughEmail(eventId, myInvitee.toTAppElkInvitee(), undefined, token, false, fromEmailLink);
      }
    }
  };

  const handleOptIn = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventPage] Clicked on accept'
    });

    if (userContext.isLoggedIn() && !event?.hasWaitlist) {
      const currInvitee = findInvitee();

      const encodedName = stob64(currInvitee.name ?? userContext.name);
      const encodedEmail = stob64(currInvitee.email ?? userContext.getEmail() ?? '');
      const encodedPhone = stob64(currInvitee.phone ?? userContext.getPhoneNumber() ?? '');

      //TODO: ensure title is present in all cases
      const encodedTitle = stob64(event?.title ?? '');
      navigate(
        `/event/rsvp/action/${eventId}?r=yes&et=${encodedTitle}&en=${encodedName}&np=${currInvitee.notificationPreference}&iu=${currInvitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: false
          }
        });
    } else {
      modalContext.show({
        contents: <RSVPYesModal close={modalContext.hide} event={event} eventId={eventId}
                                nonUpdateRSVPThroughEmail={nonUpdateRSVPThroughEmail}/>
      });
    }
  };

  const handleOptOut = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventPage] Clicked on decline'
    });

    if (userContext.isLoggedIn()) {
      const currInvitee = findInvitee();

      const encodedName = stob64(currInvitee.name ?? userContext.name);
      const encodedEmail = stob64(currInvitee.email ?? userContext.getEmail() ?? '');
      const encodedPhone = stob64(currInvitee.phone ?? userContext.getPhoneNumber() ?? '');

      //TODO: ensure title is present in all cases
      const encodedTitle = stob64(event?.title ?? '');
      navigate(
        `/event/rsvp/action/${eventId}?r=no&et=${encodedTitle}&en=${encodedName}&np=${currInvitee.notificationPreference}&iu=${currInvitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: false
          }
        });
    } else {
      modalContext.show({
        contents: <RSVPNoModal close={modalContext.hide} event={event} eventId={eventId}
                               nonUpdateRSVPThroughEmail={nonUpdateRSVPThroughEmail}/>
      });
    }
  };

  const deleteEvent = () => {
    if (eventId) {
      modalContext.show({
        contents: <DeleteEventModal close={modalContext.hide} eventId={eventId}/>
      });
    }
  };

  const cancelEvent = () => {
    if (eventId) {
      modalContext.show({
        contents: <CancelEventModal close={modalContext.hide} eventId={eventId}/>
      });
    }
  };

  const menuOptions: KebabMenuOption[] = [
    {
      id: 'cohost',
      label: 'Cohosts',
      icon: <UserGroup/>
    },
    {
      id: 'clone',
      label: 'Clone party',
      icon: <Duplicate/>
    }
  ];

  if ((event?.attendees?.length ?? 0) > 1) {
    menuOptions.push({
      id: 'cancel',
      label: 'Cancel party',
      icon: <TrashOutline/>,
      color: '#F00'
    });
  } else {
    menuOptions.push({
      id: 'delete',
      label: 'Delete party',
      icon: <TrashOutline/>,
      color: '#F00'
    });
  }

  const onMenuClick = (id: string) => {
    if (id === 'clone') {
      cloneEvent();
    } else if (id === 'delete') {
      deleteEvent();
    } else if (id === 'cancel') {
      cancelEvent();
    } else if (id === 'cohost') {
      onCohost();
    }
  };

  let actions: ReactNode = <></>;

  const inviteeRsvped= findRsvpedInvitee();

  if (event?.isHost) {
    if (event.cancelledTimestamp === undefined || event.cancelledTimestamp === 0) {
      const isMobile = window.matchMedia(`(${DeviceQueries.mobile})`).matches;
      actions = (
        <ActionRow>
          {isMobile ? (
            <>
              <SmallOutlineButton onClick={onShare} $noMin={true}>
                <MailOutline size={13}/> Invites
              </SmallOutlineButton>
              {
                event.startTime > Date.now() &&
                <SmallOutlineButton onClick={handleEditClick} $noMin={true}>
                  <PencilAlt size={13}/> Edit
                </SmallOutlineButton>
              }
              {event.attendees.length > 1 ? <SmallOutlineButton onClick={handleTextBlast} $noMin={true}>
                  <TextBlast size={13}/> Send Message
                </SmallOutlineButton>
                : null}
              <ShareTooltipContainer>
                <Tooltip anchorSelect="#eventCopyLink" place="top" className="copiedTooltip"
                         noArrow={true} isOpen={showCopiedLinkTooltip}>
                  Copied link!
                </Tooltip>
                <SmallOutlineButton onClick={onShareLink} $noMin={true} id={'eventCopyLink'}>
                  {getIsMacOS() || getIsWindows() ? <LinkIcon size={13}/> : <Share size={16}/>}
                </SmallOutlineButton>
              </ShareTooltipContainer>
            </>
          ) : (
            <>
              <OutlineButton onClick={onShare} $noMin={true}>
                <MailOutline/> Invites
              </OutlineButton>
              {
                event.startTime > Date.now() &&

                <OutlineButton onClick={handleEditClick} $noMin={true}>
                  <PencilAlt size={20}/> Edit
                </OutlineButton>
              }
              {event.attendees.length > 1 ? <OutlineButton onClick={handleTextBlast} $noMin={true}>
                  <TextBlast size={20}/> Send Message
                </OutlineButton>
                : null}
              <ShareTooltipContainer>
                <Tooltip anchorSelect="#eventCopyLink" place="top" className="copiedTooltip"
                         noArrow={true} isOpen={showCopiedLinkTooltip}>
                  Copied link!
                </Tooltip>
                <OutlineButtonNoText onClick={onShareLink} $noMin={true} id={'eventCopyLink'}>
                  {getIsMacOS() || getIsWindows() ? <LinkIcon size={20}/> : <Share size={20}/>}
                </OutlineButtonNoText>
              </ShareTooltipContainer>
            </>
          )}
          <MenuContainer>
            <KebabMenu options={menuOptions} onClick={onMenuClick}/>
          </MenuContainer>
        </ActionRow>
      );
    }
  } else if (inviteeRsvped?.waitlistStatus === TAppElkReviewStatus.PENDING_APPROVAL) {
    actions = <ChangeResponse>
      <span>You have requested to join the list for the party</span>
    </ChangeResponse>
  } else if (inviteeRsvped?.waitlistStatus === TAppElkReviewStatus.DENIED) {
    actions = <ChangeResponse>
      <span>You&apos;re not on the list, we&apos;ll let you know if this changes</span>
    </ChangeResponse>
  } else if (inviteeRsvped?.rsvpStatus === TAppElkAttendeeStatus.YES) {
    actions = <ChangeResponse>
      <RSVPButton disabled={false} onClick={handleChangeRSVP} type={'button'} secondary={true}>
        <span>You RSVP&apos;ed that you&apos;re going <ChangeText>(change)</ChangeText></span>
      </RSVPButton>
    </ChangeResponse>;
  } else if (inviteeRsvped?.rsvpStatus === TAppElkAttendeeStatus.NO) {
    actions = <ChangeResponse>
      <RSVPButton disabled={false} onClick={handleChangeRSVP} type={'button'} secondary={true}>
        <span>You RSVP&apos;ed that you cannot attend <ChangeText>(change)</ChangeText></span>
      </RSVPButton>
    </ChangeResponse>;
  } else if (event?.hasWaitlist) {
    actions =
      <ActionRow>
        <OutlineButton onClick={handleOptIn}>Get on the guest list</OutlineButton>
      </ActionRow>;
  } else if (!hasRSVPed) {
    actions =
      <ActionRow>
        <OutlineButton onClick={handleOptIn}>Accept</OutlineButton>
        <OutlineButton onClick={handleOptOut}>Decline</OutlineButton>
      </ActionRow>;
  }

  if (event) {
    const desktopContent = <>
      <TopModule event={event} isAttending={inviteeRsvped?.rsvpStatus === TAppElkAttendeeStatus.YES}>
        {!readOnly && actions}
      </TopModule>
      <AlbumDetails event={event} setShowPhotoViewer={setShowPhotoViewer} showLogo={false}/>
      <GuestDetailsContainer>
        {!readOnly && separatedMessages.blasts.length > 0 &&
          <MessagesContainer>
            <SectionSubheader>MESSAGES ({separatedMessages.blasts.length})</SectionSubheader>
            <MessageGroup messages={separatedMessages.blasts}/>
          </MessagesContainer>
        }

        <AttendeesList
          event={event}
          inviteeUuid={inviteeUuid}
          readOnly={readOnly ?? false}
          messagesByInviteeId={separatedMessages.byInviteeId}
        />
        <FooterLinks/>
      </GuestDetailsContainer>
    </>

    const mobileContent = <CenteredContent>
      <TopModule event={event} isAttending={inviteeRsvped?.rsvpStatus === TAppElkAttendeeStatus.YES}>
        {!readOnly && actions}
      </TopModule>

      <EventModules>

        <AlbumDetails event={event} setShowPhotoViewer={setShowPhotoViewer} showLogo={false}/>

        {!readOnly && separatedMessages.blasts.length > 0 &&
          <MessagesContainer>
            <SectionSubheader>MESSAGES ({separatedMessages.blasts.length})</SectionSubheader>
            <MessageGroup messages={separatedMessages.blasts}/>
          </MessagesContainer>
        }

        <AttendeesList
          event={event}
          inviteeUuid={inviteeUuid}
          readOnly={readOnly ?? false}
          messagesByInviteeId={separatedMessages.byInviteeId}
        />
      </EventModules>

      <FooterLinks/>
    </CenteredContent>

    return <>
      <BasePageHeader page={UPageType.Event}/>
      {showInviteBanner && toastContext.content}
      {showPreview && toastContext.content}
      <BasePageContent $wide={true} $full={true} $flush={true} $centered={true} containerRef={pageScrollRef}>
        <PageContentContainer>
          {!!event.cancelledTimestamp && <CanceledBanner>Event Canceled</CanceledBanner>}
          <DesktopShow>{desktopContent}</DesktopShow>
          <MobileShow>{mobileContent}</MobileShow>
        </PageContentContainer>
      </BasePageContent>
      {showPreview &&
        <StickyFooter>
          <ActionRow $noMargins={true}>
            <StickyFooterWhiteButton $invert={true} type="button" onClick={exitPreview}>
              Return to editing your party
            </StickyFooterWhiteButton>
          </ActionRow>
        </StickyFooter>
      }
      <PhotoViewer event={event} show={showPhotoViewer} close={() => setShowPhotoViewer(false)}/>
    </>;
  } else {
    return null;
  }
};

const ChangeResponse = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const ChangeText = styled.span`
  color: var(--shine-bg-color);
  //text-decoration: underline;
`

const SectionSubheader = styled.div`
  font-size: 16px;
  margin: 10px 0;
`;

const PageContentContainer = styled.div`
  //width: calc(min(1512px, 100%));
  //width: 100%;
  //display: flex;
  //justify-content: center;
  padding: 60px 10%;
  //box-sizing: border-box;
  color: #FFF;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1205px;
  width: 80%;

  @media (${DeviceQueries.mobile}) {
    padding: 25px 10%;
  }
`;

const CenteredContent = styled.div`
  //max-width: 684px;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const GuestDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const MessagesContainer = styled.div`
  width: 100%;
`

const OutlineButtonNoText = styled(OutlineButton)`
  height: 24px;
  box-sizing: content-box;
`;

const CanceledBanner = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: rgba(var(--sp-bg-rgb), 0.23);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;

  @media (${DeviceQueries.mobile}) {
    font-size: 18px;
  }
`

export default EventPage;
