import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface IUAutoCompleteItem<T> {
  id: string;
  displayString: string;
  value: T;
}

interface Props<T> {
  values: IUAutoCompleteItem<T>[];
  onSelect: (value: IUAutoCompleteItem<T>) => void;
  onCancel: () => void;
  Format?: React.FC<{value: IUAutoCompleteItem<T>}>
}

const AutocompleteDropdown = <T, >({
  children,
  values,
  onSelect,
  onCancel,
  Format
}: PropsWithChildren<Props<T>>) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        setActiveIndex(Math.min(activeIndex + 1, values.length - 1));
      } else if (event.key === 'ArrowUp') {
        setActiveIndex(Math.max(activeIndex - 1, 0));
      } else if (event.key === 'Enter') {
        if (activeIndex !== -1) {
          onSelect(values[activeIndex]);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    containerRef.current?.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      containerRef.current?.removeEventListener('keydown', handleKeyPress)
    };
  }, [values.length, activeIndex]);

  useEffect(() => {
    setActiveIndex(-1);
  }, [values]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      onCancel();
    }
  };

  return <DropdownContainer ref={containerRef}>
    {children}
    { values.length > 0 ?
    <Dropdown ref={dropdownRef}>
      {
        values.map((value, index) => <DropdownItem
          key={value.id}
          onMouseDown={() => onSelect(value)}
          $active={activeIndex === index}
        >
          {Format ? <Format value={value}/> : value.displayString}
        </DropdownItem>)
      }
    </Dropdown> : null }
  </DropdownContainer>;
};

export const DropdownContainer = styled.div`
  position: relative;
  overflow: visible;
`;

export const Dropdown = styled.div`
  position: absolute;
  left: 0;
  z-index: 50;
  min-width: 400px;
  margin-top: 5px;
  box-sizing: border-box;
  font-weight: 300;

  background: #FFFFFF;
  color: #000;
  border: 1px solid #E8ECEF;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1), 0 5px 25px rgba(91, 91, 91, 0.1);
  border-radius: 6px;

  overflow: visible;

  // padding: 8px 12px;
`;

const DropdownItem = styled.div<{$active: boolean}>`
  cursor: pointer;
  // padding: 5px;
  ${({$active}) => $active ? 'background-color: #FFEEDD;' : '' }
`;

export default AutocompleteDropdown;
