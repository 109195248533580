import React, { PropsWithChildren, RefObject, UIEvent } from 'react';
import styled from 'styled-components';

import { DeviceQueries, VerticalFullContainer } from 'Common/src/components/styled';

export const BasePageContent = ({
                                  $wide,
                                  $full,
                                  $flush,
                                  $relative,
                                  $centered,
                                  $scrolling,
                                  containerRef,
                                  onScroll,
                                  children
                                }: PropsWithChildren<{
  $wide?: boolean,
  $full?: boolean,
  $flush?: boolean,
  $relative?: boolean,
  $centered?: boolean,
  $scrolling?: boolean,
  containerRef?: RefObject<HTMLDivElement>,
  onScroll?: (e: UIEvent) => void
}>) => {
  return <BasePageContentOuter>
    <BasePageContentInner $wide={$wide} $full={$full} $flush={$flush} $relative={$relative} $centered={$centered}
                          $scrolling={$scrolling} ref={containerRef} onScroll={onScroll}>
      {children}
    </BasePageContentInner>
  </BasePageContentOuter>;
};

const BasePageContentOuter = styled(VerticalFullContainer)`
  width: 100%;
  align-items: center;
  font-family: Plus Jakarta Sans, sans-serif;
`;

const BasePageContentInner = styled(VerticalFullContainer)<{
  $wide?: boolean,
  $full?: boolean,
  $flush?: boolean,
  $relative?: boolean,
  $centered?: boolean,
  $scrolling?: boolean
}>`
  ${({ $relative = true }) => $relative ? 'position: relative;' : ''};
  ${({ $flush = false }) => $flush ? '' : 'padding: 25px 40px;'};
  ${({ $centered = false }) => $centered ? 'align-items: center;' : ''};
  ${({ $scrolling = true }) => $scrolling ? '' : 'overflow: unset;'};

  @media not (${DeviceQueries.mobile}) {
    ${({ $wide = false }) => $wide ? '' : 'width: 400px;'}
    ${({ $full = false, $flush = false }) => $full ? ($flush ? 'width: 100%;' : 'width: calc(100% - 80px);') : ''}
  }

  @media (${DeviceQueries.mobile}) {
    ${({ $full = false, $flush = false }) => $full ? ($flush ? 'width: 100%;' : 'padding: 10px; width: calc(100% - 20px);') : ''}
  }
  z-index: 2;
`;

export const ShadedBox = styled.div`
  margin-bottom: 25px;
  padding: 5px 15px;
  border-radius: 8px;
  border: solid 1px rgba(255, 255, 255, 0.23);
  background-color: var(--shine-system-background-color);
`;
