import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../contexts/UserContext';
import { generateAttendeeCountString, generateAttendeeString } from '../lib/attendance';
import ActionButton from './buttons/ActionButton';
import { getNormalizedPhotoUrl } from '../lib/images';
import { getDefaultTextColor } from '../lib/colorPicker';
import { IUEvent, IUInvitee } from '../lib/event';
import { convertTimestampToDateString, generateDisplayStartTime, generateTimeString, isPast } from '../lib/time';
import { useModalContext } from '../contexts/ModalContext';
import TrashOutline from './icons/TrashOutline';
import DeleteDraftModal from './modals/DeleteDraftModal';

import { DeviceQueries } from 'Common/src/components/styled';
import KebabMenu, { KebabMenuOption } from 'Common/src/components/KebabMenu';

import { TAppElkAttendeeStatus, TAppElkInviteStatus, TAppElkReviewStatus } from 'TProtocol/prototypes/events/messages';

const dateFormat = (timeZone?: string) => {
  return new Intl.DateTimeFormat('en-US', { weekday: 'short', month: 'numeric', day: 'numeric', timeZone: timeZone });
};

const EventOption = ({ event, showAttendees, isCreatePrompt, onImageClick }: {
  event?: IUEvent,
  showAttendees: boolean,
  isCreatePrompt: boolean,
  onImageClick: () => void
}) => {
  const userContext = useUserContext();
  const modalContext = useModalContext();

  const [attendeeString, setAttendeeString] = useState<string>('');
  const [timeString, setTimeString] = useState<string>('');

  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState('');
  const navigate = useNavigate();

  const isEventPast = event !== undefined && isPast(event);

  useEffect(() => {
    if (event && !!event.cancelledTimestamp) {
      setIsCancelled(true);
      //TODO: (bella) add date cancelled when server is ready
    } else {
      setAttendeeString(
        generateAttendeeString(
          event?.attendees ?? [],
          isEventPast,
          userContext.id,
          userContext.phones,
          userContext.emails
        )
      );
      setTimeString(generateTimeString(event));
    }
  }, [event]);

  useEffect(() => {
    void getUrl();
  }, [event?.photoUrl]);

  const getUrl = async () => {
    if (event?.photoUrl) {
      setPhotoUrl(await getNormalizedPhotoUrl(event?.photoUrl));
    }
  };

  const onCreate = () => {
    navigate('/instant');
  };

  const menuOptions: KebabMenuOption[] = [
    {
      id: 'delete',
      label: 'Delete draft',
      icon: <TrashOutline/>,
      color: '#F00'
    }
  ];

  const onMenuClick = async (eventId: string, id: string) => {
    if (eventId !== undefined && id === 'delete') {
      modalContext.show({
        contents: <DeleteDraftModal close={modalContext.hide} eventId={eventId}/>
      });
    }
  };

  const title = event?.title?.trim() ?? 'Untitled Party';

  let attendeeBoxContents: ReactNode;
  const attendees = (event?.attendees ?? []).filter(attendee => {
    return attendee.waitlistStatus !== TAppElkReviewStatus.DENIED && attendee.waitlistStatus !== TAppElkReviewStatus.PENDING_APPROVAL;
  });
  const going = attendees.filter(
    (attendee): attendee is IUInvitee => attendee?.rsvpStatus === TAppElkAttendeeStatus.YES);
  const invited = attendees.filter((attendee): attendee is IUInvitee => {
    return attendee?.inviteStatus !== undefined &&
      (attendee?.rsvpStatus === TAppElkAttendeeStatus.INVITED || attendee?.rsvpStatus === TAppElkAttendeeStatus.SEEN);
  });
  const pending = attendees.filter((attendee): attendee is IUInvitee => {
    return attendee?.inviteStatus === TAppElkInviteStatus.PENDING;
  });
  const seen = attendees.filter((attendee): attendee is IUInvitee => {
    return attendee?.inviteStatus === undefined && attendee?.rsvpStatus === TAppElkAttendeeStatus.SEEN;
  });
  let goingCount = going.length;
  going.map((going) => goingCount += going?.additionalGuestCount ?? 0);

  if (event?.isDraft) {
    attendeeBoxContents = <GeneralText>Draft</GeneralText>;
  } else if (goingCount > 0 || invited.length > 0 || pending.length > 0) {
    const attendeeCountText = generateAttendeeCountString(goingCount, invited, seen, pending, isEventPast);
    if (event?.cancelledTimestamp) {
      attendeeBoxContents = <GeneralText>Canceled</GeneralText>;
    } else if (event?.isHost) {
      attendeeBoxContents = attendeeCountText !== '' ? <GeneralText>{attendeeCountText}</GeneralText> : null;
    } else if (attendeeString !== '') {
      attendeeBoxContents = <GeneralText>{attendeeString}</GeneralText>;
    }
  }

  let boxContents: ReactNode;
  if (showAttendees && event?.hostedBy !== undefined) {
    boxContents = <>
      <TitleText>{title}</TitleText>
      <EventDetailsText>
        {(event?.startTime ?? 0) > 0 ?
          `${convertTimestampToDateString(event.startTime, event.timeZone, false)} at ${generateDisplayStartTime(event)}`
          : 'Unknown start time'}
      </EventDetailsText>
      <EventDetailsText>Hosted by {event.isHost ? 'you' : event?.hostedBy}</EventDetailsText>
    </>;
  }

  let image: ReactNode;
  if (isCreatePrompt) {
    image = <Photo $cancelled={isCancelled}>
      🎉<StickyElement>Party time</StickyElement></Photo>;
  } else if (photoUrl) {
    image = <PhotoBox>
      {attendeeBoxContents && <StickyElement>{attendeeBoxContents}</StickyElement>}
      <Photo src={photoUrl}></Photo>
    </PhotoBox>;
  } else {
    image = <PhotoBox>
      {attendeeBoxContents && <StickyElement>{attendeeBoxContents}</StickyElement>}
      <EmptyPhoto/>
    </PhotoBox>;
  }

  if (isCreatePrompt && event === undefined) {
    return (
      <div>
        <RoundedOptionBox $color={'#4BB749'} $loading={true}>
          <EmojiAndDetails onClick={onImageClick}>
            {image}
            <DetailsBox>
              <OptionRow>
                Start by creating a party
              </OptionRow>
              <OptionRow>
                &nbsp;
              </OptionRow>
              <OptionRow>
                &nbsp;
              </OptionRow>
            </DetailsBox>
          </EmojiAndDetails>
          <ActionButton type="button" onClick={onCreate} outline={true}>Create party</ActionButton>
        </RoundedOptionBox>
      </div>
    );
  } else if (showAttendees) {
    return (
      <div>
        <BoxContainer>
          <BlackBackgroundBox/>
          <OptionBox $color={event?.colors.primary ?? '#fff'} $loading={event === undefined} $cancelled={isCancelled}>
            <EmojiAndDetails onClick={onImageClick}>
              {image}
            </EmojiAndDetails>
          </OptionBox>
          <AttendeesBoxRow>
            <AttendeesBox>
              {boxContents}
            </AttendeesBox>
            {event?.isDraft && <KebabMenu options={menuOptions} onClick={(id) => onMenuClick(event.id, id)}/>}
          </AttendeesBoxRow>
        </BoxContainer>
      </div>
    );
  } else {
    return (
      <div>
        <RoundedOptionBox $color={event?.colors.primary ?? '#fff'} $loading={event === undefined}>
          <EmojiAndDetails onClick={onImageClick}>
            {image}
            <AttendeesTextBox>
              {boxContents}
            </AttendeesTextBox>
            <DetailsBox>
              <OptionRow>
                {title ?? <>&nbsp;</>}
              </OptionRow>
              <OptionRow>
                {event?.location.displayName ?? <>&nbsp;</>}
              </OptionRow>
              <OptionRow>
                {(event?.startTime ?? 0) > 0 ? dateFormat(event?.timeZone).format(event?.startTime) : <>&nbsp;</>}
              </OptionRow>
              <OptionRow>
                {timeString !== '' ? timeString : <>&nbsp;</>}
              </OptionRow>
            </DetailsBox>
          </EmojiAndDetails>
        </RoundedOptionBox>
      </div>
    );
  }
};

const AttendeesBoxRow = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
`;

const AttendeesBox = styled.div<{ $loading?: boolean }>`
  border-radius: 0 10px;
  ${({ $loading }) => $loading ? 'border-top: 0' : ''};
  transition: background-color 1s;
  flex: 1;
`;

const TitleText = styled.div<{ $color?: string }>`
  font-size: 16px;
  font-weight: 500;
`;

const GeneralText = styled.div`
  font-size: 16px;
`;

const AttendeesTextBox = styled.div`
  position: absolute;
  right: 0;
  margin-right: 10px;
  background-color: var(--sp-bg-color);
  padding: 10px;
  border-radius: 25px 25px;
  box-shadow: 0 24px 24px 0 rgba(var(--sp-text-rgb), 0.2);

`;

const StickyElement = styled.div`
  position: absolute;
  bottom: 0; // Align to the bottom
  right: 0; // Align to the right

  padding: 8px 16px;
  //color: rgba(var(--sp-text-rgb), 0.60);
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-weight: 500;
  border-radius: 9px 0;
  //background: rgba(var(--sp-bg-rgb), 0.70);
  background: rgba(255, 255, 255, 0.70);
  //border: 1px solid var(--sp-border-color);
  backdrop-filter: blur(5px);
`;

const OptionBox = styled.div<{ $color: string, $loading?: boolean, $cancelled?: boolean }>`
  background-color: ${({ $color, $cancelled }) =>
    $cancelled ?
      `rgba(${parseInt($color.slice(1, 3), 16)}, ${parseInt($color.slice(3, 5), 16)}, ${parseInt(
        $color.slice(5, 7), 16)}, 0.6)` :
      'rgba(235,235,235,0.2)'
  };
  z-index: 2;
  color: ${({ $color, $cancelled }) => ($cancelled ? 'rgba(var(--sp-bg-rgb), 0.6)' : getDefaultTextColor($color))};
  font-size: 18px;
  display: flex;
  border-radius: 10px;
  border-bottom: 0;
  transition: background-color 1s;
  flex-direction: column;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;

  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale(1.02) translateY(-6px);
  }

  &:active {
    transform: scale(0.99) translateY(0px);;
  }

  &:has(+ ${AttendeesBox}) {
    ${({ $loading }) => $loading ? 'border-bottom: 0' : ''};
  }
`;

const RoundedOptionBox = styled.div<{ $color: string, $loading?: boolean }>`
  position: relative;
  background-color: ${({ $color }) => $color};
  padding: 20px;
  color: white;
  font-size: 18px;
  display: flex;
  border-radius: 10px;
  border-bottom: 0;
  transition: background-color 1s;
  flex-direction: column;
`;

const Photo = styled.img<{ $cancelled?: boolean }>`
  width: 311px;
  height: 311px;
  object-fit: contain;
  vertical-align: top;
  border-radius: 10px;
  opacity: ${({ $cancelled }) => ($cancelled ? '0.6' : '1')};
  box-shadow: 0 0 10px 0 rgba(var(--sp-text-rgb), 0.10);

  @media (${DeviceQueries.mobile}) {
    width: 242px;
    height: auto;
    aspect-ratio: 1;
  }
`;

const EmptyPhoto = styled.div`
  width: 311px;
  height: 311px;

  border-radius: 10px;
  background: #D1D1D1;

  @media (${DeviceQueries.mobile}) {
    width: 242px;
    height: 242px;
  }
`;

const PhotoBox = styled.div`
`;

const DetailsBox = styled.div`
  flex: 2;
`;
const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BlackBackgroundBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px 0;
`;

const OptionRow = styled.div`
  margin: 5px 0;
`;

const EmojiAndDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1; /* Take up the available horizontal space */
  align-items: start;
  cursor: pointer;
`;

const EventDetailsText = styled.div`
  color: rgba(var(--sp-text-rgb), 0.70);
  font-size: 16px;
  font-weight: 500;
`;

export default EventOption;
