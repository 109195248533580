import React from 'react';

const Facebook = ({ size = 24, color = 'currentColor' }: { size?: number, color?: string }) => {
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9631_7774)">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3 0H21C22.654 0 24 1.345 24 3V21C24 22.654 22.654 24 21 24H16.5V15.75H18.75L20.25 12H16.5V9C16.5 8.23892 17.0678 8.24331 17.8026 8.24899C17.8672 8.24949 17.9331 8.25 18 8.25H19.5V4.5H16.5C14.014 4.5 12 6.514 12 9V12H9V15.75H12V24H3C1.345 24 0 22.654 0 21V3C0 1.345 1.345 0 3 0Z"
            fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_9631_7774">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>;
};

export default Facebook;
