import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import DropdownSelector from './icons/DropdownSelector';
import { getEventTintColor } from '../lib/colorPicker';
import { useBackgroundContext } from '../contexts/BackgroundContext';

interface Props<T> {
  values: DropdownItem<T>[];
  label?: string;
  defaultText?: ReactNode;
  disabled?: boolean;
  value: T;
  onChange: (value: T) => void;
}

export interface DropdownItem<T> {
  id: T;
  label: string;
  selectedLabel?: string;
  displayOnly?: boolean;
}

const Dropdown = <T, >({
                         values,
                         label,
                         defaultText = '',
                         disabled,
                         value,
                         onChange
                       }: Props<T>) => {
  const backgroundContext = useBackgroundContext();

  const [showMenu, setShowMenu] = useState(false);
  const menuDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (menuDivRef.current && !menuDivRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const onDropdownClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMenuClick = (e: React.MouseEvent, id: T) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(false);
    onChange(id);
  };

  const selectedValue = values.find((v) => v.id === value);
  const buttonText = selectedValue?.selectedLabel ?? selectedValue?.label ?? defaultText;
  const selectableValues = values.filter((v) => !v.displayOnly);

  return <DropdownButtonContainer>
    <ButtonContainer>
      <DropdownButton disabled={disabled} onClick={onDropdownClick}>
        <ContentContainer>
          { label !== undefined && <Label>{label}</Label> }
          <TextWrapper>{buttonText}</TextWrapper>
        </ContentContainer>
        <DropdownSelector/>
      </DropdownButton>
    </ButtonContainer>
    <DropdownMenuAnchor>
      {showMenu && <DropdownMenu ref={menuDivRef}>
        {
          selectableValues.map((v) => <MenuItem
            key={`${v.id}`}
            onClick={(e) => handleMenuClick(e, v.id)}
            $selected={v.id === value}
            $highlightColor={getEventTintColor(backgroundContext.colors.primary)}
          >
            {v.label}
          </MenuItem>)
        }
      </DropdownMenu>}
    </DropdownMenuAnchor>
  </DropdownButtonContainer>;
};

const TextWrapper = styled.div`
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  color: var(--sp-text-color);
`;

const Label = styled(TextWrapper)`
  color: rgba(var(--sp-text-rgb), 0.60);
`;

const ContentContainer = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const DropdownButton = styled.button`
  border: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
  padding: 8px 10px;
  font-family: Plus Jakarta Sans, sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`;

const DropdownMenuAnchor = styled.div`
  height: 0;
  position: relative;
  z-index: 200;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 12px;
  right: 0;
  color: black;
  border-radius: 12px;
  background: rgba(var(--sp-bg-rgb), 0.90);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
  width: 100%;
  max-height: 225px;
  overflow-y: scroll;
`;

const MenuItem = styled.div<{
  $selected: boolean,
  $highlightColor: string
}>`
  cursor: pointer;
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(var(--sp-text-rgb), 0.60);
  border-bottom: 1px solid rgba(var(--sp-text-rgb), 0.06);

  ${({ $selected, $highlightColor }) => $selected && `
    background-color: rgba(255, 255, 255, 0.25);
    color: #FFFFFF;
  `};

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
    color: #FFFFFF;
  }

  &:first-of-type {
    border-radius: 12px 12px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }
`;

const DropdownButtonContainer = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  justify-content: center;
`;

export default Dropdown;
