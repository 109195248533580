import React from 'react';

const RightPaginationArrow = ({ color = 'currentColor', size = 19, opacity = 1 }: { color?: string, size?: number, opacity?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7.20483 6.17088H1.82468V4.83631H7.20483L5.14139 2.639L6.02758 1.69531L9.60385 5.50359L6.02758 9.31188L5.14139 8.36819L7.20483 6.17088Z"
            fill={color}/>
    </g>
  </svg>
;
};

export default RightPaginationArrow;
