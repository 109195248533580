import React, { FormEvent } from 'react';
import Envelope from '../icons/Envelope';
import PhoneInput from '../SingleFieldForm/PhoneInput';
import ErrorIcon from '../icons/ErrorIcon';
import styled from 'styled-components';
import Phone from '../icons/Phone';
import WhiteInput from '../forms/WhiteInput';

interface Props {
  phone: string;
  email: string;
  showPhoneInput: boolean;
  switchToEmail: () => void;
  switchToPhone: () => void;
  handleEmailChange: (value: string) => void;
  handlePhoneChange: (value: string, fullValue: string) => void;
  submitPhone?: (e: FormEvent) => Promise<void>;
  submitEmail?: (e: FormEvent) => Promise<void>;
  useMoreVisibleLabels?: boolean;
  error?: string;
  setError?: React.Dispatch<React.SetStateAction<string>>;
  includeMargin?: boolean;
  autoFocus?: boolean;
}

const SinglePhoneEmailInput = ({
                                 showPhoneInput,
                                 phone,
                                 email,
                                 switchToEmail,
                                 switchToPhone,
                                 submitEmail,
                                 submitPhone,
                                 handleEmailChange,
                                 handlePhoneChange,
                                 useMoreVisibleLabels,
                                 error = '',
                                 setError,
                                 includeMargin,
                                 autoFocus = true
                               }: Props) => {
  const goToEmail = () => {
    setError && setError('');
    switchToEmail();
  };

  const goToPhone = () => {
    setError && setError('');
    switchToPhone();
  };

  const phoneInput = submitPhone
    ? <InputForm onSubmit={submitPhone}>
      <PhoneInput
        autoFocus={autoFocus}
        placeholder="(123) 456-7890"
        isInvalid={error !== ''}
        autoComplete="tel"
        value={phone}
        required={true}
        onChange={handlePhoneChange}
        disabled={false}
      />
    </InputForm>
    : <PhoneInput
      autoFocus={autoFocus}
      placeholder="(123) 456-7890"
      isInvalid={error !== ''}
      autoComplete="tel"
      value={phone}
      required={true}
      onChange={handlePhoneChange}
      disabled={false}
    />;

  const emailInput = submitEmail
    ? <InputForm onSubmit={submitEmail}>
      <WhiteInput
        autoComplete="email"
        autoFocus={autoFocus}
        placeholder="stella@sunshine.com"
        $isInvalid={error !== ''}
        disabled={false}
        value={email}
        onChange={(e) => handleEmailChange(e.currentTarget.value)}
      />
    </InputForm>
    : <WhiteInput
      autoComplete="email"
      autoFocus={autoFocus}
      placeholder="stella@sunshine.com"
      $isInvalid={error !== ''}
      disabled={false}
      value={email}
      onChange={(e) => handleEmailChange(e.currentTarget.value)}
    />;

  return showPhoneInput
    ? <LoginInput $includeMargin={includeMargin}>
      <LoginInputHeader>
        <LoginInputHeaderTitle
          $isInvalid={error !== ''}
          $useMoreVisible={useMoreVisibleLabels}>
          {useMoreVisibleLabels ? 'Your phone' : 'Phone'}
        </LoginInputHeaderTitle>
        <LoginInputHeaderButton>
          <LoginInputHeaderButtonText onClick={goToEmail}>
            <Envelope size={14}/> Use Email Instead
          </LoginInputHeaderButtonText>
        </LoginInputHeaderButton>
      </LoginInputHeader>
      {phoneInput}
      {
        error && <ErrorContainer>
          <ErrorIcon/> <span>{error}</span>
        </ErrorContainer>
      }
    </LoginInput>
    : <LoginInput $includeMargin={includeMargin}>
      <LoginInputHeader>
        <LoginInputHeaderTitle
          $isInvalid={error !== ''}
          $useMoreVisible={useMoreVisibleLabels}>
          {useMoreVisibleLabels ? 'Your email' : 'Email'}
        </LoginInputHeaderTitle>
        <LoginInputHeaderButton>
          <LoginInputHeaderButtonText onClick={goToPhone}>
            <Phone/> Use Phone Instead
          </LoginInputHeaderButtonText>
        </LoginInputHeaderButton>
      </LoginInputHeader>
      {emailInput}
      {
        error && <ErrorContainer>
          <ErrorIcon/> <span>{error}</span>
        </ErrorContainer>
      }
    </LoginInput>;
};

export const LoginInput = styled.div<{
  $includeMargin?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 6px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  ${({ $includeMargin }) => $includeMargin && 'margin: 20px 0;'};
`;

const LoginInputHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
`;

const LoginInputHeaderTitle = styled.div<{
  $isInvalid?: boolean,
  $useMoreVisible?: boolean
}>`
  font-size: ${({ $useMoreVisible }) => $useMoreVisible ? '16px' : '14px'};
  font-weight: ${({ $useMoreVisible }) => $useMoreVisible ? '400' : '500'};
  ${({ $isInvalid, $useMoreVisible }) => $isInvalid ? 'color: #B3251E;' : !$useMoreVisible ? 'opacity: 65%;' : ''}
  color: var(--sp-text-color);
`;

const LoginInputHeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

const LoginInputHeaderButtonText = styled.div`
  font-size: 14px;
  font-weight: 500;
  opacity: 65%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const InputForm = styled.form`
  display: contents;
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: 6px;
  color: #B3251E;
  font-size: 12px;
  align-items: center;
`;

export default SinglePhoneEmailInput;
