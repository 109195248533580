import React from 'react';
import styled from 'styled-components';

import { IUToastConfig } from '../contexts/ToastContext';
import InformationCircleOutline from './icons/InformationCircleOutline';

import { DeviceQueries } from 'Common/src/components/styled';

const Toast = ({ config }: { config: IUToastConfig }) => {
  return <Notification $topCenter={config.positionTop}>
    <InformationCircleOutline/>
    <TextContainer>
      {config.contents}
      {config.actionText && <>{' '}<ActionText onClick={config.onActionClick}
                                               $useEventColor={config.useEventColor}>{config.actionText}</ActionText></>}
    </TextContainer>
  </Notification>;
};

const Notification = styled.div<{ $topCenter?: boolean }>`
  position: absolute;
  ${({ $topCenter }) => $topCenter ? 'top: 50px; left: 50%; transform: translateX(-50%);' : 'bottom: 70px; right: 24px;'};
  //bottom: 24px;
  //right: 24px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(var(--sp-text-rgb), 0.10);
  background: var(--sp-bg-color);
  box-shadow: 0 0 10px 0 rgba(var(--sp-text-rgb), 0.05);
  color: var(--sp-text-color);
  max-width: 50%;
  box-sizing: border-box;
  z-index: 15;

  @media (${DeviceQueries.mobile}) {
    position: static;
    bottom: auto;
    right: auto;
    transform: none;
    ${({ $topCenter }) => !$topCenter && 'border-radius: 0;'};
    max-width: 100vw;
    width: 100vw;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const ActionText = styled.span<{ $useEventColor?: boolean }>`
  color: ${({ $useEventColor }) => $useEventColor ? 'var(--shine-bg-color)' : '#FF4747'};
  cursor: pointer;
  text-decoration: underline;
`;

export default Toast;
