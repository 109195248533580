import { v4 as uuidv4 } from 'uuid';

import { IUUserContext } from '../contexts/UserContext';
import { createUnauthHeader } from '../lib/performance';
import logRpc from '../util/logRpc';

import { augmentService } from 'Common/src/lib/ThriftServiceAugmentor';
import Net from 'Common/src/lib/Net';

import {
  TAppElkEvent,
  TAppElkImageStyle, TElkGenerateEventFromMadLibsRequest, TElkGenerateEventFromMadLibsResponse,
  TElkGenerateImageRequest,
  TElkGenerateImageResponse,
  TElkGenerateSampleImageRequest,
  TElkGetEventDescriptionRequest,
  TElkGetEventDescriptionResponse,
  TElkGetSearchKeywordsRequest,
  TElkGetSearchKeywordsResponse,
  TElkGetThemeSuggestionRequest,
  TElkGetThemeSuggestionResponse
} from 'TProtocol/prototypes/events/messages';
import { TElkThemeService } from 'PProtocol/prototypes/events';
import { IUEvent } from '../lib/event';

const createService = (): TElkThemeService.Client => {
  return augmentService(Net.createService(TElkThemeService, '/json/ElkThemeService'));
};

export const getEventThemeSuggestion = async (userContext: IUUserContext,
  eventName: string): Promise<TElkGetThemeSuggestionResponse> => {
  logRpc(userContext, 'getEventThemeSuggestion');
  const request: TElkGetThemeSuggestionRequest = new TElkGetThemeSuggestionRequest({
    eventName
  });

  const client: TElkThemeService.Client = createService();

  const response = await client.getEventThemeSuggestion(createUnauthHeader(), request);
  logRpc(userContext, 'getEventThemeSuggestion', true);
  return response;
};

export const generateEventImage = async (userContext: IUUserContext, event: IUEvent, useVerbatim: boolean,
  prompt: string | undefined, style?: TAppElkImageStyle): Promise<TElkGenerateImageResponse> => {
  logRpc(userContext, 'generateEventImage');
  const request: TElkGenerateImageRequest = new TElkGenerateImageRequest({
    eventName: event.title,
    eventUuid: event.id,
    prompt,
    useVerbatim: useVerbatim,
    taskId: uuidv4(),
    style
  });

  const client: TElkThemeService.Client = createService();

  const response = await client.generateEventImage(userContext.getAuthHeader(), request);
  logRpc(userContext, 'generateEventImage', true);
  return response;
};

export const sampleEventImage = async (
  userContext: IUUserContext,
  event: IUEvent,
  remainingCoinBalance: number,
  useVerbatim: boolean,
  prompt: string | undefined,
  style?: TAppElkImageStyle
): Promise<TElkGenerateImageResponse> => {
  logRpc(userContext, 'generateEventImage');
  const request: TElkGenerateSampleImageRequest = new TElkGenerateSampleImageRequest({
    eventName: event.title,
    eventUuid: event.id,
    prompt,
    useVerbatim: useVerbatim,
    taskId: uuidv4(),
    remainingCoinBalance,
    style
  });

  const client: TElkThemeService.Client = createService();

  const response = await client.sampleEventImage(createUnauthHeader(), request);
  logRpc(userContext, 'generateEventImage', true);
  return response;
};

export const generateEventFromMadLibs = async (
  userContext: IUUserContext,
  event: TAppElkEvent,
  theme: string
): Promise<TElkGenerateEventFromMadLibsResponse> => {
  logRpc(userContext, 'generateEventFromMadLibs');
  const request: TElkGenerateEventFromMadLibsRequest = new TElkGenerateEventFromMadLibsRequest({
    eventName: event.details.title,
    location: event.details.location,
    startTime: event.details.startTime,
    endTime: event.details.endTime ?? 0,
    theme: theme,
    eventId: event.id
  });

  const client: TElkThemeService.Client = createService();
  const response = await client.generateEventFromMadLibs(createUnauthHeader(), request);
  logRpc(userContext, 'generateEventFromMadLibs', true);
  return response;
};

// authed version of madlibs, named differently since we are trying to get away from madlibs nomenclature
export const quickCreateEvent = async (
  userContext: IUUserContext,
  event: TAppElkEvent,
  theme: string
): Promise<TElkGenerateEventFromMadLibsResponse> => {
  logRpc(userContext, 'quickCreateEvent');
  const request: TElkGenerateEventFromMadLibsRequest = new TElkGenerateEventFromMadLibsRequest({
    eventName: event.details.title,
    location: event.details.location,
    startTime: event.details.startTime,
    endTime: event.details.endTime ?? 0,
    theme: theme,
    eventId: event.id
  });

  const client: TElkThemeService.Client = createService();

  const response = await client.quickCreateEvent(userContext.getAuthHeader(true), request);
  logRpc(userContext, 'quickCreateEvent', true);
  return response;
};

export const getSearchKeywords = async (
  userContext: IUUserContext,
  prompt: string
): Promise<TElkGetSearchKeywordsResponse> => {
  logRpc(userContext, 'getSearchKeywords');
  const request: TElkGetSearchKeywordsRequest = new TElkGetSearchKeywordsRequest({
    prompt
  });

  const client: TElkThemeService.Client = createService();
  const response = await client.getSearchKeywords(createUnauthHeader(), request);
  logRpc(userContext, 'getSearchKeywords', true);
  return response;
};

export const getEventDescription = async (
  userContext: IUUserContext,
  prompt: string,
  eventName: string,
  theme: string
): Promise<TElkGetEventDescriptionResponse> => {
  logRpc(userContext, 'getEventDescription');
  const request: TElkGetEventDescriptionRequest = new TElkGetEventDescriptionRequest({
    prompt,
    eventName,
    theme
  });

  const client: TElkThemeService.Client = createService();
  const response = await client.getEventDescription(createUnauthHeader(), request);
  logRpc(userContext, 'getEventDescription', true);
  return response;
};
