import React, { ReactNode, useContext, useState } from 'react';

import Toast from '../components/Toast';

export interface IUToastConfig {
  contents: string;
  actionText?: string;
  onActionClick?: () => void;
  positionTop?: boolean;
  useEventColor?: boolean;
}

export interface IUToastContext {
  show: (config: IUToastConfig) => void;
  hide: () => void;
  shown: boolean;
  content: ReactNode;
}

const ToastContext = React.createContext<IUToastContext | null>(null);

export function ToastContextProvider(props: { children: React.ReactNode }) {
  const [config, setConfig] = useState<IUToastConfig | null>(null);
  const [shown, setShown] = useState(false);

  const show = (config: IUToastConfig) => {
    setConfig(config);
    setShown(true);
  };

  const hide = () => {
    setConfig(null);
    setShown(false);
  };

  const context: IUToastContext = {
    show,
    hide,
    shown,
    content: config !== null ? <Toast config={config}/> : null
  };

  return <ToastContext.Provider value={context}>
    {props.children}
  </ToastContext.Provider>;
}

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (context === null) {
    throw new Error('useToastContext must be used within a ToastContextProvider');
  }
  return context;
};
