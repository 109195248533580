import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Location, useBlocker, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BasePageContent } from '../BasePage';
import { useEditEventContext } from '../../contexts/EditEventContext';
import { endUserEvent, startUserEvent, UserEventType } from '../../lib/performance';
import { ColorScheme, useBackgroundContext } from '../../contexts/BackgroundContext';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { useUserContext } from '../../contexts/UserContext';
import BasePageHeader, { UPageType } from '../BasePageHeader';
import { parseName } from '../../util/attendee';
import EventRow from './EventRow';
import { EventsFilterType, IUEvent, separateEvents } from '../../lib/event';
import FooterLinks from '../../components/FooterLinks';
import { CarouselWrapper, FilterContainer, FilterPill, InactiveFilterPill } from '../../components/PillToggle';
import { useAutoSaveContext } from '../../contexts/AutoSaveContext';
import { useModalContext } from '../../contexts/ModalContext';

import { DeviceQueries } from 'Common/src/components/styled';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../../core/libs/Firebase';

const MergeModal = lazy(() => import(
  /* webpackChunkName: "login" */
  /* webpackPrefetch: true */
  '../../components/modals/MergeModal'
  ));

interface EventsPageState {
  activeTab: EventsFilterType;
  scrollTop?: number;
}

const FilterLabels: {
  [key in EventsFilterType]: string;
} = {
  [EventsFilterType.All]: 'All',
  [EventsFilterType.HostedByYou]: 'Hosted by you',
  [EventsFilterType.Upcoming]: 'Upcoming',
  [EventsFilterType.Past]: 'Past',
  [EventsFilterType.Drafts]: 'Drafts'
};

const EventsPage = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location<EventsPageState>;
  const eventContext = useEditEventContext();
  const userContext = useUserContext();
  useBlocker(() => {
    const scrollTop = containerRef.current?.scrollTop ?? 0;
    if (!savedScrollRef.current) {
      savedScrollRef.current = true;
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location?.state,
          scrollTop
        }
      });
    }
    modalContext.hide();
    return false;
  });
  const eventCacheContext = useEventCacheContext();
  const autoSaveContext = useAutoSaveContext();
  const backgroundContext = useBackgroundContext();
  const modalContext = useModalContext();
  const [eventsByFilter, setEventsByFilter] = useState(separateEvents([], userContext.id ?? ''));
  const [filteredEvents, setFilteredEvents] = useState<IUEvent[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const savedScrollRef = useRef(false);

  const activeTab = (location.state?.activeTab as EventsFilterType) || EventsFilterType.All;

  const drafts = autoSaveContext.drafts;

  useEffect(() => {
    document.title = 'Shine Parties - My Parties';
    logEvent(firebaseAnalytics, 'page_view', {
      page_title: 'Events - Analytics'
    });

    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
    void loadEvents();
    eventContext.clearEvent();
  }, []);

  useEffect(() => {
    if (userContext.shouldShowMergeModal()) {
      modalContext.show({
        contents: <Suspense fallback={<div/>}><MergeModal fromRsvp={false} close={modalContext.hide}/></Suspense>
      })
    }
  }, [userContext.phones, userContext.emails])

  useEffect(() => {
    if (eventCacheContext.events !== undefined) {
      endUserEvent(userContext);
    }
  }, [eventCacheContext.events]);

  useEffect(() => {
    let eventsToShow: IUEvent[];

    if (eventCacheContext.events === undefined) {
      eventsToShow = [];
    } else {
      const separatedEvents = separateEvents(eventCacheContext.events, userContext.id ?? '');
      setEventsByFilter(separatedEvents);
      eventsToShow = separatedEvents[activeTab];
    }
    eventsToShow = eventsToShow.sort(sortEvents);

    if (activeTab === EventsFilterType.Drafts) {
      setFilteredEvents(autoSaveContext?.drafts ?? []);
      if (autoSaveContext?.drafts?.length === 0) {
        navigate('/events', { state: { activeTab: EventsFilterType.All }, replace: true });
      }
    } else {
      setFilteredEvents(eventsToShow);
    }

    setTimeout(() => {
      if (location.state?.scrollTop && containerRef.current) {
        containerRef.current.scrollTo({ top: location.state.scrollTop });
      }
    }, 0);
  }, [activeTab, eventCacheContext.events, userContext.id, autoSaveContext.drafts]);

  const [firstName] = parseName(userContext.name);

  const setActiveTab = (filter: EventsFilterType) => {
    navigate('/events', { state: { activeTab: filter }, replace: true });
  };

  const loadEvents = async () => {
    await eventCacheContext.fetchEvents();
    await autoSaveContext.fetchDrafts();
  };

  const onTap = (event: IUEvent) => {
    if (event.isDraft) {
      navigate('/event/draft/edit/' + event.id);
    } else {
      startUserEvent(UserEventType.LoadEventFromEvents);
      navigate('/event/' + event.id);
    }
  };

  const sortEvents = (a: IUEvent, b: IUEvent) => b.startTime - a.startTime;

  const renderEvents = () => {
    if (filteredEvents.length > 0) {
      return filteredEvents.map(event => (
        <EventRow key={event.id} event={event} showAttendees={true} onClick={() => onTap(event)}/>
      ));
    } else if (eventCacheContext.events !== undefined) {
      return null;
    } else {
      return [2, 3, 4, 5, 6, 7, 8].map(
        (i) => <EventRow showAttendees={true} key={i} onClick={() => null}/>
      );
    }
  };

  return (
    <>
      <BasePageHeader page={UPageType.Events}/>
      <BasePageContent $wide={true} $full={true} containerRef={containerRef}>
        <TopContent>
          {firstName && <GreetingText>
            Hey there, {firstName}!
          </GreetingText>}
          <FilterWrapper>
            <UpcomingEventsText>
              You have {eventsByFilter.UPCOMING.length} upcoming {eventsByFilter.UPCOMING.length !== 1 ? 'parties' : 'party'}.
            </UpcomingEventsText>
            <CarouselWrapper>
              <FilterContainer>
                {
                  Object.keys(FilterLabels).map((filterType: EventsFilterType) => {
                    if (filterType === EventsFilterType.Drafts && (drafts === undefined || drafts.length === 0)) {
                      return null;
                    }

                    const PillTag = filterType === activeTab ? FilterPill : InactiveFilterPill;
                    return <PillTag
                      key={filterType}
                      onClick={() => setActiveTab(filterType)}
                    >
                      {FilterLabels[filterType]} ({
                      filterType === EventsFilterType.Drafts ? drafts?.length : eventsByFilter[filterType].length
                    })
                    </PillTag>;
                  })
                }
              </FilterContainer>
            </CarouselWrapper>
          </FilterWrapper>
        </TopContent>
        <div>
          <CarouselWrapper>
            <EventsContainer>
              {renderEvents()}
              {eventCacheContext.events !== undefined ?
                <EventRow key="create" event={undefined} showAttendees={false} onClick={() => null} showCreate={true}
                          showPlus={filteredEvents.length > 0}/> : null}
            </EventsContainer>
          </CarouselWrapper>
          <FooterLinks/>
        </div>
      </BasePageContent>
    </>
  );
};

const GreetingText = styled.div`
  font-size: 40px;
  font-weight: 500;
  word-wrap: break-word;

  @media (${DeviceQueries.mobile}) {
    font-size: 30px;
  }
`;

const EventsContainer = styled.div`
  display: flex;
  gap: 46px 41px;

  @media not (${DeviceQueries.mobile}) {
    flex-wrap: wrap;
  }

  @media (${DeviceQueries.mobile}) {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: -17px; // Hide bottom scroll bar
    padding-bottom: 17px; // Prevent bottom content cut off
    gap: 0 20px;
    scroll-snap-type: x mandatory;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
  width: 100%;
  color: var(--sp-text-color);

  @media (${DeviceQueries.mobile}) {
    margin-bottom: 20px;
    gap: 0;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
    gap: 8px;
  }
`;

const UpcomingEventsText = styled.div`
  font-size: 28px;
  font-weight: 400;

  @media (${DeviceQueries.mobile}) {
    font-size: 20px;
  }
`;

export default EventsPage;

