import React, { ReactNode } from 'react';
import styled from 'styled-components';

import AttendeeCircleRow from './AttendeeCircleRow';
import { amIUnverified } from '../../lib/attendance';
import { IUEvent, IUInvitee } from '../../lib/event';

import { TAppElkMessage } from 'TProtocol/prototypes/events/messages';
import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  event: IUEvent;
  attendees?: (IUInvitee | undefined)[];
  isInvited?: boolean;
  myInviteeUuid: string | undefined;
  messagesByInviteeId: Map<string, TAppElkMessage[]>;
  onVerifyClick: () => void;
  onReplyClick: (inviteeId: string) => void;
}

const AttendeeGroup = ({
                         event,
                         attendees,
                         isInvited = false,
                         myInviteeUuid,
                         messagesByInviteeId,
                         onVerifyClick,
                         onReplyClick
                       }: Props) => {
  const readOnly = !!event.cancelledTimestamp;

  if (attendees === undefined) {
    const blanks: ReactNode[] = [];
    for (let i = 0; i < 12; i++) {
      blanks.push(<AttendeeCircleRow
        key={`blank-${i}`}
        isInvited={false}
        amIUnverified={false}
        amIHost={false}
        onVerifyClick={onVerifyClick}
        onReplyClick={onReplyClick}
        readOnly={readOnly}
      />);
    }

    return <Group>
      {blanks}
    </Group>;
  } else {
    const midpointIndex = Math.floor((attendees.length + 1) / 2);
    return <Group>
      <Column>
        {attendees.slice(0, midpointIndex).map((attendee, index) =>
          <AttendeeCircleRow
            key={attendee?.userId ?? index}
            attendee={attendee}
            responseMessages={attendee?.inviteeId ? messagesByInviteeId.get(attendee?.inviteeId) : undefined}
            isInvited={isInvited}
            amIUnverified={amIUnverified(attendee, myInviteeUuid)}
            amIHost={event.isHost}
            onVerifyClick={onVerifyClick}
            onReplyClick={onReplyClick}
            readOnly={readOnly}
          />)}
      </Column>
      <Column>
        {attendees.slice(midpointIndex).map((attendee, index) =>
          <AttendeeCircleRow
            key={attendee?.userId ?? index}
            attendee={attendee}
            responseMessages={attendee?.inviteeId ? messagesByInviteeId.get(attendee?.inviteeId) : undefined}
            isInvited={isInvited}
            amIUnverified={amIUnverified(attendee, myInviteeUuid)}
            amIHost={event.isHost}
            onVerifyClick={onVerifyClick}
            onReplyClick={onReplyClick}
            readOnly={readOnly}
          />)}
      </Column>
    </Group>;
  }
};

const Group = styled.div`
  display: flex;
  max-width: 100%;
  gap: 10%;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
    gap: 8px;
  }
`;

const Column = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`

export default AttendeeGroup;
