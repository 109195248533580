import { IUEvent, IULocation } from '../../lib/event';
import ThanksgivingPhoto from '../../assets/promos/thanksgiving.png';
import { TAppAddress } from 'TProtocol/common/models';
import OktoberfestPhoto from '../../assets/promos/oktoberfest.png';
import HalloweenPartyPhoto from '../../assets/promos/halloweenParty.png';
import { HOLIDAY_OFFSET, nextDate, nextEaster, nextMardisGras, nextUsaThanksgiving } from '../../lib/holidays';
import { InspirationTheme } from '../../lib/inspiration';

interface SampleEvent {
  event: IUEvent;
  until: Date;
  theme: InspirationTheme;
}

export const SampleSeasonalEvents: SampleEvent[] = [
  {
    event: new IUEvent({
      photoUrl: OktoberfestPhoto,
      backgroundAnimationUrl: ' https://videos.pexels.com/video-files/3291693/3291693-sd_426_240_25fps.mp4',
      description: 'Come join us for a festive Oktoberfest filled with hearty German food, refreshing drinks, and lively entertainment! Enjoy bratwurst, pretzels, and more while soaking in the vibrant atmosphere. Bring your family and friends for fun games and music — don\'t miss this celebration of Bavarian culture and community!',
      title: 'Oktoberfest Celebration',
      startTime: nextDate(10, 5).setUTCHours(12),
      endTime: nextDate(10, 5).setUTCHours(16),
      location: new IULocation({
        displayName: 'The Berghoff Restaurant, Chicago, IL',
        address: new TAppAddress({
          address1: 'The Berghoff Restaurant, 17 West Adams Street',
          admin1: 'IL',
          city: 'Chicago',
          country: 'United States',
          postalCode: '60603'
        })
      }),
      timeZone: 'America/Chicago'
    }),
    until: nextDate(10, 1, HOLIDAY_OFFSET),
    theme: InspirationTheme.Holiday // TODO: Replace with Oktoberfest theme
  },
  {
    event: new IUEvent({
      photoUrl: HalloweenPartyPhoto,
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3189462/3189462-sd_426_240_24fps.mp4',
      description: 'You\'re invited to a spooktacular Halloween bash at our home! Join us for a night of eerie fun, delicious treats, and chilling drinks. Dress up in your best costume and get ready for games, music, and plenty of laughs. Bring your friends and family for a hauntingly good time — we can\'t wait to celebrate with you!',
      title: 'Spooky Soirée',
      startTime: nextDate(10, 31).setUTCHours(23),
      endTime: nextDate(11, 1).setUTCHours(3),
      location: new IULocation({
        displayName: '45 Stillwater Ave, Bangor, ME 04401',
        address: new TAppAddress({
          address1: '45 Stillwater Avenue',
          admin1: 'ME',
          city: 'Bangor',
          country: 'United States',
          postalCode: '04401'
        })
      })
    }),
    until: nextDate(10, 31, HOLIDAY_OFFSET),
    theme: InspirationTheme.Halloween
  },
  {
    event: new IUEvent({
      photoUrl: ThanksgivingPhoto,
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3198159/3198159-sd_426_240_25fps.mp4',
      description: 'Gather with us for a heartwarming Thanksgiving celebration, where we\'ll express gratitude and enjoy a bountiful feast. Expect a day filled with laughter, traditional Thanksgiving dishes, and the company of friends and family in a cozy, festive atmosphere.',
      title: 'Taylor Family Thanksgiving',
      startTime: nextUsaThanksgiving().setUTCHours(0),
      endTime: 0,
      location: new IULocation({
        displayName: 'Chileno Valley Ranch, Petaluma, CA',
        address: new TAppAddress({
          address1: 'Chileno Valley Ranch, 5105 Chileno Valley Road',
          admin1: 'CA',
          city: 'Petaluma',
          country: 'United States',
          postalCode: '94952'
        })
      })
    }),
    until: nextUsaThanksgiving(HOLIDAY_OFFSET),
    theme: InspirationTheme.Thanksgiving
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FHoliday_Happy_Hour.jpg?alt=media',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/857032/857032-sd_640_360_30fps.mp4',
      description: 'Come celebrate the season with us! Join us for holiday treats, warm cocktails, and joyful music, surrounded by sparkling decorations. Dress in your holiday best, bring your loved ones, and let’s ring in the holidays together!',
      title: 'Holiday Cocktails',
      startTime: nextDate(12, 10).setHours(14),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '245 Maplewood Drive',
          admin1: 'NY',
          city: 'Kingston',
          country: 'United States',
          postalCode: '12401'
        })
      }),
      colors: {
        primary: '#FFC008',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(12, 25, HOLIDAY_OFFSET),
    theme: InspirationTheme.Holiday
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFestive_Gathering.jpg?alt=media',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/855167/855167-sd_640_360_30fps.mp4',
      description: 'You’re invited to join our family for an evening filled with holiday cheer! Gather with us for festive treats, warm drinks, and holiday music. Dress in your holiday best, bring your loved ones, and let’s celebrate the season together!',
      title: 'Our Annual Holiday Party',
      startTime: nextDate(12, 16).setHours(18),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '128 Willowbrook Lane',
          admin1: 'OR',
          city: 'Ashland',
          country: 'United States',
          postalCode: '97520'
        })
      }),
      colors: {
        primary: '#025B27',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(12, 25, HOLIDAY_OFFSET),
    theme: InspirationTheme.Holiday
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FNew_Years_Eve_Party.png?alt=media',
      title: 'New Year\'s Eve Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5926164/5926164-sd_426_240_30fps.mp4',
      description: 'Join us for a night of glitz, glam, and good times as we countdown to to the New Year! We\'ll have delicious food, refreshing drinks, and electrifying music. Get ready to dance the night away and celebrate the start of a new year with friends and family.',
      startTime: nextDate(12, 31).setHours(19),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '312 Willowbrook Lane',
          admin1: 'CT',
          city: 'Stamford',
          country: 'United States',
          postalCode: '06902'
        })
      }),
      colors: {
        primary: '#5A36E9',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(1, 1, HOLIDAY_OFFSET),
    theme: InspirationTheme.NewYears
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Fsuggestions%2Fa292303c-dbaf-4471-82fe-4929cfc32e02%2Fc6437167-0501-4139-ba5f-22a4562ea5ca?alt=media',
      title: 'Toast the New Year',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/854394/854394-sd_640_360_30fps.mp4',
      description: 'As the clock strikes midnight, let\'s raise a glass and toast to the New Year! Join us for a night of fun and festivities as we say goodbye to 2024 and hello to a brand new year.',
      startTime: nextDate(12, 31).setHours(19),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '321 New Ave.',
          admin1: 'CA',
          city: 'Silverlake',
          country: 'United States',
          postalCode: '90039'
        })
      }),
      colors: {
        primary: '#009688',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(1, 1, HOLIDAY_OFFSET),
    theme: InspirationTheme.NewYears
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2Flunar_ny.jpeg?alt=media',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/6691596/6691596-sd_240_426_24fps.mp4',
      description: 'Join us for a fun-filled evening of cooking, laughter and good fortune. We\'ll be making delicious dumplings, sharing stories and welcoming the Year of the Snake.',
      title: 'Lunar New Year',
      startTime: nextDate(1, 29).setHours(18),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '245 Maplewood Drive',
          admin1: 'NY',
          city: 'Kingston',
          country: 'United States',
          postalCode: '12401'
        })
      }),
      colors: {
        primary: '#FF0000',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(1, 29, HOLIDAY_OFFSET),
    theme: InspirationTheme.LunarNewYear
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FFootball_Rocket.jpg?alt=media',
      title: 'Super Bowl Watch Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7005865/7005865-sd_426_240_24fps.mp4',
      description: 'Let\'s huddle up for the big game! Join us for a Super Bowl showdown! 🏈🍔🍻\n\nBring your favorite team\'s jersey and your appetite. Hope you can make it!',
      startTime: nextDate(2, 9).setHours(15),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '789 Super St.',
          admin1: 'CA',
          city: 'Sonoma',
          country: 'United States',
          postalCode: '95476'
        })
      }),
      colors: {
        primary: '#448AFF',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(2, 10, HOLIDAY_OFFSET),
    theme: InspirationTheme.Football
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FGalentines_Celebration.jpg?alt=media',
      title: 'Galentine\'s Dinner',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5734066/5734066-sd_426_240_30fps.mp4',
      description: 'Let\'s celebrate the girls! What better way to celebrate the day of love than with your favorite gals?\n\nJoin us for a Galentine\'s Day Brunch filled with love, laughter, and mimosas!',
      startTime: nextDate(2, 14).setHours(19),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '123 Heart Lane',
          admin1: 'TN',
          city: 'Nashville',
          postalCode: '37219'
        })
      }),
      colors: {
        primary: '#5A36E9',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(2, 15, HOLIDAY_OFFSET),
    theme: InspirationTheme.Galentines
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finspirations%2FValentines_Day_Soiree.jpg?alt=media',
      title: 'Valentine\'s Day Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3027640/3027640-sd_426_240_25fps.mp4',
      description: 'Join us in celebrating the day of love with a night of sweets, wine, and all things delicious and divine.\n\nWe hope you can make it!',
      startTime: nextDate(2, 14).setHours(19),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '321 Heart Lane',
          admin1: 'TN',
          city: 'Nashville',
          postalCode: '37219'
        })
      }),
      colors: {
        primary: '#FF4747',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(2, 15, HOLIDAY_OFFSET),
    theme: InspirationTheme.Valentines
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finstant_invites%2F842c8ace-b37b-4c41-bae9-553bffac016e.jpg?alt=media',
      title: 'Mardis Gras Feast',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/9750258/9750258-sd_240_426_25fps.mp4',
      description: 'A Feast for the Senses! You\'re invited to a vibrant Mardi Gras celebration. Join us for food, music, and dancing!\n\nWe hope you can make it!',
      startTime: nextMardisGras().setHours(19),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: 'Bourbon St.',
          admin1: 'LA',
          city: 'New Orleans',
          postalCode: '70130'
        })
      }),
      colors: {
        primary: '#009688',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextMardisGras(HOLIDAY_OFFSET),
    theme: InspirationTheme.MardisGras
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finstant_invites%2F7f72107c-263d-4481-8ac3-0e6045031415.jpg?alt=media',
      title: 'St. Patrick\'s Day Party',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/5085171/5085171-sd_426_240_30fps.mp4',
      description: 'Get ready to don your green and raise a glass! You\'re invited to a St. Patrick\'s Day celebration filled with Irish music, delicious food, and good cheer. We look forward to celebrating with you!',
      startTime: nextDate(3, 17).setHours(17),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '123 Lucky Ave.',
          admin1: 'CA',
          city: 'San Jose',
          postalCode: '95112'
        })
      }),
      colors: {
        primary: '#004b00',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextDate(3, 17, HOLIDAY_OFFSET),
    theme: InspirationTheme.SaintPatricks
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finstant_invites%2F38144daa-ffaf-4d02-8fae-3b57431ccd0d.jpg?alt=media',
      title: 'Easter Brunch',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/3992010/3992010-sd_426_226_25fps.mp4',
      description: 'Celebrate Easter with us! You\'re invited to a delightful brunch filled with spring flavors and Easter fun. We hope you can make it!',
      startTime: nextEaster().setHours(12),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '123 Bunny St.',
          admin1: 'MA',
          city: 'Springfield',
          postalCode: '01115'
        })
      }),
      colors: {
        primary: '#ff4747',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextEaster(HOLIDAY_OFFSET),
    theme: InspirationTheme.Easter
  },
  {
    event: new IUEvent({
      photoUrl: 'https://firebasestorage.googleapis.com/v0/b/elk-ga/o/events%2Finstant_invites%2F54bf55a3-c7aa-45a4-8bdd-35f075920fb0.jpg?alt=media',
      title: 'Easter Egg Hunt',
      backgroundAnimationUrl: 'https://videos.pexels.com/video-files/7957514/7957514-sd_240_426_24fps.mp4',
      description: 'Get ready for some egg-citing fun! You\'re invited to our annual Easter Egg Hunt! We\'ll have hidden eggs, treats, and spring-time fun for all ages.',
      startTime: nextEaster().setHours(12),
      endTime: 0,
      location: new IULocation({
        address: new TAppAddress({
          address1: '123 Bunny St.',
          admin1: 'MA',
          city: 'Springfield',
          postalCode: '01115'
        })
      }),
      colors: {
        primary: '#f4bdff',
        secondary: '',
        highlightText: '',
        text: '',
        buttonTextColor: ''
      }
    }),
    until: nextEaster(HOLIDAY_OFFSET),
    theme: InspirationTheme.Easter
  }
];

export const getUpcoming = () => {
  return SampleSeasonalEvents.sort((a, b) => a.until.getTime() -  b.until.getTime()).slice(0, 2);
};
