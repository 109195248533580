import { IUAutoCompleteItem } from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';
import { v4 as uuidv4 } from 'uuid';

import { TAppContactLite, TAppPhotoLite } from 'TProtocol/contactlite/messages';
import { TAppElkAttendeeStatus, TAppElkMutualMember } from 'TProtocol/prototypes/events/messages';
import { IUEvent, IUInvitee } from '../lib/event';
import { IUUserContext } from '../contexts/UserContext';

type UAutoCompleteContact = {
  isContact: true;
  isMember: false;
  isInvitee: false;
  userId?: string;
  name: string;
  email: string;
  photoUrl?: string;
}

type UAutoCompleteMutualMember = {
  isContact: false;
  isMember: true;
  isInvitee: false;
  userId: string;
  name: string;
  email?: string;
  photoUrl?: string;
  sharedEvents: string[];
}

type UAutoCompleteInvitee = {
  isContact: boolean;
  isMember: false;
  isInvitee: true;
  userId?: string;
  invitee: IUInvitee;
  name: string;
  email?: string;
  photoUrl?: string;
}

export type IUAutoCompleteContact = UAutoCompleteContact | UAutoCompleteMutualMember | UAutoCompleteInvitee;

export const isMutualMember = (contact: IUAutoCompleteContact): contact is UAutoCompleteMutualMember => {
  return contact.isMember;
};

export const extractNameFromContactLite = (contact?: TAppContactLite): string => {
  if (contact === undefined) {
    return '';
  }

  for (const field of contact.fields) {
    if (field.name !== undefined) {
      return field.name.fullName;
    }
  }

  return '';
};

export const extractPhoneFromContactLite = (contact: TAppContactLite): string => {
  for (const field of contact.fields) {
    if (field.phone !== undefined) {
      return field.phone.phoneNumber;
    }
  }

  return '';
};

export const extractEmailFromContactLite = (contact: TAppContactLite): string => {
  for (const field of contact.fields) {
    if (field.email !== undefined) {
      return field.email.emailAddress;
    }
  }

  return '';
};

export const extractEmailsFromContactLite = (contact: TAppContactLite): string[] => {
  const emails: string[] = [];

  for (const field of contact.fields) {
    if (field.email !== undefined) {
      emails.push(field.email.emailAddress);
    }
  }

  return emails;
};

export const extractPhotoFromContactLite = (contact?: TAppContactLite): TAppPhotoLite | undefined => {
  if (contact === undefined) {
    return undefined;
  }

  for (const field of contact.fields) {
    if (field.photo !== undefined) {
      return field.photo;
    }
  }

  return undefined;
};

const byName = (a: TAppContactLite, b: TAppContactLite) => {
  return (extractNameFromContactLite(a) ?? '') < (extractNameFromContactLite(b) ?? '') ? -1 : 1;
};

export const filterContactLitesByName = (contacts: TAppContactLite[], nameFilter?: string) => {
  return contacts.filter(contact => {
    const name = extractNameFromContactLite(contact);
    return name && (!nameFilter || name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1);
  }).sort(byName);
};

export const filterContactLitesByEmail = (contacts: TAppContactLite[], emailFilter?: string) => {
  return contacts.filter(contact => {
    if (emailFilter === undefined) {
      return true;
    }

    const emails = extractEmailsFromContactLite(contact);
    for (const email of emails) {
      if (email.toLowerCase().indexOf(emailFilter.toLowerCase()) !== -1) {
        return true;
      }
    }

    return false;
  });
};

export const filterContextLites = (contacts?: TAppContactLite[], filter?: string) => {
  if (contacts === undefined) {
    return [];
  }

  return contacts.filter(contact => {
    if (filter === undefined) {
      return true;
    }

    const emails = extractEmailsFromContactLite(contact);
    for (const email of emails) {
      if (email.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        return true;
      }
    }

    const name = extractNameFromContactLite(contact);
    return name && (!filter || name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  });
};

export const filterMutualMembers = (mutualMembers?: TAppElkMutualMember[], filter?: string) => {
  if (mutualMembers === undefined) {
    return [];
  }

  return mutualMembers.filter(mutualMember => {
    if (filter === undefined) {
      return true;
    }

    for (const email of mutualMember.member.emails ?? []) {
      if (email.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        return true;
      }
    }

    const name = mutualMember.member.name;
    return !filter || name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  });
};

export const generateAutocompleteItemsFromContactLites = (contacts: TAppContactLite[]): IUAutoCompleteItem<UAutoCompleteContact>[] => {
  return contacts.flatMap((contact) => {
    const name = extractNameFromContactLite(contact);
    const photo = extractPhotoFromContactLite(contact);
    return extractEmailsFromContactLite(contact).map((email) => {
      return {
        id: uuidv4(),
        displayString: '',
        value: {
          isContact: true,
          isMember: false,
          isInvitee: false,
          name,
          email,
          photoUrl: photo?.photoUrl
        }
      };
    });
  });
};

export const generateAutocompleteItemsFromMutualMembers = (mutualMembers: TAppElkMutualMember[]): IUAutoCompleteItem<UAutoCompleteMutualMember>[] => {
  return mutualMembers.map((mutualMember) => {
    return {
      id: uuidv4(),
      displayString: '',
      value: {
        isContact: false,
        isMember: true,
        isInvitee: false,
        userId: mutualMember.member.userId,
        name: mutualMember.member.name,
        email: mutualMember.member.emails?.[0],
        photoUrl: mutualMember.member.photoUrl,
        sharedEvents: mutualMember.sharedEvents
      }
    };
  });
};

export const generateAutocompleteItemsFromInvitees = (attendees?: IUInvitee[]): IUAutoCompleteItem<UAutoCompleteInvitee>[] => {
  if (attendees === undefined) {
    return [];
  }
  return attendees.map((attendee) => {
    return {
      id: uuidv4(),
      displayString: '',
      value: {
        isContact: false,
        isMember: false,
        isInvitee: true,
        userId: attendee.userId,
        email: attendee.email,
        invitee: attendee,
        name: attendee.name ?? '',
        photoUrl: attendee.photoUrl,
        rsvpStatus: attendee.rsvpStatus
      }
    }
  })
}

export const removeExistingCohosts = (invitees: IUInvitee[], items: IUAutoCompleteItem<IUAutoCompleteContact>[]): IUAutoCompleteItem<IUAutoCompleteContact>[] => {
  const cohostUserIds = new Set<string | undefined>();

  invitees.forEach(invitee => {
    if (invitee.cohostStatus !== undefined) {
      cohostUserIds.add(invitee.userId);
    }
  })

  return items.filter(item => {
    return !cohostUserIds.has(item.value.userId);
  });
}

export const generateAutoCompleteItems = ({ userContext, event, search, usersOnly, includeAttendees } : {
  userContext: IUUserContext;
  search: string;
  event?: IUEvent;
  usersOnly?: boolean;
  includeAttendees?: boolean;
}) => {
  const contacts = filterContextLites(
    userContext.contacts,
    search
  );

  const mutualMembers = filterMutualMembers(
    userContext.mutualMembers,
    search
  );

  const byName = (a: IUAutoCompleteItem<IUAutoCompleteContact>, b: IUAutoCompleteItem<IUAutoCompleteContact>) => {
    const aVal = a.value.name ?? a.value.email;
    const bVal = b.value.name ?? b.value.email;

    return aVal < bVal ? -1 : 1;
  };

  const byEmail = new Map<string, IUAutoCompleteItem<IUAutoCompleteContact>>();
  const byUserId = new Map<string, IUAutoCompleteItem<IUAutoCompleteContact>>();

  let autoCompleteInvitees = includeAttendees ? generateAutocompleteItemsFromInvitees(event?.attendees) : [];
  autoCompleteInvitees = autoCompleteInvitees.filter(
    (invitee) => {
      const value = invitee.value;
      if (value.email !== undefined) {
        byEmail.set(value.email, invitee);
      }
      if (value.userId) {
        byUserId.set(value.userId, invitee);
      }
      return value.userId !== userContext.id &&
        (
          value.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          (value.email ?? '').toLowerCase().indexOf(search.toLowerCase()) !== -1
        )
    }
  );

  let autoCompleteContactLites = generateAutocompleteItemsFromContactLites(contacts);
  autoCompleteContactLites = autoCompleteContactLites.filter(contactLite => {
    if (byEmail.has(contactLite.value.email)) {
      return false;
    } else {
      byEmail.set(contactLite.value.email, contactLite);
    }
    if (contactLite.value.userId !== undefined) {
      if (byUserId.has(contactLite.value.userId)) {
        return false;
      } else  {
        byUserId.set(contactLite.value.userId, contactLite);
      }
    }
    return true;
  });

  let autoCompleteMutualMembers = generateAutocompleteItemsFromMutualMembers(mutualMembers);
  autoCompleteMutualMembers = autoCompleteMutualMembers.filter(mutualMember => {
    if (mutualMember.value.email !== undefined) {
      if (byEmail.has(mutualMember.value.email)) {
        return false;
      } else {
        byEmail.set(mutualMember.value.email, mutualMember);
      }
    }
    if (byUserId.has(mutualMember.value.userId)) {
      return false;
    } else  {
      byUserId.set(mutualMember.value.userId, mutualMember);
    }
    return true;
  });

  let autoCompleteItems: IUAutoCompleteItem<IUAutoCompleteContact>[] = [
    ...autoCompleteInvitees,
    ...autoCompleteContactLites,
    ...autoCompleteMutualMembers
  ];

  if (usersOnly) {
    autoCompleteItems = autoCompleteItems.filter((item) => item.value.userId !== undefined);
  }

  if (event !== undefined) {
    autoCompleteItems = removeExistingCohosts(event.attendees, autoCompleteItems);
  }

  return autoCompleteItems.sort(byName).slice(0, 10);
}
