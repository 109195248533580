import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useEditEventContext } from '../contexts/EditEventContext';
import { EventsFilterType } from '../lib/event';
import { useUserContext } from '../contexts/UserContext';
import { UButterBarLevel, useButterBarContext } from '../contexts/ButterBarContext';

import { VerticalFullContainer } from 'Common/src/components/styled';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../core/libs/Firebase';

const CreateDraftAction = () => {
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const butterBarContext = useButterBarContext();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Shine Parties - Create Draft';
    logEvent(firebaseAnalytics, 'page_view', {
      page_title: 'Create Draft Action - Analytics'
    });

    if (editEventContext.event !== undefined) {
      void saveDraft();
    } else {
      // Possibly show an error instead?
      navigate('/');
    }
  }, [editEventContext.event]);

  const saveDraft = async () => {
    try {
      await editEventContext.storeEvent(true);

    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.ERROR,
        userId: userContext.id,
        tag: ULogTag.Network,
        message: `[CreateEventAction] Error during draft creation: ${stringifyError(e)}`
      });
      butterBarContext.show({
        level: UButterBarLevel.ERROR,
        contents: 'Sorry, something went wrong saving your draft. Please try again. If the problem persists, please contact us at support@sunshine.com.',
        duration: 20000
      });
    }
    navigate('/events', { state: { activeTab: EventsFilterType.Drafts } });
  };

  return <Container>
    Saving your draft...
  </Container>;
};

const Container = styled(VerticalFullContainer)`
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
`;

export default CreateDraftAction;
