import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { ModalContent, ModalHeader, ModalTitle, Overlay, SystemModalContainer } from '../modals/CommonModal';
import EventFormInput from '../EventForm/EventFormInput';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import SystemButton, { ButtonInternal } from '../buttons/SystemButton';
import { WideWhiteButton } from '../buttons/WhiteButton';

const UserEmailModal = ({ onCancel, onSubmit }: {
  onCancel: () => void,
  onSubmit: (email: string) => void,
  saving: boolean
}) => {
  const userContext = useUserContext();

  const [email, setEmail] = useState(userContext.getEmail() || '');

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleSubmit = () => {
    userContext.setEmail(email);
    onSubmit(email);
  };

  return <Overlay onMouseDown={onCancel}>
    <SystemModalContainer onMouseDown={onModalClick}>
      <ModalContent>
        <ModalHeader>
          <BackArrowContainer onClick={onCancel}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Send preview invite</ModalTitle>
        </ModalHeader>

        <EventFormInput
          placeholder={'Enter email to send preview invite'}
          value={email}
          onChange={(email: string) => setEmail(email)}
          inModal={true}
          isLabelOutside={true}
          excludeBackground={true}
          noVertMargin={true}
        />
      </ModalContent>

      <Footer>
        <WideWhiteButton $invert={true} onClick={handleSubmit}>Confirm</WideWhiteButton>
      </Footer>
    </SystemModalContainer>
  </Overlay>;
};

const Footer = styled.div`
  ${ButtonInternal} {
    width: 100%;
  }
`;

export default UserEmailModal;
