import React from 'react';
import { FilterType, InviteesByType } from '../../util/attendee';
import { CarouselWrapper, FilterContainer, FilterPill, InactiveFilterPill } from '../PillToggle';

interface Props {
  inviteesByType: InviteesByType;
  activeFilters: Set<FilterType>;
  onFilterChange: (filterType: FilterType, status: boolean) => void;
}

const FilterLabels: {
  [key in FilterType]: string;
} = {
  [FilterType.All]: 'All',
  [FilterType.Unsent]: 'Unsent',
  [FilterType.Going]: 'Going',
  [FilterType.NotGoing]: 'Not Going',
  [FilterType.Awaiting]: 'Awaiting'
};

const InviteFilters = ({ inviteesByType, activeFilters, onFilterChange }: Props) => {
  return <CarouselWrapper>
    <FilterContainer>
      {
        Object.keys(FilterLabels).map((filterType: FilterType) => {
          const PillTag = activeFilters.has(filterType) ? FilterPill : InactiveFilterPill;
          return <PillTag
            key={filterType}
            onClick={() => onFilterChange(filterType, !activeFilters.has(filterType))}
          >
            {FilterLabels[filterType]} ({inviteesByType[filterType].length})
          </PillTag>;
        })
      }
    </FilterContainer>
  </CarouselWrapper>;
};


export default InviteFilters;
