import React, { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import Sparkles from '../icons/Sparkles';
import { DeviceQueries } from 'Common/src/components/styled';
import ColorButton from './ColorButton';

interface Props {
  buttonText: ReactNode;
  disabled: boolean;
  textFontSize?: number;
  shorter?: boolean;
  onClick?: (e: MouseEvent) => void;
  buttonColor?: string;
  textColor?: string;
  fullWidth?: boolean;
}

const SparkleButton = ({
                         buttonText,
                         textFontSize,
                         disabled,
                         onClick,
                         buttonColor,
                         textColor,
                         fullWidth = true,
                         children
                       }: PropsWithChildren<Props>) => {
  return <ButtonContainer $fullWidth={fullWidth}>
    <ColorButton onClick={onClick} disabled={disabled}
                 customButtonColor={buttonColor} customTextColor={textColor}>
      <SparklesContainer>
        { children ?? <Sparkles size={24} color="var(--button-text-color)"/> }
      </SparklesContainer>
      <TextWrapper $fontSize={textFontSize}>{buttonText}</TextWrapper>
    </ColorButton>
  </ButtonContainer>;
};


export default SparkleButton;

const TextWrapper = styled.div<{ $fontSize?: number }>`
  align-content: center;
  text-align: left;
  font-size: ${({ $fontSize }) => $fontSize !== undefined ? `${$fontSize}px` : '20px'};;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div<{$fullWidth: boolean}>`
  ${({$fullWidth}) => $fullWidth ? 'width: 100%;' : ''}
  display: inline-flex;
  flex-direction: row;
  z-index: 100;
  justify-content: center;
`;

export const SparklesContainer = styled.div`
  height: 24px;
  padding: 5px;
`;

