import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { convertToIUEvent, EventReducer } from '../state/reducers/event';
import { useUserContext } from './UserContext';
import { useEventCacheContext } from './EventCacheContext';
import { useCoinContext } from './CoinContext';
import { createEvent } from '../api/ElkEventService';
import {
  generateEventFromMadLibs, generateEventImage,
  getEventDescription, getSearchKeywords, quickCreateEvent, sampleEventImage
} from '../api/TElkThemeService';
import {
  areEqualEvents,
  IUEvent,
  IUEventColors,
  IUEventErrors,
  IUEventQuestion,
  IUInvitee,
  IULocation
} from '../lib/event';
import { useAutoSaveContext } from './AutoSaveContext';

import { promiseTimeout, promiseTimeoutWithDefault, TimeoutError } from 'Common/src/utils/PromiseTimeout';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkBackgroundAnimation,
  TAppElkEvent, TAppElkImage,
  TAppElkImageStyle,
  TAppElkNotificationPreference,
  TAppElkPhotoUploadMode,
  TElkCreateEventResponse,
  TElkGenerateEventFromMadLibsResponse,
  TElkGenerateImageResponse,
  TElkGetSearchKeywordsResponse,
} from 'TProtocol/prototypes/events/messages';
import { findPromptForImageWithURL } from '../lib/images';
import { fromZonedTime } from 'date-fns-tz';

declare const ELK_COINS_ENABLED: boolean;

const ONE_DAY = 24 * 60 * 60 * 1000;

class KeywordsPresentError extends Error {
}

export enum UEventFormField {
  Title = 1,
  Image,
  Day,
  Time,
  Location
}

export interface IUEventContext {
  event: IUEvent | undefined;
  previousEvent: IUEvent | undefined;
  originalEvent: IUEvent | undefined;
  createNewEvent: (defaults?: Partial<IUEvent>, prompt?: string | undefined) => void;
  eventDefaults: () => Partial<IUEvent>;
  storeEvent: (force?: boolean) => Promise<void>;
  unstoreEvent: (isCreate: boolean, eventId?: string) => boolean;
  unstoreOrLoadEvent: (isCreate: boolean, eventId?: string) => Promise<boolean>;
  fetchEvent: (eventId: string, modId?: string) => Promise<IUEvent | undefined>;
  generateEvent: () => Promise<TAppElkEvent | undefined>;
  createEvent: (event: TAppElkEvent, photoUploadMode: TAppElkPhotoUploadMode | undefined,
                albumId?: string) => Promise<void>;
  handleCreationResponse: (response: TElkCreateEventResponse) => void;
  getEventId: () => string | undefined;
  setEventId: (id: string) => void;
  setIsHost: (isHost: boolean) => void;
  updateFormErrors: (value: IUEventErrors) => void;
  setTitle: (title: string) => void;
  setDate: (date: Date) => void;
  setDateQuery: (dateQuery?: string) => void;
  setTimes: (startTime?: number, endTime?: number) => void;
  setLocation: (location: IULocation) => void;
  setHostedBy: (hostedBy: string) => void;
  setHostNotification: (hostNotification: string) => void;
  setCommunicationPreference: (communicationPreference: TAppElkNotificationPreference) => void;
  setHasWaitlist: (hasWaitlist: boolean) => void;
  setColors: (colors: IUEventColors) => void;
  setAnimation: (animation: TAppElkBackgroundAnimation) => void;
  setBackgroundAnimationSearchQuery: (query: string) => void;
  setBackgroundAnimationUrl: (animationUrl: string) => void;
  setVideoBackgroundDisabled: (disabled?: boolean) => void;
  setPhotoUrl: (photoUrl: string) => void;
  setTimeZone: (tz: string) => void;
  setEvent: (event: IUEvent) => void;
  setNewEvent: (event: IUEvent) => void;
  saveCheckpoint: () => void;
  restoreCheckpoint: () => void;
  fetchEventSuggestion: (style?: TAppElkImageStyle) => Promise<void>;
  fetchPreviousImages: (force?: boolean) => Promise<void>;
  clearEvent: () => void;
  updateUrl: (url: string) => void;
  setIsOpenInvite: (isOpenInvite: boolean) => void;
  generateDescription: () => Promise<void>;
  setDescription: (description: string) => void;
  setShowGuestList: (showGuestList: boolean) => void;
  setHostUploadMode: (hostUploadMode: TAppElkPhotoUploadMode) => void;
  setModificationId: (modificationId: string) => void;
  cancelEvent: () => void;
  setMessage: (message: string) => void;
  setRemindersEnabled: (remindersEnabled: boolean) => void;
  addAQuestion: () => void;
  addSongRequestQuestion: () => void;
  modifyQuestion: (question: IUEventQuestion) => void;
  deleteQuestion: (question: IUEventQuestion) => void;
  setPrompt: (prompt: string) => void;
  setFieldInvalid: (field: UEventFormField) => void;
  isFieldInvalid: (field: UEventFormField) => boolean;
  clearFieldValidity: () => void;
  setFormErrorDiv: (field: UEventFormField, div: HTMLDivElement | null) => void;
  scrollToError: () => void;
  error?: string;
  setError: (error: string | undefined) => void;
  setPlaylistId: (playlistId: string | undefined) => void;
  fetchEventInspirations: () => Promise<void>;
  hasEventChanged: () => boolean;
  updateOriginalEvent: () => void;
  updateDateWithTimeZone: (startTimeStr: string, endTimeStr: string, timeZone: string) => void;
  setViewedEventOptions: (viewedEventOptions: boolean) => void;
}

const EditEventContext = createContext<IUEventContext | null>(null);

export const EditEventContextProvider = (props: PropsWithChildren<object>) => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const autoSaveContext = useAutoSaveContext();
  const coinContext = useCoinContext();

  const [error, setError] = useState<string | undefined>();
  const formErrorDivs = useRef<Map<UEventFormField, HTMLDivElement | null>>(new Map());
  const formValidity = useRef<Set<UEventFormField>>(new Set());
  const fetchPreviousImagesPromise = useRef<Promise<void> | undefined>();

  const eventCreating = useRef(false);

  const [state, dispatch] = useReducer(EventReducer, {
    event: undefined,
    previousEvent: undefined,
    lastSavedEvent: undefined,
    originalEvent: undefined
  });

  useEffect(() => {
    const heartbeat = async () => {
      await storeEvent();
    };

    const timer = setInterval(heartbeat, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [state.event, state.lastSavedEvent]);

  useEffect(() => {
    if (state.event !== undefined && !areEqualEvents(state.event, state.lastSavedEvent)) {
      autoSaveContext.save(state.event, true);
    }
  }, [state.event]);

  const storeEvent = async (force?: boolean) => {
    if (state.event !== undefined &&
      (state.event.isCreate || state.event.isDraft) &&
      (force || !areEqualEvents(state.event, state.lastSavedEvent)) &&
      (!state.event.isDraft || eventCacheContext.getEvent(state.event.id) === undefined)) {
      await autoSaveContext.save(state.event, false);
      dispatch({ type: 'updateLastSaved' });
    }
  };

  const unstoreEvent = (isCreate: boolean, eventId?: string) => {
    const thisEventId = eventId ?? state.event?.id;
    if (thisEventId === undefined) {
      return false;
    }
    const savedEvent = autoSaveContext.load(thisEventId);
    if (savedEvent !== undefined && isCreate === savedEvent.isCreate) {
      setEvent(savedEvent);
      return true;
    } else {
      createNewEvent({ ...eventDefaults(), id: eventId });
      return false;
    }
  };

  const unstoreOrLoadEvent = async (isCreate: boolean, eventId?: string) => {
    if (eventId) {
      if (unstoreEvent(isCreate, eventId)) {
        return true;
      }
      const event = await autoSaveContext.fetchDraft(eventId);

      if (event && isCreate === event.isCreate) {
        setEvent(event);
        return true;
      }
    }
    return false;
  };

  const getEventId = () => {
    return state.event?.id;
  };

  const setTitle = (title: string) => {
    dispatch({ type: 'setTitle', title });
  };

  const setDate = (date: Date) => {
    dispatch({ type: 'setDate', date });
  };

  const setDateQuery = (dateQuery?: string) => {
    dispatch({ type: 'setDateQuery', dateQuery });
  };

  const setTimes = (startTime?: number, endTime?: number) => {
    dispatch({ type: 'setTimes', startTime, endTime });
  };

  const setLocation = (location: IULocation) => {
    dispatch({ type: 'setLocation', location });
  };

  const setColors = (colors: IUEventColors) => {
    dispatch({ type: 'setColors', colors });
  };

  const setAnimation = (animation: TAppElkBackgroundAnimation) => {
    dispatch({ type: 'setAnimation', animation });
  };

  const setBackgroundAnimationSearchQuery = (query: string) => {
    dispatch({ type: 'setBackgroundAnimationSearchQuery', query });
  };

  const setBackgroundAnimationUrl = (animationUrl: string) => {
    dispatch({ type: 'setBackgroundAnimationUrl', animationUrl });
  };

  const setVideoBackgroundDisabled = (disabled?: boolean) => {
    dispatch({ type: 'setVideoBackgroundDisabled', disabled });
  };

  const setPhotoUrl = (photoUrl: string) => {
    dispatch({ type: 'setPhotoUrl', photoUrl });

    const event = state.event;
    if (event?.previousImages) {
      const selectedImagePrompt = findPromptForImageWithURL(event.previousImages, photoUrl);
      if (selectedImagePrompt) {
        setPrompt(selectedImagePrompt);
      }
    }
  };

  const setHostUploadMode = (hostUploadMode: TAppElkPhotoUploadMode) => {
    dispatch({ type: 'setHostUploadMode', hostUploadMode });
  };

  const setTimeZone = (tz: string) => {
    dispatch({ type: 'setTimeZone', tz });
  };

  const setEventId = (id: string) => {
    dispatch({ type: 'setEventId', id });
  };

  const setIsHost = (isHost: boolean) => {
    dispatch({ type: 'setIsHost', isHost });
  };

  const setEvent = (event: IUEvent) => {
    dispatch({ type: 'setEvent', event });
  };

  const saveCheckpoint = () => {
    dispatch({ type: 'saveCheckpoint' });
  };

  const restoreCheckpoint = () => {
    dispatch({ type: 'restoreCheckpoint' });
  };

  const setNewEvent = (event: IUEvent) => {
    dispatch({ type: 'setNewEvent', event });
  };

  const clearEvent = () => {
    dispatch({ type: 'clearEvent' });
  };

  const setIsOpenInvite = (isOpenInvite: boolean) => {
    dispatch({ type: 'setIsOpenInvite', isOpenInvite });
  };

  const setMessage = (message: string) => {
    dispatch({ type: 'setMessage', message });
  };

  const setRemindersEnabled = (remindersEnabled: boolean) => {
    dispatch({ type: 'setRemindersEnabled', remindersEnabled });
  };

  const setShowGuestList = (showGuestList: boolean) => {
    dispatch({ type: 'setShowGuestList', showGuestList: showGuestList });
  };

  const setDescription = (description: string) => {
    dispatch({ type: 'setDescription', description });
  };

  const setDescriptionFromServer = (description: string, backgroundAnimationSearchQuery?: string) => {
    dispatch({ type: 'setDescriptionFromServer', description, backgroundAnimationSearchQuery });
  };

  const setHostedBy = (hostedBy: string) => {
    dispatch({ type: 'setHostedBy', hostedBy });
  };

  const setHostNotification = (hostNotification: string) => {
    dispatch({ type: 'setHostNotification', hostNotification });
  };

  const setCommunicationPreference = (communicationPreference: TAppElkNotificationPreference) => {
    dispatch({ type: 'setCommunicationPreference', communicationPreference });
  };

  const setHasWaitlist = (hasWaitlist: boolean) => {
    dispatch({ type: 'setHasWaitlist', hasWaitlist });
  };

  const createNewEvent = (defaults?: Partial<IUEvent>) => {
    dispatch({ type: 'createNewEvent', defaults });
  };

  const updateFormErrors = (value: IUEventErrors) => {
    dispatch({ type: 'updateFormErrors', value });
  };

  const updateUrl = (url: string) => {
    dispatch({ type: 'updateUrl', url });
  };

  const fetchEvent = async (eventId: string) => {
    const event = await eventCacheContext.fetchEvent({ eventId, isPreview: !userContext.isLoggedIn() });

    if (event !== undefined) {
      dispatch({ type: 'setEvent', event });
    }

    return event;
  };

  const fetchEventInspirations = async () => {
    if (state.event !== undefined && state.event.suggestionTags === undefined) {
      const response = await getSearchKeywords(userContext, `${state.event.title} ${state.event.backgroundAnimationSearchQuery} ${state.event.prompt}`);
      dispatch({ type: 'setSuggestionTags', tags: response.keywords });
    }
  };

  const eventDefaults = (): Partial<IUEvent> => {
    let userDefaults: Partial<IUEvent>;
    if (userContext.isLoggedIn()) {
      userDefaults = {
        hostedBy: userContext.name,
        attendees: [
          new IUInvitee({
            inviteeId: uuidv4(),
            userId: userContext.id,
            name: userContext.name,
            email: userContext.getEmail(),
            rsvpMessage: '',
            additionalGuestCount: 0,
            role: TAppElkAttendeeRole.ORGANIZER,
            rsvpStatus: TAppElkAttendeeStatus.YES,
            isNew: true
          })
        ],
        hostEmail: userContext.getEmail(),
        startTime: Math.round((Date.now() + ONE_DAY) / (60 * 60 * 1000)) * 60 * 60 * 1000,
        isCreate: true,
        isDraft: true
      };
    } else {
      userDefaults = {
        isCreate: true,
        isDraft: true
      };
    }
    return userDefaults;
  };

  const generateEvent = async () => {
    if (state.event === undefined) {
      return undefined;
    }

    if (state.event.attendees.length === 0 && state.event.isCreate && userContext.id !== undefined && userContext.name !== '') {
      state.event.attendees.push(
        new IUInvitee({
          inviteeId: uuidv4(),
          userId: userContext.id,
          name: userContext.name,
          email: state.event.hostEmail,
          rsvpMessage: '',
          additionalGuestCount: 0,
          role: TAppElkAttendeeRole.ORGANIZER,
          rsvpStatus: TAppElkAttendeeStatus.YES,
          isNew: true
        })
      );
    }

    return await state.event.convertToTAppElkEvent(userContext.name);
  };

  const createEventInternal = async (event: TAppElkEvent, photoUploadMode: TAppElkPhotoUploadMode | undefined,
                                     albumId?: string) => {
    if (!eventCreating.current) {
      eventCreating.current = true;
      await createEvent(userContext, event, photoUploadMode, true, albumId);
      dispatch({ type: 'createComplete' });
      eventCreating.current = false;
    }
  };

  const handleCreationResponse = (response: TElkCreateEventResponse) => {
    dispatch({ type: 'handleCreationResponse', response });
  };

  const addAQuestion = () => {
    dispatch({ type: 'addAQuestion' });
  };

  const addSongRequestQuestion = () => {
    dispatch({ type: 'addSongRequestQuestion' });
  };

  const modifyQuestion = (question: IUEventQuestion) => {
    dispatch({ type: 'modifyQuestion', question });
  };

  const deleteQuestion = (question: IUEventQuestion) => {
    dispatch({ type: 'deleteQuestion', question });
  };

  const cancelEvent = () => {
    dispatch({ type: 'cancelEvent' });
  };

  const fetchEventSuggestion = async (style?: TAppElkImageStyle) => {
    const event = state.event;

    dispatch({ type: 'startImageFetch' });

    if (event) {
      const result = await fetchEventImage(event, style === undefined, event.prompt, style);

      dispatch({ type: 'handleNewPhotos', photos: result });

      // if (ELK_COINS_ENABLED && !userContext.isLoggedIn()) {
      //   coinContext.spendCoins(result);
      // }

      const newPhotoUrl = result?.[0];
      void fetchPreviousImages(true, newPhotoUrl);
    }
  };

  const fetchEventImage = async (event: IUEvent, useVerbatim: boolean, prompt?: string,
                                 style?: TAppElkImageStyle): Promise<string[] | undefined> => {
    try {
      let generatePromise: Promise<TElkGenerateImageResponse>;
      if (userContext.isLoggedIn()) {
        generatePromise = generateEventImage(userContext, event, useVerbatim, prompt, style);
      } else {
        generatePromise = sampleEventImage(userContext, event, coinContext.getBalance(), useVerbatim, prompt, style);
      }
      const response = await promiseTimeoutWithDefault(
        generatePromise,
        60000,
        new TElkGenerateImageResponse({
          imageUrls: []
        })
      );
      dispatch({ type: 'setPreviousImages', images: response.images ?? [] });
      return response.imageUrls;
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.ImageGen,
        message: `[EditEventContext] Error generating image: ${stringifyError(e)}`
      });

      return undefined;
    }
  };

  const setModificationId = (modificationId: string) => {
    dispatch({ type: 'setModificationId', modificationId });
  };

  const setPrompt = (prompt: string) => {
    dispatch({ type: 'setPrompt', prompt });
  };

  const setFormErrorDiv = (field: UEventFormField, div: HTMLDivElement) => {
    formErrorDivs.current.set(field, div);
  };

  const setFieldInvalid = (field: UEventFormField) => {
    formValidity.current.add(field);
  };

  const isFieldInvalid = (field: UEventFormField) => {
    return formValidity.current.has(field);
  };

  const clearFieldValidity = () => {
    formValidity.current.clear();
  };

  const scrollToError = () => {
    for (const field of [UEventFormField.Title, UEventFormField.Image, UEventFormField.Day, UEventFormField.Time, UEventFormField.Location]) {
      if (formValidity.current.has(field)) {
        formErrorDivs.current.get(field)?.scrollIntoView();
        break;
      }
    }
  };

  const setPlaylistId = (playlistId: string) => {
    dispatch({ type: 'setPlaylistId', playlistId });
  };

  const generateDescription = async () => {
    const event = state.event;

    if (event === undefined) {
      return;
    }

    setDescription('');

    const descriptionResponse = await getEventDescription(
      userContext,
      event.prompt ?? '',
      event.title,
      ''
    );

    if (descriptionResponse.eventDescription !== undefined) {
      setDescription(descriptionResponse.eventDescription);
    }
  };

  const fetchPreviousImages = async (force = false, photoUrl?: string) => {
    const event = state.event;

    if (userContext.isLoggedIn() && event !== undefined) {
      if (fetchPreviousImagesPromise.current) {
        return fetchPreviousImagesPromise.current;
      } else if (!force && event.previousImages !== undefined) {
        const selectedImagePrompt = findPromptForImageWithURL(event.previousImages, photoUrl ?? event.photoUrl);
        if (selectedImagePrompt) {
          setPrompt(selectedImagePrompt);
        }
        return;
      }

      const imageHistory = await userContext.fetchEventImageHistory();
      const images = imageHistory.images.filter(image => image.eventUuid === event.id);

      dispatch({ type: 'setPreviousImages', images });

      const selectedImagePrompt = findPromptForImageWithURL(images, photoUrl ?? event.photoUrl);
      if (selectedImagePrompt) {
        setPrompt(selectedImagePrompt);
      }
    }

  };
  const updateDateWithTimeZone = (startTimeStr: string, endTimeStr: string, timeZone: string) => {
    if (startTimeStr !== '') {
      const startTime = fromZonedTime(startTimeStr, timeZone).getTime();
      const endTime: number | undefined = endTimeStr !== '' ? fromZonedTime(endTimeStr, timeZone).getTime() : undefined;

      const isValid = startTime !== undefined && (!endTimeStr || (endTime !== undefined && endTime > startTime));
      if (isValid) {
        setTimes(startTime, endTime ?? 0);
      }
    }
  };

  const hasEventChanged = () => {
    return !areEqualEvents(state.event, state.originalEvent);
  }

  const updateOriginalEvent = () => {
    dispatch({ type: 'updateOriginalEvent' });
  }

  const setViewedEventOptions = (viewedEventOptions: boolean) => {
    dispatch({ type: 'setViewedEventOptions', viewedEventOptions });
  }

  const context: IUEventContext = {
    event: state.event,
    previousEvent: state.previousEvent,
    originalEvent: state.originalEvent,
    createNewEvent,
    eventDefaults,
    storeEvent,
    unstoreEvent,
    unstoreOrLoadEvent,
    fetchEvent,
    generateEvent,
    createEvent: createEventInternal,
    getEventId,
    setEventId,
    setIsHost,
    setColors,
    setAnimation,
    setBackgroundAnimationSearchQuery,
    setBackgroundAnimationUrl,
    setVideoBackgroundDisabled,
    setPhotoUrl,
    setTimeZone,
    handleCreationResponse,
    updateFormErrors,
    setTitle,
    setDate,
    setDateQuery,
    setTimes,
    setLocation,
    fetchEventSuggestion,
    fetchPreviousImages,
    clearEvent,
    setEvent,
    setNewEvent,
    saveCheckpoint,
    restoreCheckpoint,
    updateUrl,
    setIsOpenInvite,
    setDescription,
    generateDescription,
    setShowGuestList: setShowGuestList,
    setModificationId,
    cancelEvent,
    setHostedBy,
    setHostNotification,
    setCommunicationPreference,
    setHasWaitlist,
    setMessage,
    setRemindersEnabled,
    addAQuestion,
    addSongRequestQuestion,
    modifyQuestion,
    deleteQuestion,
    setPrompt,
    setFieldInvalid,
    isFieldInvalid,
    clearFieldValidity,
    setFormErrorDiv,
    scrollToError,
    error,
    setError,
    setHostUploadMode,
    setPlaylistId,
    fetchEventInspirations,
    hasEventChanged,
    updateOriginalEvent,
    updateDateWithTimeZone,
    setViewedEventOptions
  };

  return (
    <EditEventContext.Provider value={context}>
      {props.children}
    </EditEventContext.Provider>
  );
};

export const useEditEventContext = () => {
  const context = useContext(EditEventContext);
  if (context === null) {
    throw new Error('useEventContext must be used within a EditEventContextProvider');
  }
  return context;
};
