import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';
import { WhiteButton } from '../buttons/WhiteButton';

export const ActionRow = styled.div<{ $noMargins?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  ${({ $noMargins = false }) => !$noMargins && 'margin-top: 10px;'};

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    gap: 2px;
  }
`;

export const EventModules = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-top: 20px;
  text-align: left;
  color: rgba(256, 256, 256, 0.9);

  @media not (${DeviceQueries.mobile}) {
    gap: 30px;
  }
`;

export const EventModule = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const EventModuleHeading = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const EventDetailsCell = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;

  &:nth-child(2n+1) {
    flex-direction: row;
    justify-self: center;
    align-items: center;
  }
`;

export const EventDetailsCellInner = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const EventDetailsCellInnerForAutocomplete = styled(EventDetailsCellInner)`
  @media (${DeviceQueries.mobile}) {
    & > div:nth-child(1) {
      width: 100%;
    }
  }
`;

export const EventDetailsIconWrapper = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`;

export const PillInputRaw = styled.input<{ $withCancel?: boolean, $expanding?: boolean, $fontSize?: number }>`
  display: flex;
  padding: ${({ $withCancel = false }) => $withCancel ? '4px 30px 4px 8px' : '4px 8px'};
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--sp-module-bg-color);
  border: 2px solid var(--sp-border-color);
  width: 280px;
  box-sizing: border-box;

  color: var(--sp-text-color);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: ${({ $fontSize = 18 }) => $fontSize}px;
  font-weight: 400;

  &::-webkit-calendar-picker-indicator {
    filter: invert(0.90);
  }
  
  &:disabled {
    opacity: 0.7;
  }

  ${({ $expanding }) => $expanding && `
  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
  `}
`;

export const PillInputActionButton = styled(PillInputRaw).attrs({
  as: 'button'
})<{ $width?: string }>`
  width: ${({ $width }) => $width ?? 'auto'};
  cursor: pointer;
`;

export const OutlinedPillInputActionButton = styled(PillInputActionButton)`
  border-radius: 8px;
  border: 2px solid var(--sp-text-color);
  background: transparent;
  color: var(--sp-text-color);
  text-align: center;
  font-size: 16px;
  line-height: 20px; /* 125% */
  padding: 6px 18px;

  &:hover {
    border-radius: 8px;
    background: rgba(var(--sp-bg-rgb), 0.30);
    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
  }

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    justify-content: center;
  }
`

export const PillDiv = styled(PillInputRaw).attrs({
  as: 'div'
})`
  width: auto;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
`;

export const EventDetails = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px 8px;
  font-size: 18px;

  ${PillInputRaw} {
    margin-bottom: 4px;
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StickyFooter = styled.div`
  background: rgba(var(--sp-bg-rgb), 0.10);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  padding: 12px 0;
  width: 100%;
`

export const StickyFooterWhiteButton = styled(WhiteButton)`
  @media (${DeviceQueries.mobile}) {
    min-width: 120px;
  }
`
