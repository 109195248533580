import { useEditEventContext } from '../contexts/EditEventContext';
import { useEffect, useState } from 'react';
import { ErrorResponse, Videos } from 'pexels';
import { IUEventColors } from '../lib/event';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../contexts/UserContext';
import useGiphy from '../lib/giphy';
import usePexels from '../lib/pexels';
import { calculateSeason } from '../util/seasons';

const DEFAULT_COLORS: IUEventColors = {
  primary: '#fae5ff',
  secondary: '#FFFFFF',
  highlightText: '#FFFFFF',
  text: '#000000',
  buttonTextColor: '#ffffff'
};

const useVideoBackgroundPicker = () => {
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const giphy = useGiphy();
  const pexels = usePexels();

  const [lastSearchQuery, setLastSearchQuery] = useState<string>('');
  const [videosList, setVideosList] = useState<string[]>([]);
  const [videoListIndex, setVideoListIndex] = useState<number>(0);
  const [turnOff, setTurnOff] = useState(false);
  const [lastUrl, setLastUrl] = useState<string | undefined>();

  const setNewBackgroundVideo = async (searchQuery: string) => {
    if (searchQuery.length > 0 && searchQuery.trim() !== '') {
      const combinedVideosList = await getAllVideosForQuery(searchQuery);
      setVideosList(combinedVideosList);
      setVideoListIndex(0);
      setLastSearchQuery(searchQuery);
      editEventContext.setBackgroundAnimationSearchQuery(searchQuery);
    }
  };

  const setDefaultBackgroundVideo = async () => {
    const defaultQuery = calculateSeason(editEventContext.event?.date || new Date());

    await setNewBackgroundVideo(defaultQuery);
    editEventContext.setBackgroundAnimationSearchQuery(defaultQuery);
    editEventContext.setColors(DEFAULT_COLORS);
    setLastSearchQuery(defaultQuery);
    editEventContext.updateOriginalEvent();
  };

  const prepopulateVideoList = async () => {
    const searchQuery = editEventContext.event?.backgroundAnimationSearchQuery;
    const backgroundAnimationUrl = editEventContext.event?.backgroundAnimationUrl;
    if (!searchQuery && !backgroundAnimationUrl && !editEventContext.event?.videoBackgroundDisabled) {
      void setDefaultBackgroundVideo();
    } else if (searchQuery && searchQuery.length > 0 && searchQuery.trim() !== '') {
      setLastSearchQuery(searchQuery);
      const combinedVideosList = await getAllVideosForQuery(searchQuery);
      setVideosList(combinedVideosList);
      if (editEventContext.event?.backgroundAnimationUrl === combinedVideosList[0]) {
        // When user clicks button for next video, index will be incremented to 1 to second video in list
        setVideoListIndex(0);
      } else {
        // When user clicks button for next video, index will be incremented to 0 to first video in list 
        setVideoListIndex(-1);
      }
    }
  };

  const getAllVideosForQuery = async (searchQuery: string): Promise<string[]> => {
    let pexelsList: string[] = [];
    try {
      pexelsList = await getPexelsResults(searchQuery);
    } catch (e) {
      // Do nothing, pexelsList will be empty
    }
    if (pexelsList.length === 0) {
      // Fall back to using results from giphy if nothing is returned from pexels
      const giphyList = await getGiphyResults(searchQuery);
      return giphyList;
    } else {
      return pexelsList;
    }
  };

  const getGiphyResults = async (searchQuery: string) => {
    const videoResults = await giphy.getGifsWithSearchTerm(searchQuery, true);

    if (videoResults && videoResults.data.length > 0) {
      return videoResults.data.map(gif => giphy.getGifVideoLink(gif)).filter(url => url !== null);
    } else {
      const gifResults = await giphy.getGifsWithSearchTerm(searchQuery, false);
      if (gifResults) {
        return gifResults.data.map(gif => giphy.getGifVideoLink(gif)).filter(url => url !== null);
      } else {
        return [];
      }
    }
  };

  const getPexelsResults = async (searchQuery: string) => {
    const videosResponse = await pexels.getPexelsVideosWithSearchTerm(searchQuery);
    const errorResponse = videosResponse as ErrorResponse;
    const videosObj = videosResponse as Videos;

    if (errorResponse.error) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[useVideoBackgroundPicker] Error requesting Pexels videos: ${errorResponse.error}`
      });
      return [];
    } else {
      const videos = videosObj.videos;
      return videos.map(video => pexels.getPexelsVideoFileLink(video));
    }
  };

  const interleaveArrays = (array1: string[], array2: string[]) => {
    // Merge two arrays with alternating elements from each array
    const result = [];
    const maxLength = Math.max(array1.length, array2.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < array1.length && array1[i].trim() !== '') {
        result.push(array1[i]);
      }
      if (i < array2.length && array2[i].trim() !== '') {
        result.push(array2[i]);
      }
    }

    return result;
  };

  const decrementIndex = () => {
    if (videoListIndex > 0) {
      setVideoListIndex(videoListIndex - 1);
    }
  };

  const incrementIndex = () => {
    if (videoListIndex < videosList.length) {
      setVideoListIndex(videoListIndex + 1);
    }
  };

  const hasMore = () => {
    return videoListIndex < videosList.length;
  };

  useEffect(() => {
    if (turnOff) {
      setLastUrl(editEventContext.event?.backgroundAnimationUrl);
      editEventContext.setBackgroundAnimationUrl('');
    } else if (videoListIndex >= 0 && videoListIndex < videosList.length) {
      const videoLink = videosList[videoListIndex];
      console.log('Background Video Link: ', videoLink);
      editEventContext.setBackgroundAnimationUrl(videoLink);
    } else if (lastUrl !== undefined) {
      editEventContext.setBackgroundAnimationUrl(lastUrl);
    }
  }, [lastSearchQuery, videoListIndex, turnOff]);

  return {
    setNewBackgroundVideo,
    setDefaultBackgroundVideo,
    incrementIndex,
    decrementIndex,
    hasMore,
    prepopulateVideoList,
    turnOff,
    setTurnOff
  };

};

export default useVideoBackgroundPicker;
