import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Dot from './icons/Dot';
import { DesktopShow } from './styled/Common';
import LeftPaginationArrow from './icons/LeftPaginationArrow';
import RightPaginationArrow from './icons/RightPaginationArrow';

export interface CarouselItem {
  contents: ReactNode;
  onClick?: () => void;
}

interface Props {
  items: CarouselItem[];
  width: number;
}

const Carousel = ({ items, width }: Props) => {
  const [page, setPage] = useState(0);

  const autoScrollingRef = useRef(false);
  const scrollTimeoutRef = useRef<number | undefined>();
  const scrollerRef = useRef<HTMLDivElement>(null);

  const pageRefs: RefObject<(HTMLDivElement | null)[]> = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && scrollTimeoutRef.current === undefined) {
          pageRefs.current?.forEach((div, index) => {
            if (div === entry.target) {
              setPage(index);
            }
          });
        }
      });
    }, { threshold: 0.5, root: scrollerRef.current });

    pageRefs.current?.forEach((div) => {
      if (div !== null) {
        observer.observe(div);
      }
    });

    scrollerRef.current?.addEventListener('scroll', function () {
      if (!autoScrollingRef.current) {
        return;
      }
      if (scrollTimeoutRef.current !== undefined) {
        clearTimeout(scrollTimeoutRef.current);
      }
      scrollTimeoutRef.current = window.setTimeout(function () {
        scrollTimeoutRef.current = undefined;
        autoScrollingRef.current = false;
      }, 100);
    });

    return () => {
      observer.disconnect();
      clearTimeout(scrollTimeoutRef.current);
    };
  }, []);

  const onPageClick = (newPage: number) => {
    setPage(newPage);
    pageRefs.current?.[newPage]?.scrollIntoView({ behavior: 'smooth' });
    autoScrollingRef.current = true;
  };

  const dots: ReactNode[] = [];
  for (let i = 0; i < items.length; i++) {
    if (i === page) {
      dots.push(<DotWrapper key={i}><Dot size={7} color="var(--sp-text-color)"/></DotWrapper>);
    } else {
      dots.push(<DotWrapper key={i} onClick={() => onPageClick(i)}><Dot size={5} color="#999999"
                                                                        opacity={0.5}/></DotWrapper>
      );
    }
  }

  return <>
    <ScrollMask>
      <ScrollingContent ref={scrollerRef} $width={width}>
        <ImagesContainer>
          {
            items.map((item, i) => <Page key={i}
                                         ref={(element) => pageRefs.current && (pageRefs.current[i] = element)}
                                         onClick={item.onClick}
                                         $width={width}
            >
              {item.contents}
            </Page>)
          }
        </ImagesContainer>
      </ScrollingContent>
    </ScrollMask>
    <DesktopShow>
      <PaginationContainer>
        {page === 0 ?
          <ArrowWrapper $clickable={false}><LeftPaginationArrow size={10} color="#999999"
                                                                opacity={0.2}/></ArrowWrapper> :
          <ArrowWrapper $clickable={true} onClick={() => onPageClick(page - 1)}><LeftPaginationArrow size={10}
                                                                                                     color="#383F45"/></ArrowWrapper>}
        {dots}
        {page === items.length - 1 ?
          <ArrowWrapper $clickable={false}><RightPaginationArrow size={10} color="#999999"
                                                                 opacity={0.2}/></ArrowWrapper> :
          <ArrowWrapper $clickable={true} onClick={() => onPageClick(page + 1)}><RightPaginationArrow size={10}
                                                                                                      color="#383F45"/></ArrowWrapper>}
      </PaginationContainer>
    </DesktopShow>
  </>;
};

const ScrollMask = styled.div`
  overflow: hidden;
`;

const ScrollingContent = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  overflow: auto;
  position: relative;

  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  margin-bottom: -17px; // Hide bottom scroll bar
  padding-bottom: 17px; // Prevent bottom content cut off
`;

const ImagesContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-right: 100px;
`;

const Page = styled.div<{ $width: number }>`
  scroll-snap-align: start;
  
  &:last-child {
    padding-right: ${({$width}) => $width}px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const ArrowWrapper = styled.div<{ $clickable: boolean }>`
  margin: 4px;
  display: flex;
  ${({ $clickable }) => $clickable ? 'cursor: pointer;' : ''}
`;

const DotWrapper = styled.span`
  cursor: pointer;
  display: flex;
`;

export default Carousel;
