import React from 'react';

const ChartSquareBarOutline = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path
      d="M19.8618 14.5234V11.8984C19.8618 10.0345 18.3508 8.52344 16.4868 8.52344H14.9868C14.3655 8.52344 13.8618 8.01976 13.8618 7.39844V5.89844C13.8618 4.03448 12.3508 2.52344 10.4868 2.52344H8.61182M8.61182 15.2734H16.1118M8.61182 18.2734H12.3618M10.8618 2.52344H5.98682C5.3655 2.52344 4.86182 3.02712 4.86182 3.64844V20.8984C4.86182 21.5198 5.3655 22.0234 5.98682 22.0234H18.7368C19.3581 22.0234 19.8618 21.5198 19.8618 20.8984V11.5234C19.8618 6.55288 15.8324 2.52344 10.8618 2.52344Z"
      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default ChartSquareBarOutline;
