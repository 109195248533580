import ColorThief, { Color } from 'colorthief';

import { IUEventColors } from './event';

import { BLACK, WHITE } from 'Common/src/lib/Colors';

interface PaletteColor {
  rank: number;
  color: Color;
  saturation: number;
}

export const getPalette = (img: HTMLImageElement): IUEventColors | null => {
  const colorThief = new ColorThief();

  const palette = colorThief.getPalette(img, 10);
  if (palette) {
    let colors: PaletteColor[] = palette.map((color, index) => {
      return {
        rank: index + 1,
        color,
        saturation: RGBToHSL(color)[1]
      };
    });

    colors = colors.sort((colorA, colorB) => {
      return (colorB.saturation / colorB.rank) - (colorA.saturation / colorA.rank);
    });

    const primary = colorToStr(colors[0].color);
    const highlightText = getSuggestedTextColor(primary, colorToStr(colors[2].color));

    return {
      primary,
      secondary: WHITE,
      highlightText,
      text: WHITE,
      buttonTextColor: WHITE
    };
  } else {
    return null;
  }
};

export const getFullPalette = (img: HTMLImageElement): string[] => {
  const colorThief = new ColorThief();

  const palette = colorThief.getPalette(img, 10);
  if (palette) {
    return palette.map((color) => colorToStr(color));
  }
  return [];
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
};

export const colorToStr = (color: Color): string => {
  const [r, g, b] = color;

  if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
    return '';
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const strToColor = (color: string): Color | null => {
  const match = color.match(/^#([0-9a-f][0-9a-f])([0-9a-f][0-9a-f])([0-9a-f][0-9a-f])/i);
  if (match) {
    return [parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16)];
  }
  return null;
};

export const isBrightColor = (colorStr: string) => {
  const blackContrast = colorContrast(colorStr, BLACK) ?? 0;
  const whiteContrast = colorContrast(colorStr, WHITE) ?? 1;
  return blackContrast > whiteContrast;
};

export const getDefaultTextColor = (colorStr: string) => {
  return isBrightColor(colorStr) && colorStr !== '#FC4C10' && colorStr !== '#FF6422' ? BLACK : WHITE;
};

export const getDefaultErrorColor = (colors: IUEventColors) => {
  const primaryRgb = strToColor(colors.primary);
  const systemBg = calculateBackgroundColor(colors);
  const alpha = systemBg[1];

  let brightBg = true;
  if (primaryRgb) {
    const blendedBg: Color = [
      alpha * systemBg[0] + (1 - alpha) * primaryRgb[0],
      alpha * systemBg[0] + (1 - alpha) * primaryRgb[1],
      alpha * systemBg[0] + (1 - alpha) * primaryRgb[2],
    ];

    brightBg = isBrightColor(colorToStr(blendedBg));
  }

  return brightBg ? '#680810' : '#ed1b2e';
};

export const calculateBackgroundColor = (colors: IUEventColors): [number, number] => {
  const brightPrimary = isBrightColor(colors.primary);
  const brightSecondary = isBrightColor(colors.secondary);
  const brightText = isBrightColor(colors.text);

  if ((brightPrimary || brightSecondary) && brightText) {
    return [0, 0.5];
  } else if ((!brightPrimary || !brightSecondary) && !brightText) {
    return [255, 0.5];
  } else if (brightText) {
    return [255, 0.18];
  } else {
    return [0, 0.1];
  }
};

export const getSystemBackgroundColor = (colors: IUEventColors) => {
  const bgColor = calculateBackgroundColor(colors);

  return `rgba(${bgColor[0]},${bgColor[0]},${bgColor[0]},${bgColor[1]})`;
};

export const getBlurOverlayColor = (colors: IUEventColors) => {
  const bgColor = strToColor(colors.primary);

  if (bgColor !== null) {
    return `rgba(${bgColor[0]},${bgColor[1]},${bgColor[2]},0.2)`;
  } else {
    return 'rgab(255, 255, 255, 0.5)';
  }
};

export const getLogoColor = (colors: IUEventColors) => {
  if (colors.text.toLowerCase() === '#ffffff') {
    return '#ffffff';
  }
  return '#FECC17';
};

export const RGBToHSL = (colorRgb: Color): Color => {
  let [r, g, b] = colorRgb;
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

const HSLToRGB = (colorHsl: Color): Color => {
  // eslint-disable-next-line prefer-const
  let [h, s, l] = colorHsl;
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [Math.round(255 * f(0)), Math.round(255 * f(8)), Math.round(255 * f(4))];
};

const relativeLuminanceW3C = (color: Color | null) => {
  if (color === null) {
    return null;
  }

  const [R8bit, G8bit, B8bit]: Color = color;

  const RsRGB = R8bit / 255;
  const GsRGB = G8bit / 255;
  const BsRGB = B8bit / 255;

  const R = (RsRGB <= 0.03928) ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
  const G = (GsRGB <= 0.03928) ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
  const B = (BsRGB <= 0.03928) ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

export const colorContrast = (colorA: string, colorB: string) => {
  const lumA = relativeLuminanceW3C(strToColor(colorA));
  const lumB = relativeLuminanceW3C(strToColor(colorB));

  if (lumA === null || lumB === null) {
    return null;
  }

  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
};

export const getSuggestedTextColor = (colorStr: string, textColorStr: string): string => {
  const colorRgb = strToColor(colorStr);
  const textColorRgb = strToColor(textColorStr);

  if (colorRgb === null || textColorRgb === null) {
    return WHITE;
  }
  let workingTextColorRgb = textColorRgb;

  const inc = isBrightColor(colorStr) ? -0.5 : 0.5;

  while ((colorContrast(colorToStr(colorRgb), colorToStr(workingTextColorRgb)) ?? 0) < 4.5) {
    const newTextColorHsl = RGBToHSL(workingTextColorRgb);
    newTextColorHsl[2] += inc;
    if (newTextColorHsl[2] > 100 || newTextColorHsl[2] < 0) {
      break;
    }
    workingTextColorRgb = HSLToRGB(newTextColorHsl);
  }

  return colorToStr(workingTextColorRgb);
};

export const getDarkenedColor = (colorStr: string) => {
  const colorRgb = strToColor(colorStr);

  if (colorRgb === null) {
    return BLACK;
  }
  let workingColorRgb = colorRgb;

  const inc = isBrightColor(colorStr) ? -0.5 : 0.5;

  for (let sat = RGBToHSL(workingColorRgb)[2]; sat > 0 && sat <= 100; sat += inc) {
    const whiteContrast = colorContrast('#FFFFFF', colorToStr(workingColorRgb)) ?? 0;
    const blackContrast = colorContrast('#000000', colorToStr(workingColorRgb)) ?? 0;
    if (whiteContrast > blackContrast) {
      break;
    }
    const newColorHsl = RGBToHSL(workingColorRgb);
    newColorHsl[2] += inc;
    if (newColorHsl[2] > 100 || newColorHsl[2] < 0) {
      break;
    }
    workingColorRgb = HSLToRGB(newColorHsl);
  }

  return colorToStr(workingColorRgb);
};

const getAdjustedStartAndEndColors = (color1: string,
                                      color2: string): [Color | null, Color | null, boolean, number] => {
  const color1Rgb = strToColor(color1);
  const color2Rgb = strToColor(color2);
  if (color1Rgb && color2Rgb) {
    const color1Hsl = RGBToHSL(color1Rgb);
    const color2Hsl = RGBToHSL(color2Rgb);

    let startHsl: Color;
    let endHsl: Color;
    let swapped = false;
    let distance = Math.abs(color1Hsl[0] - color2Hsl[0]);
    if (distance < 180) {
      startHsl = color1Hsl;
      endHsl = color2Hsl;
    } else {
      startHsl = color2Hsl;
      endHsl = color1Hsl;
      endHsl[0] += 360;
      swapped = true;
      distance = 360 - distance;
    }
    return [startHsl, endHsl, swapped, distance];
  }
  return [null, null, false, 0];
};

const calculateStepColors = (startHsl: Color, endHsl: Color, steps: number) => {
  const colors: string[] = [];
  for (let i = 1; i < steps + 1; i++) {
    colors.push(colorToStr(HSLToRGB([
      startHsl[0] + ((endHsl[0] - startHsl[0]) * i / (steps + 1)) % 360,
      startHsl[1] + ((endHsl[1] - startHsl[1]) * i / (steps + 1)),
      startHsl[2] + ((endHsl[2] - startHsl[2]) * i / (steps + 1)),
    ])));
  }
  return colors;
};

export const getStepColors = (color1: string, color2: string, steps: number): string[] => {
  const [startHsl, endHsl, swapped] = getAdjustedStartAndEndColors(color1, color2);
  if (startHsl && endHsl) {
    const colors = calculateStepColors(startHsl, endHsl, steps);

    return swapped ? colors.reverse() : colors;
  }
  return [];
};

export const getColorSpread = (primary: string, secondary: string, steps: number): string[] => {
  const [startHsl, endHsl, swapped, distance] = getAdjustedStartAndEndColors(primary, secondary);
  const primaryHsl = swapped ? endHsl : startHsl;
  if (distance < 45) {
    return getStepColors(primary, secondary, steps);
  } else if (primaryHsl !== null) {
    return calculateStepColors(
      [(primaryHsl[0] - 22.5) % 360, primaryHsl[1], primaryHsl[2]],
      [(primaryHsl[0] + 22.5) % 360, primaryHsl[1], primaryHsl[2]],
      steps
    );
  }
  return [];
};

export const extractRGBValues = (colorString: string) => {
  if (colorString.includes('#')) {
    const hex = colorString.replace(/^#/, '');

    // Parse the hex string to get the individual color components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return {
      r: r,
      g: g,
      b: b
    };
  } else {
    // Extract numbers between parentheses
    const match = colorString.match(/\((.*?)\)/);
    if (match) {
      const matches = match[1].split(',').map(Number);

      // Check if it's a valid RGBA format
      if (matches.length < 3 || matches.length > 4) {
        throw new Error('Invalid RGBA format');
      }

      return {
        r: matches[0],
        g: matches[1],
        b: matches[2]
      };
    } else {
      return null;
    }
  }
};

export const extractSameRGBWithAlpha = (rgbaString: string, a: number) => {
  const rgbObj = extractRGBValues(rgbaString);
  if (rgbObj) {
    return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${a})`;
  } else {
    return '';
  }
};

export const blendColors = (baseColor: string, overlayColor: string, opacity: number) => {
  // Add overlay with opacity over the base color and return corresponding hex code
  const baseRgb = strToColor(baseColor);
  const overlayRgb = strToColor(overlayColor);

  if (baseRgb === null || overlayRgb === null) {
    return baseColor;
  }

  // Apply the blending formula
  const r = Math.round((1 - opacity) * baseRgb[0] + opacity * overlayRgb[0]);
  const g = Math.round((1 - opacity) * baseRgb[1] + opacity * overlayRgb[1]);
  const b = Math.round((1 - opacity) * baseRgb[2] + opacity * overlayRgb[2]);

  return colorToStr([r, g, b]);
};

export const getEventTintColor = (primaryColor: string) => {
  return blendColors(primaryColor, '#000000', 0.4);
};
