import React from 'react';

const LinkedIn = ({ size = 24, color = 'currentColor' }: { size?: number, color?: string }) => {
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9631_7777)">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0 2.882C0 1.291 1.291 0 2.882 0C4.472 0 5.763 1.291 5.764 2.882C5.764 4.473 4.473 5.791 2.882 5.791C1.291 5.791 0 4.473 0 2.882ZM23.994 24V23.999H24V15.197C24 10.891 23.073 7.57397 18.039 7.57397C15.619 7.57397 13.995 8.90197 13.332 10.161H13.262V7.97597H8.48901V23.999H13.459V16.065C13.459 13.976 13.855 11.956 16.442 11.956C18.991 11.956 19.029 14.34 19.029 16.199V24H23.994ZM0.395996 7.97705H5.372V24H0.395996V7.97705Z"
            fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_9631_7777">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>;
};

export default LinkedIn;
