import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FilterPill } from '../../components/PillToggle';
import {
  getInspirationsById,
  InspirationIdea,
  InspirationTheme
} from '../../lib/inspiration';
import { useUserContext } from '../../contexts/UserContext';

import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

interface Row {
  label: string;
  theme: InspirationTheme;
  inspirations: (InspirationIdea | undefined)[]
}

const GalleryRows = () => {
  const navigate = useNavigate();
  const userContext = useUserContext();

  const [inspirationsById, setInspirationsById] = useState(new Map<string, InspirationIdea>());

  useEffect(() => {
    void fetchInspirations();
  }, []);

  const fetchInspirations = async () => {
    setInspirationsById(
      await getInspirationsById()
    );
  };

  const viewGallery = (theme?: InspirationTheme) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[WelcomePage] Clicked on view gallery pill'
    });

    navigate('/instant', {
      state: {
        theme
      }
    });
  };

  const inspire = (inspiration: InspirationIdea) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[WelcomePage] Clicked on inspo gallery event'
    });

    navigate(`/event/create/fromGallery/${inspiration.id}`);
  };

  const inspirations: Row[] = [{
    label: 'Themed Parties',
    theme: InspirationTheme.Party_70s,
    inspirations: [
      inspirationsById.get('231'),
      inspirationsById.get('1200'),
      inspirationsById.get('1117'),
      inspirationsById.get('1207'),
      inspirationsById.get('87'),
      inspirationsById.get('194')
    ]
  }, {
    label: 'Kids Birthdays',
    theme: InspirationTheme.Animals,
    inspirations: [
      inspirationsById.get('13'),
      inspirationsById.get('1490'),
      inspirationsById.get('1318'),
      inspirationsById.get('1188'),
      inspirationsById.get('1365'),
      inspirationsById.get('1499')
    ]
  }, {
    label: 'Adult Birthdays',
    theme: InspirationTheme.Floral,
    inspirations: [
      inspirationsById.get('1284'),
      inspirationsById.get('1075'),
      inspirationsById.get('1448'),
      inspirationsById.get('1261'),
      inspirationsById.get('1213'),
      inspirationsById.get('1234'),
    ]
  }, {
    label: 'Sports and Activities',
    theme: InspirationTheme.Football,
    inspirations: [
      inspirationsById.get('105'),
      inspirationsById.get('1282'),
      inspirationsById.get('102'),
      inspirationsById.get('1419'),
      inspirationsById.get('213'),
      inspirationsById.get('216'),
    ]
  }, {
    label: 'Baby',
    theme: InspirationTheme.BabyBoy,
    inspirations: [
      inspirationsById.get('26'),
      inspirationsById.get('1171'),
      inspirationsById.get('1156'),
      inspirationsById.get('1169'),
      inspirationsById.get('128'),
      inspirationsById.get('1433'),
    ]
  }];

  return <InspirationGalleryContainer>
    <InspirationGallerySectionContainer>
      <InspirationCategorySections>
        {inspirations.map(theme => <CategoryContainer key={theme.label}>
          <CategoryHeader>
            <CategoryTitle>{theme.label}</CategoryTitle>
            <ViewGalleryPillButton onClick={() => viewGallery(theme.theme)}>View gallery</ViewGalleryPillButton>
          </CategoryHeader>
          <CarouselWrapper>
            <GalleryContainer>
              {theme.inspirations.map((inspiration) => inspiration ? <Inspiration
                  key={inspiration.id} onClick={() => inspire(inspiration)}>
                  <InspirationImage src={inspiration.photoUrl}/>
                  <InspirationText>
                    {inspiration.title}
                  </InspirationText>
                </Inspiration> : null)
              }
            </GalleryContainer>
          </CarouselWrapper>
        </CategoryContainer>)}
      </InspirationCategorySections>
    </InspirationGallerySectionContainer>
  </InspirationGalleryContainer>;
};

const GalleryContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: -17px; // Hide bottom scroll bar
  padding-bottom: 17px; // Prevent bottom content cut off
  gap: 41px;

  @media (${DeviceQueries.mobile}) {
    gap: 16px;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (${DeviceQueries.mobile}) {
    gap: 12px;
  }
`;

const Inspiration = styled.div`
  width: 311px;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (${DeviceQueries.mobile}) {
    width: 242px;
  }
`;

const InspirationImage = styled.img`
  width: 311px;
  max-height: 311px;
  object-fit: contain;
  vertical-align: top;
  border-radius: 10px;
  opacity: 1;

  @media (${DeviceQueries.mobile}) {
    width: 242px;
    max-height: 242px;
  }
`;

const InspirationText = styled.div`
  display: flex;
  flex-direction: column;
  transition: background-color 1s;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CategoryTitle = styled.div`
  font-size: 40px;
  font-weight: 500;

  @media (${DeviceQueries.mobile}) {
    font-size: 24px;
  }
`;

const CarouselWrapper = styled.div`
  overflow: hidden;
`;

const InspirationGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  width: 100%;

  @media (${DeviceQueries.mobile}) {
    gap: 20px;
  }
`;

const InspirationGallerySectionContainer = styled.div`
  width: 100%;
`;

const InspirationCategorySections = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (${DeviceQueries.mobile}) {
    gap: 20px;
  }
`;

const ViewGalleryPillButton = styled(FilterPill).attrs({
  as: 'button'
})`
  width: fit-content;
  font-family: Plus Jakarta Sans, sans-serif;
  color: var(--sp-bg-color);

  &:hover {
    filter: brightness(90%);
  }
`;

export default GalleryRows;
