import React, { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { useModalContext } from '../contexts/ModalContext';
import ImageCropper from './ImageCropper';

const fileTypeExtensions = ['.png', '.gif', '.jpeg', '.jpg'];

const ImageUploader = ({ circular, fullWidth, children, onImageCropAvailable, onPopupOpen, onPopupClose }: {
  circular?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
  onImageCropAvailable(dataUrl: string): void;
  onPopupOpen?: () => void;
  onPopupClose?: () => void;
}) => {
  const modalContext = useModalContext();

  const [dragActive, setDragActive] = useState(false);

  const onDragEnter = () => {
    setDragActive(true);
  };

  const onDragLeave = () => {
    setDragActive(false);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setDragActive(false);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents


        const imageData = reader.result;
        if (imageData !== null) {
          if (onPopupOpen) {
            onPopupOpen();
          }
          modalContext.show({
            contents: <ImageCropper image={imageData} circular={circular}
                                    whenDone={onImageCropAvailable}
                                    close={() => {
                                      if (onPopupClose) onPopupClose();
                                      modalContext.hide();
                                    }}/>
          });
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': fileTypeExtensions },
    onDrop: onDrop,
    multiple: false,
    onDragEnter,
    onDragLeave
  });

  return <UploadContainer className={dragActive ? 'image-upload-drag-active' : ''} {...getRootProps()} $fullWidth={fullWidth}>
    <input {...getInputProps()}/>
    {children}
  </UploadContainer>;
};

const UploadContainer = styled.div<{$fullWidth?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export default ImageUploader;
