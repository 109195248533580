import React from 'react';

const MailOutline = ({ color = 'currentColor', size = 25 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path
      d="M22.1118 7.02344V17.5234C22.1118 18.7661 21.1045 19.7734 19.8618 19.7734H4.86182C3.61918 19.7734 2.61182 18.7661 2.61182 17.5234V7.02344M22.1118 7.02344C22.1118 5.7808 21.1045 4.77344 19.8618 4.77344H4.86182C3.61918 4.77344 2.61182 5.7808 2.61182 7.02344M22.1118 7.02344V7.26615C22.1118 8.04748 21.7065 8.77288 21.041 9.18238L13.541 13.7978C12.8179 14.2428 11.9058 14.2428 11.1826 13.7978L3.6826 9.18238C3.01717 8.77288 2.61182 8.04748 2.61182 7.26615V7.02344"
      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default MailOutline;
