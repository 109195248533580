import React from 'react';
import styled from 'styled-components';
import find from 'lodash/find';
import reject from 'lodash/reject';
import { IUEvent, IUInvitee } from '../../lib/event';
import AttendeeGroup from '../../components/attendees/AttendeeGroup';
import { stob64 } from '../../util/stringutils';
import { useNavigate } from 'react-router-dom';
import { loginUrl } from '../../lib/login';
import ActionButton from '../../components/buttons/ActionButton';
import { sendInvites } from '../../api/ElkEventService';
import { useUserContext } from '../../contexts/UserContext';
import { useButterBarContext } from '../../contexts/ButterBarContext';
import { useModalContext } from '../../contexts/ModalContext';
import AttendeeReplyModal from '../../components/modals/AttendeeReplyModal';
import { PageEnum } from '../Login/LoginPage';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkInviteStatus,
  TAppElkMessage,
  TAppElkReviewStatus
} from 'TProtocol/prototypes/events/messages';
import { isPast } from '../../lib/time';

interface Props {
  event: IUEvent;
  inviteeUuid?: string;
  readOnly: boolean;
  messagesByInviteeId: Map<string, TAppElkMessage[]>;
}

const AttendeesList = ({ event, inviteeUuid, readOnly, messagesByInviteeId }: Props) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const butterBarContext = useButterBarContext();
  const modalContext = useModalContext();
  const { getEmail, getPhone } = useInviteeUuidStorage();

  const onVerifyAccountClick = () => {
    if (!readOnly) {
      const attendee = attendees.find((attendee) => attendee?.inviteeId === inviteeUuid);
      if (attendee !== undefined) {
        const rsvpStatus = attendee.rsvpStatus;
        if (rsvpStatus === TAppElkAttendeeStatus.NO || rsvpStatus === TAppElkAttendeeStatus.YES) {
          const rsvpParam = rsvpStatus === TAppElkAttendeeStatus.YES ? 'yes' : 'no';
          const encodedMessage = stob64(attendee?.rsvpMessage ?? 'm');
          const additionalGuestCount = attendee?.additionalGuestCount ?? 0;
          const encodedTitle = stob64(event?.title ?? 'this party');
          const encodedName = stob64(attendee?.name ?? '');
          const inviteeUuid = attendee?.inviteeId ?? '';
          let loginMethodPage, encodedPhone, encodedEmail;
          if (userContext.isLoggedIn()) {
            loginMethodPage = attendee.email !== undefined && attendee.phone === undefined ? PageEnum.Email : PageEnum.Phone;
            encodedEmail = stob64(attendee.email ?? '');
            encodedPhone = stob64(attendee.phone ?? '');
          } else {
            loginMethodPage = getEmail() !== undefined && getPhone() === undefined ? PageEnum.Email : PageEnum.Phone;
            encodedEmail = stob64(getEmail() ?? '');
            encodedPhone = stob64(getPhone() ?? '');
          }

          navigate(
            `/event/rsvp/action/${event.id}?r=${rsvpParam}&em=${encodedMessage}&ng=${additionalGuestCount}&et=${encodedTitle}&en=${encodedName}&iu=${inviteeUuid}&ee=${encodedEmail}&ep=${encodedPhone}`,
            { replace: true, state: { finishingRSVP: true, page: loginMethodPage } }
          );
        }
      } else {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.WARN,
          userId: userContext.id,
          tag: ULogTag.UserInfo,
          message: `[AttendeesList] onVerifyAccountClick - Could not find inviteeUuid ${inviteeUuid} in attendees list for event ${event.id}`
        });

        navigate(loginUrl('/event/' + event?.id));
      }
    }
  };

  const onReplyClick = (inviteeUuid: string) => {
    if (!readOnly) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: `[AttendeesList] Clicked on reply link to inviteeUuid ${inviteeUuid}`
      });

      modalContext.show({
        contents: <AttendeeReplyModal
          event={event}
          inviteeId={inviteeUuid}
          close={() => modalContext.hide()}
        />
      });
    }
  };

  const sendPendingInvites = async () => {
    const inviteeIds: string[] = [];

    pending.forEach((invitee) => {
      if (invitee.inviteStatus === TAppElkInviteStatus.PENDING) {
        inviteeIds.push(invitee.inviteeId);
        invitee.inviteStatus = TAppElkInviteStatus.TRIGGERED;
      }
    });

    await sendInvites(userContext, event.id, inviteeIds, undefined);
    butterBarContext.show({
      contents: `${pending.length} guest${pending.length !== 1 ? 's' : ''} invited successfully.`
    });
  };

  let attendees: IUInvitee[] = [];
  if (event !== undefined) {
    attendees = event.attendees;
    const hosts = attendees.filter((attendee): attendee is IUInvitee => attendee?.role === TAppElkAttendeeRole.ORGANIZER);
    attendees = attendees.filter(
      attendee => {
        if (attendee?.role === TAppElkAttendeeRole.ORGANIZER) {
          return false;
        }
        if (attendee?.waitlistStatus === TAppElkReviewStatus.DENIED || attendee?.waitlistStatus === TAppElkReviewStatus.PENDING_APPROVAL) {
          return false;
        }
        return attendee?.rsvpStatus === TAppElkAttendeeStatus.YES ||
          attendee?.rsvpStatus === TAppElkAttendeeStatus.NO ||
          ((attendee?.rsvpStatus === TAppElkAttendeeStatus.INVITED || attendee?.rsvpStatus === TAppElkAttendeeStatus.SEEN) && event.isHost);
      });
    if (hosts.length) {
      attendees = [...hosts, ...attendees];
    }
  }

  const showAttendees = event.publicGuestList || event.isHost;

  const going = attendees.filter(
    (attendee): attendee is IUInvitee => attendee?.rsvpStatus === TAppElkAttendeeStatus.YES ||
      attendee?.role === TAppElkAttendeeRole.ORGANIZER);
  const notGoing = attendees.filter(
    (attendee): attendee is IUInvitee => attendee?.rsvpStatus === TAppElkAttendeeStatus.NO);
  const invited = attendees.filter((attendee): attendee is IUInvitee => {
    return attendee?.inviteStatus !== undefined &&
      (attendee?.rsvpStatus === TAppElkAttendeeStatus.INVITED || attendee?.rsvpStatus === TAppElkAttendeeStatus.SEEN);
  });
  const pending = attendees.filter((attendee): attendee is IUInvitee => {
    return attendee?.inviteStatus === TAppElkInviteStatus.PENDING;
  });
  const seen = attendees.filter((attendee): attendee is IUInvitee => {
    return attendee?.inviteStatus === undefined && attendee?.rsvpStatus === TAppElkAttendeeStatus.SEEN;
  });
  let goingCount = going.length;
  const respondedCount = going.length;
  going.map((going) => goingCount += going?.additionalGuestCount ?? 0);

  const attendingBox = goingCount > 0 ?
    <AttendeeSection>
      <>
        <div>
          {!showAttendees ? 'Going' : respondedCount === goingCount ? `Going (${goingCount})` : `Going (${respondedCount} responded, ${goingCount} ${isPast(event) ? 'attended' : 'attending'})`}
        </div>

        <AttendeeGroup
          event={event}
          attendees={going}
          onVerifyClick={onVerifyAccountClick}
          onReplyClick={onReplyClick}
          myInviteeUuid={inviteeUuid}
          messagesByInviteeId={messagesByInviteeId}
        />
      </>
    </AttendeeSection> : null;

  const nonAttendeesBox = (notGoing.length > 0) ?
    <AttendeeSection>
      {showAttendees ? <div>{isPast(event) ? 'Couldn\'t' : 'Can\'t'} make it ({notGoing.length})</div> :
        <div>{isPast(event) ? 'Couldn\'t' : 'Can\'t'} make it</div>}
      <AttendeeGroup
        event={event}
        attendees={notGoing}
        onVerifyClick={onVerifyAccountClick}
        onReplyClick={onReplyClick}
        myInviteeUuid={inviteeUuid}
        messagesByInviteeId={messagesByInviteeId}
      />
    </AttendeeSection>
    : null;

  const invitedNotPending = invited.filter((attendee) => attendee.inviteStatus !== TAppElkInviteStatus.PENDING).length;
  const invitedPending = invited.filter((attendee) => attendee.inviteStatus === TAppElkInviteStatus.PENDING).length;
  const invitedBox = (event.isHost && invited.length > 0 && !readOnly) ? <AttendeeSection>
    <div>
      {showAttendees && invitedNotPending !== 0 && invitedPending !== 0
        ? `${invitedNotPending} awaiting response (${invitedPending} invite${invitedPending === 1 ? '' : 's'} unsent)`
        : showAttendees && invitedNotPending !== 0
          ? `${invitedNotPending} awaiting response`
          : showAttendees && invitedPending !== 0
            ? `${invitedPending} invite${invitedPending === 1 ? '' : 's'} unsent`
            : 'Invited'}
    </div>
    <AttendeeGroup
      event={event}
      attendees={invited}
      onVerifyClick={onVerifyAccountClick}
      onReplyClick={onReplyClick}
      myInviteeUuid={inviteeUuid}
      messagesByInviteeId={messagesByInviteeId}
    />
    {(!readOnly && pending.length > 0 && !isPast(event)) ?
      <ActionButton onClick={sendPendingInvites} overrideColor={true} bgColor={'#FFFFFF'}>
        Send {pending.length} Pending Invite{pending.length === 1 ? '' : 's'}
      </ActionButton> : null}
  </AttendeeSection> : null;

  const seenBox = (event.isHost && seen.length > 0 && !readOnly) ? <AttendeeSection>
    <div>
      Seen ({seen.length})
    </div>
    <AttendeeGroup
      event={event}
      attendees={seen}
      onVerifyClick={onVerifyAccountClick}
      onReplyClick={onReplyClick}
      myInviteeUuid={inviteeUuid}
      messagesByInviteeId={messagesByInviteeId}
    />
  </AttendeeSection> : null;

  return <Container>
    {attendingBox}
    {nonAttendeesBox}
    {invitedBox}
    {seenBox}
    {!showAttendees && <AttendeeBody>Guest list is private for this event.</AttendeeBody>}
  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`

const AttendeeSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AttendeeBody = styled.div`
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const AttendeeTitleGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default AttendeesList;
