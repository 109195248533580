import React, { useContext, useEffect, useRef, useState } from 'react';
import { Unsubscribe, getDatabase, onValue, ref } from 'firebase/database';

import { useUserContext } from './UserContext';
import { deleteDraft, editEventDraft, fetchDraft, fetchDrafts } from '../api/ElkEventService';
import { isEventEmpty, IUEvent } from '../lib/event';
import { autoLoadEventFromStorage, autoSaveEventToStorage, clearSavedEventInStorage } from '../api/ElkAutoSaveApi';
import { app } from '../core/libs/Firebase';
import { convertToIUEvent } from '../state/reducers/event';
import { isUserHost } from '../lib/attendance';

export interface IUAutoSaveContext {
  fetchDrafts: () => Promise<void>;
  fetchDraft: (eventId: string) => Promise<IUEvent | undefined>;
  drafts?: IUEvent[];
  save: (event: IUEvent, localOnly?: boolean) => Promise<boolean>;
  load: (eventId?: string) => IUEvent | undefined;
  remove: (eventId: string) => Promise<void>;
  subscribeToDraft: (eventDraftId: string) => void;
  lastSaved: number;
}

const AutoSaveContext = React.createContext<IUAutoSaveContext | null>(null);

export function AutoSaveContextProvider(props: { children: React.ReactNode }) {
  const userContext = useUserContext();

  const [drafts, setDrafts] = useState<IUEvent[] | undefined>(undefined);
  const [lastSaved, setLastSaved] = useState(Date.now());

  const homepageModId = useRef(new Date().getTime().toString());
  const unsubscribeHandlers = useRef<Map<string, () => void>>(new Map());

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;
    if (userContext.id !== undefined) {
      const db = getDatabase(app);
      const valueRef = ref(db, `drafts/users/${userContext.id}`);

      unsubscribe = onValue(valueRef, (snapshot) => {
        const data = snapshot.val() as { DRAFTS_HOMEPAGE_DATA_MODIFICATION: string } | null;
        if (data) {
          void fetchDraftsInternal(data.DRAFTS_HOMEPAGE_DATA_MODIFICATION);
        }
      });
    }

    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
      unsubscribeHandlers.current.forEach(unsubscribe => {
        unsubscribe();
      });
    };
  }, [userContext.id]);

  const save = async (event: IUEvent, localOnly?: boolean): Promise<boolean> => {
    if (isEventEmpty(event)) {
      return false;
    }

    autoSaveEventToStorage(event);
    if (userContext.isLoggedIn()) {
      if (event.isDraft) {
        updateDrafts(event);
      }

      if (localOnly !== true) {
        event.lastSavedTimestamp = Date.now();

        const tAppElkDraftEvent = await event.convertToTAppElkDraftEvent(userContext.name);
        await editEventDraft(userContext, tAppElkDraftEvent);

        return true;
      }
    }
    return false;
  };

  const load = (eventId?: string) => {
    if (eventId === undefined) {
      return undefined;
    }
    const savedEvent = autoLoadEventFromStorage(eventId);
    if (savedEvent !== null) {
      return savedEvent;
    }
    return undefined;
  };

  const remove = async (eventId: string) => {
    const unsubscribeHandler = unsubscribeHandlers.current.get(eventId);
    if (unsubscribeHandler) {
      unsubscribeHandler();
    }

    clearSavedEventInStorage(eventId);

    if (userContext.isLoggedIn()) {
      setDrafts((drafts) => drafts?.filter((draft) => draft.id !== eventId));

      await deleteDraft(userContext, eventId);
    }
  };

  const subscribeToDraft = (eventId: string) => {
    if (unsubscribeHandlers.current.has(eventId)) {
      return;
    }

    const db = getDatabase(app);
    const valueRef = ref(db, `drafts/${eventId}`);

    unsubscribeHandlers.current.set(eventId, onValue(valueRef, (snapshot) => {
      const data = snapshot.val() as { MODIFICATION_ID?: string };

      const savedEvent = autoLoadEventFromStorage(eventId);

      if (data.MODIFICATION_ID !== undefined && (savedEvent === null || +data.MODIFICATION_ID > (savedEvent.lastAutoSaveTs ?? 0))) {
        void fetchDraftInternal(eventId, true);
      }
    }));
  };

  const fetchDraftInternal = async (eventId: string, force?: boolean): Promise<IUEvent | undefined> => {
    const existingDraft = load(eventId);

    if (!existingDraft || force) {
      const response = await fetchDraft(userContext, eventId);

      if (response.event) {
        const event = convertToIUEvent({
          event: response.event,
          isCreate: true,
          isDraft: true
        });

        event.isHost = isUserHost(event, userContext.id);

        autoSaveEventToStorage(event);

        setLastSaved(Date.now());

        return event;
      }
    }

    return undefined;
  };

  const fetchDraftsInternal = async (modId?: string) => {
    if (modId && homepageModId.current >= modId) {
      return;
    }

    if (modId) {
      homepageModId.current = modId;
    }

    const response = await fetchDrafts(userContext);

    const events = response.events;
    setDrafts(
      events.map((event) => convertToIUEvent({
        event,
        isCreate: true,
        isDraft: true
      }))
    );
  };

  const updateDrafts = (draft: IUEvent) => {
    draft.isDraft = true;

    setDrafts(drafts => {
      if (drafts === undefined) {
        return undefined;
      }
      const existingIndex = drafts.findIndex((d) => d.id === draft.id);

      if (existingIndex !== -1) {
        drafts.splice(existingIndex, 1, draft);
        return [...drafts];
      } else {
        return [
          ...drafts,
          draft
        ];
      }
    });
  };

  const context: IUAutoSaveContext = {
    fetchDrafts: fetchDraftsInternal,
    fetchDraft: fetchDraftInternal,
    drafts,
    save,
    load,
    remove,
    subscribeToDraft,
    lastSaved
  };

  return <AutoSaveContext.Provider value={context}>
    {props.children}
  </AutoSaveContext.Provider>;
}

export const useAutoSaveContext = () => {
  const context = useContext(AutoSaveContext);
  if (context === null) {
    throw new Error('useAutoSaveContext must be used within a AutoSaveContextProvider');
  }
  return context;
};
