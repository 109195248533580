import React, { MouseEvent, PropsWithChildren } from 'react';
import { IUEvent } from '../../lib/event';
import styled from 'styled-components';

import { BigImage, BigImagePlaceholder } from '../Create/GeneratedImageSelection';

import { DeviceQueries } from 'Common/src/components/styled';
import EventDetailsModule from './EventDetailsModule';
import { parseHyperlinks } from '../../util/links';
import { DesktopShow, MobileShow } from '../../components/styled/Common';
import { WhiteButton } from '../../components/buttons/WhiteButton';
import { useNavigate } from 'react-router-dom';
import ChevronRight from '../../components/icons/ChevronRight';
import { useUserContext } from '../../contexts/UserContext';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { InspirationTheme, getThemeLabel } from '../../lib/inspiration';
import SparkleButton from '../../components/buttons/SparkleButton';

declare const ELK_COOKIE_DOMAIN: string; // sunshine.com

const TopModule = ({ event, forSampleInvite, isAttending, theme, children }: PropsWithChildren<{
  event: IUEvent,
  forSampleInvite?: boolean,
  isAttending: boolean,
  theme?: InspirationTheme
}>) => {
  const userContext = useUserContext();
  const navigate = useNavigate();

  const isRootDomain = window.location.host === ELK_COOKIE_DOMAIN;

  const description = event.description.split('\n')
    .map((str: string, i: number) => <div key={i}>{parseHyperlinks(str)}<br/></div>);

  const onContainerClick = (e: MouseEvent) => {
    if (forSampleInvite) {
      navigateToGallery(e);
    }
  }

  const navigateToGallery = (e: MouseEvent) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[TopModule] Clicked Browse Category button'
    });
    e.preventDefault();
    e.stopPropagation();
    navigate(`/instant${theme !== undefined ? `?theme=${theme}` : ''}`);
  }

  return <EventInfoContainer $forSampleInvite={forSampleInvite} onClick={onContainerClick}>
    <LeftColumn $forSampleInvite={forSampleInvite}>
      {event.photoUrl !== undefined
        ? <CustomBigImage src={event.photoUrl} $fullWidth={true} $forSampleInvite={forSampleInvite}/>
        : <BigImagePlaceholder $fullWidth={true}/>}
      <DesktopShow>{children}</DesktopShow>
    </LeftColumn>
    <RightColumn $adjustMarginTop={children !== undefined} $forSampleInvite={forSampleInvite}>
      <TopContent>
        <Title>
          {event.title}
        </Title>
        <MobileShow>{children}</MobileShow>
      </TopContent>
      {event.description.trim() !== '' &&
          <Description $forSampleInvite={forSampleInvite}>
            {description}
          </Description>}
      <EventDetailsModule event={event} readOnly={forSampleInvite} isAttending={isAttending}
                          forSampleInvite={forSampleInvite}/>
      {forSampleInvite &&
          <SampleInviteButtonFooter>
            { isRootDomain ? <SparkleButton
                buttonText="Create your invite"
                disabled={false}
                onClick={navigateToGallery}
                buttonColor={'var(--sp-text-color)'}
                textColor={'var(--sp-bg-color)'}
                fullWidth={false}
              /> :
              <SampleInviteRedirectButton onClick={navigateToGallery}>
                  Browse { (theme !== undefined) ? getThemeLabel(theme) : 'Category'} <SampleInviteButtonSpan>
                  <DesktopShow><ChevronRight/></DesktopShow>
                  <MobileShow><ChevronRight size={20}/></MobileShow>
              </SampleInviteButtonSpan>
              </SampleInviteRedirectButton> }
          </SampleInviteButtonFooter>}
    </RightColumn>
  </EventInfoContainer>
};

const Title = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 4px;

  @media (${DeviceQueries.mobile}) {
    margin-top: 10px;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
`;

const EventInfoContainer = styled.div<{ $forSampleInvite?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ $forSampleInvite }) => $forSampleInvite && 'color: white; cursor: pointer;'}

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
  }

  @media not (${DeviceQueries.mobile}) {
    ${({ $forSampleInvite }) => $forSampleInvite && 'gap: 30px;'}
  }

`

const LeftColumn = styled.div<{ $forSampleInvite?: boolean }>`
  @media not (${DeviceQueries.mobile}) {
    ${({ $forSampleInvite }) => !$forSampleInvite && 'width: 50%;'}
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (${DeviceQueries.mobile}) {
    ${({ $forSampleInvite }) => $forSampleInvite && 'display: flex; justify-content: center;'}
  }
`

const RightColumn = styled.div<{ $adjustMarginTop: boolean, $forSampleInvite?: boolean }>`
  position: relative;
  display: flex;
  //padding: 0 30px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media not (${DeviceQueries.mobile}) {
    width: 50%;
    flex-grow: 1;
    padding: ${({ $forSampleInvite }) => $forSampleInvite ? '0 0 72px 0' : '0 30px'};
  }

  @media (${DeviceQueries.mobile}) {
    padding: 0;
  }
`

const Description = styled.div<{ $forSampleInvite?: boolean }>`
  font-size: ${({ $forSampleInvite }) => $forSampleInvite ? '16px' : '18px'};
  font-weight: 400;
  word-break: break-word;

  a, a:visited {
    color: #FFF;
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const TopContent = styled.div`
`

const CustomBigImage = styled(BigImage)<{ $forSampleInvite?: boolean }>`
  @media (${DeviceQueries.mobile}) {
    ${({ $forSampleInvite }) => $forSampleInvite && 'max-width: 500px;'}
  }
`

const SampleInviteButtonFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media not (${DeviceQueries.mobile}) {
    position: absolute;
    bottom: 0;
  }
`

const SampleInviteRedirectButton = styled(WhiteButton)`
  color: var(--sp-text-color);
  text-align: center;
  font-weight: 500;

  @media not (${DeviceQueries.mobile}) {
    padding: 15px 18px;
    font-size: 20px;
    //line-height: 30.509px; /* 125.723% */
  }

  @media (${DeviceQueries.mobile}) {
    font-size: 18px;
    line-height: normal;
  }
`

const SampleInviteButtonSpan = styled.span`
  display: flex;
`

export default TopModule;
