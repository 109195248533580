import styled from 'styled-components';

export const Overlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--sp-text-rgb), .4);
`;

export const TransparentOverlayBottomModal = styled(Overlay)`
  justify-content: flex-end;
  background-color: transparent;
`;

export const ModalContainer = styled.div<{ $wide?: boolean, $maxWidth?: string, $smallerGap?: boolean }>`
  background: var(--shine-bg-color);
  color: var(--sp-text-color);
  font-family: Plus Jakarta Sans, sans-serif;
  position: relative;
  overflow-y: scroll;
  display: flex;
  max-width: ${({ $maxWidth }) => `calc(min(${$maxWidth ?? '429px'}, 100% - 60px))`};
  padding: 20px;
  flex-direction: column;
  gap: ${({ $smallerGap }) => $smallerGap ? '15px' : '30px'};
  border-radius: 16px;
  background: rgba(var(--sp-bg-rgb), 0.90);
  width: 100%;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari
  max-height: 95vh;
`;

export const BottomModalContainer = styled(ModalContainer)<{ $noOverflowScroll?: boolean }>`
  background: rgba(var(--sp-bg-rgb), 0.80);
  max-width: calc(min(429px, 100% - 40px));
  border-radius: 16px 16px 0 0;
  ${({ $noOverflowScroll }) => $noOverflowScroll && 'overflow-y: auto'};
`;

export const SystemModalContainer = styled(ModalContainer)`
  --shine-bg-color: #FFF;
  --shine-highlight-color: #FF6422;
  --shine-system-button-text-color: #FFF;
`;

export const ModalClose = styled.div`
  float: right;
  height: 0;
  cursor: pointer;
  margin-top: -10px;
  margin-right: -10px;
`;

export const Title = styled.h1`
  margin: 0 25px 15px 0;
  display: flex;
  text-align: left;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 111.111% */
`;

export const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;


export const ModalHeadAndSubHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ModalHeader = styled.div<{ $hasClose?: boolean }>`
  display: flex;
  width: ${({ $hasClose }) => $hasClose ? '100%' : 'calc(100% - 20px);'};
  gap: 4px;
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const ModalSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
