import React, {
  ClipboardEvent,
  FormEvent,
  Fragment,
  KeyboardEvent,
  PropsWithChildren,
  ReactNode,
  Ref,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';

import { Container, ErrorContainer, InputContainer, Label } from './forms/Input';
import { useUserContext } from '../contexts/UserContext';
import { ActionMenuInput } from './EventForm/ActionMenuCommon';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { WHITE } from 'Common/src/lib/Colors';

const ExpandingTextInput = ({
                              value,
                              label,
                              placeholder,
                              textColor = 'currentColor',
                              onChange,
                              onSubmit,
                              charLimit,
                              icon,
                              noVertMargin,
                              noMargin = false,
                              minHeight,
                              error,
                              errorRef,
                              fontSize,
                              lineHeight,
                              backgroundColor,
                              excludeBackground,
                              isForSideBar,
                              as,
                              submitButton,
                              modId,
                              children
                            }: PropsWithChildren<{
  value: string,
  label?: string;
  placeholder?: string,
  textColor?: string,
  onChange: (value: string) => void,
  onInvalid?: (e: FormEvent) => void,
  onSubmit?: (value?: string) => void;
  charLimit?: number;
  required?: boolean;
  icon?: ReactNode;
  noVertMargin?: boolean;
  noMargin?: boolean;
  minHeight?: number;
  error?: string;
  errorRef?: Ref<HTMLDivElement>;
  fontSize?: string;
  lineHeight?: string;
  backgroundColor?: string;
  excludeBackground?: boolean;
  isForSideBar?: boolean;
  as?: React.FC;
  submitButton?: React.ReactNode;
  modId?: number;
}>) => {
  const userContext = useUserContext();

  const [charCount, setCharCount] = useState<number | undefined>(charLimit);
  const [text, setText] = useState(value);
  const [invalid, setInvalid] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const modIdRef = useRef<number>(0);

  useEffect(() => {
    if (divRef.current && ((!divRef.current.innerText || value === '') || modId && modIdRef.current < modId)) {
      divRef.current.innerText = value;
      onTextChange(value);
      if (modId) {
        modIdRef.current = modId;
      }
    }
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && onSubmit !== undefined) {
      onSubmit(value);
      e.preventDefault();
    }
  };

  const onTextChange = (text: string) => {
    const trimmedText = text.replace(/\s*\n\s*\n\s*/g, '\n\n');
    onChange(trimmedText);
    if (charLimit !== undefined) {
      setCharCount(charLimit - trimmedText.length);
    }
    setText(trimmedText);
    setInvalid(false);
  };

  const handleCopy = (e: ClipboardEvent) => {
    const text = window.getSelection()?.toString();
    if (text) {
      e.clipboardData.setData('text/plain', text);
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    const text = e.clipboardData.getData('text/plain');
    try {
      if (document.execCommand !== undefined) {
        document.execCommand('insertText', false, text);
        e.preventDefault();
      }
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[ExpandingTextInput] handlePaste - "${text}" Error: ${stringifyError(e)}`
      });
    }
    setInvalid(false);
  };

  let charLimitText: ReactNode = null;
  if (charCount !== undefined) {
    charLimitText =
      <CharacterCount $charCount={charCount} $color={textColor}>{charCount} characters left</CharacterCount>;
  }

  const filled = text !== '' && text !== '\n';

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleInput = (e: FormEvent<HTMLDivElement>) => {
    setInvalid(false);
    onTextChange(e.currentTarget.innerText);
  };

  let ThisInputContainer = InputContainer;
  let excludeContainerBackground = excludeBackground;
  let inputColor = 'currentColor';
  let noInputMargin = noMargin;
  if (isForSideBar) {
    ThisInputContainer = ActionMenuInput;
    excludeContainerBackground = true;
    inputColor = WHITE;
    fontSize = '16px';
    lineHeight = '20px';
    noInputMargin = true;
  }

  let InnerInputContainer = Fragment;
  if (icon !== undefined) {
    InnerInputContainer = IconAndInputContainer;
  }

  const innerContent = <>
    <ThisInputContainer $filled={filled || isFocused || placeholder !== undefined} $minHeight={minHeight}
                        $color={textColor}>
      <InnerInputContainer>
        {icon !== undefined && <IconWrapper>{icon}</IconWrapper>}
        <TextInput
          data-ph={placeholder}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onCopy={handleCopy}
          onPaste={handlePaste}
          ref={divRef}
          contentEditable
          $color={inputColor}
          onInput={handleInput}
          $fontSize={fontSize}
          $lineHeight={lineHeight}
          $smallerTopMargin={label === undefined}
          $noMargin={noInputMargin}
          $bottomMargin={charCount === undefined && !children}
          $excludeBackground={excludeBackground}>
        </TextInput>
      </InnerInputContainer>
      <Label>{label}</Label>
      {submitButton}
    </ThisInputContainer>
    {charLimitText}
    {error ? <ErrorContainer>{error}</ErrorContainer> :
      invalid ? <ErrorContainer>This is a required field</ErrorContainer> : null}
    {children}
  </>;


  if (as) {
    const OuterContainer = as;

    return <OuterContainer>
      {innerContent}
    </OuterContainer>;
  } else {
    return <Container ref={errorRef} $growing={true} $noVertMargin={noVertMargin}
                      $excludeBackground={excludeContainerBackground}
                      $backgroundColor={backgroundColor}>
      {innerContent}
    </Container>;
  }
};


const TextInput = styled.div<{
  $color: string,
  $fontSize?: string,
  $lineHeight?: string,
  $smallerTopMargin?: boolean,
  $noMargin?: boolean,
  $bottomMargin?: boolean,
  $excludeBackground?: boolean
}>`
  color: ${({ $color }) => $color};
  outline: 0;
  //padding-bottom: 5px;
  font-size: ${({ $fontSize }) => $fontSize ?? '24px'};
  line-height: ${({ $lineHeight }) => $lineHeight ?? '32px'};
  padding: ${({
                $smallerTopMargin,
                $noMargin,
                $bottomMargin
              }) => $noMargin ? 0 : `${$smallerTopMargin ? '10px' : '25px'} 5px ${$bottomMargin ? '10px' : '0'} 17px`};
  min-width: 5px; // Prevent cursor from being hidden
  width: 100%;
  box-sizing: border-box;
  word-wrap: anywhere;

  &:empty:not(:focus):before {
    content: attr(data-ph);
    opacity: 0.6;
    pointer-events: none;
  }
`;

const IconAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

const CharacterCount = styled.div<{
  $color?: string;
  $charCount: number;
}>`
  color: ${({
              $charCount,
              $color = 'currentColor'
            }) => ($charCount <= 0 ? 'var(--shine-system-error-color)' : $color)}; // Change color when 0 or negative
  text-align: right; // Align to the right (or left, as you prefer)
  margin: 0 10px 5px 0;
  font-size: 12px;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
`;

export default ExpandingTextInput;
