import React, { FormEvent, RefObject } from 'react';
import styled from 'styled-components';

import { PillInputRaw } from './Common';

import CloseIcon from 'Common/src/components/icons/CloseIcon';
import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  value?: string;
  disabled?: boolean;
  required?: boolean;
  expanding?: boolean;
  onChange: (value: string) => void;
  inputRef?: RefObject<HTMLInputElement>;
  onInvalid?: (e: FormEvent) => void;
  type?: 'datetime-local';
  placeholder?: string;
  onCancel?: () => void;
}

const PillInput = ({
                     value,
                     disabled,
                     required,
                     expanding = false,
                     onChange,
                     inputRef,
                     onInvalid,
                     type,
                     placeholder,
                     onCancel
                   }: Props) => {

  const onInputInvalid = (e: FormEvent) => {
    e.preventDefault();
    if (onInvalid) {
      onInvalid(e);
    }
  };

  return <Container $expanding={expanding}>
    <PillInputRaw
      value={value}
      disabled={disabled}
      required={required}
      onChange={(e) => onChange(e.currentTarget.value)}
      ref={(node) => {
        if (inputRef) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputRef.current = node;
        }
      }}
      onInvalid={onInputInvalid}
      type={type}
      placeholder={placeholder}
      $withCancel={onCancel !== undefined}
      $expanding={expanding}
    />
    {onCancel !== undefined && <CancelButton onClick={onCancel}><CloseIcon/></CancelButton>}
  </Container>;
};

const Container = styled.div<{ $expanding: boolean }>`
  position: relative;

  ${({ $expanding }) => $expanding && `
  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
  `}
`;

const CancelButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`;

export default PillInput;
