import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { calculateColor, parseName } from '../../util/attendee';
import { getDownloadUrl, getNormalizedPhotoUrl } from '../../lib/images';
import DashedCircle from '../icons/DashedCircle';
import { IUAutoCompleteContact } from '../../util/contact';
import { IUInvitee } from '../../lib/event';

import { TAppElkProfile } from 'TProtocol/prototypes/events/messages';

const NameRegex = /^([^ ]*) (.*)$/;

const AttendeeCircle = ({ attendee, small = false, size = 45 }: {
  attendee?: IUInvitee | TAppElkProfile | IUAutoCompleteContact,
  small?: boolean
  size?: number
}) => {
  const [photoUrl, setPhotoUrl] = useState('');

  useEffect(() => {
    let mounted = true;
    const getUrl = async () => {
      if (attendee?.photoUrl !== undefined) {
        const url = getNormalizedPhotoUrl(attendee.photoUrl);
        const downloadUrl = await getDownloadUrl(url);

        if (mounted) {
          setPhotoUrl(downloadUrl);
        }
      }
    };

    void getUrl();
    return () => {
      mounted = false;
    };
  }, [attendee?.photoUrl]);

  if (attendee === undefined) {
    return <DashedCircle color="#c6c6c6"/>;
  }

  const [firstName, lastName] = parseName(attendee.name);

  const email = ('email' in attendee ? attendee.email : '');
  
  let interior: ReactNode = '';

  if (photoUrl === '') {
    if (firstName !== '') {
      interior = `${firstName?.[0]?.toUpperCase() ?? ''}${lastName?.[0]?.toUpperCase() ?? ''}`;
    } else if (email) {
      interior = `${email[0].toUpperCase()}`;
    }
  } else {
    // circle with profile picture
    interior = <img src={photoUrl} alt="Attendee Profile" />;
  }

  const color = calculateColor(attendee);

  return <Circle $color={color} $small={small} $size={size}>
    {interior}
  </Circle>;
};

export const Circle = styled.div<{ $color: string, $small: boolean, $size: number }>`
  background-color: ${({ $color }) => $color};
  width: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 22.5px;

  font-size: ${({ $small }) => $small ? '16' : '24'}px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export default AttendeeCircle;
