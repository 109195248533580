import React, { MouseEvent, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  secondary?: boolean;
}

const RSVPButton = ({
                      type,
                      children,
                      secondary = false,
                      onClick,
                      disabled
                    }: PropsWithChildren<Props>) => {
  return <Button type={type} onClick={onClick} disabled={disabled} $secondary={secondary}>
    {children}
  </Button>;
};

const Button = styled.button<{ $secondary: boolean }>`
  font-family: Plus Jakarta Sans, sans-serif;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  //flex-direction: row;
  justify-content: center;
  align-items: center;
  //width: 155px;
  padding: 6px 12px;
  border-radius: 10px;
  border: 1px solid rgba(var(--sp-text-rgb), 0.10);
  background-color: ${({ $secondary }) => $secondary ? 'rgba(var(--sp-bg-rgb), 0.20)' : '#FFFFFF'};
  box-shadow: 0 0 10px 0 rgba(var(--sp-text-rgb), 0.05);
  color: ${({ $secondary }) => $secondary ? '#FFFFFF' : '#000000'};

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: .5;
  }
`;

export default RSVPButton;
