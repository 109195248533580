import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  onInputChange: (value: boolean) => void;
  $labelFontSize?: string;
  $dark?: boolean;
}

const Toggle = (props: PropsWithChildren<Props>) => {
  const { id, checked, onInputChange, $dark = false, children } = props;
  const defaultChecked = checked ?? false;

  const inputProps: Partial<Props> = { ...props };
  delete inputProps.children;
  delete inputProps.onInputChange;

  const onClick = () => {
    onInputChange(!defaultChecked);
  };

  return <ToggleContainer>
    <ToggleInput
      type="checkbox"
      {...inputProps}
      onChange={onClick}
      checked={defaultChecked}
      $dark={$dark}
    />
    <label htmlFor={id}>
      <InnerLabel>{children}</InnerLabel>
    </label>
  </ToggleContainer>;
};

const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const CommonToggle = styled.input<{
  $labelFontSize?: string;
  $dark?: boolean;
}>`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  & + label {
    position: relative;
    font-size: ${({ $labelFontSize }) => $labelFontSize ?? '14px'};
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    color: currentColor;
    width: 100%;
  }

  & + label::before {
    content: "";
    display: inline-block;
    flex-shrink: 0;
    width: 44px;
    height: 28px;
    background-color: #e9e9ea;
    border-radius: 14px;
    margin-right: 8px;
    transition: background-color 0.2s ease;
  }

  & + label::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &:checked + label::before {
    background-color: #34C759;
  }

  &:checked + label::after {
    transform: translateX(16px);
  }

  &:disabled + label::before {
    background-color: #e9e9ea;
    opacity: 0.5;
  }

  &:disabled + label::after {
    opacity: 0.5;
  }

  &:focus + label::before {
    box-shadow: 0 0 0 4px rgba(52, 199, 89, 0.2);
  }
`;

const ToggleInput = styled(CommonToggle)<{
  $dark?: boolean;
}>``;

const InnerLabel = styled.span`
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export default Toggle; 