import React from 'react';
import styled from 'styled-components';

import { parseHyperlinks } from '../../util/links';

const MessageText = ({ message }: { message: string }) => {

  const paragraphs = message.split(/\s*\n\s*/);

  return <>
    {paragraphs.map((paragraph, index) => <Paragraph key={index}>{parseHyperlinks(paragraph)}</Paragraph>)}
  </>;
};

const Paragraph = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;

  &:last-child {
    margin-bottom: 0;
  }
  
  a {
    color: currentColor;
  }
`;

export default MessageText;
