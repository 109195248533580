import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { logEvent } from 'firebase/analytics';

import { useUserContext } from '../../contexts/UserContext';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { BasePageContent } from '../BasePage';
import { endUserEvent } from '../../lib/performance';
import { useModalContext } from '../../contexts/ModalContext';
import BasePageHeader, { UPageType } from '../BasePageHeader';
import FooterLinks from '../../components/FooterLinks';
import { WhiteButton } from '../../components/buttons/WhiteButton';
import PencilAlt from '../../components/icons/PencilAlt';
import { ActionRow, EventModules } from '../../components/EventForm/Common';
import TopModule from './TopModule';
import EventDetailsModule from './EventDetailsModule';
import TrashOutline from '../../components/icons/TrashOutline';
import DeleteDraftModal from '../../components/modals/DeleteDraftModal';
import { useAutoSaveContext } from '../../contexts/AutoSaveContext';
import { firebaseAnalytics } from '../../core/libs/Firebase';
import Modal from '../../components/modals/Modal';
import { EventsFilterType } from '../../lib/event';

import KebabMenu, { KebabMenuOption } from 'Common/src/components/KebabMenu';
import { DeviceQueries } from 'Common/src/components/styled';

const DraftPage = () => {
  const userContext = useUserContext();
  const autoSaveContext = useAutoSaveContext();
  const backgroundContext = useBackgroundContext();
  const modalContext = useModalContext();

  const navigate = useNavigate();
  const { draftId } = useParams();

  const event = draftId !== undefined ? autoSaveContext.load(draftId) : undefined;

  useEffect(() => {
    if (event !== undefined) {
      document.title = `Shine Parties - ${event.title}`;
      endUserEvent(userContext);
      backgroundContext.setColors(
        {
          colors: event?.colors,
          animationUrl: event.backgroundAnimationUrl,
          animationDisabled: event.videoBackgroundDisabled
        }
      );

      logEvent(firebaseAnalytics, 'page_view', {
        page_title: 'Draft Page - Analytics'
      });
    }
  }, [event?.title, event?.backgroundAnimationUrl]);

  useEffect(() => {
    void fetchDraft();
  }, [draftId]);

  const fetchDraft = async () => {
    if (draftId !== undefined) {
      try {
        await autoSaveContext.fetchDraft(draftId);
      } catch (e) {
        modalContext.show({
          contents: <Modal
            title="Draft Error"
            subtitle="This draft does not exist."
            primaryButtonLabel="View my drafts"
            onPrimaryClick={() => {
              modalContext.hide();
              navigate('/events', { state: { activeTab: EventsFilterType.Drafts } });
            }}
          />
        });
      }
    }
  }

  const handleEditClick = () => {
    modalContext.hide();
    if (event === undefined) {
      return;
    }
    navigate('/event/draft/edit/' + event.id);
  };

  const menuOptions: KebabMenuOption[] = [
    {
      id: 'delete',
      label: 'Delete draft',
      icon: <TrashOutline/>,
      color: '#F00'
    }
  ];

  const onMenuClick = async (id: string) => {
    if (draftId !== undefined && id === 'delete') {
      modalContext.show({
        contents: <DeleteDraftModal close={modalContext.hide} eventId={draftId}/>
      });
    }
  };

  if (event) {
    const description = event.description.split('\n')
      .map((str: string, i: number) => <div key={i}>{str}<br/></div>);

    return <>
      <BasePageHeader page={UPageType.Event}/>
      <BasePageContent $wide={true} $full={true} $flush={true} $centered={true}>
        <PageContentContainer>
          <CenteredContent>
            <TopModule event={event} isAttending={true}>
              <ActionRow>
                <WhiteButton onClick={handleEditClick} $noMin={true}>
                  <PencilAlt size={20}/> Edit
                </WhiteButton>
                <MenuContainer>
                  <KebabMenu options={menuOptions} onClick={onMenuClick}/>
                </MenuContainer>
              </ActionRow>
            </TopModule>

            <EventModules>
              {event.description.trim() !== '' &&
                <NewContentDescription>
                  {description}
                </NewContentDescription>}

              <EventDetailsModule event={event} isAttending={true}/>
            </EventModules>

            <FooterLinks/>
          </CenteredContent>
        </PageContentContainer>
      </BasePageContent>
    </>;
  } else {
    return null;
  }
};

const NewContentDescription = styled.div`
  font-size: 22px;
  font-weight: 400;

  @media (${DeviceQueries.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;


const PageContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px;
  box-sizing: border-box;
  color: #FFF;
`;

const CenteredContent = styled.div`
  max-width: 684px;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default DraftPage;
