import React, { FormEvent, MouseEvent, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fromZonedTime } from 'date-fns-tz';
import PlaceResult = google.maps.places.PlaceResult;

import { UEventFormField, useEditEventContext } from '../../contexts/EditEventContext';
import TimeFormInput from '../EventForm/TimeFormInput';
import { convertTimestampToDate, convertTimestampToTimeString } from '../../lib/time';
import GeneratedImageSelection from '../../pages/Create/GeneratedImageSelection';
import { extractSameRGBWithAlpha, getPalette } from '../../lib/colorPicker';
import { calculateImagePalette, getPhotoDataUrl } from '../../lib/images';
import ExpandingTextInput from '../ExpandingTextInput';
import { EventsFilterType, IUEvent, IULocation } from '../../lib/event';
import { getAddressString, hasNonAddressPlaceName } from '../../lib/location';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import {
  ActionRow,
  EventDetails,
  EventDetailsCell,
  EventDetailsCellInner,
  EventDetailsCellInnerForAutocomplete,
  EventDetailsIconWrapper,
  EventModule,
  EventModuleHeading,
  EventModules,
  OutlinedPillInputActionButton,
  PillDiv
} from '../EventForm/Common';
import LocationMarker from '../icons/LocationMarker';
import AddToCal from '../icons/AddToCal';
import PillInput from '../EventForm/PillInput';
import PillInputWithIcon from '../EventForm/PillInputWithIcon';
import { useToastContext } from '../../contexts/ToastContext';
import { useUserContext } from '../../contexts/UserContext';
import { ActionMenuTab } from '../EventForm/ActionMenu';
import Sparkles from '../icons/Sparkles';

import AddressAutocomplete, {
  PacContainerWidthStyle
} from 'Common/src/components/AddressAutocomplete/AddressAutocomplete';
import { calculateTimeZone } from 'Common/src/api/GoogleTimezoneApi';
import { DeviceQueries } from 'Common/src/components/styled';
import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent } from 'Common/src/api/SumoLogicApi';

import { TAppAddress } from 'TProtocol/common/models';
declare const SHOULD_SHOW_AI_MODULES: boolean;

const EventForm = ({ event, formRef, onAddImage, onSaveDraft, setOpenTab, setCreateDisabled }: {
  event: IUEvent,
  formRef: RefObject<HTMLFormElement>,
  handleEditClick?: () => void;
  inviteeUuid?: string | undefined;
  onAddImage?: () => void;
  onSaveDraft?: () => void;
  setOpenTab?: (tab: ActionMenuTab) => void;
  setCreateDisabled?: (createDisabled: boolean) => void;
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const backgroundContext = useBackgroundContext();
  const toastContext = useToastContext();
  const addressInputRef = useRef<HTMLInputElement>(null);

  const [showPlaceName, setShowPlaceName] = useState(false);
  const [placeResult, setPlaceResult] = useState<PlaceResult>();

  useEffect(() => {
    if (event.location.placeName) {
      setShowPlaceName(true);
    }
  }, [event.location.placeName]);

  const [startTime, setStartTime] = useState<string>(
    (event.startTime ? convertTimestampToTimeString(event.startTime, event.timeZone) : '' || '')
  );

  const [endTime, setEndTime] = useState<string>(
    (event.endTime ? convertTimestampToTimeString(event.endTime, event.timeZone) : '' || '')
  );

  const firstUpdate = useRef(!event.inspirationId && !event.isFromQuickCreate);
  const [day] = useState<Date | null>(
    event.date ? event.date : (event.startTime ? convertTimestampToDate(event.startTime, event.timeZone) : null)
  );

  useEffect(() => {
    if (day !== null) {
      const startDatetime = fromZonedTime(startTime, event.timeZone).getTime();
      const endDatetime = endTime !== '' ? fromZonedTime(endTime, event.timeZone).getTime() : undefined;

      editEventContext.setTimes(startDatetime, endDatetime ?? 0);
      return;
    }
  }, [day, startTime, endTime]);

  useEffect(() => {
    if (!firstUpdate.current) {
      void calculatePalette();
    }
    firstUpdate.current = false;
  }, [event.photoUrl]);

  useEffect(() => {
    if (!event.isCreate) {
      return;
    }

    const missingFields: string[] = [];
    if (!event.title) {
      missingFields.push('title');
    }
    if (!event.startTime) {
      missingFields.push('start time');
    }
    if (!event.photoUrl) {
      missingFields.push('image');
    }
    if (event.location.address === undefined || getAddressString(event.location.address) === '') {
      missingFields.push('location');
    }
    if (setCreateDisabled) {
      setCreateDisabled(missingFields.length > 0);
    }

    if (missingFields.length > 0) {
      const article = missingFields[0] === 'image' ? 'an' : 'a';

      let items: string;
      if (missingFields.length === 1) {
        items = missingFields[0];
      } else if (missingFields.length === 2) {
        items = missingFields.join(' and ');
      } else {
        const lastField = missingFields.pop();
        items = `${missingFields.join(', ')}, and ${lastField}`;
      }

      toastContext.show({
        contents: `You still need ${article} ${items} to create your party.`,
        actionText: userContext.isLoggedIn() ? 'Save draft.' : 'Log in to save draft.',
        onActionClick: handleSaveDraft
      });
    } else {
      toastContext.hide();
    }

    return () => {
      toastContext.hide();
    };
  }, [event.photoUrl, event.title, event.description, event.startTime, event.location.address]);

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[EventForm] ${action}`
    });
  };

  const calculatePalette = async () => {
    if (event.photoUrl && event.photoUrl !== editEventContext.previousEvent?.photoUrl) {
      editEventContext.setColors(await calculateImagePalette(event.photoUrl));
    }
  };

  const handleInputChange = (name: string, value: string) => {
    if (name === 'description') {
      editEventContext.setDescription(value);
    } else if (name === 'hosted') {
      editEventContext.setHostedBy(value);
    } else if (name === 'title') {
      editEventContext.setTitle(value);
    } else if (name === 'subtitle') {
      editEventContext.setBackgroundAnimationSearchQuery(value);
    }
  };

  const handleAddressChange = (addressStr: string, address?: TAppAddress, place?: PlaceResult) => {
    logUserAction(`Selected address: ${JSON.stringify(place)}`);
    editEventContext.setLocation(new IULocation({
      displayName: addressStr,
      address,
      place
    }));
    editEventContext.updateFormErrors({
      ...event.errors,
      invalidLocation: false
    });
  };

  const handlePlaceNameChange = (placeName?: string) => {
    editEventContext.setLocation(new IULocation({
      ...event.location,
      placeName
    }));
  };

  const handleCancelPlaceName = () => {
    handlePlaceNameChange(undefined);
    setShowPlaceName(false);
  };

  const handleAddPlaceName = () => {
    logUserAction('Add place name');
    setShowPlaceName(true);
    if (placeResult && hasNonAddressPlaceName(placeResult)) {
      handlePlaceNameChange(placeResult.name);
    }
  }

  const onStartTimeChange = (start: string) => {
    setStartTime(start);
    editEventContext.updateDateWithTimeZone(start, endTime, event.timeZone);
  };

  const onEndTimeChange = (end: string) => {
    setEndTime(end);
    editEventContext.updateDateWithTimeZone(startTime, end, event.timeZone);
  };

  const handleLatLonChange = async (lat: number, lng: number) => {
    const timeZone = await calculateTimeZone(lat, lng, event?.startTime);
    editEventContext.setTimeZone(timeZone);
    editEventContext.updateDateWithTimeZone(startTime, endTime, timeZone);
  };

  const handleSaveDraft = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[EventForm] Clicked on save draft toast'
    });

    if (userContext.isLoggedIn()) {
      navigate('/events', { state: { activeTab: EventsFilterType.Drafts } });
    } else if (onSaveDraft !== undefined) {
      onSaveDraft();
    }
  };

  const generateDescription = (e: MouseEvent) => {
    logUserAction('Generate description');
    e.preventDefault();
    editEventContext.generateDescription();
  };

  let placeNameContent: ReactNode = null;
  if (event.location.address) {
    if (showPlaceName) {
      placeNameContent = <PillInputWithIcon
        icon={<TinyText>AKA</TinyText>}
        value={event.location.placeName}
        onChange={handlePlaceNameChange}
        inputRef={addressInputRef}
        placeholder=""
        onCancel={handleCancelPlaceName}
        expanding={true}
      />;
    } else {
      placeNameContent =
        <OutlinedPillInputActionButton onClick={handleAddPlaceName}>
          {/*<TinyText>AKA</TinyText>*/}
          Add venue name
        </OutlinedPillInputActionButton>;
    }
  }

  return <Form ref={formRef} onSubmit={(e) => e.preventDefault()}>
    <NewContent>
      <GeneratedImageSelection event={event} excludeImage={false} onAddImage={onAddImage} setOpenTab={setOpenTab}/>
      <NewContentText>
        <NewContentTitle>
          <ExpandingTextInput
            placeholder={'Party Title*'}
            value={event.title}
            textColor="#FFF"
            onChange={(value) => handleInputChange('title', value)}
            onInvalid={() => editEventContext.setFieldInvalid(UEventFormField.Title)}
            error={event.errors.invalidTitle ? 'Enter a valid title' : undefined}
            errorRef={(div: HTMLDivElement) => editEventContext.setFormErrorDiv(UEventFormField.Title, div)}
            required={true}
            noMargin={true}
            noVertMargin={true}
            fontSize={'40px'}
            lineHeight={'46px'}
            excludeBackground={true}
          />
        </NewContentTitle>

        <CustomEventModules>
          <EventModule>
            <EventModuleHeading>
              Place & time
            </EventModuleHeading>
            <EventDetails>
              <EventDetailsCell>
                <EventDetailsIconWrapper>
                  <LocationMarker/>
                </EventDetailsIconWrapper>
              </EventDetailsCell>
              <EventDetailsCell>
                <EventDetailsCellInnerForAutocomplete>
                  <PacContainerWidthStyle $width={500}/>
                  <AddressAutocomplete
                    inputRef={addressInputRef}
                    onSelect={handleAddressChange}
                    onSelectLatLon={handleLatLonChange}
                    setPlace={setPlaceResult}>
                    <PillInput
                      value={event.location.displayName}
                      onChange={handleAddressChange}
                      required={true}
                      inputRef={addressInputRef}
                      placeholder="Party address*"
                      expanding={true}
                    />
                  </AddressAutocomplete>
                  {placeNameContent}
                </EventDetailsCellInnerForAutocomplete>
                {
                  (event.errors.invalidLocation || editEventContext.isFieldInvalid(UEventFormField.Location)) &&
                  <ErrorContainer>
                    Select a valid location from Google Maps
                  </ErrorContainer>
                }
              </EventDetailsCell>

              <EventDetailsCell>
                <EventDetailsIconWrapper>
                  <AddToCal/>
                </EventDetailsIconWrapper>
              </EventDetailsCell>
              <EventDetailsCell>
                <EventDetailsCellInner>
                  <TimeFormInput
                    startTime={startTime}
                    endTime={endTime}
                    timeZone={event.timeZone}
                    onStartChange={onStartTimeChange}
                    onEndChange={onEndTimeChange}
                    onInvalid={() => editEventContext.setFieldInvalid(UEventFormField.Time)}
                    error={event.errors.invalidStartDate ? 'Start date cannot be in the past' :
                      event.errors.invalidDatePair ? 'End time must be after start time' :
                        event.errors.invalidDuration ? 'Party cannot be over 2 weeks long' : undefined}
                    required={true}
                    errorRef={(div: HTMLDivElement) => editEventContext.setFormErrorDiv(UEventFormField.Time, div)}
                  />
                </EventDetailsCellInner>
              </EventDetailsCell>
            </EventDetails>
          </EventModule>

          <EventModule>
            <EventModuleHeading>
              Description
            </EventModuleHeading>
            <ExpandingTextInput
              value={event.description}
              onChange={(value) => handleInputChange('description', value)}
              textColor="var(--sp-text-color)"
              fontSize={'18px'}
              backgroundColor={extractSameRGBWithAlpha(backgroundContext.colors.highlightText, 0.50)}
              placeholder={event.title ? `short paragraph for a party invitation for ${event.title}` : ''}
              as={PillDiv}
            >
              { !event.description && SHOULD_SHOW_AI_MODULES && <GenerateButton onClick={generateDescription} disabled={!event.title}>
                <Sparkles size={18}/>
                Generate AI description
              </GenerateButton> }
            </ExpandingTextInput>
          </EventModule>
        </CustomEventModules>
      </NewContentText>
    </NewContent>
  </Form>;
};

const Form = styled.form`
  width: 100%;
`;

export const ErrorContainer = styled.div`
  font-size: 16px;
  color: var(--shine-system-error-color);
  font-weight: bold;
  margin: 1px 0 5px 16px;
  text-align: left;
`;

export const NewContent = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgba(var(--sp-text-rgb), 0.9);
  margin: auto;
  padding: 25px;
  box-sizing: border-box;

  --shine-system-text-color: rgba(var(--sp-bg-rgb), 0.9);
  --shine-system-button-text-color: rgba(var(--sp-bg-rgb), 0.9);
`;

const NewContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  @media (${DeviceQueries.mobile}) {
    margin-top: 10px;
  }
`;

const NewContentText = styled.div`
  width: 100%;
`;

const TinyText = styled.span`
  font-size: 10px;
  font-weight: 700;
`;

const CustomEventModules = styled(EventModules)`
  width: auto;
`

const ButtonRow = styled(ActionRow)`
  margin-top: 50px;
`

const GenerateButton = styled(OutlinedPillInputActionButton)`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  display: flex;
  height: 43px;
  padding: 10px 18px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  margin: 0 12px 12px;

  @media (${DeviceQueries.mobile}) {
    width: auto;
  }
`;

export default EventForm;

