import styled from 'styled-components';

const WhiteInput = styled.input<{
  $isInvalid?: boolean
}>`
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 12px;
  color: var(--sp-text-color);
  background: rgba(var(--sp-bg-rgb), 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
  border: 1px solid ${({ $isInvalid }) => $isInvalid ? '#B3251E;' : 'var(--sp-border-color)'};

  &:focus {
    outline: ${({ $isInvalid }) => $isInvalid ? '0.5px solid #B3251E' : 'none'};
    border: 1px solid ${({ $isInvalid }) => $isInvalid ? '#B3251E;' : 'var(--sp-border-color)'};;
  }

  &:disabled {
    background: rgba(var(--sp-text-rgb), 0.20);
  }
`;

export const TallerWhiteInput = styled(WhiteInput)`
  padding: 10px;
`;

export default WhiteInput;
