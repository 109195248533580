import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { IUEvent } from '../../lib/event';
import { useEditEventContext } from '../../contexts/EditEventContext';
import GeneratedImageSelection from '../../pages/Create/GeneratedImageSelection';
import BackgroundAnimationPicker from '../../pages/Create/BackgroundAnimationPicker';
import MultiColorPicker from '../ColorPicker/MultiColorPicker';
import Questions from '../questions/Questions';
import useVideoBackgroundPicker from '../../hooks/useVideoBackgroundPicker';
import PexelsLogo from '../../assets/PexelsLogoWhite.png';
import ExpandingTextInput from '../ExpandingTextInput';
import { ActionMenuLabel, ContentTitle, ContentTitleWrapper } from './ActionMenuCommon';
import { DesktopShow } from '../styled/Common';
import QuestionMarkCircle from '../icons/QuestionMarkCircle';
import { useModalContext } from '../../contexts/ModalContext';
import { useUserContext } from '../../contexts/UserContext';
import SpotifyQuestionsWidget from '../SpotifyQuestionsWidget';
import LogoWhiteNarrowT from '../../assets/LogoWhiteNarrowT.png';
import { WideWhiteButton } from '../buttons/WhiteButton';
import PhotographOutline from '../icons/PhotographOutline';
import ComputerOutline from '../icons/ComputerOutline';
import Settings from '../icons/Settings';
import XCircle from '../icons/XCircle';
import { Container } from '../forms/Input';
import { getReminderTimeString } from '../../lib/time';
import { ShareTooltipContainer } from '../styled/CopyLinkTooltip';
import Dropdown, { DropdownItem } from '../Dropdown';
import EventFormInput from './EventFormInput';

import { DeviceQueries, isMobile } from 'Common/src/components/styled';
import Checkbox, { CheckboxReminderText } from 'Common/src/components/Checkbox';
import Radio from 'Common/src/components/Radio';
import Toggle from 'Common/src/components/Toggle';
import { ULogApplication, ULogTag, ULogSeverity, logSumoEvent } from 'Common/src/api/SumoLogicApi';

import { TAppElkEventNotification, TAppElkEventQuestionType, TAppElkNotificationPreference } from 'TProtocol/prototypes/events/messages';
import ChevronUp from '../icons/ChevronUp';
import ChevronDown from '../icons/ChevronDown';

const ONE_DAY = 24 * 60 * 60 * 1000;

export enum ActionMenuTab {
  GUESTS = 'Event options',
  IMAGE_GEN = 'Image',
  THEME = 'Background',
  QUESTIONS = 'Questions',
  MORE = 'More',
  HOME = 'Home',
  PROFILE = 'Profile'
}

declare const ELK_WEBAPP_URL: string;
declare const APP_ENV: string;

const INVITE_GUESTS_OPTIONS: DropdownItem<TAppElkNotificationPreference>[]  = [{
  id: TAppElkNotificationPreference.EMAIL,
  label: 'Email'
}, {
  id: TAppElkNotificationPreference.PHONE,
  label: 'Text'
}, {
  id: TAppElkNotificationPreference.BOTH,
  label: 'Both email and text'
}];

const ActionMenu = ({ event, scrollToTop, openTab, setOpenTab }: {
  event: IUEvent;
  isCreate?: boolean,
  scrollToTop: () => void;
  openTab?: ActionMenuTab;
  setOpenTab: (tab?: ActionMenuTab) => void;
}) => {
  const videoPicker = useVideoBackgroundPicker();
  const editEventContext = useEditEventContext();
  const userContext = useUserContext();
  const modalContext = useModalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { baseInspirationId } = useParams();
  const initialBackgroundLoad = useRef<boolean>(true);

  const inEditMode = location.pathname.includes('/edit') || location.pathname.includes('/create');
  const inStageEnv = APP_ENV === 'DEV' || APP_ENV === 'STAGE';

  const [videoPromptChanged, setVideoPromptChanged] = useState<boolean>(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  //TODO: remove feature flag after Spotify approval
  const SPOTIFY_FEATURE_FLAG_ENABLED = userContext.id === 'cf936152-2823-4c21-8713-d80c4ffe8e4c';
  // const SPOTIFY_FEATURE_FLAG_ENABLED = true;

  const [showCopiedVideoUrl, setShowCopiedVideoUrl] = useState<boolean>(false);
  const [showCopiedColorHex, setShowCopiedColorHex] = useState<boolean>(false);

  useEffect(() => {
    if (showCopiedVideoUrl) {
      const timerId = setTimeout(() => {
        setShowCopiedVideoUrl(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopiedVideoUrl]);

  useEffect(() => {
    if (showCopiedColorHex) {
      const timerId = setTimeout(() => {
        setShowCopiedColorHex(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopiedColorHex]);

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[GeneratedImageSelection] ${action}`
    });
  };

  const copyVideoUrl = () => {
    logUserAction('Copied video URL');

    if (event.backgroundAnimationUrl) {
      void navigator.clipboard.writeText(event.backgroundAnimationUrl);
      setShowCopiedVideoUrl(true);
    }
  };

  const copyColorHex = () => {
    logUserAction('Copied color hex');

    void navigator.clipboard.writeText(event.colors.primary);
    setShowCopiedColorHex(true);
  };

  useEffect(() => {
    if (inEditMode) {
      if (event.backgroundAnimationUrl) {
        void videoPicker.prepopulateVideoList();
      } else if (!event.backgroundAnimationSearchQuery) {
        if (!baseInspirationId) {
          void videoPicker.setDefaultBackgroundVideo();
        }
      } else {
        void videoPicker.setNewBackgroundVideo(event.backgroundAnimationSearchQuery);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state?.spotifySuccess) {
      setOpenTab(ActionMenuTab.QUESTIONS);
    }
  }, [location.state]);

  const handleInputChange = (name: string, value: string) => {
    if (name === 'description') {
      editEventContext.setDescription(value);
    } else if (name === 'hosted') {
      editEventContext.setHostedBy(value);
    } else if (name === 'title') {
      editEventContext.setTitle(value);
    } else if (name === 'subtitle') {
      editEventContext.setBackgroundAnimationSearchQuery(value);
      if (initialBackgroundLoad.current) {
        initialBackgroundLoad.current = false;
      } else {
        setVideoPromptChanged(true);
      }
    }
  };

  const handleTabClick = (tab: ActionMenuTab) => {
    logUserAction(`Navigated to ${tab}`);

    if (openTab === tab) {
      handleSaveClick();
    } else if (tab === ActionMenuTab.HOME) {
      navigate('/');
    } else if (tab === ActionMenuTab.PROFILE) {
      navigate('/profile/edit');
    } else {
      editEventContext.setViewedEventOptions(true);
      editEventContext.saveCheckpoint();
      setOpenTab(tab);
    }
  };

  const handleSaveClick = () => {
    logUserAction('Clicked save');

    setOpenTab(undefined);
    modalContext.hide();
  };

  const handleCancelClick = () => {
    logUserAction('Clicked cancel');

    editEventContext.restoreCheckpoint();
    setOpenTab(undefined);
    modalContext.hide();
  }

  const onShuffleVideo = (query: string) => {
    logUserAction('Clicked shuffle');

    if (videoPromptChanged || !videoPicker.hasMore()) {
      void videoPicker.setNewBackgroundVideo(query);
      setVideoPromptChanged(false);
    } else {
      videoPicker.incrementIndex();
    }
  };

  const onImageGenerate = () => {
    if (isMobile()) {
      setOpenTab(undefined);
      modalContext.hide();
      scrollToTop();
    }
  };

  const formatLastSavedTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    if (Date.now() - timestamp > ONE_DAY) {
      return new Intl.DateTimeFormat('us',
        { hour: 'numeric', hour12: true, minute: '2-digit', month: 'short', day: 'numeric' }).format(date);
    } else {
      return new Intl.DateTimeFormat('us', { hour: 'numeric', hour12: true, minute: '2-digit' }).format(date);
    }
  };

  const onLogoClick = () => {
    logUserAction('Clicked on logo');

    navigate('/');
  };

  const editEventTabs = <TabsContainer $isCollapsed={openTab === undefined} $includeLabels={false}>
    <ActionTabs>
      <DesktopShow>
        <MenuTab
          onClick={onLogoClick}
          $active={false} $noHover={true} $color="var(--sp-text-color)">
          <img src={LogoWhiteNarrowT} width={110} height={45}/>
        </MenuTab>
      </DesktopShow>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.IMAGE_GEN)}
        $active={openTab === ActionMenuTab.IMAGE_GEN}
      >
        <IconContainer><PhotographOutline/></IconContainer>
        Cover image
      </MenuTab>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.THEME)}
        $active={openTab === ActionMenuTab.THEME}
      >
        <IconContainer><ComputerOutline/></IconContainer>
        Background
      </MenuTab>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.QUESTIONS)}
        $active={openTab === ActionMenuTab.QUESTIONS}
      >
        <IconContainer><QuestionMarkCircle/></IconContainer>
        Guest questions
      </MenuTab>
      <MenuTab
        onClick={() => handleTabClick(ActionMenuTab.GUESTS)}
        $active={openTab === ActionMenuTab.GUESTS}
      >
        <IconContainer><Settings/></IconContainer>
        Event options
      </MenuTab>
    </ActionTabs>
    {event.lastSavedTimestamp && <SavedAt>
        <div>Saved at</div>
      {formatLastSavedTimestamp(event.lastSavedTimestamp)}
    </SavedAt>}
  </TabsContainer>;

  return (
    <ActionMenuArea>
      {
        openTab !== undefined && <Overlay onClick={handleSaveClick}/>
      }
      <ActionMenuContainer $isCollapsed={openTab === undefined}>
        {inEditMode && editEventTabs}

        <ContentWrapper $isCollapsed={openTab === undefined}>
          <ContentAnimationMask $isCollapsed={openTab === undefined}>
            <ContentArea $isCollapsed={openTab === undefined}>
              <ContentTitleWrapper $withMarginBottom={openTab === ActionMenuTab.QUESTIONS}>
                <ContentTitle>
                  {openTab}
                </ContentTitle>
                <CloseIconWrapper onClick={handleSaveClick}>
                  <XCircle/>
                </CloseIconWrapper>
              </ContentTitleWrapper>
              {openTab === ActionMenuTab.IMAGE_GEN &&
                  <ImageTabContent>
                      <GeneratedImageSelection event={event} excludeImage={true} onImageGenerate={onImageGenerate}/>
                  </ImageTabContent>
              }
              {openTab === ActionMenuTab.THEME &&
                  <ThemeTabContent>
                      <PickersContainer>
                          <BackgroundAnimationPicker
                              searchQuery={event.backgroundAnimationSearchQuery ?? ''}
                              onSearchQueryChange={(value) => handleInputChange('subtitle', value)}
                              onSubmit={onShuffleVideo}
                          />
                          <WideWhiteButton $invert={true}
                              disabled={event.backgroundAnimationSearchQuery?.length === 0 || (!videoPromptChanged && !videoPicker.hasMore())}
                              onClick={() => onShuffleVideo(event.backgroundAnimationSearchQuery ?? '')}
                          >
                            {videoPromptChanged ? 'Search' : 'Try another'}
                          </WideWhiteButton>
                          <Checkbox id="turn-off-video"
                                    checked={editEventContext.event?.videoBackgroundDisabled}
                                    onInputChange={(checked) => editEventContext.setVideoBackgroundDisabled(checked)}
                                    $labelFontSize="16px"
                                    $dark={true}>
                              Turn off video background
                          </Checkbox>
                        {inStageEnv &&
                            <ShareTooltipContainer>
                                <Tooltip anchorSelect="#copyVideoUrl" place="top" className="copiedTooltip"
                                         noArrow={true} isOpen={showCopiedVideoUrl}>
                                    Copied video URL!
                                </Tooltip>

                                <ActionMenuLabel id={'copyVideoUrl'}
                                                 onClick={copyVideoUrl}
                                                 style={{ cursor: 'pointer' }}>
                                    Video URL:<br/>{event.backgroundAnimationUrl}
                                </ActionMenuLabel>
                            </ShareTooltipContainer>
                        }
                          <MultiColorPicker/>
                        {inStageEnv &&
                            <ShareTooltipContainer>
                                <Tooltip anchorSelect="#copyColorHex" place="top" className="copiedTooltip"
                                         noArrow={true} isOpen={showCopiedColorHex}>
                                    Copied hex code!
                                </Tooltip>
                                <ActionMenuLabel id={'copyColorHex'} onClick={copyColorHex}
                                                 style={{ cursor: 'pointer' }}>
                                    Tint color: {event.colors.primary}
                                </ActionMenuLabel>
                            </ShareTooltipContainer>
                        }
                      </PickersContainer>
                      <VideoSourceLogos>
                        {/*UNCOMMENT BELOW if giphy video results are reenabled*/}
                        {/*<img src={GiphyLogo}/>*/}
                          <Link to="https://www.pexels.com" target="_blank" rel="noreferrer noopener">
                              <PexelsLogoImg src={PexelsLogo}/>
                          </Link>
                      </VideoSourceLogos>
                  </ThemeTabContent>
              }
              {openTab === ActionMenuTab.GUESTS && (
                <GuestTabContent>
                  <OptionsContainer>
                    <InfoText>
                    Get photos from your event! Photo sharing is easy and FREE with the <br/><ShineLink href={`https://${event.albumDetails?.albumUrl ?? 'photos.sunshine.com'}`}
                                                                      target="_blank" rel="noreferrer">Shine app</ShineLink>
                    </InfoText>

                    <Toggle id={'field-add'}
                            checked={editEventContext.event?.openInvite ?? false}
                            onInputChange={(checked) => editEventContext.setIsOpenInvite(checked)}
                            $labelFontSize={'16px'}
                            $dark={true}>
                      Allow guests to invite additional people
                    </Toggle>
                    <Toggle id={'GuestList'}
                            checked={editEventContext.event?.publicGuestList ?? false}
                            onInputChange={(checked) => editEventContext.setShowGuestList(checked)}
                            $labelFontSize={'16px'}
                            $dark={true}>
                      Allow guests to view guest list
                    </Toggle>

                    <Toggle id={'Reminders'}
                            checked={editEventContext.event?.remindersEnabled ?? false}
                            onInputChange={(checked) => editEventContext.setRemindersEnabled(checked)}
                            $labelFontSize={'16px'}
                            $dark={true}>
                      Send guests reminders
                    </Toggle>



                    <HostNameSection>
                      <Label>Host name:</Label>
                      <EventFormInput
                        placeholder={'Host name'}
                        value={event.hostedBy}
                        onChange={(value) => editEventContext.setHostedBy(value)}
                        excludeBackground={true}
                        noVertMargin={true}
                        isLabelOutside={true}
                        inModal={true}
                      />
                    </HostNameSection>

                    <AdvancedSection>
                      <AdvancedHeader onClick={() => setShowAdvanced(!showAdvanced)}>
                        {showAdvanced ? 'Less' : 'More'}
                        {showAdvanced ? <ChevronUp /> : <ChevronDown />}
                      </AdvancedHeader>
                      {showAdvanced && (
                        <AdvancedContent>
                          <DropdownWrapper>
                            <ActionMenuLabel>Choose notification options for your guests:</ActionMenuLabel>
                            <Dropdown
                              values={INVITE_GUESTS_OPTIONS}
                              value={event.communicationPreference}
                              onChange={(value) => editEventContext.setCommunicationPreference(value)}
                            />
                          </DropdownWrapper>


                    {(editEventContext.event?.isCreate || editEventContext.event?.hasWaitlist) && (
                      <Toggle id={'guest-approval'}
                              checked={editEventContext.event?.hasWaitlist ?? false}
                              onInputChange={(checked) => editEventContext.setHasWaitlist(checked)}
                              $labelFontSize={'16px'}
                              $dark={true}>
                        Require guest approval
                        <CheckboxReminderText>
                          Guests request to &quot;Get on the list&quot;. Only guests you approve can see party details.
                        </CheckboxReminderText>
                      </Toggle>
                    )}

                          <RsvpUpdatesContainer>
                            <RsvpLabel>Get RSVP updates:</RsvpLabel>
                            <RadioContainer>
                              <Radio
                                id="hostNotificationsRsvp"
                                checked={event.hostNotification === TAppElkEventNotification.ATTENDEE_RSVP}
                                name="hostNotification"
                                value="rsvp"
                                onInputChange={editEventContext.setHostNotification}
                                $labelFontSize="16px"
                                $dark={true}
                              >as they arrive</Radio>
                              <Radio
                                id="hostNotificationsDigest"
                                checked={event.hostNotification === TAppElkEventNotification.DAILY_DIGEST}
                                name="hostNotification"
                                value="digest"
                                onInputChange={editEventContext.setHostNotification}
                                $labelFontSize="16px"
                                $dark={true}
                              >daily digest</Radio>
                            </RadioContainer>
                          </RsvpUpdatesContainer>
                        </AdvancedContent>
                      )}
                    </AdvancedSection>
                  </OptionsContainer>
                </GuestTabContent>
              )}
              {openTab === ActionMenuTab.QUESTIONS && <>
                  <Questions
                      event={event}
                      questions={event.questions.filter(
                        question => question.questionType === TAppElkEventQuestionType.TEXT)}
                      questionType={TAppElkEventQuestionType.TEXT}
                      handleAddQuestion={() => editEventContext.addAQuestion()}
                      onCancel={handleCancelClick}
                      onSave={handleSaveClick}
                  />
                {/* ------ SPOTIFY FEATURE FLAG ----- */}
                {SPOTIFY_FEATURE_FLAG_ENABLED &&
                    <SpotifyQuestionsWidget event={event} onCancel={handleCancelClick} onSave={handleSaveClick}/>}
              </>}
            </ContentArea>
          </ContentAnimationMask>
        </ContentWrapper>
      </ActionMenuContainer>
    </ActionMenuArea>
  );
};

const ActionMenuContainer = styled.div<{
  $isCollapsed: boolean
}>`
  display: flex;
  z-index: 100;
  position: relative;

  //overflow-x: hidden; // Prevent horizontal scrolling

  @media (${DeviceQueries.mobile}) {
    flex-direction: column-reverse; // Stack vertically with tabs at the bottom
    width: 100%;
    height: auto;
    padding: 0;
  }

  @media not (${DeviceQueries.mobile}) {
    height: 100%;
    //max-width: 45vw;
  }
`;

const TabsContainer = styled.div<{
  $isCollapsed: boolean,
  $includeLabels: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0; // Prevent the tab container from shrinking
  backdrop-filter: blur(40px);
  width: 140px;
  background: rgba(var(--sp-bg-rgb), 0.80);
  z-index: 101;

  @media (${DeviceQueries.mobile}) {
    padding: 10px;
    box-sizing: border-box;
    width: 100%; // Take full width of the screen
    flex-direction: row; // Align tabs horizontally
    justify-content: space-around; // Distribute tabs evenly
    border-top: 1px solid rgba(15, 8, 22, 0.00);
    margin-top: 1px; // To show border
    z-index: 101;
  }
`;

const ContentWrapper = styled.div<{
  $isCollapsed: boolean
}>`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (${DeviceQueries.mobile}) {
    width: 100%; // Use the full width on mobile
    max-height: 60vh;
      //max-height: ${({ $isCollapsed }) => ($isCollapsed ? '0' : 'calc(60vh)')}; // Adjust height dynamically
    //border-top-left-radius: 12px;
    //border-top-right-radius: 12px;
  }

`;

const ContentAnimationMask = styled.div<{ $isCollapsed: boolean }>`
  width: ${({ $isCollapsed }) => $isCollapsed ? 0 : '400px'};
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: width 0.3s cubic-bezier(.36, -0.01, 0, .77);

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    position: relative;
    height: auto;
  }
`;

const ContentArea = styled.div<{ $isCollapsed: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-x: hidden; // Prevent horizontal scrolling within content area
  transition: background-color 0.3s ease, margin-left 0.3s cubic-bezier(.36, -0.01, 0, .77), margin-bottom 0.3s cubic-bezier(.36, -0.01, 0, .77);
  color: var(--sp-text-color);
  width: 400px;
  height: 100%;
  backdrop-filter: blur(40px);
  background: rgba(var(--sp-bg-rgb), 0.80);
  border-radius: 0 16px 16px 0;
  //position: absolute;
  box-sizing: border-box;
  z-index: 100;

  @media not (${DeviceQueries.mobile}) {
    margin-left: ${({ $isCollapsed }) => $isCollapsed ? '-400px' : 0};
  }

  @media (${DeviceQueries.mobile}) {
    height: auto;
    max-height: 60vh;
    width: 100%;
    background: rgba(var(--sp-bg-rgb), 0.78);
    border-radius: 16px 16px 0 0;
    bottom: 0;
    margin-bottom: ${({ $isCollapsed }) => $isCollapsed ? '-100vh' : 0};
  }
`;

const MenuTab = styled.div<{
  $active: boolean,
  $noHover?: boolean,
  $color?: string
}>`
  padding: 25px;
  cursor: pointer;
  ${({ $active }) => $active ? 'background: rgba(var(--sp-text-rgb), 0.06); box-shadow: 0 0 4px 0 rgba(var(--sp-text-rgb), 0.04);' : 'background: transparent'};
  transition: background-color 0.3s ease;
  color: ${({$color}) => $color ?? 'rgba(var(--sp-text-rgb), 0.70)'};
  flex-direction: column;
  //height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  @media (${DeviceQueries.mobile}) {
    border-radius: 10px;
    background-color: ${({ $active }) => $active ? 'rgba(var(--sp-text-rgb), 0.06)' : 'transparent'};
    padding: 10px;
    height: 72px;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    word-spacing: 100vw;
  }

  &:hover {
    ${({ $noHover, $active }) => !$noHover && !$active && 'background: rgba(var(--sp-text-rgb), 0.03)'};
  }
`;

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 20px;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  gap: 14px;
`;

const ThemeTabContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const VideoSourceLogos = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PexelsLogoImg = styled.img`
  height: 30px;
  margin-left: 15px;
  margin-bottom: -15px;
`;

const ActionMenuArea = styled.div`
  z-index: 100;
`;

const ActionTabs = styled.div`
  @media (${DeviceQueries.mobile}) {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
`;

const PickersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Container} {
    min-height: auto;
  }
`;

const IconContainer = styled.div`
  height: 24px;
`;

const ImageTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GuestTabContent = styled.div`
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const HostNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${DeviceQueries.mobile}) {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
`;

export const OpaqueOverlay = styled(Overlay)`
  background: #00000066;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
`;

const SavedAt = styled.div`
  color: rgba(var(--sp-text-rgb), 0.40);
  font-size: 14px;
  padding-bottom: 30px;
  text-align: center;

  @media (${DeviceQueries.mobile}) {
    display: none;
  }
`;

const ShineLink = styled.a`
  font-weight: bold;
  color: currentColor;

  &:visited {
    color: currentColor;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfoText = styled.div`
  font-size: 16px;
  color: #FFF;
  margin-bottom: 8px;
`;

const RsvpUpdatesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const RsvpLabel = styled.div`
  font-size: 16px;
`;

const AdvancedSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AdvancedHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #FFF;
  
  &:hover {
    opacity: 0.8;
  }
`;

const AdvancedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 8px;
`;

const HostNameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  font-size: 16px;
`;

export default ActionMenu;
