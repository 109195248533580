import React, { ReactNode } from 'react';

import { StyleLabels } from '../../lib/styles';
import Dropdown from '../Dropdown';

import { TAppElkImageStyle } from 'TProtocol/prototypes/events/messages';

interface Props {
  disabled: boolean;
  style: TAppElkImageStyle;
  onStyleChange: (style: TAppElkImageStyle) => void;
}

const MENU_ITEMS = (Object.keys(StyleLabels) as unknown as (keyof typeof StyleLabels)[]).map((id) => {
  return {
    id,
    label: StyleLabels[id]
  };
}).sort((a, b) => {
  if (+a.id === TAppElkImageStyle.DEFAULT) {
    return -1;
  }
  if (+b.id === TAppElkImageStyle.DEFAULT) {
    return 1;
  }
  return (a.label < b.label) ? -1 : 1;
});

const ImageStyleDropdownButton = ({
                                    disabled,
                                    style,
                                    onStyleChange
                                  }: Props) => {
  return <Dropdown
    values={MENU_ITEMS}
    label="Style"
    defaultText="None"
    disabled={disabled}
    value={style}
    onChange={onStyleChange}
  />;
};

export default ImageStyleDropdownButton;
