import React, { ChangeEvent, ReactNode, RefObject } from 'react';
import styled from 'styled-components';

import CloseIcon from 'Common/src/components/icons/CloseIcon';
import { DeviceQueries } from 'Common/src/components/styled';

interface Props {
  value?: string;
  placeholder: string;
  inputRef?: RefObject<HTMLInputElement>;
  onChange: (value: string) => void;
  onCancel?: () => void;
  icon: ReactNode;
  expanding?: boolean;
}

const PillInputWithIcon = ({ value, placeholder, inputRef, onChange, onCancel, icon, expanding = false }: Props) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return <Container $expanding={expanding}>
    <Icon>
      {icon}
    </Icon>
    <Input
      value={value ?? ''}
      placeholder={placeholder}
      onChange={handleChange}
      ref={(node) => {
        if (inputRef) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputRef.current = node;
        }
      }}
    />
    {onCancel !== undefined && <CancelButton onClick={onCancel}><CloseIcon size={18}/></CancelButton>}
  </Container>;
};

const Container = styled.div<{ $expanding: boolean }>`
  display: flex;
  border-radius: 10px;
  background: var(--sp-module-bg-color);
  border: 2px solid var(--sp-border-color);
  align-items: center;
  margin-bottom: 4px;

  ${({ $expanding }) => $expanding && `
  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
  `}
  &:focus-within {
    outline-offset: -2px;
    outline: #4D90FE auto 5px;
  }
`;

const Input = styled.input`
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 400;
  font-size: 18px;
  flex-grow: 1;
  padding: 4px 8px;
  border: 0;
  background-color: transparent;
  color: var(--sp-text-color);
  width: 168px;

  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  display: flex;
  padding-left: 8px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  display: flex;
  margin: 0 8px;
`;

export default PillInputWithIcon;
