import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  title: ReactNode;
  subtitle: ReactNode;
  onScreenClick?: () => void;
  wide?: boolean;
}

const BaseModalPage = ({ children, title, subtitle, onScreenClick, wide }: PropsWithChildren<Props>) => {
  return <Screen onClick={onScreenClick}>
    <PseudoModal $wide={wide} onClick={(e) => e.stopPropagation()}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalSubtitle>{subtitle}</ModalSubtitle>
      </ModalHeader>
      <ModalMain>
        {children}
      </ModalMain>
    </PseudoModal>
  </Screen>;
};

const Screen = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sp-text-color);
  z-index: 100;
`;

const PseudoModal = styled.div<{
  $wide?: boolean
}>`
  height: auto;
  width: ${({ $wide }) => $wide ? '470px' : '330px'};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 20px;
  border: 2px solid var(--sp-border-color);
  background: rgba(var(--sp-bg-rgb), 0.80);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 24px;
`;

export const ModalTitle = styled.div`
  font-size: 28px;
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 600;
  width: 100%;
`;

export const ModalSubtitle = styled.div`
  font-size: 16px;
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 500;
  width: 100%;
  opacity: 80%;
`;

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default BaseModalPage;
