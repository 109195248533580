import styled from 'styled-components';

export const NotYouText = styled.div`
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  overflow-wrap: anywhere;
`

export const NotYouActionText = styled(NotYouText).attrs({
  as: 'span'
})`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
`
