import React, { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import {
  ModalContainer,
  ModalContent,
  ModalHeadAndSubHead,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
  Overlay,
  SystemModalContainer
} from './CommonModal';
import { useUserContext } from '../../contexts/UserContext';
import { ButtonInternal } from '../buttons/SystemButton';
import XMarkCircle from '../icons/XMarkCircle';
import { WideOutlineButton } from '../buttons/OutlineButton';
import { WideWhiteButton } from '../buttons/WhiteButton';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  secondaryButtonLabel?: string;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  onClickOutside?: () => void;
  onSecondaryClick?: () => void;
  onPrimaryClick?: () => void;
  showClose?: boolean;
  secondaryButtonOutlined?: boolean;
  useSystemColors?: boolean;
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
                                                     title,
                                                     subtitle,
                                                     secondaryButtonLabel,
                                                     primaryButtonLabel,
                                                     primaryButtonDisabled = false,
                                                     secondaryButtonDisabled = false,
                                                     onClickOutside,
                                                     onSecondaryClick,
                                                     onPrimaryClick,
                                                     children,
                                                     showClose,
                                                     secondaryButtonOutlined,
                                                     useSystemColors
                                                   }) => {
  const userContext = useUserContext();

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onOutsideClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[Modal] Manually closed modal'
    });

    onClickOutside && onClickOutside();
  };

  const content = <div>
    {showClose && <CloseContainer onClick={onOutsideClick}>
      <XMarkCircle color="#9EA5AD"/>
    </CloseContainer>}

    <ModalContent>
      <ModalHeadAndSubHead>
        <ModalHeader $hasClose={!showClose}>
          {title && <ModalTitle>{title}</ModalTitle>}
        </ModalHeader>
        {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
      </ModalHeadAndSubHead>

      {children}
    </ModalContent>
  </div>;

  return <Overlay onMouseDown={onOutsideClick}>
    {useSystemColors
      ? <SystemModalContainer onMouseDown={onModalClick} $smallerGap={subtitle === undefined}>
        {content}
        {primaryButtonLabel !== undefined || secondaryButtonLabel !== undefined ? <ButtonGroup>
          {secondaryButtonLabel &&
              <Wrapper>
                  <WideOutlineButton onClick={onSecondaryClick} disabled={secondaryButtonDisabled}>
                    {secondaryButtonLabel}
                  </WideOutlineButton>
              </Wrapper>
          }
          {primaryButtonLabel &&
              <Wrapper>
                  <WideWhiteButton $invert={true} onClick={onPrimaryClick} disabled={primaryButtonDisabled}>
                    {primaryButtonLabel}
                  </WideWhiteButton>
              </Wrapper>
          }
        </ButtonGroup> : <></>}
      </SystemModalContainer>
      : <ModalContainer onMouseDown={onModalClick} $smallerGap={subtitle === undefined}>
        {content}
        {primaryButtonLabel !== undefined || secondaryButtonLabel !== undefined ? <ButtonGroup>
          {secondaryButtonLabel &&
              <Wrapper>
                  <WideOutlineButton onClick={onSecondaryClick} disabled={secondaryButtonDisabled}>
                    {secondaryButtonLabel}
                  </WideOutlineButton>
              </Wrapper>
          }
          {primaryButtonLabel &&
              <Wrapper>
                  <WideWhiteButton $invert={true} onClick={onPrimaryClick} disabled={primaryButtonDisabled}>
                    {primaryButtonLabel}
                  </WideWhiteButton>
              </Wrapper>
          }
        </ButtonGroup> : <></>}
      </ModalContainer>}
  </Overlay>;
};

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 11px;
  gap: 10px;

  ${ButtonInternal} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export default Modal;
