import React from 'react';
import styled from 'styled-components';

import AttendeeCircle from './AttendeeCircle';

import { TAppElkMessage } from 'TProtocol/prototypes/events/messages';

const dateTimeFormat = new Intl.DateTimeFormat(
  'en-US',
  { month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: '2-digit' }
);

interface Props {
  message: TAppElkMessage;
}

const AttendeeHostReply = ({ message }: Props) => {
  return <RowContainer>
    <AttendeeCircle attendee={message.senderProfile} small={true} size={32}/>
    <Content>
      <SenderRow>
        <SenderName>{message.senderProfile.name}</SenderName>
        <SentTime>{dateTimeFormat.format(message.sentTime.toNumber())}</SentTime>
      </SenderRow>
      <div>
        {message.message}
      </div>
    </Content>
  </RowContainer>;
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const SenderRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const SenderName = styled.span`
  font-weight: 500;
`;

const SentTime = styled.span`
  font-size: 14px;
  color: rgba(var(--sp-text-rgb), 0.90);
  font-weight: 400;
`;

export default AttendeeHostReply;
