import React from 'react';
import styled from 'styled-components';

import { IUEvent } from '../../lib/event';
import PhotosLink from './PhotosLink';
import { DesktopShow, MobileShow } from '../../components/styled/Common';

import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { useUserContext } from '../../contexts/UserContext';

const AlbumDetails = ({ event, setShowPhotoViewer, isRo, showLogo }: {
  event: IUEvent,
  setShowPhotoViewer: React.Dispatch<React.SetStateAction<boolean>>
  isRo?: boolean,
  showLogo?: boolean
}) => {
  const userContext = useUserContext();

  const albumDetails = event.albumDetails;

  if (albumDetails === undefined) {
    return null;
  }

  // Desktop constants
  const maxNumRowsDesktop = 2;
  const maxNumColsDesktop = 9;
  const maxPhotosDisplayedDesktop = maxNumRowsDesktop * maxNumColsDesktop;
  const numDisplayedPhotosDesktop = Math.min(albumDetails.photoMetas?.length ?? 0, maxPhotosDisplayedDesktop);

  // Mobile constants
  const maxNumRowsMobile = 1;
  const maxNumColsMobile = 3;
  const maxPhotosDisplayedMobile = maxNumRowsMobile * maxNumColsMobile;
  const numDisplayedPhotosMobile = Math.min(albumDetails.photoMetas?.length ?? 0, maxPhotosDisplayedMobile);

  const isBottomLeftPhoto = (index: number, maxNumCols: number, numDisplayedPhotos: number) => {
    const isFirstInRow = index % maxNumCols === 0;
    const isInLastRow = Math.floor(index / maxNumCols) === Math.floor((numDisplayedPhotos - 1) / maxNumCols);
    return isFirstInRow && isInLastRow;
  }

  const isBottomRightPhoto = (index: number, maxNumCols: number, numDisplayedPhotos: number) => {
    const isLastInRow = index % maxNumCols === maxNumCols - 1;
    const isInLastRow = Math.floor(index / maxNumCols) === Math.floor((numDisplayedPhotos - 1) / maxNumCols);
    return isLastInRow && isInLastRow;
  }

  const handleClickPhotosPreview = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[AlbumDetails] Clicked on photos preview'
    });
    setShowPhotoViewer(true);
  }

  const desktopPhotosPreview = albumDetails.photoMetas !== undefined && albumDetails.photoMetas.length > 0
    ? <PreviewContainer onClick={handleClickPhotosPreview}>
      {
        albumDetails.photoMetas.slice(0, numDisplayedPhotosDesktop).map((photoMeta, index) => {
          if (index === numDisplayedPhotosDesktop - 1 && (albumDetails.photoMetas?.length ?? 0) > maxPhotosDisplayedDesktop) {
            return <ImageContainer key={photoMeta.photoId} $maxPhotoLimit={maxPhotosDisplayedDesktop}>
              <ExtraImageCount
                $roundedBottomRightCorner={isBottomRightPhoto(index, maxNumColsDesktop, numDisplayedPhotosDesktop)}>
                <ExtraImageCountText>+{albumDetails.photoMetas ? albumDetails.photoMetas?.length - maxPhotosDisplayedDesktop : 0}</ExtraImageCountText>
              </ExtraImageCount>
            </ImageContainer>
          } else {
            return <ImageContainer key={photoMeta.photoId} $maxPhotoLimit={maxPhotosDisplayedDesktop}>
              <PreviewImage src={photoMeta.thumbnailUrl}
                            $roundedBottomLeftCorner={isBottomLeftPhoto(index, maxNumColsDesktop, numDisplayedPhotosDesktop)}
                            $roundedBottomRightCorner={isBottomRightPhoto(index, maxNumColsDesktop, numDisplayedPhotosDesktop)}
              />
            </ImageContainer>
          }
        })}
    </PreviewContainer>
    : null;

  const mobilePhotosPreview = albumDetails.photoMetas !== undefined && albumDetails.photoMetas.length > 0
    ? <PreviewContainer onClick={handleClickPhotosPreview}>
      {
        albumDetails.photoMetas.slice(0, numDisplayedPhotosMobile).map((photoMeta, index) => {
          if (index === numDisplayedPhotosMobile - 1 && (albumDetails.photoMetas?.length ?? 0) > maxPhotosDisplayedMobile) {
            return <ImageContainer key={photoMeta.photoId} $maxPhotoLimit={maxPhotosDisplayedMobile}>
              <ExtraImageCount
                $roundedBottomRightCorner={isBottomRightPhoto(index, maxNumColsMobile, numDisplayedPhotosMobile)}>
                <ExtraImageCountText>+{albumDetails.photoMetas ? albumDetails.photoMetas?.length - maxPhotosDisplayedMobile : 0}</ExtraImageCountText>
              </ExtraImageCount>
            </ImageContainer>
          } else {
            return <ImageContainer key={photoMeta.photoId} $maxPhotoLimit={maxPhotosDisplayedMobile}>
              <PreviewImage src={photoMeta.thumbnailUrl}
                            $roundedBottomLeftCorner={isBottomLeftPhoto(index, maxNumColsMobile, numDisplayedPhotosMobile)}
                            $roundedBottomRightCorner={isBottomRightPhoto(index, maxNumColsMobile, numDisplayedPhotosMobile)}
              />
            </ImageContainer>
          }
        })}
    </PreviewContainer>
    : null;

  return <Container>
    <PhotosLink event={event} isRo={isRo} useLogo={showLogo}/>
    <DesktopShow>
      {desktopPhotosPreview}
    </DesktopShow>
    <MobileShow>
      {mobilePhotosPreview}
    </MobileShow>
  </Container>;
};

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 0 12px;

  @media (${DeviceQueries.mobile}) {
    justify-content: center;
    padding: 0;
  }
`;

const ImageContainer = styled.div<{
  $maxPhotoLimit: number
}>`
  width: 115px;
  height: 115px;
  margin: 8px;

  ${({ $maxPhotoLimit }) => `&:nth-child(n + ${$maxPhotoLimit + 1}) {
      display: none;
    }`};

  @media (${DeviceQueries.mobile}) {
    width: 85px;
    height: 85px;
    margin: 6px;
  }
}
`

const PreviewImage = styled.img<{
  $roundedBottomLeftCorner?: boolean,
  $roundedBottomRightCorner?: boolean
}>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  ${({
       $roundedBottomLeftCorner,
       $roundedBottomRightCorner
     }) => `border-radius: 0 0 ${$roundedBottomRightCorner ? '8px' : '0'} ${$roundedBottomLeftCorner ? '8px' : '0'}`};
`;

const ExtraImageCount = styled.div<{
  $roundedBottomLeftCorner?: boolean,
  $roundedBottomRightCorner?: boolean
}>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  ${({
       $roundedBottomLeftCorner,
       $roundedBottomRightCorner
     }) => `border-radius: 0 0 ${$roundedBottomRightCorner ? '8px' : '0'} ${$roundedBottomLeftCorner ? '8px' : '0'}`};
  background: rgba(var(--sp-bg-rgb), 0.20);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExtraImageCountText = styled.div`
  color: #FFF;
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  transform: translateX(-5px); // To center text based on number excluding + character

  @media (${DeviceQueries.mobile}) {
    font-size: 20px;
  }
`

const Container = styled.div`
  padding: 0px;
  //background-color: rgba(256, 256, 256, 0.1);
  //border-radius: 24px;
  //backdrop-filter: blur(80px);
`

export default AlbumDetails;
