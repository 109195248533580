import React from 'react';

const ChevronRight = ({ color = 'currentColor', size = 27 }: { color?: string, size?: number }) => {
  return <svg width={size} height={size} viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="heroicons-solid/chevron-right">
      <path id="Vector 335 (Stroke)" fillRule="evenodd" clipRule="evenodd"
            d="M18.6998 12.9222C19.019 13.2413 19.019 13.7587 18.6998 14.0778L10.5279 22.2498C10.2087 22.569 9.6913 22.569 9.37216 22.2498C9.05303 21.9307 9.05303 21.4133 9.37216 21.0941L16.9663 13.5L9.37217 5.90586C9.05303 5.58673 9.05303 5.06931 9.37217 4.75017C9.6913 4.43103 10.2087 4.43103 10.5279 4.75017L18.6998 12.9222Z"
            fill={color}/>
    </g>
  </svg>;
};

export default ChevronRight;
