import React from 'react';
import { useNavigate } from 'react-router-dom';

import VideoAnimation from '../animations/VideoAnimation';
import { InviteContent, InviteDarkMask } from './Common';
import { IUEvent } from '../../lib/event';
import TopModule from '../../pages/Event/TopModule';
import { useUserContext } from '../../contexts/UserContext';
import { getBlurOverlayColor } from '../../lib/colorPicker';
import { InspirationTheme } from '../../lib/inspiration';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const SeasonalInvite = ({ event, theme }: { event: IUEvent, theme?: InspirationTheme }) => {
  const userContext = useUserContext();
  const navigate = useNavigate();

  const navigateToGallery = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[SeasonalInvite] Clicked on hero image'
    });
    navigate('/instant', {
      state: {
        theme: InspirationTheme.Holiday
      }
    });
  }

  return <>
    <InviteDarkMask/>
    <VideoAnimation url={event.backgroundAnimationUrl ?? ''}
                    asBackground={false}
                    colorOverride={getBlurOverlayColor(event.colors)}
    />
    <InviteContent onClick={navigateToGallery}>
      <TopModule event={event} forSampleInvite={true} isAttending={true} theme={theme}/>
    </InviteContent>
  </>
}

export default SeasonalInvite;
