import React, { PropsWithChildren, ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useUserContext } from '../contexts/UserContext';
import PersonCropCircle from '../components/icons/PersonCropCircle';
import { loginUrl } from '../lib/login';
import LogoWhiteT from '../assets/LogoWhiteT.png';
import { useBackgroundContext } from '../contexts/BackgroundContext';
import { useEditEventContext } from '../contexts/EditEventContext';
import Home from '../components/icons/Home';
import { OutlineButton } from '../components/buttons/OutlineButton';
import Logout from '../components/icons/Logout';
import PlusCircleOutline from '../components/icons/PlusCircleOutline';
import { DesktopShow, MobileShow } from '../components/styled/Common';
import { calculateColor } from '../util/attendee';
import sunshineLogo from '../../../mouse-web/src/core/assets/sunshine_logo.png';

import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const NameRegex = /^([^ ]*) (.*)$/;

export enum UPageType {
  Welcome = 1,
  Events,
  Event,
  Login,
  QuickCreate,
  CreateEvent,
  Gallery,
  EditEvent,
  ProfileEdit,
  Invite,
  Cohost,
  QuickCreateLoading
}

declare const ELK_COOKIE_DOMAIN: string; // sunshine.com

const BasePageHeader = ({
                          children,
                          hide,
                          page
                        }: PropsWithChildren<{
  hide?: boolean,
  page?: UPageType
}>) => {
  const userContext = useUserContext();
  const backgroundContext = useBackgroundContext();
  const editEventContext = useEditEventContext();
  const navigate = useNavigate();
  const location = useLocation();

  const isRootDomain = window.location.host === ELK_COOKIE_DOMAIN;

  const onHome = () => {
    navigate('/');
  };

  const onLogin = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[BasePageHeader] Clicked on login'
    });

    if (location.pathname === '/welcome') {
      navigate(loginUrl('/'));
    } else {
      navigate(loginUrl(location.pathname + location.search), { state: location.state });
    }
  };

  const onNew = () => {
    editEventContext.clearEvent();
    navigate('/instant');
  };

  const onProfileClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[BasePageHeader] Clicked on profile edit link'
    });
  };

  const backgroundTextColor = backgroundContext.systemColors.backgroundText;
  const logoColor = page === UPageType.Invite ? '#FFFFFF' : 'var(--shine-logo-color)';

  let createContent: ReactNode = null;
  if (page === UPageType.Events) {
    createContent = <OutlineButton onClick={onNew} $noMin={true} $invert={true}>
      <PlusCircleOutline size={20}/>
      <DesktopShow> Create your Invite</DesktopShow>
      <MobileShow> Create</MobileShow>
    </OutlineButton>;
  }

  let rightContent: ReactNode = null;

  if (userContext.isLoggedIn()) {
    let profileIcon: ReactNode;
    if (userContext.photoUrl) {
      profileIcon = <UserPhoto $url={userContext.photoUrl}/>;
    } else if (userContext.name) {
      const names = (userContext?.name ?? '').match(NameRegex) ?? [userContext.name, userContext.name, ''];
      const firstName = names?.[1];
      const lastName = names?.[2];

      profileIcon = <UserCircle $color={calculateColor(userContext.generateElkUser())}>
        {firstName?.[0]?.toUpperCase() ?? ''}{lastName?.[0]?.toUpperCase() ?? ''}
      </UserCircle>;
    } else {
      profileIcon = <PersonCropCircle size={40} color={backgroundTextColor}/>;
    }

    rightContent = <NavContainer>
      {
        page !== UPageType.Events && <OutlineButton onClick={onHome} $noMin={true}><Home size={20}/> Upcoming</OutlineButton>
      }
      <IconContainer>
        <HeaderLink to="/profile/edit" onClick={onProfileClick}>
          {profileIcon}
        </HeaderLink>
      </IconContainer>
    </NavContainer>;
  } else if (page !== UPageType.Login) {
    rightContent = <NavContainer>
      <OutlineButton onClick={onLogin} $noMin={true}><Logout/> Sign In</OutlineButton>
    </NavContainer>;
  }

  const desktopShowLogo = page !== UPageType.EditEvent && page !== UPageType.CreateEvent && page !== UPageType.Gallery;

  return <BoxContainer $hide={hide}>
    <HeaderItem>
      {
        desktopShowLogo ? <HeaderLink to="/">
          {isRootDomain ? <LogoImg src={sunshineLogo} width={195} height={68} alt="Sunshine"/> :
            <img src={LogoWhiteT} width={150} height={50}/>}
        </HeaderLink> : <MobileShow>
          <HeaderLink to="/"><img src={LogoWhiteT} width={150} height={50}/></HeaderLink>
        </MobileShow>
      }
    </HeaderItem>
    <HeaderItem>
      {children !== undefined ? children : <>
        {createContent}
        {rightContent}
      </>}
    </HeaderItem>
  </BoxContainer>;
};

const BoxContainer = styled.div<{ $hide?: boolean }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 0;
  gap: 10px;
  z-index: 10;
  font-family: Plus Jakarta Sans, sans-serif;
  //border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  //backdrop-filter: blur(10px);

  @media (${DeviceQueries.mobile}) {
    overflow: hidden;
    padding: ${({ $hide = false }) => $hide ? '0' : '10px'};
    height: ${({ $hide = false }) => $hide ? '0' : '60px'};
    transition: height 0.3s cubic-bezier(.36, -0.01, 0, .77), padding 0.3s cubic-bezier(.36, -0.01, 0, .77);
  }
`;

const HeaderLink = styled(Link)`
  color: var(--shine-logo-color);
  text-decoration: none;
`;

const LogoImg = styled.img`
  @media (${DeviceQueries.mobile}) {
    width: 97px;
    height: 34px;
  }
`;

const HeaderItem = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;

  @media (${DeviceQueries.mobile}) {
    font-size: 8pt;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  //height: 35px;
`;

const UserPhoto = styled.div<{ $url: string }>`
  width: 38px;
  height: 38px;
  border-radius: 100px;
  background: url('${({ $url }) => $url}') lightgray 50% / cover no-repeat;;
`;

const UserCircle = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 38px;
  height: 38px;
  border-radius: 100px;

  font-size: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export default BasePageHeader;
