import React from 'react';

const LeftPaginationArrow = ({ color = 'currentColor', size = 19, opacity = 1 }: {
  color?: string,
  size?: number,
  opacity?: number
}) => {
  return <svg width={size} height={size} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.62339 4.86193H9.14283V6.14765H3.62339L5.74025 8.26451L4.83112 9.17365L1.16226 5.50479L4.83112 1.83594L5.74025 2.74507L3.62339 4.86193Z"
            fill={color}/>
    </g>
  </svg>;
};

export default LeftPaginationArrow;
