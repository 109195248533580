import React, { FormEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { LoginInput, LoginInputHeader, LoginInputHeaderTitle } from '../../pages/Login/LoginCommon';
import Modal from './Modal';
import ConfirmCode from '../login/ConfirmCode';
import PhoneInput from '../SingleFieldForm/PhoneInput';
import WhiteInput from '../forms/WhiteInput';
import { validateEmail } from '../../util/email';
import { CodeType } from '../../lib/login';
import { useUserContext } from '../../contexts/UserContext';
import ErrorIcon from '../icons/ErrorIcon';
import { WideWhiteButton } from '../buttons/WhiteButton';
import { confirmUserMerge } from '../../api/UserServiceApi';

import { sendPiiVerificationCode, verifyWithPiiAndCode } from 'Common/src/api/AuthenticationApi';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import { TAppPii } from 'TProtocol/authentication/messages';

enum MergeModalTypeEnum {
  Phone = 1,
  Email,
  PhoneCode,
  EmailCode,
  ConfirmMergePhone,
  ConfirmMergeEmail,
  PhoneConfirmed,
  EmailConfirmed
}

interface Props {
  fromRsvp: boolean,
  defaultPhone?: string,
  defaultEmail?: string,
  close: () => void
}

const MergeModal = ({ fromRsvp, defaultPhone, defaultEmail, close }: Props) => {
  const userContext = useUserContext();

  const [type, setType] = useState<MergeModalTypeEnum>();
  const [phone, setPhone] = useState('');
  const [fullPhone, setFullPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingCode, setSubmittingCode] = useState(false);
  const [piiToConfirm, setPiiToConfirm] = useState<TAppPii[] | undefined>();

  useEffect(() => {
    if (defaultEmail) {
      setType(MergeModalTypeEnum.Email);
      setEmail(defaultEmail);
      setValidEmail(validateEmail(defaultEmail));
    } else if (defaultPhone) {
      setType(MergeModalTypeEnum.Phone);
      setPhone(defaultPhone);
    } else {
      const email = userContext.getEmail();
      const phone = userContext.getPhoneNumber();
      if (phone && !email) {
        setType(MergeModalTypeEnum.Email);
      } else if (!phone && email) {
        setType(MergeModalTypeEnum.Phone);
      } else {
        close();
      }
    }
  }, [defaultEmail, defaultPhone]);

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[MergeModal] ${action}`
    });
  };

  const onPhoneSubmit = async (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    logUserAction('Phone submit');
    e.preventDefault();
    setSubmitting(true);

    void submitPhone();
  };

  const submitPhone = async () => {
    const response = await sendPiiVerificationCode(userContext.getAuthHeader(), new TAppPii({ phone: fullPhone }));
    setSubmitting(false);
    if (response.errorMessage) {
      setError(response.errorMessage);
    } else if (response.success) {
      setType(MergeModalTypeEnum.PhoneCode);
    } else {
      setError('Something went wrong sending your verification code.')
    }
  };

  const onEmailSubmit = async (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    logUserAction('Email submit');
    e.preventDefault();

    if (!validEmail) {
      setError('Please enter a valid email address.');
      return;
    }

    setSubmitting(true);

    void submitEmail();
  };

  const submitEmail = async () => {
    const response = await sendPiiVerificationCode(userContext.getAuthHeader(), new TAppPii({ email }));
    setSubmitting(false);
    if (response.errorMessage) {
      setError(response.errorMessage);
    } else if (response.success) {
      setType(MergeModalTypeEnum.EmailCode);
    } else {
      setError('Something went wrong sending your verification code.')
    }
  };

  const handleEmailChange = (email: string) => {
    setEmail(email);
    setValidEmail(validateEmail(email));
    setError('');
  };

  const handlePhoneChange = (phone: string, fullValue: string) => {
    setPhone(phone);
    setFullPhone(fullValue);
  }

  const onResendCode = () => {
    logUserAction('Resend code');
    setSubmittingCode(true);
    if (type === MergeModalTypeEnum.PhoneCode) {
      void submitPhone();
    } else {
      void submitEmail();
    }
  };

  const onConfirmCode = async (code: string) => {
    logUserAction('Confirm code');
    setSubmittingCode(true);
    const response = await verifyWithPiiAndCode(
      userContext.getAuthHeader(),
      new TAppPii(type === MergeModalTypeEnum.PhoneCode ? { phone: fullPhone } : { email }),
      code
    );

    if (response.response.addPiiSuccess) {
      userContext.addPii(response.response.addPiiSuccess.pii);
      close();
    } else if (response.response.mergePrompt) {
      const newType = type === MergeModalTypeEnum.PhoneCode ? MergeModalTypeEnum.ConfirmMergePhone : MergeModalTypeEnum.ConfirmMergeEmail;
      setType(newType);
      setPiiToConfirm(response.response.mergePrompt.pii);
    } else {
      setError('Failed to verify code, please try again.');
      setSubmittingCode(false);
    }
  };

  const onConfirmMerge = async () => {
    logUserAction('Confirm merge');
    if (piiToConfirm) {
      await confirmUserMerge(userContext.getAuthHeader(), piiToConfirm);
      setType(
        type === MergeModalTypeEnum.ConfirmMergePhone ? MergeModalTypeEnum.PhoneConfirmed : MergeModalTypeEnum.EmailConfirmed);
    }
  };

  const onClose = () => {
    logUserAction('Close modal');
    userContext.snoozeMergeModal();
    close();
  };

  const formatPii = (pii: TAppPii): ReactNode => {
    return pii.email ? <>email <strong>{pii.email}</strong></> : <>phone number <strong>{pii.phone}</strong></>;
  };

  const formatPiiList = (piis: TAppPii[]): ReactNode => {
    let piiText: ReactNode = null;
    if (piis.length === 2) {
      piiText = <>both your {formatPii(piis[0])} and {formatPii(piis[1])}</>;
    } else if (piis.length > 2) {
      const piiToFormat = [...piis];
      const lastPii = piiToFormat.pop() as TAppPii;
      const piiSubTexts: ReactNode[] = piiToFormat.map(formatPii);
      piiSubTexts.push(<>and {formatPii(lastPii)}</>);

      piiText = <>your {piiSubTexts.map((subText, index) => <>
        {index > 0 && ', '}
        {subText}
      </>)}</>;
    }

    return piiText;
  };

  let content: ReactNode;
  let title: ReactNode;
  let subtitle: ReactNode;

  if (type === MergeModalTypeEnum.Phone) {
    if (fromRsvp) {
      title = 'RSVP received';
      subtitle = 'This phone number is not associated with your account, do you want to add it?';
    } else {
      title = 'Complete your profile';
      subtitle = 'Add your phone number to stay connected';
    }
    content = <>
      <LoginInput>
        <LoginInputHeader>
          <LoginInputHeaderTitle>Phone Number</LoginInputHeaderTitle>
        </LoginInputHeader>
        <InputForm onSubmit={onPhoneSubmit}>
          <PhoneInput
            autoFocus={true}
            placeholder="(123) 555-1234"
            disabled={submitting}
            autoComplete="tel"
            value={phone}
            required={true}
            onChange={handlePhoneChange}
          />
        </InputForm>
        {
          error && <ErrorContainer>
            <ErrorIcon/> <span>{error}</span>
          </ErrorContainer>
        }
      </LoginInput>
      <WideWhiteButton onClick={onPhoneSubmit} disabled={submitting}>
        Verify phone number
      </WideWhiteButton>
    </>;
  } else if (type === MergeModalTypeEnum.Email) {
    if (fromRsvp) {
      title = 'RSVP received';
      subtitle = 'This email address is not associated with your account, do you want to add it?';
    } else {
      title = 'Complete your profile';
      subtitle = 'Add your email to stay connected';
    }
    content = <>
      <LoginInput>
        <LoginInputHeader>
          <LoginInputHeaderTitle>Email</LoginInputHeaderTitle>
        </LoginInputHeader>
        <InputForm onSubmit={onEmailSubmit}>
          <WhiteInput
            autoComplete="email"
            autoFocus={true}
            placeholder="stella@sunshine.com"
            $isInvalid={error !== ''}
            disabled={false}
            value={email}
            onChange={(e) => handleEmailChange(e.currentTarget.value)}
          />
        </InputForm>
        {
          error && <ErrorContainer>
            <ErrorIcon/> <span>{error}</span>
          </ErrorContainer>
        }
      </LoginInput>
      <WideWhiteButton onClick={onEmailSubmit} disabled={submitting} $invert={true}>
        Verify email
      </WideWhiteButton>
    </>;
  } else if (type === MergeModalTypeEnum.PhoneCode || type === MergeModalTypeEnum.EmailCode) {
    title = 'Enter code';
    subtitle = `We sent a code to ${type === MergeModalTypeEnum.PhoneCode ? phone : email}`;
    content = <CodeContainer>
      <ConfirmCode
        disabled={submittingCode}
        error={error}
        codeType={type === MergeModalTypeEnum.PhoneCode ? CodeType.Phone : CodeType.Email}
        onConfirm={onConfirmCode}
        onResend={onResendCode}
      />
    </CodeContainer>;
  } else if ((type === MergeModalTypeEnum.ConfirmMergePhone || type === MergeModalTypeEnum.ConfirmMergeEmail) && piiToConfirm) {
    title = 'Confirm merge';
    subtitle = <>There is already a Shine account for this {type === MergeModalTypeEnum.ConfirmMergePhone ? 'phone number' : 'email'}.
    This will merge {formatPiiList(piiToConfirm)} so you can log in to any of them to access your account.</>;

    content = <WideWhiteButton onClick={onConfirmMerge}>
      Confirm
    </WideWhiteButton>;
  } else if ((type === MergeModalTypeEnum.PhoneConfirmed || type === MergeModalTypeEnum.EmailConfirmed) && piiToConfirm) {
    const newPiiType = type === MergeModalTypeEnum.PhoneConfirmed ? 'phone number' : 'email';

    title = 'Profile will be updated';
    subtitle = <>Your {newPiiType} has been added to your profile. You can now use {formatPiiList(piiToConfirm)} to log
      in and stay connected</>;

    content = <WideWhiteButton onClick={close}>
      Continue
    </WideWhiteButton>;
  } else {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.WARN,
      tag: ULogTag.UserAuth,
      message: `[MergeModal] Error trying to show merge modal: type=${type} and piiToConfirm=${
        JSON.stringify(piiToConfirm)
      }`
    });
    // close();
  }

  return <Modal onClickOutside={onClose} showClose={true} title={title} subtitle={subtitle}>
    {content}
  </Modal>;
};

const InputForm = styled.form`
  display: contents;
`;

const ErrorContainer = styled.div`
  display: flex;
  gap: 6px;
  color: #B3251E;
  font-size: 12px;
  align-items: center;
`;

const CodeContainer = styled.div`
  margin:auto;
`;

export default MergeModal;
