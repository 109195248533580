import React from 'react';
import styled from 'styled-components';

import {
  FilteredInspirationCategories,
  getMainCategory,
  getNextHolidayTheme,
  InspirationMainCategory,
  InspirationSubCategory,
  InspirationTheme,
  sortedHolidayDefinitions
} from '../../lib/inspiration';

import { DeviceQueries } from 'Common/src/components/styled';

type ThemePillConfig = {
  id: InspirationTheme;
  label: string;
  isTheme: true;
}

type MainCatPillConfig = {
  id: InspirationMainCategory;
  label: string;
  isTheme: false;
}

type PillConfig = ThemePillConfig | MainCatPillConfig;

const PillRow = ({ openMainCategory, setOpenMainCategory, onThemeClick, onMouseLeave }: {
  openMainCategory?: InspirationMainCategory;
  setOpenMainCategory: (mainCat?: InspirationMainCategory) => void;
  onThemeClick: (theme?: InspirationTheme) => void;
  onMouseLeave: () => void;
}) => {
  const mainCat = openMainCategory === undefined ? undefined : getMainCategory(openMainCategory);

  const onPillOver = (pill: PillConfig, isClick: boolean) => {
    if (pill.isTheme) {
      if (isClick) {
        onThemeClick(pill.id);
      } else {
        setOpenMainCategory(undefined);
      }
    } else {
      if (isClick && pill.id === openMainCategory) {
        setOpenMainCategory(undefined);
      } else {
        setOpenMainCategory(pill.id);
      }
    }
  };

  const nextHolidayTheme = getNextHolidayTheme();
  const pills: PillConfig[] = [
    {
      id: nextHolidayTheme.id,
      label: nextHolidayTheme.label,
      isTheme: true,
    },
    ...(FilteredInspirationCategories.map((mainCat) => {
      return {
        id: mainCat.id,
        label: mainCat.label,
        isTheme: false
      } as MainCatPillConfig;
    }))
  ];

  return <FullRow onMouseLeave={onMouseLeave}>
    <Row>
      {
        pills.map((pill, index) => <Pill
            key={index} onMouseEnter={() => onPillOver(pill, false)}
            onClick={() => onPillOver(pill, true)}
          >
            <PillLabel $selected={pill.id === openMainCategory}>
              {pill.label}
            </PillLabel>
          </Pill>
        )
      }
    </Row>
    {mainCat === undefined ? null : <Anchor>
      <Dropdown>
        {
          mainCat.subCategories.map(subCat => <SubCat key={subCat.id}>
            {
              subCat.themes[0].solo ?
                <SubCatLabel onClick={() => onThemeClick(subCat.themes[0].id)}
                             $clickable={true}>{subCat.label}</SubCatLabel> :
                <SubCatLabel>{subCat.label}</SubCatLabel>
            }
            {
              (subCat.id === InspirationSubCategory.Holidays ? sortedHolidayDefinitions(subCat.themes) : subCat.themes)
                .map(theme => <Theme onClick={() => onThemeClick(theme.id)} key={theme.id}>
                  {theme.label}
                </Theme>)
            }
          </SubCat>)
        }
      </Dropdown>
    </Anchor>}
  </FullRow>;
};

const FullRow = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
`;

const Pill = styled.div`
  display: flex;
  padding: 7px 17px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  cursor: pointer;
`;

const PillLabel = styled.div<{ $selected: boolean }>`
  font-size: 18px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  white-space: nowrap;
  ${({ $selected }) => $selected ? 'text-decoration: underline;' : ''}
`;

const Anchor = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

const Dropdown = styled.div`
  position: absolute;
  background-color: var(--sp-bg-color);
  width: 100svw;
  left: -40px;
  z-index: 100;
  display: flex;
  align-items: start;
  gap: 100px;
  padding: 20px 57px;
  overflow: scroll;
  box-sizing: border-box;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.20);

  @media (${DeviceQueries.mobile}) {
    gap: 20px;
  }
`;

const SubCat = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SubCatLabel = styled.div<{ $clickable?: boolean }>`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  ${({ $clickable }) => $clickable ? 'cursor: pointer;' : ''}
`;

const Theme = styled.div`
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
`;

const More = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;

export default PillRow;
