import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useEditEventContext } from '../../contexts/EditEventContext';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { useUserContext } from '../../contexts/UserContext';
import Modal from './Modal';
import { deleteEvent } from '../../api/ElkEventService';

const DeleteEventModal = ({ eventId, close }: { eventId: string, close: () => void }) => {
  const navigate = useNavigate();
  const editEventContext = useEditEventContext();
  const eventCacheContext = useEventCacheContext();
  const userContext = useUserContext();

  const handleDelete = async () => {
    editEventContext.cancelEvent();
    eventCacheContext.deleteEvent(eventId);
    void deleteEvent(userContext, eventId);
    close();
    navigate('/events');
  };

  return <Modal
    title="Delete Party"
    subtitle="Are you sure you want to delete this party?"
    secondaryButtonLabel="Not now"
    onSecondaryClick={close}
    primaryButtonLabel="Yes, delete"
    onPrimaryClick={handleDelete}
    onClickOutside={close}
  />;
};


export default DeleteEventModal;

