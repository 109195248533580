import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { ReactChild, ReactNode, RefObject, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import PlaceResult = google.maps.places.PlaceResult;

import { convertResultToAddress, convertResultToString } from 'Common/src/api/GooglePlacesApi';

import { TAppAddress } from 'TProtocol/common/models';
import GoogleAutocomplete = google.maps.places.Autocomplete;

const LIBRARIES: Libraries = ['places'];

declare const REACT_APP_API_KEY: string;

const AddressAutocomplete = ({ inputRef, onSelect, onSelectLatLon, children, validator, onInvalid, setPlace }: {
  inputRef: RefObject<HTMLInputElement>;
  onSelect?: (address: string, tAppAddress?: TAppAddress, place?: PlaceResult) => void;
  onSelectLatLon?: (lat: number, lon: number) => void;
  children: ReactChild & ReactNode;
  validator?: (place: PlaceResult) => boolean;
  onInvalid?: () => void;
  setPlace?: React.Dispatch<React.SetStateAction<PlaceResult>>;
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_API_KEY,
    libraries: LIBRARIES,
  });

  const [addrAutocomplete, setAddrAutocomplete] = useState<GoogleAutocomplete | null>(null);
  const onLoad = (autocomplete: GoogleAutocomplete) => {
    autocomplete.setFields(['formatted_address', 'address_components', 'name', 'geometry']);
    setAddrAutocomplete(autocomplete);
  };

  const onKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (onSelect && inputRef.current) {
        onSelect(inputRef.current.value);
      }
    }
  };

  useEffect(() => {
    // This is to handle the case where user hits enter instead of selecting from drop down.
    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', onKeydown);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', onKeydown);
      }
    }
  }, []);

  const onPlaceChanged = () => {
    if (addrAutocomplete === null) {
      return;
    }

    const place = addrAutocomplete.getPlace();
    const fullAddress = convertResultToString(place);

    if (inputRef.current) {
      if (validator !== undefined && !validator(place)) {
        if (onInvalid) {
          onInvalid();
        }
        return;
      }

      inputRef.current.value = fullAddress.trim();
      if (onSelect) {
        const address = convertResultToAddress(place);

        if (address) {
          onSelect(fullAddress.trim(), address, place);
        } else {
          onSelect(fullAddress.trim());
        }
      }
      if (onSelectLatLon) {
        if (place.geometry?.location?.lat() && place.geometry?.location?.lng()) {
          onSelectLatLon(place.geometry?.location?.lat(), place.geometry?.location?.lng());
        }
      }
      if (setPlace) {
        setPlace(place);
      }
    }
  };


  if (!isLoaded) {
    return <></>;
  } else {
    return <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      {children}
    </Autocomplete>;
  }


};

export const PacContainerWidthStyle = createGlobalStyle<{ $width: number }>`
  .pac-container {
    width: ${({ $width }) => $width}px !important;
  }
`

export default AddressAutocomplete;
