import React, { useState } from 'react';

import { useUserContext } from '../contexts/UserContext';
import { updateElkUser } from '../api/ElkEventService';
import Modal from './modals/Modal';

import { TAppElkTextPermissionStatus } from 'TProtocol/prototypes/events/messages';

const PhotosUserTextPermissionCheck = () => {
  const userContext = useUserContext();

  const [submitting, setSubmitting] = useState(false);

  if (userContext.name === undefined) {
    return null;
  }

  const save = async (accepted: boolean) => {
    const newUser = userContext.generateElkUser({
      name: userContext.name,
      photoUrl: userContext.photoUrl,
      grantedTextPermission: accepted,
      textPermissionStatus: accepted ? TAppElkTextPermissionStatus.GRANTED : TAppElkTextPermissionStatus.REJECTED
    });

    if (newUser) {
      setSubmitting(true);
      await updateElkUser(userContext.getAuthHeader(), newUser);

      setSubmitting(false);
      userContext.setGrantedTextPermissions(accepted);
      userContext.setAskForTextPermission(false);
    }
  };

  if (userContext.askForTextPermission) {
    return <Modal
      primaryButtonDisabled={submitting}
      title="Text permissions"
      subtitle="Please send me texts for invitations, updates responses and reminders. Message and data rates may apply. To opt-out, reply STOP."
      secondaryButtonLabel="No"
      onSecondaryClick={() => save(false)}
      primaryButtonLabel="Yes"
      onPrimaryClick={() => save(true)}
    />;
  } else {
    return null;
  }
};


export default PhotosUserTextPermissionCheck;

