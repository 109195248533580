import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { IUButterBarConfig, UButterBarLevel } from '../contexts/ButterBarContext';

import CloseIcon from 'Common/src/components/icons/CloseIcon';

const ButterBar = ({ config, onClose }: {
  config: IUButterBarConfig,
  onClose: (config: IUButterBarConfig) => void
}) => {
  let color: string;
  if (config.level === UButterBarLevel.ERROR) {
    color = '#b63330';
  } else if (config.level === UButterBarLevel.WARN) {
    color = '#d3c14e';
  } else {
    color = '#FFF'; // DARK_MODE
  }

  return <Bar $color={color} $hidden={config.hidden ?? false}>
    {config.contents}
    <Close onClick={() => onClose(config)}><CloseIcon/></Close>
  </Bar>;
};

const slideIn = keyframes`
  from {
    bottom: -60px
  }
  to {
    bottom: 0;
  }
`;

const slideOut = keyframes`
  from {
    bottom: 0
  }
  to {
    bottom: -60px;
  }
`;

const Bar = styled.div<{ $color: string, $hidden: boolean }>`
  position: fixed;
  bottom: 0;
  background-color: ${({ $color }) => $color};
  width: calc(100% - 20px);
  z-index: 500;
  color: #000; // DARK_MODE
  padding: 6px 12px;
  animation: ${({ $hidden }) => $hidden ? css`0.3s ${slideOut}` : css`0.3s ${slideIn}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Close = styled.div`
  font-size: 20px;
  cursor: pointer;
`;

export default ButterBar;
