import React from 'react';
import styled from 'styled-components';

const Sparkles = ({ color = 'currentColor', size = 24 }: {
  color?: string,
  size?: number
}) => {
  return <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M30 15C31.1162 15 32.0972 15.7399 32.4038 16.8132L35.1145 26.3005C36.3009 30.4531 39.5469 33.6991 43.6995 34.8855L53.1868 37.5962C54.2601 37.9028 55 38.8838 55 40C55 41.1162 54.2601 42.0972 53.1868 42.4038L43.6995 45.1145C39.5469 46.3009 36.3009 49.5469 35.1145 53.6995L32.4038 63.1868C32.0972 64.2601 31.1162 65 30 65C28.8838 65 27.9028 64.2601 27.5962 63.1868L24.8855 53.6995C23.6991 49.5469 20.4531 46.3009 16.3005 45.1145L6.8132 42.4038C5.73994 42.0972 5 41.1162 5 40C5 38.8838 5.73994 37.9028 6.8132 37.5962L16.3005 34.8855C20.4531 33.6991 23.6991 30.4531 24.8855 26.3005L27.5962 16.8132C27.9028 15.7399 28.8838 15 30 15Z"
          fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M60 5C61.1472 5 62.1471 5.78074 62.4254 6.89366L63.2882 10.3452C64.072 13.4802 66.5198 15.928 69.6548 16.7118L73.1063 17.5746C74.2193 17.8529 75 18.8528 75 20C75 21.1472 74.2193 22.1471 73.1063 22.4254L69.6548 23.2882C66.5198 24.072 64.072 26.5198 63.2882 29.6548L62.4254 33.1063C62.1471 34.2193 61.1472 35 60 35C58.8528 35 57.8529 34.2193 57.5746 33.1063L56.7118 29.6548C55.928 26.5198 53.4802 24.072 50.3452 23.2882L46.8937 22.4254C45.7807 22.1471 45 21.1472 45 20C45 18.8528 45.7807 17.8529 46.8937 17.5746L50.3452 16.7118C53.4802 15.928 55.928 13.4802 56.7118 10.3452L57.5746 6.89366C57.8529 5.78074 58.8528 5 60 5Z"
          fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M55 50C56.0761 50 57.0314 50.6886 57.3717 51.7094L58.6859 55.6519C59.1835 57.1449 60.3551 58.3165 61.8481 58.8141L65.7906 60.1283C66.8114 60.4686 67.5 61.4239 67.5 62.5C67.5 63.5761 66.8114 64.5314 65.7906 64.8717L61.8481 66.1859C60.3551 66.6835 59.1835 67.8551 58.6859 69.3481L57.3717 73.2906C57.0314 74.3114 56.0761 75 55 75C53.9239 75 52.9686 74.3114 52.6283 73.2906L51.3141 69.3481C50.8165 67.8551 49.6449 66.6835 48.1519 66.1859L44.2094 64.8717C43.1886 64.5314 42.5 63.5761 42.5 62.5C42.5 61.4239 43.1886 60.4686 44.2094 60.1283L48.1519 58.8141C49.6449 58.3165 50.8165 57.1449 51.3141 55.6519L52.6283 51.7094C52.9686 50.6886 53.9239 50 55 50Z"
          fill={color}/>
  </svg>
    ;
};

export default Sparkles;
