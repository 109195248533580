import styled from 'styled-components';
import { WhiteButton } from '../../components/buttons/WhiteButton';

export const LoginInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 12px;
  padding: 0 24px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

export const LoginInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 6px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

export const Spacer = styled.div`
  height: 100px;
  width: 100%;
`;

export const LoginInputHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
`;

export const LoginInputHeaderTitle = styled.div<{
  $isInvalid?: boolean
}>`
  font-size: 14px;
  font-weight: 500;
  ${({ $isInvalid }) => $isInvalid ? 'color: #B3251E;' : 'opacity: 40%;'}
`;

export const LoginButton = styled.button<{
  $hasEventBackground?: boolean
}>`
  font-family: Plus Jakarta Sans, sans-serif;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: auto;
  padding: ${({ $hasEventBackground }) => $hasEventBackground ? '7px 20px;' : '6px 10px;'};
  border-radius: 100px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

