import { DeviceQueries, VerticalFullContainer } from 'Common/src/components/styled';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const FramedPage = ({ children }: PropsWithChildren<object>) => {
  return <Page>
    {children}
  </Page>;
};

const Page = styled(VerticalFullContainer)`
  width: calc(100vw - 40px);
  max-width: 1000px;
  margin: 0 128px;
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  background: rgba(var(--sp-bg-rgb), 0.90);
  color: var(--sp-text-color);

  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari

  @media (${DeviceQueries.mobile}) {
    width: calc(100vw - 40px);
    margin: 0;
  }
`;

export const FrameHeader = styled.div`
  display: flex;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const FrameBody = styled(VerticalFullContainer)``;

export const FrameFooter = styled.div``;

export const FrameHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
`;

export const FrameHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export default FramedPage;
