import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import { ColorScheme, useBackgroundContext } from '../contexts/BackgroundContext';
import BasePageHeader, { UPageType } from './BasePageHeader';
import { BasePageContent } from './BasePage';
import SparkleButton from '../components/buttons/SparkleButton';
import { useEditEventContext } from '../contexts/EditEventContext';
import { InspirationMainCategory, InspirationTheme } from '../lib/inspiration';
import { DesktopShow } from '../components/styled/Common';
import { getUpcoming } from '../components/seasonal/sampleEvents';
import SeasonalInvite from '../components/seasonal/SeasonalInvite';
import { firebaseAnalytics } from '../core/libs/Firebase';
import { useUserContext } from '../contexts/UserContext';
import PillRow from './Welcome/PillRow';
import WelcomeFooter from './Welcome/WelcomeFooter';
import Slideshow from '../components/Slideshow';
import { ContentContainer, HeadlineMainText, HeadlineRow, PromoTitle } from './WelcomePage';
import PhotosAppPromo from './Welcome/PhotosAppPromo';
import GalleryRows from './Welcome/GalleryRows';

import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

const WelcomePageShine = () => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const backgroundContext = useBackgroundContext();
  const editEventContext = useEditEventContext();

  const [openMainCategory, setOpenMainCategory] = useState<InspirationMainCategory | undefined>();

  useEffect(() => {
    document.title = 'Shine Parties';
    logEvent(firebaseAnalytics, 'page_view', {
      page_title: 'Welcome - Analytics'
    });
    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
  }, []);

  const onContentLeave = () => {
    setOpenMainCategory(undefined);
  };

  const onCreateClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[WelcomePage] Clicked on create invite button'
    });

    editEventContext.clearEvent();
    navigate('/instant');
  };

  const viewGallery = (theme?: InspirationTheme) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[WelcomePage] Clicked on view gallery pill'
    });

    navigate('/instant', {
      state: {
        theme
      }
    });
  };

  const slideshowEvents = getUpcoming().map(event => {
    return {
      content: <SeasonalInvite key={event.event.id} event={event.event} theme={event.theme}/>,
      delay: 5000
    };
  });

  const photosAppPromo = {
    content: <PhotosAppPromo dark={true}>
      <PromoTitle>Easy photo sharing<DesktopShow><br/></DesktopShow> for your party</PromoTitle>
      <PromoSubtitle>Try the Shine photo sharing app!</PromoSubtitle>
    </PhotosAppPromo>,
    delay: 5000
  };

  const slideshowData = [...slideshowEvents, photosAppPromo];

  return <>
    <BasePageHeader page={UPageType.Welcome}/>
    <BasePageContent $wide={true} $flush={true} $full={true}>
      <ContentContainer>
        <HeadlineRow>
          <HeadlineTextContainer>
            <HeadlineMainText>Fun AI Party Invites</HeadlineMainText>
          </HeadlineTextContainer>
          <SparkleButtonContainer>
            <SparkleButton buttonText="Create your invite" disabled={false} onClick={onCreateClick}
                           buttonColor={'var(--sp-text-color)'} textColor={'var(--sp-bg-color)'}/>
          </SparkleButtonContainer>
        </HeadlineRow>
        <PillRow openMainCategory={openMainCategory} setOpenMainCategory={setOpenMainCategory} onThemeClick={viewGallery} onMouseLeave={onContentLeave}/>
        <Slideshow slidesData={slideshowData}/>
        <GalleryRows/>
      </ContentContainer>
      <WelcomeFooter isRootDomain={false}/>
    </BasePageContent>
  </>;
};

const HeadlineTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (${DeviceQueries.mobile}) {
    gap: 8px;
  }
`;

const SparkleButtonContainer = styled.span`
  display: inline-flex;
  margin: 10px 0;
  flex-direction: column;
  gap: 10px;
`;


const PromoSubtitle = styled.div`
  color: var(--sp-text-color);
  font-size: 40px;
  font-weight: 400;

  @media (${DeviceQueries.mobile}) {
    font-size: 20px;
    line-height: 32px;
  }
`;

export default WelcomePageShine;

