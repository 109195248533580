import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ChevronDown from '../../../elk-web/src/components/icons/ChevronDown';
import Checkmark from '../../../elk-web/src/components/icons/Checkmark';

interface Props<T> {
  values: DropdownButtonItem<T>[];
  defaultText?: ReactNode;
  disabled?: boolean;
  onChange: (value: T) => void;
  value: T;
}

export interface DropdownButtonItem<T> {
  id: T;
  label: string;
  textColor?: string;
  buttonColor?: string;
  buttonTextColor?: string;
}

const DropdownButton = <T,>({ values, defaultText, disabled, onChange, value }: Props<T>) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (menuDivRef.current && !menuDivRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);


  const onDropdownClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMenuClick = (id: T) => {
    setShowMenu(false);
    onChange(id);
  };

  const selectedValue = values.find((v) => v.id === value);
  const buttonText = selectedValue?.label ?? defaultText;

  return <DropdownButtonContainer>
    <ButtonContainer>
      <DropdownButtonButton
        disabled={disabled}
        onClick={onDropdownClick}
        $bgColor={selectedValue?.buttonColor}
        $textColor={selectedValue?.buttonTextColor ?? selectedValue?.textColor}>
        <ContentContainer>
          {buttonText}
        </ContentContainer>
        <ChevronDown size={18} color={selectedValue?.buttonColor !== undefined ? 'currentColor' : '#000'}/>
      </DropdownButtonButton>
    </ButtonContainer>
    <DropdownMenuAnchor>
      {showMenu && <DropdownMenu ref={menuDivRef}>
        {
          values.map((v) => <MenuItem
            key={`${v.id}`}
            onClick={() => handleMenuClick(v.id)}
            $color={v.textColor}
          >
            {v.label}
            {v.id === value ? <Checkmark color="#000" size={14}/> : null}
          </MenuItem>)
        }
      </DropdownMenu>}
    </DropdownMenuAnchor>
  </DropdownButtonContainer>;
};

const DropdownButtonContainer = styled.div`
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  justify-content: center;
`;

const DropdownButtonButton = styled.button<{$bgColor?: string, $textColor?: string}>`
  display: flex;
  height: 29px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${({$textColor}) => $textColor ?? '#000'};
  background: ${({$bgColor}) => $bgColor ?? '#FFF'};
  border: 0;  
  border-radius: 16px;

  /* Shadows/Medium */
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
`;

const ContentContainer = styled.span`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const DropdownMenuAnchor = styled.div`
  height: 0;
  position: relative;
  z-index: 200;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  color: black;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); // Safari
  width: 100%;
  max-height: 225px;
  overflow-y: scroll;

  border-radius: 10px;
  background: #FEFEFE;

  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.12), 0 15px 35px 0 rgba(103, 110, 118, 0.08);
  cursor: pointer;
`;

const MenuItem = styled.div<{$color?: string}>`
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({$color}) => $color ?? '#000'};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default DropdownButton;
