import React from 'react';

const TechCrunchLogo = ({ size = 42, color = 'currentColor' }: { size?: number, color?: string }) => {
  return <svg width={size * 2.02} height={size} viewBox="0 0 85 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9631_7799)">
      <path d="M42.2734 0V14H28.2734V42H14.2734V14H0.273438V0H42.2734Z" fill={color}/>
      <path d="M84.2734 0H56.2734V14H84.2734V0Z" fill={color}/>
      <path d="M84.2734 28V42H42.2734V14H56.2734V28H84.2734Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_9631_7799">
        <rect width="84" height="42" fill="white" transform="translate(0.273438)"/>
      </clipPath>
    </defs>
  </svg>;
};

export default TechCrunchLogo;
