import React, { PropsWithChildren, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';

type Size = 'small' | 'medium' | 'large';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: RefObject<HTMLInputElement>;
  $size?: Size;
  id: string;
  onInputChange: (value: boolean) => void;
  $labelFontSize?: string;
  $dark?: boolean;
}

const Checkbox = (props: PropsWithChildren<Props>) => {
  const { inputRef, id, checked, onInputChange, $dark = false, children } = props;
  const defaultChecked = checked ?? false;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const inputProps: Partial<Props> = { ...props };
  delete inputProps.children;
  delete inputProps.onInputChange;

  useEffect(() => {
    setIsChecked(checked ?? false);
  }, [checked]);

  const onClick = () => {
    setIsChecked(!isChecked);
    onInputChange(!isChecked);
  };

  return <div>
    <CheckboxInput
      type="checkbox"
      ref={inputRef}
      {...inputProps}
      onChange={onClick}
      checked={isChecked}
      $dark={$dark}
    />
    <label htmlFor={id}>
      <InnerLabel>{children}</InnerLabel>
    </label>
  </div>;
};

const calculateSize = (size: Size) => {
  if (size === 'small') {
    return 16;
  } else if (size === 'medium') {
    return 20;
  } else {
    return 24;
  }
};

const CommonCheckbox = styled.input<{
  $labelFontSize?: string;
  $dark?: boolean;
}>`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  & + label {
    position: relative;
    font-size: ${({ $labelFontSize }) => $labelFontSize ?? '14px'};
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    color: currentColor;
  }

  & + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    border-radius: 4px;
    background: ${({$dark}) => $dark ? '#000' : '#FFF'};
    ${({ $dark }) => $dark && 'border: 1px solid #FFF;'}

    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:disabled + label::before {
    background-color: ${({$dark}) => $dark ? '#303030' : '#E5E7EA'};
    ${({ $dark }) => $dark && 'border: 1px solid #999;'}
  }

  &:checked + label::before, &:indeterminate + label::before {
    background-color: ${({$dark}) => $dark ? 'white' : 'black'};
  }

  &:checked:disabled + label::before, &:indeterminate:disabled + label::before {
    background-color: ${({$dark}) => $dark ? '#666' : '#F1F0F0'};
  }

  &:focus + label::before {
    box-shadow: 0 0 0 4px rgba(252, 101, 16, 0.16), 0 2px 5px 0 rgba(252, 101, 16, 0.08), 0 0 0 1px rgba(252, 101, 16, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:indeterminate:focus + label::before {
    box-shadow: 0 0 0 4px rgba(103, 110, 118, 0.16), 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.64), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover:not(:disabled) + label::before {
    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.64), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover:checked:not(:disabled) + label::before, &:hover:indeterminate:not(:disabled) + label::before {
    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.24), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:checked + label::after, &:indeterminate + label::after {
    content: " ";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    text-align: center;
    background-color: transparent;
    font-size: 10px;
    margin: 3px 3px 4px 2px;
    background-size: cover;
  }
`;

const CheckboxInput = styled(CommonCheckbox)<{
  $size?: Size;
  $labelFontSize?: string;
  $dark: boolean;
}>`
  & + label::before {
    min-width: ${({ $size = 'medium' }) => calculateSize($size)}px;
    height: ${({ $size = 'medium' }) => calculateSize($size)}px;
  }

  &:checked + label::after {
    min-width: ${({ $size = 'medium' }) => calculateSize($size) - 4}px;
    height: ${({ $size = 'medium' }) => calculateSize($size) - 4}px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" height="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" viewBox="0 0 20 20" fill="none"><path d="M3.75 10.625L8.75 15.625L16.25 4.375" stroke="${({ $dark }) => $dark ? 'black': 'white' }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  &:checked:disabled + label::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" height="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" viewBox="0 0 20 20" fill="none"><path d="M3.75 10.625L8.75 15.625L16.25 4.375" stroke="${({ $dark }) => $dark ? 'white': 'black' }" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  &:indeterminate + label::after {
    min-width: ${({ $size = 'medium' }) => calculateSize($size) - 4}px;
    height: ${({ $size = 'medium' }) => calculateSize($size) - 4}px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" height="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" viewBox="0 0 20 20" fill="none"><path d="M15 10L5 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  &:indeterminate:disabled + label::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" height="${({ $size = 'medium' }) => calculateSize(
            $size) - 4}" viewBox="0 0 20 20" fill="none"><path d="M15 10L5 10" stroke="%23CED2D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}`;

const InnerLabel = styled.span`
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CheckboxReminderText = styled.div`
  font-size: 12px;
`;

export default Checkbox;
