import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import EventFormInput, { InputType } from '../EventForm/EventFormInput';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import { stob64 } from '../../util/stringutils';
import AdditionalGuestsPicker from '../rsvp/AdditionalGuestPicker';
import NotificationPreferenceSelector from '../rsvp/NotificationPreferenceSelector';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import { stripPlusOne } from '../../lib/phone';
import { IUEvent, IUInvitee } from '../../lib/event';
import EditableAnswer from '../questions/EditableAnswer';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import { DesktopShow, MobileShow } from '../styled/Common';
import ColorButton from '../buttons/ColorButton';
import { findCurrentInvitee } from '../../util/attendee';
import PhoneInput from '../SingleFieldForm/PhoneInput';
import { ExternalLabel } from '../forms/Input';
import { ErrorContainer, LoginInput } from '../login/SinglePhoneEmailInput';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { validateEmail } from '../../util/email';
import { TallerWhiteInput } from '../forms/WhiteInput';
import ErrorIcon from '../icons/ErrorIcon';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeStatus,
  TAppElkEventAnswer,
  TAppElkNotificationPreference,
  TAppElkPhotoUploadMode
} from 'TProtocol/prototypes/events/messages';
import { WideWhiteButton } from '../buttons/WhiteButton';
import { WideOutlineButton } from '../buttons/OutlineButton';

const ChangeRSVPModal = ({ close, event, inviteeInput, eventId, clickedYes, clickedChangeResponse }: {
  close: () => void,
  event: IUEvent | undefined,
  inviteeInput?: IUInvitee | undefined,
  eventId: string | undefined,
  clickedYes?: boolean,
  clickedChangeResponse?: boolean
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const { setQuestionResponse, getPhone, getEmail, getToken, deleteToken } = useInviteeUuidStorage();
  const formRef = useRef<HTMLFormElement>(null);

  const [invitee, setInvitee] = useState<IUInvitee | undefined>(inviteeInput);
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [questionRequiredErrors, setQuestionRequiredErrors] = useState(new Set<number>);

  const [buttonText, setButtonText] = useState<string>('Confirm');
  const [uploadMode] = useState(event?.albumDetails?.photoUploadMode ?? userContext.defaultUploadMode);

  const [message, setMessage] = useState<string>(invitee?.rsvpMessage ?? '');
  const [hasChanged, setHasChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const isYesSelected = invitee?.rsvpStatus === TAppElkAttendeeStatus.YES;
  const isNoSelected = invitee?.rsvpStatus === TAppElkAttendeeStatus.NO;

  const { getInviteeUuid } = useInviteeUuidStorage();

  useEffect(() => {
    if (userContext.isLoggedIn()) {
      const phone = userContext.getPhoneNumber();

      if (phone) {
        setPhoneNum(stripPlusOne(phone));
      }
    }

  }, [userContext.phones]);

  useEffect(() => {
    const phone = getPhone();
    const email = getEmail();
    const currInvitee = findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
      userContext.phones ?? (phone ? [phone] : undefined), userContext.emails ?? (email ? [email] : undefined));

    if (!currInvitee.phone || currInvitee.phone.trim() === '') {
      currInvitee.phone = getPhone() ?? userContext.getPhoneNumber();
    }
    if (!currInvitee.email || currInvitee.email.trim() === '') {
      currInvitee.email = getEmail() ?? userContext.getEmail();
    }

    setInvitee(new IUInvitee(currInvitee));
    setHasChanged(true);
    setMessage(currInvitee.rsvpMessage ?? '');
    setPhoneNum(stripPlusOne(currInvitee.phone ?? userContext.getPhoneNumber() ?? ''));
  }, []);

  useEffect(() => {
    if (isYesSelected) {
      setButtonText('Accept');
    } else if (isNoSelected) {
      setButtonText('Decline');
    } else {
      setButtonText('Update');
    }
  }, [invitee]);

  if (invitee === undefined) {
    return null;
  }

  const additionalGuestCount = invitee.additionalGuestCount;

  const handleImIn = async () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ChangeRSVPModal] Handle Im in'
    });
    const numGuests = additionalGuestCount;
    let messageSlot: string;
    if (message === '') {
      messageSlot = 'm';
    } else {
      messageSlot = message;
    }

    const encodedMessage = stob64(messageSlot);
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedPhone = stob64(invitee.phone ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    //TODO: ensure title is present in all cases
    const encodedTitle = stob64(event?.title ?? '');
    const uploadModeString = uploadMode === TAppElkPhotoUploadMode.MANUAL ? 'manual' : uploadMode === TAppElkPhotoUploadMode.AUTOMATIC ? 'magic' : '';
    if (!userContext.isLoggedIn()) {
      if (getToken(eventId) !== undefined) {
        navigate(
          `/event/updateemail/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      } else {
        navigate(
          `/event/unauthrsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      }
    } else {
      navigate(
        `/event/rsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&pu=${uploadModeString}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: true
          }
        });
    }
  };

  const handleImOut = async () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ChangeRSVPModal] Handle Im out'
    });
    const encodedMessage = stob64(message);
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedPhone = stob64(invitee.phone ?? '');
    const encodedTitle = stob64(event?.title ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    if (!userContext.isLoggedIn()) {
      if (getToken(eventId) !== undefined) {
        navigate(
          `/event/updateemail/action/${eventId}?r=no&em=${encodedMessage}&et=${encodedTitle}&en=${encodedName}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}&iu=${invitee.inviteeId}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      } else {
        navigate(
          `/event/unauthrsvp/action/${eventId}?r=no&em=${encodedMessage}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      }
    } else {
      navigate(
        `/event/rsvp/action/${eventId}?r=no&em=${encodedMessage}&ng=${additionalGuestCount}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: true
          }
        });
    }

  };

  const onModalClick = (e: MouseEvent) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ChangeRSVPModal] modal click'
    });
    e.stopPropagation();
  };

  const onMessageChange = (message: string) => {
    setMessage(message);
    invitee.rsvpMessage = message;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onPhoneChange = (phoneNumber: string, fullPhone: string) => {
    setPhoneNum(phoneNumber);
    invitee.phone = fullPhone;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidPhone(false);
    eventId && deleteToken(eventId);
  };

  const onEmailChange = (emailAddress: string) => {
    invitee.email = emailAddress;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidEmail(false);
    eventId && deleteToken(eventId);
  };

  const onNotificationPreferenceChange = (preference: TAppElkNotificationPreference) => {
    invitee.notificationPreference = preference;
    if (preference === TAppElkNotificationPreference.PHONE) {
      invitee.email = undefined;
    } else if (preference === TAppElkNotificationPreference.EMAIL) {
      invitee.phone = undefined;
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onAnswerChange = (invitee: IUInvitee, answer: TAppElkEventAnswer) => {
    setQuestionResponse(eventId ?? '', answer);
    const existingAnswer = invitee.answers?.find((ans) => ans.questionUuid === answer.questionUuid);
    if (existingAnswer) {
      invitee.answers = invitee.answers?.map((ans) =>
        ans.questionUuid == answer.questionUuid ? ans = answer : ans
      );
    } else {
      let concatenatedAnswers;
      if (invitee.answers) {
        // Use the spread operator to concatenate arrays
        concatenatedAnswers = [...invitee.answers, answer];
      } else {
        concatenatedAnswers = [answer];
      }
      invitee.answers = concatenatedAnswers;
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const handleYesClick = () => {
    if (invitee.rsvpStatus === TAppElkAttendeeStatus.NO) {
      onMessageChange('');
    }
    invitee.rsvpStatus = TAppElkAttendeeStatus.YES;
    setInvitee(new IUInvitee(invitee));
    setButtonText('Confirm');
    setHasChanged(true);
  };

  const handleNoClick = () => {
    if (invitee.rsvpStatus === TAppElkAttendeeStatus.YES) {
      onMessageChange('');
    }
    invitee.rsvpStatus = TAppElkAttendeeStatus.NO;
    invitee.additionalGuestCount = 0;
    setMessage('');
    setButtonText('Decline');
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const validateForm = () => {
    const invalidPhone = !isPossiblePhoneNumber(invitee.phone ?? '');
    const invalidEmail = !validateEmail(invitee.email);

    const isNameInvalid = invitee.name === undefined || invitee.name.trim() === '';
    setInvalidName(isNameInvalid);

    if (isNameInvalid) {
      // TODO: remove logs
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: `[ChangeRSVPModal] Invalid name: ${invitee.name}`
      });
      return false;
    }

    for (let i = 0; i < (event?.questions.length ?? 0); i++) {
      const existingAnswer = invitee.answers?.find((ans) => ans.questionUuid === event?.questions[i].questionUuid);

      if (event?.questions[i].isRequired && (existingAnswer?.answerValue === undefined || existingAnswer?.answerValue?.text === '')) {
        questionRequiredErrors.add(i);
      } else {
        questionRequiredErrors.delete(i);
      }
    }
    setQuestionRequiredErrors(new Set(questionRequiredErrors));

    switch (invitee.notificationPreference) {
      case TAppElkNotificationPreference.EMAIL:
        setInvalidPhone(false);
        setInvalidEmail(invalidEmail);
        return !invalidEmail;
      case TAppElkNotificationPreference.PHONE:
        setInvalidPhone(invalidPhone);
        setInvalidEmail(false);
        return !invalidPhone;
      case TAppElkNotificationPreference.BOTH:
        setInvalidPhone(invalidPhone);
        setInvalidEmail(invalidEmail);
        return !invalidEmail && !invalidPhone;
      default:
        return true;
    }
  };

  const onConfirmClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[ChangeRSVPModal] On confirm click: changed: ${clickedChangeResponse}, clickedYes=${clickedYes}, isNoSelected=${isNoSelected}`
    });
    if ((clickedChangeResponse && isNoSelected) || (!clickedChangeResponse && !clickedYes)) {
      void handleImOut();
      return;
    }

    const validForm = validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[ChangeRSVPModal] Invalid form'
      });
      return;
    }

    void handleImIn();
    // updateTextPermission();
  };

  const setGuestCount = (count: number) => {
    invitee.additionalGuestCount = count;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);

  };

  const onNameChange = (name: string) => {
    invitee.name = name;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidName(false);
  };

  const onClose = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ChangeRSVPModal] Closed modal'
    });

    close();
  };

  const modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
    <ModalHeader>
      <BackArrowContainer onClick={onClose}>
        <BackArrow/>
      </BackArrowContainer>
      <ModalTitle>Confirm your RSVP</ModalTitle>
    </ModalHeader>
    {clickedChangeResponse
      ? <ChangeResponseContainer>
        <div>Can you attend?</div>
        <div>
          <InputLabel htmlFor="attending">
            <input type="radio" name="rsvp" id="attending"
                   checked={isYesSelected}
                   onChange={handleYesClick}
            />
            Yes
          </InputLabel>
          <InputLabel htmlFor="declining">
            <input type="radio" name="rsvp" id="declining"
                   checked={isNoSelected}
                   onChange={handleNoClick}
            />
            No
          </InputLabel>
        </div>
      </ChangeResponseContainer>
      : <></>}

    <GuestCountDetails>
      <LoginInput $includeMargin={true}>
        <ExternalLabel>Who will {isNoSelected ? 'not ' : ''}be attending?</ExternalLabel>
        <TallerWhiteInput
          autoFocus={false}
          placeholder="Taylor Swift"
          $isInvalid={invalidName}
          disabled={false}
          value={invitee.name ?? ''}
          onChange={(e) => onNameChange(e.currentTarget.value)}
        />
        {invalidName && <ErrorContainer><ErrorIcon/><span>Please provide a valid name.</span></ErrorContainer>}
      </LoginInput>
      {event?.openInvite !== false && (clickedYes || (clickedChangeResponse && isYesSelected)) ?
        <AdditionalGuestsPicker additionalGuestCount={additionalGuestCount}
                                setGuestCount={setGuestCount}/> : <></>}
    </GuestCountDetails>

    {(clickedYes || (clickedChangeResponse && isYesSelected)) && event?.communicationPreference === 3 ? <NotificationPreferenceSelector
      value={invitee.notificationPreference ?? event?.communicationPreference}
      onChange={onNotificationPreferenceChange}
    /> : <></>}

    {
      ((clickedYes || (clickedChangeResponse && isYesSelected)) && ((invitee.notificationPreference === undefined && clickedYes) || invitee.notificationPreference === TAppElkNotificationPreference.BOTH || invitee.notificationPreference === TAppElkNotificationPreference.PHONE)) ?
        <LoginInput $includeMargin={true}>
          <ExternalLabel>What&apos;s your phone number? (required)</ExternalLabel>
          <PhoneInput
            autoFocus={false}
            placeholder="(123) 456-7890"
            isInvalid={invalidPhone}
            autoComplete="tel"
            value={phoneNum}
            required={true}
            onChange={onPhoneChange}
            disabled={false}
            taller={true}
          />
          {invalidPhone &&
              <ErrorContainer><ErrorIcon/><span>Please provide a valid phone number.</span></ErrorContainer>}
        </LoginInput>
        : <></>
    }
    {
      ((clickedYes || (clickedChangeResponse && isYesSelected)) && invitee.notificationPreference === TAppElkNotificationPreference.EMAIL || invitee.notificationPreference === TAppElkNotificationPreference.BOTH) ?
        <LoginInput $includeMargin={true}>
          <ExternalLabel>What&apos;s your email? (required)</ExternalLabel>
          <TallerWhiteInput
            autoComplete="email"
            autoFocus={false}
            placeholder="stella@sunshine.com"
            $isInvalid={invalidEmail}
            disabled={false}
            value={invitee.email}
            onChange={(e) => onEmailChange(e.currentTarget.value)}
          />
          {invalidEmail && <ErrorContainer><ErrorIcon/><span>Please provide a valid email.</span></ErrorContainer>}
        </LoginInput> : <></>
    }

    <EventFormInput
      placeholder="Type message here"
      event={event}
      label="Message to the host"
      value={message}
      maxLength={1024}
      onChange={(value) => onMessageChange(value)}
      inputType={InputType.Description}
      optionNumber={0}
      isLabelOutside={true}
      excludeBackground={true}
      inModal={true}
    />

    {(clickedYes || (clickedChangeResponse && isYesSelected)) ? event?.questions.map(
      (question, index) => <EditableAnswer
        question={question}
        invitee={invitee}
        key={question.questionUuid}
        onAnswerChange={onAnswerChange}
        showRequired={questionRequiredErrors.has(index)}
      />) : <></>}
    <ButtonFooter>
      <WideWhiteButton $invert={true} type="button" onClick={onConfirmClick}
                   disabled={submitting || !hasChanged}>{buttonText}</WideWhiteButton>
      {clickedChangeResponse && <WideOutlineButton type="button" onClick={onClose}>Cancel</WideOutlineButton>}
    </ButtonFooter>
  </RSVPForm>;

  return (
    <>
      <DesktopShow>
        <Overlay onMouseDown={onClose}>
          <ModalContainer onMouseDown={onModalClick}>
            {modalContent}
          </ModalContainer>
        </Overlay>
      </DesktopShow>
      <MobileShow>
        <TransparentOverlayBottomModal onClick={onClose}>
          <BottomModalContainer onClick={onModalClick} $noOverflowScroll={true}>
            {modalContent}
          </BottomModalContainer>
        </TransparentOverlayBottomModal>
      </MobileShow>
    </>
  );
};

const RSVPForm = styled.form`
  margin: 5px;
  width: calc(100% - 10px);
`;

const InputLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  margin-right: 30px;
`;

const ChangeResponseContainer = styled.div`
  margin: 20px 0;
`;

const GuestCountDetails = styled.div`
  position: relative;
`;

const ButtonFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default ChangeRSVPModal;
