import React from 'react';

const TextBlast = ({ size = 25, color = 'currentColor' }: { size?: number, color?: string }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path
      d="M10.8404 16.1132C10.1515 16.0538 9.45431 16.0234 8.75 16.0234H8C5.51472 16.0234 3.5 14.0087 3.5 11.5234C3.5 9.03815 5.51472 7.02344 8 7.02344H8.75C9.45431 7.02344 10.1515 6.9931 10.8404 6.93366M10.8404 16.1132C11.0933 17.0749 11.4237 18.0052 11.8246 18.8969C12.0721 19.4473 11.8842 20.1062 11.3616 20.4079L10.7053 20.7869C10.1539 21.1052 9.4456 20.904 9.17841 20.3261C8.5518 18.9708 8.06541 17.5373 7.73786 16.0444M10.8404 16.1132C10.4552 14.6479 10.25 13.1096 10.25 11.5234C10.25 9.93722 10.4552 8.39894 10.8404 6.93366M10.8404 16.1132C14 16.3858 16.9845 17.2706 19.6747 18.6484M10.8404 6.93366C14 6.66104 16.9845 5.77627 19.6747 4.39852M19.6747 4.39852C19.557 4.01938 19.4302 3.64427 19.2944 3.27344M19.6747 4.39852C20.2097 6.12171 20.5557 7.92806 20.6886 9.79335M19.6747 18.6484C19.557 19.0275 19.4302 19.4026 19.2944 19.7734M19.6747 18.6484C20.2097 16.9252 20.5557 15.1188 20.6886 13.2535M20.6886 9.79335C21.1844 10.2061 21.5 10.8279 21.5 11.5234C21.5 12.219 21.1844 12.8408 20.6886 13.2535M20.6886 9.79335C20.7293 10.3647 20.75 10.9417 20.75 11.5234C20.75 12.1052 20.7293 12.6821 20.6886 13.2535"
      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default TextBlast;
