import React from 'react';
import styled from 'styled-components';

import TheInformationLogo from '../../components/logos/TheInformationLogo';
import WiredLogo from '../../components/logos/WiredLogo';
import TechCrunchLogo from '../../components/logos/TechCrunchLogo';
import FastCompanyLogo from '../../components/logos/FastCompanyLogo';
import Facebook from '../../components/icons/social/Facebook';
import Instagram from '../../components/icons/social/Instagram';
import Twitter from '../../components/icons/social/Twitter';
import LinkedIn from '../../components/icons/social/LinkedIn';
import { DeviceQueries } from 'Common/src/components/styled';

const WelcomeFooter = ({ isRootDomain }: { isRootDomain: boolean }) => {
  return <>
    <Banner>
      <AsSeenIn>
        As seen in
      </AsSeenIn>
      <a href="https://www.theinformation.com/articles/can-marissa-mayer-eclipse-herself" target="_blank" rel="noreferrer">
        <TheInformationLogo color="#000"/>
      </a>
      <a href="https://www.wired.com/story/big-interview-marissa-mayer-yahoo-sunshine-ai/" target="_blank" rel="noreferrer">
        <WiredLogo color="#000"/>
      </a>
      <a href="https://techcrunch.com/2020/11/18/marissa-mayers-startup-launches-its-first-official-product-sunshine-contacts/" target="_blank" rel="noreferrer">
        <TechCrunchLogo color="#000"/>
      </a>
      <a href="https://www.fastcompany.com/90576544/marissa-mayer-enrique-munoz-torres-sunshine-lumi-labs" target="_blank" rel="noreferrer">
        <FastCompanyLogo color="#000"/>
      </a>
    </Banner>
    <Footer>
      <Icons>
        <a href="https://www.facebook.com/LetYourSunshineIn" target="_blank" rel="noreferrer"><Facebook/></a>
        <a href="https://www.instagram.com/brightsunshiningday/" target="_blank" rel="noreferrer"><Instagram/></a>
        <a href="https://x.com/Sunshine" target="_blank" rel="noreferrer"><Twitter/></a>
        <a href="https://www.linkedin.com/company/sunshineinc/" target="_blank" rel="noreferrer"><LinkedIn/></a>
      </Icons>
      <form
        action="https://sunshine.us20.list-manage.com/subscribe/post?u=8fd6ceee6c3e2feb4e654986f&amp;id=b8227784b0&amp;f_id=005767e6f0"
        method="post" target="_blank">
        <SubscribeContainer>
          <SubscribeInput placeholder="Sign up for our newsletter" type="email" name="EMAIL" required={true}/>
          <input type="hidden" name="b_8fd6ceee6c3e2feb4e654986f_b8227784b0" value=""/>
          <SubmitButton>Subscribe</SubmitButton>
        </SubscribeContainer>
      </form>
      <Links>
        <a href="https://about.sunshine.com/">About</a>
        <a href="https://about.sunshine.com/careers/">Career</a>
        <a href="https://about.sunshine.com/let-the-sunshine-in/">Blog</a>
        {
          isRootDomain ? null : <>
            <a href="https://about.sunshine.com/customer-support/">Support</a>
            <a href="https://about.sunshine.com/privacy-policy-3/">Privacy</a>
            <a href="https://about.sunshine.com/app-terms/">Terms</a>
          </>
        }
      </Links>
    </Footer>
    {
      isRootDomain ? <SmallLinks>
          <a href="https://about.sunshine.com/customer-support/">Support</a>
          <a href="https://about.sunshine.com/privacy-policy-3/">Privacy</a>
          <a href="https://about.sunshine.com/app-terms/">Terms</a>
      </SmallLinks> : null
    }
  </>;
};

const Banner = styled.div`
  width: 100svw;
  background: #FFFCF0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 40px 100px;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 30px;

  @media (${DeviceQueries.mobile}) {
    padding: 40px 16px;
  }
`;

const AsSeenIn = styled.div`
  color: #181726;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.36px;
  flex-wrap: wrap;
`;

const Footer = styled.div`
  width: 100svw;
  padding: 35px 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  @media (${DeviceQueries.mobile}) {
    padding: 35px 16px;
  }

  a, a:visited {
    text-decoration: none;
    color: currentColor;
  }
`;

const Icons = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const SubscribeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SubscribeInput = styled.input`
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  background: var(--sp-module-bg-color);
  border: 2px solid var(--sp-border-color);
  width: 400px;
  box-sizing: border-box;
  color: currentColor;

  &::placeholder {
    color: #9EA5AD;
  }

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 38px;
  color: var(--sp-text-color);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  @media (${DeviceQueries.mobile}) {
    gap: 10px;
  }
`;

const SmallLinks = styled.div`
  display: flex;
  justify-content: center;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  gap: 20px;
  margin-bottom: 20px;

  a, a:visited {
    text-decoration: none;
    color: currentColor;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  height: 44px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 0;
  border-radius: 8px;
  background: #000;
  color: #FFF;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export default WelcomeFooter;
