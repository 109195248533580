import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';

export const FilterContainer = styled.div`
  display: flex;
  padding: 6px;
  align-items: flex-end;
  gap: 8px;
  overflow-x: auto;
  flex-shrink: 0;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (${DeviceQueries.mobile}) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 5px; // Prevent bottom content cut off
  }
`;

export const FilterPill = styled.div`
  padding: 6px 10px;
  border-radius: 20px;
  border: 2px solid var(--sp-border-color);
  font-size: 12px;
  cursor: pointer;
  color: var(--sp-bg-color);
  background: var(--sp-text-color);
`;

export const InactiveFilterPill = styled(FilterPill)`
  background: none;
  color: var(--sp-text-color);
`;

export const CarouselWrapper = styled.div`
  //overflow: hidden;
`;
