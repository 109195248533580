import { useEffect } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';

import { updateAttendance } from '../api/ElkEventService';
import { useUserContext } from '../contexts/UserContext';
import { useEventCacheContext } from '../contexts/EventCacheContext';
import { v4 as uuidv4 } from 'uuid';
import { IUInvitee } from '../lib/event';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus } from 'TProtocol/prototypes/events/messages';

export interface UpdateAttendanceUserActionState {
  inviteeUuid?: string;
  token?: string;
  showPhotosPromo?: boolean;
  showRSVP?: boolean;
  fromLogin?: boolean;
  rsvpStatus?: TAppElkAttendeeStatus;
  inviteeName?: string;
  forceLogin?: boolean;
}

const UpdateAttendeeUserAction = () => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const location = useLocation() as Location<UpdateAttendanceUserActionState>;
  const navigate = useNavigate();
  const { eventId } = useParams();

  const inviteeUuid = location.state?.inviteeUuid;
  const showPhotosPromo = location.state?.showPhotosPromo;
  const showRSVP = location.state?.showRSVP;
  const fromLogin = location.state?.fromLogin;
  const updatedRsvpStatus = location.state?.rsvpStatus;
  const inviteeName = location.state?.inviteeName;

  useEffect(() => {
    const phone = userContext.getPhoneNumber();
    if (eventId !== undefined && userContext.name !== undefined && userContext.name.trim() !== ''
      && ((phone !== undefined && phone.trim() !== '') || (userContext.emails ?? []).length > 0)) {
      void fetchAndUpdate(eventId);
    }
  }, [eventId, userContext.name, userContext.phones, userContext.emails]);

  const fetchAndUpdate = async (eventId: string) => {
    const event = await eventCacheContext.fetchEvent({ eventId, inviteeUuid });

    const inviteeMe = event?.attendees.find((attendee) => attendee.inviteeId === inviteeUuid ?? '');
    let userInvitee = event?.attendees.find((attendee) => attendee.userId === userContext.id);
    let finalInvitee;

    if (userInvitee) {
      // User already has an existing invitee
      userInvitee.photoUrl = userContext.photoUrl;
      userInvitee.name = userContext.name;
      userInvitee.email = userContext.getEmail();
      userInvitee.phone = userContext.getPhoneNumber();
      if (updatedRsvpStatus !== undefined) {
        userInvitee.role = TAppElkAttendeeRole.ATTENDEE;
        userInvitee.rsvpStatus = updatedRsvpStatus;
      }
      await updateAttendance(userContext, eventId, userInvitee.toTAppElkInvitee(), undefined, undefined, undefined,
        true);

      finalInvitee = userInvitee;

    } else if (inviteeMe) {
      if (inviteeMe.userId && inviteeMe.userId.trim() !== '' && inviteeMe.userId !== userContext.id) {
        // inviteeMe has different userId from current logged in user
        if (!userInvitee) {
          userInvitee = new IUInvitee({
            inviteeId: uuidv4(),
            rsvpStatus: updatedRsvpStatus ?? inviteeMe.rsvpStatus,
            role: TAppElkAttendeeRole.ATTENDEE,
            additionalGuestCount: inviteeMe.additionalGuestCount,
            userId: userContext.id,
          });
        }
        userInvitee.photoUrl = userContext.photoUrl;
        userInvitee.name = inviteeName ?? userContext.name;
        userInvitee.email = userContext.getEmail();
        userInvitee.phone = userContext.getPhoneNumber();
        if (updatedRsvpStatus !== undefined) {
          userInvitee.role = TAppElkAttendeeRole.ATTENDEE;
          userInvitee.rsvpStatus = updatedRsvpStatus;
        }
        await updateAttendance(userContext, eventId, userInvitee.toTAppElkInvitee(), undefined, undefined, undefined,
          true);

        finalInvitee = userInvitee;
      } else {
        // inviteeMe is unclaimed by a user
        inviteeMe.userId = userContext.id;
        if (updatedRsvpStatus !== undefined) {
          inviteeMe.rsvpStatus = updatedRsvpStatus;
        }
        if (inviteeName !== undefined) {
          inviteeMe.name = inviteeName;
        }
        const phone = userContext.getPhoneNumber();
        if (phone !== undefined && phone.trim() !== '') {
          inviteeMe.phone = phone;
        }
        if (userContext.getEmail() !== undefined && userContext.getEmail()?.trim() !== '') {
          inviteeMe.email = userContext.getEmail();
        }
        //todo: check if photo upload mode needs to be maintained here
        await updateAttendance(userContext, eventId, inviteeMe.toTAppElkInvitee(), undefined, undefined, undefined,
          true);

        finalInvitee = inviteeMe;
      }
    } else {
      // Create new invitee
      const attendee = new IUInvitee({
        inviteeId: inviteeUuid ?? uuidv4(),
        rsvpStatus: updatedRsvpStatus ?? TAppElkAttendeeStatus.SEEN,
        role: TAppElkAttendeeRole.ATTENDEE,
        additionalGuestCount: 0,
        userId: userContext.id,
        photoUrl: userContext.photoUrl,
        name: userContext.name ?? inviteeName,
        email: userContext.getEmail(),
        phone: userContext.getPhoneNumber(),
        isNew: inviteeUuid === undefined
      });

      await updateAttendance(userContext, eventId, attendee.toTAppElkInvitee(), undefined, undefined, undefined, true);

      finalInvitee = attendee;
    }

    let rsvpParam = '';
    if (showRSVP && finalInvitee !== undefined) {
      if (finalInvitee.rsvpStatus === TAppElkAttendeeStatus.NO) {
        rsvpParam = '?r=no';
      } else if (finalInvitee.rsvpStatus === TAppElkAttendeeStatus.YES) {
        rsvpParam = '?r=yes';
      }
    }

    navigate(`/event/${eventId}${rsvpParam}`,
      { state: { showRSVP, showPhotosPromo, fromLogin, inviteeName }, replace: true });
  };

  return null;
};

export default UpdateAttendeeUserAction;
