import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import KebabIcon from './icons/KebabIcon';

export interface KebabMenuOption {
  id: string;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  color?: string;
}

interface Props {
  options: KebabMenuOption[],
  onClick: (id: string) => void;
  disabled?: boolean;
  wide?: boolean;
}

const KebabMenu = ({ options, onClick, disabled = false, wide=false }: Props) => {
  const [open, setOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)
      && iconRef.current && !iconRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const onIconClick = () => {
    if (open) {
      setOpen(false);
    } else if (options.length > 0) {
      setOpen(true);
    }
  };

  const onItemClick = (id: string) => {
    onClick(id);
    setOpen(false);
  };

  return <PopoverContainer>
    {open &&
      <KebabPopover ref={popoverRef} $wide={wide}>
        {
          options.map((option) => <KebabPopoverItem
            key={option.id}
            onClick={() => onItemClick(option.id)}
            $color={option.color}
          >
            <Wrapper>
              {option.icon}
            </Wrapper>
            <Wrapper>
              {option.label}
            </Wrapper>
          </KebabPopoverItem>)
        }
      </KebabPopover>}
    <IconWrapper ref={iconRef}>
      <KebabIcon onClick={onIconClick}/>
    </IconWrapper>
  </PopoverContainer>;
};

const PopoverContainer = styled.div`
  position: relative;
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  text-wrap: nowrap;
`;

const KebabPopover = styled.div<{ $wide: boolean }>`
  position: absolute;
  border-radius: 10px;
  border: 1px solid #E5E5E5;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  background: rgba(255, 255, 255, 0.90);
  backdrop-filter: blur(40px);
  color: #000;
  margin-top: 40px;
  right: 0;
  ${({ $wide }) => $wide ? '' : 'width: 170px;'}
  z-index: 20;
`;

const KebabPopoverItem = styled.div<{ $color?: string }>`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  ${({ $color }) => $color && `color: ${$color};`}
  cursor: pointer;
  //align-self: stretch;

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 19px;
    border-bottom: none;
  }

  &:hover {
    background-color: #F3F3F3;
  }
`;

const IconWrapper = styled.button`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  color: currentColor;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export default KebabMenu;
