import { useEffect } from 'react';

declare const ELK_WEBAPP_URL: string; // shine.sunshine.com
declare const WWW_WEBAPP_URL: string; // www.sunshine.com
declare const ELK_COOKIE_DOMAIN: string; // sunshine.com

const HostRedirect = (): null => {

  useEffect(() => {
    const host = window.location.host;

    if (!ELK_WEBAPP_URL || host === ELK_WEBAPP_URL) {
      return;
    }

    if (host === WWW_WEBAPP_URL) {
      window.location.host = ELK_COOKIE_DOMAIN;
    } else if (host !== ELK_COOKIE_DOMAIN) {
      window.location.host = ELK_WEBAPP_URL;
    }
  }, []);

  return null;
};

export default HostRedirect;
