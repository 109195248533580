import React, { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { useUserContext } from '../../contexts/UserContext';
import EventFormInput, { InputType } from '../EventForm/EventFormInput';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import { stob64 } from '../../util/stringutils';
import AdditionalGuestsPicker from '../rsvp/AdditionalGuestPicker';
import NotificationPreferenceSelector from '../rsvp/NotificationPreferenceSelector';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import { stripPlusOne } from '../../lib/phone';
import { IUEvent, IUInvitee } from '../../lib/event';
import EditableAnswer from '../questions/EditableAnswer';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import { DesktopShow, MobileShow } from '../styled/Common';
import { WideWhiteButton } from '../buttons/WhiteButton';
import PhoneInput from '../SingleFieldForm/PhoneInput';
import SinglePhoneEmailInput, { ErrorContainer, LoginInput } from '../login/SinglePhoneEmailInput';
import { validateEmail } from '../../util/email';
import { findCurrentInvitee } from '../../util/attendee';
import { ExternalLabel } from '../forms/Input';
import { TallerWhiteInput } from '../forms/WhiteInput';
import ErrorIcon from '../icons/ErrorIcon';
import { NotYouActionText, NotYouText } from '../rsvp/Common';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkEventAnswer,
  TAppElkNotificationPreference,
  TAppElkPhotoUploadMode
} from 'TProtocol/prototypes/events/messages';
import md5 from 'md5';

enum UnauthRsvpModes {
  PHONE,
  EMAIL
}

enum ModalState {
  AUTH,
  UNAUTH_INITIAL,
  UNAUTH_UPDATE
}

const RSVPYesModal = ({
                        close,
                        event,
                        eventId,
                        showUpdateRSVP,
                        nonUpdateRSVPThroughEmail
                      }: {
  close: () => void,
  event: IUEvent | undefined,
  eventId: string | undefined,
  showUpdateRSVP?: boolean,
  nonUpdateRSVPThroughEmail?: boolean
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const formRef = useRef<HTMLFormElement>(null);
  const { getInviteeUuid, getPhone, getEmail, setQuestionResponse, clearInviteeUuidStorageForEvent, getToken, deleteToken } = useInviteeUuidStorage();

  const [invitee, setInvitee] = useState<IUInvitee | undefined>(undefined); //inviteeInput param not used anywhere
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [questionRequiredErrors, setQuestionRequiredErrors] = useState(new Set<number>);
  const [uploadMode, setUploadMode] = useState(event?.albumDetails?.photoUploadMode ?? userContext.defaultUploadMode);
  const [piiError, setPiiError] = useState<string>('');

  const [hasChanged, setHasChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [unauthRsvpMode, setUnauthRsvpMode] = useState<UnauthRsvpModes>(UnauthRsvpModes.PHONE);
  const [modalState, setModalState] = useState<ModalState>(
    userContext.isLoggedIn() || getToken(eventId) !== undefined ? ModalState.AUTH : (showUpdateRSVP || event?.hasWaitlist) ? ModalState.UNAUTH_UPDATE : ModalState.UNAUTH_INITIAL);
  const [hasChangedName, setHasChangedName] = useState<boolean>(false);

  useEffect(() => {
    if (userContext.isLoggedIn()) {
      const phone = userContext.getPhoneNumber();

      if (phone) {
        setPhoneNum(stripPlusOne(phone));
      } else if (invitee?.phone) {
        setPhoneNum(stripPlusOne(invitee.phone));
      }
    }

  }, [userContext.phones, invitee?.phone]);

  useEffect(() => {
    const phone = getPhone();
    const email = getEmail();
    const currInvitee = findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
      userContext.phones ?? (phone ? [phone] : undefined), userContext.emails ?? (email ? [email] : undefined));
    if (!currInvitee.phone || currInvitee.phone.trim() === '') {
      currInvitee.phone = getPhone() ?? userContext.getPhoneNumber();
    }
    if (!currInvitee.email || currInvitee.email.trim() === '') {
      currInvitee.email = getEmail() ?? userContext.getEmail();
    }
    // Default notif preference to both
    if (modalState !== ModalState.UNAUTH_INITIAL) {
      currInvitee.notificationPreference = event?.communicationPreference ?? TAppElkNotificationPreference.BOTH;
    }
    setInvitee(new IUInvitee(currInvitee));
    setHasChanged(true);
    setPhoneNum(stripPlusOne(currInvitee.phone ?? ''));
    if (event?.communicationPreference === TAppElkNotificationPreference.EMAIL) {
      setUnauthRsvpMode(UnauthRsvpModes.EMAIL);
    } else if (event?.communicationPreference === TAppElkNotificationPreference.PHONE) {
      setUnauthRsvpMode(UnauthRsvpModes.PHONE);
    } else {
      setUnauthRsvpMode(currInvitee.email ? UnauthRsvpModes.EMAIL : UnauthRsvpModes.PHONE);
    }
  }, []);

  useEffect(() => {
    setPhoneNum(stripPlusOne(invitee?.phone ?? ''));
  }, [invitee]);

  if (invitee === undefined) {
    return null;
  }

  const additionalGuestCount = invitee.additionalGuestCount;

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[RSVPYesModal] ${action}`
    });
  };

  const handleImIn = async () => {
    logUserAction('Handle I\'m in');

    const numGuests = additionalGuestCount;
    let messageSlot: string;
    if (!invitee.rsvpMessage || invitee.rsvpMessage.trim() === '') {
      messageSlot = 'm';
    } else {
      messageSlot = invitee.rsvpMessage;
    }

    const encodedMessage = stob64(messageSlot);
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedPhone = stob64(invitee.phone ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    //TODO: ensure title is present in all cases
    const encodedTitle = stob64(event?.title ?? '');
    const uploadModeString = uploadMode === TAppElkPhotoUploadMode.MANUAL ? 'manual' : uploadMode === TAppElkPhotoUploadMode.AUTOMATIC ? 'magic' : '';
    if (!userContext.isLoggedIn()) {
      if (getToken(eventId) !== undefined) {
        navigate(
          `/event/updateemail/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      } else {
        navigate(
          `/event/unauthrsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      }
    } else {
      navigate(
        `/event/rsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&pu=${uploadModeString}&ep=${encodedPhone}`,
        {
          replace: true,
          state: {
            finishingRSVP: true
          }
        });
    }
  };

  const handleUnauthRSVP = async (finishingRSVP: boolean) => {
    logUserAction('Handle Unauth RSVP Yes');

    const numGuests = additionalGuestCount;
    let messageSlot: string;
    if (!invitee.rsvpMessage || invitee.rsvpMessage.trim() === '') {
      messageSlot = 'm';
    } else {
      messageSlot = invitee.rsvpMessage;
    }

    const encodedMessage = stob64(messageSlot);
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedPhone = stob64(invitee.phone ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    //TODO: ensure title is present in all cases
    const encodedTitle = stob64(event?.title ?? '');
    navigate(
      `/event/unauthrsvp/action/${eventId}?r=yes&em=${encodedMessage}&ng=${numGuests}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}&ep=${encodedPhone}`,
      {
        replace: true,
        state: {
          finishingRSVP
        }
      });
  };

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onMessageChange = (message: string) => {
    invitee.rsvpMessage = message;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onPhoneChange = (phoneNumber: string, fullPhone: string) => {
    setPiiError('');
    setPhoneNum(phoneNumber);
    invitee.phone = fullPhone;
    if (nonUpdateRSVPThroughEmail) {
      eventId && deleteToken(eventId);
      invitee.inviteeId = uuidv4();
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidPhone(false);
  };

  const onEmailChange = (emailAddress: string) => {
    setPiiError('');
    invitee.email = emailAddress;
    if (nonUpdateRSVPThroughEmail) {
      eventId && deleteToken(eventId);
      invitee.inviteeId = uuidv4();
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setInvalidEmail(false);
  };

  const onNotificationPreferenceChange = (preference: TAppElkNotificationPreference) => {
    invitee.notificationPreference = preference;
    if (preference === TAppElkNotificationPreference.PHONE) {
      invitee.email = undefined;
    } else if (preference === TAppElkNotificationPreference.EMAIL) {
      invitee.phone = undefined;
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onAnswerChange = (invitee: IUInvitee, answer: TAppElkEventAnswer) => {
    setQuestionResponse(eventId ?? '', answer);
    const existingAnswer = invitee.answers?.find((ans) => ans.questionUuid === answer.questionUuid);
    if (existingAnswer) {
      invitee.answers = invitee.answers?.map((ans) =>
        ans.questionUuid == answer.questionUuid ? ans = answer : ans
      );
    } else {
      let concatenatedAnswers;
      if (invitee.answers) {
        // Use the spread operator to concatenate arrays
        concatenatedAnswers = [...invitee.answers, answer];
      } else {
        concatenatedAnswers = [answer];
      }
      invitee.answers = concatenatedAnswers;
    }
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const validateForm = () => {
    const invalidPhone = !isPossiblePhoneNumber(invitee.phone ?? '');
    const invalidEmail = !validateEmail(invitee.email);

    const isNameInvalid = invitee.name === undefined || invitee.name.trim() === '';
    setInvalidName(isNameInvalid);

    if (isNameInvalid) {
      logUserAction(`Invalid name: "${invitee.name}"`);
      return false;
    }

    for (let i = 0; i < (event?.questions.length ?? 0); i++) {
      const existingAnswer = invitee.answers?.find((ans) => ans.questionUuid === event?.questions[i].questionUuid);

      if (event?.questions[i].isRequired && (existingAnswer?.answerValue === undefined || existingAnswer?.answerValue?.text === '')) {
        questionRequiredErrors.add(i);
      } else {
        questionRequiredErrors.delete(i);
      }
    }
    setQuestionRequiredErrors(new Set(questionRequiredErrors));

    switch (invitee.notificationPreference) {
      case TAppElkNotificationPreference.EMAIL:
        setInvalidPhone(false);
        setInvalidEmail(invalidEmail);
        if (invalidEmail) {
          logUserAction(`Invalid email: ${invitee.email && md5(invitee.email)}`);
        }
        return !invalidEmail;
      case TAppElkNotificationPreference.PHONE:
        setInvalidPhone(invalidPhone);
        setInvalidEmail(false);
        if (invalidPhone) {
          logUserAction(`Invalid phone: ${invitee.phone && md5(invitee.phone)}`);
        }
        return !invalidPhone;
      case TAppElkNotificationPreference.BOTH:
        setInvalidPhone(invalidPhone);
        setInvalidEmail(invalidEmail);
        if (invalidEmail || invalidPhone) {
          logUserAction(`Invalid PII: Invalid PII: email ${invitee.email && md5(invitee.email)} , phone ${invitee.phone && md5(invitee.phone)}`)
        }
        return !invalidEmail && !invalidPhone;
      default:
        return true;
    }
  };

  const validateUnauthForm = () => {
    const isNameInvalid = invitee.name === undefined || invitee.name.trim() === '';
    setInvalidName(isNameInvalid);

    if (unauthRsvpMode === UnauthRsvpModes.PHONE) {
      const invalidPhone = !isPossiblePhoneNumber(invitee.phone ?? '');
      setInvalidPhone(invalidPhone);
      if (invalidName) {
        logUserAction(`Invalid name (unauth): "${invitee.name}"`);
      }
      if (invalidPhone) {
        setPiiError('Please provide a valid phone number.');
        logUserAction(`Invalid phone (unauth): ${invitee.phone && md5(invitee.phone)}`);
      }
      return !invalidPhone && !isNameInvalid;
    } else {
      const invalidEmail = !validateEmail(invitee.email);
      setInvalidEmail(invalidEmail);
      if (invalidName) {
        logUserAction(`Invalid name (unauth): "${invitee.name}"`);
      }
      if (invalidEmail) {
        setPiiError('Please provide a valid email.');
        logUserAction(`Invalid email (unauth): ${invitee.email && md5(invitee.email)}`);
      }
      return !invalidEmail && !isNameInvalid;
    }
  };

  const onConfirmClick = () => {
    logUserAction('On accept click');

    const validForm = validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      logUserAction('Invalid form');
      return;
    }

    void handleImIn();
    // updateTextPermission();
  };

  const unauthOnConfirmClick = (finishingRSVP: boolean) => {
    logUserAction('On accept click (unauth)');

    const validForm = modalState === ModalState.UNAUTH_INITIAL ? validateUnauthForm() : validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      logUserAction('Invalid form (unauth)');
      return;
    }

    void handleUnauthRSVP(finishingRSVP);
  };

  const setGuestCount = (count: number) => {
    invitee.additionalGuestCount = count;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
  };

  const onNameChange = (name: string) => {
    invitee.name = name;
    setInvitee(new IUInvitee(invitee));
    setHasChanged(true);
    setHasChangedName(true);
    setInvalidName(false);
  };

  const onClose = () => {
    logUserAction('Closed modal');

    close();
  };

  const showResetInviteeAction = getToken(eventId) !== undefined && !userContext.isLoggedIn() && modalState === ModalState.AUTH && !hasChangedName;

  const resetInvitee = () => {
    logUserAction('Clear and update details');
    if (eventId) {
      clearInviteeUuidStorageForEvent(eventId);
    }
    const newInvitee = new IUInvitee({
      role: TAppElkAttendeeRole.ATTENDEE,
      rsvpStatus: TAppElkAttendeeStatus.YES,
      notificationPreference: event?.communicationPreference ?? TAppElkNotificationPreference.BOTH
    });
    setInvitee(newInvitee);
    setHasChangedName(true);
  };

  const onUnauthBackArrowClick = () => {
    if (event?.hasWaitlist) {
      onClose();
    } else {
      setModalState(ModalState.UNAUTH_INITIAL);
    }
  }

  const modalTitle = event?.hasWaitlist ? 'Join waitlist' : 'Accept invitation';

  const nameInput = <LoginInput $includeMargin={true}>
    <ExternalLabel>Who will be attending?</ExternalLabel>
    <TallerWhiteInput
      autoFocus={false}
      placeholder="Taylor Swift"
      $isInvalid={invalidName}
      disabled={false}
      value={invitee.name ?? ''}
      onChange={(e) => onNameChange(e.currentTarget.value)}
    />
    {invalidName && <ErrorContainer><ErrorIcon/><span>Please provide a valid name.</span></ErrorContainer>}
    {showResetInviteeAction &&
        <NotYouText>Not {!invitee.name || invitee.name.trim() === '' ? invitee.email : invitee.name}? <NotYouActionText
            onClick={resetInvitee}>Clear and update
            details</NotYouActionText></NotYouText>}
  </LoginInput>;

  const rsvpDetails = <>
    {event?.openInvite !== false && <AdditionalGuestsPicker additionalGuestCount={additionalGuestCount}
                            setGuestCount={setGuestCount}/>}

    {event?.communicationPreference === 3 &&
      <NotificationPreferenceSelector
        value={invitee.notificationPreference ?? event?.communicationPreference}
        onChange={onNotificationPreferenceChange}
      />
    }

    {
      (invitee.notificationPreference === undefined || invitee.notificationPreference === TAppElkNotificationPreference.BOTH || invitee.notificationPreference === TAppElkNotificationPreference.PHONE) ?
        <LoginInput $includeMargin={true}>
          <ExternalLabel>What&apos;s your phone number? (required)</ExternalLabel>
          <PhoneInput
            autoFocus={false}
            placeholder="(123) 456-7890"
            isInvalid={invalidPhone}
            autoComplete="tel"
            value={phoneNum}
            required={true}
            onChange={onPhoneChange}
            disabled={false}
            taller={true}
          />
          {invalidPhone &&
              <ErrorContainer><ErrorIcon/><span>Please provide a valid phone number.</span></ErrorContainer>}
          {modalState === ModalState.AUTH && !userContext.isLoggedIn() && <TextPermissionsDisclaimer>
              By providing us with your phone number you agree to receive texts when the host makes an updates or
              sends
              a reminder.
          </TextPermissionsDisclaimer>}
        </LoginInput>
        : <></>
    }
    {
      (invitee.notificationPreference === undefined || invitee.notificationPreference === TAppElkNotificationPreference.EMAIL || invitee.notificationPreference === TAppElkNotificationPreference.BOTH) ?
        <LoginInput $includeMargin={true}>
          <ExternalLabel>What&apos;s your email? (required)</ExternalLabel>
          <TallerWhiteInput
            autoComplete="email"
            autoFocus={false}
            placeholder="stella@sunshine.com"
            $isInvalid={invalidEmail}
            disabled={false}
            value={invitee.email}
            onChange={(e) => onEmailChange(e.currentTarget.value)}
          />
          {invalidEmail && <ErrorContainer><ErrorIcon/><span>Please provide a valid email.</span></ErrorContainer>}
        </LoginInput>
        : <></>
    }

    <EventFormInput
      placeholder="Type message here"
      event={event}
      label="Message to the host"
      value={invitee.rsvpMessage ?? ''}
      maxLength={1024}
      onChange={(value) => onMessageChange(value)}
      inputType={InputType.Description}
      optionNumber={0}
      isLabelOutside={true}
      excludeBackground={true}
      inModal={true}
    />

    {event?.questions.map(
      (question, index) => <EditableAnswer
        question={question}
        invitee={invitee}
        key={question.questionUuid}
        onAnswerChange={onAnswerChange}
        showRequired={questionRequiredErrors.has(index)}
      />)}
  </>;

  let modalContent: ReactNode;
  if (modalState === ModalState.AUTH) {
    modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
      <ModalContentContainer>
        <ModalHeader>
          <BackArrowContainer onClick={onClose}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>{modalTitle}</ModalTitle>
        </ModalHeader>

        {nameInput}
        {rsvpDetails}
      </ModalContentContainer>
      <ButtonFooter>
        <WideWhiteButton $invert={true} type="button" onClick={onConfirmClick}
                     disabled={submitting || !hasChanged}>Accept</WideWhiteButton>
      </ButtonFooter>
    </RSVPForm>;
  } else if (modalState === ModalState.UNAUTH_INITIAL) {
    const handleSwitchToEmail = () => {
      setUnauthRsvpMode(UnauthRsvpModes.EMAIL);
      onNotificationPreferenceChange(TAppElkNotificationPreference.EMAIL);
      onPhoneChange('', '');
    };

    const handleSwitchToPhone = () => {
      setUnauthRsvpMode(UnauthRsvpModes.PHONE);
      onNotificationPreferenceChange(TAppElkNotificationPreference.PHONE);
      onEmailChange('');
    };

    modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
      <ModalContentContainer>
        <ModalHeader>
          <BackArrowContainer onClick={onClose}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>{modalTitle}</ModalTitle>
        </ModalHeader>
        {nameInput}

        <SinglePhoneEmailInput phone={phoneNum} email={invitee.email ?? ''}
                               showPhoneInput={unauthRsvpMode === UnauthRsvpModes.PHONE}
                               switchToEmail={handleSwitchToEmail}
                               switchToPhone={handleSwitchToPhone}
                               handleEmailChange={onEmailChange}
                               handlePhoneChange={onPhoneChange}
                               useMoreVisibleLabels={true}
                               includeMargin={true}
                               autoFocus={false}
                               error={piiError}
                               setError={setPiiError}/>
      </ModalContentContainer>

      <ButtonFooter>
        <WideWhiteButton $invert={true} type="button" onClick={() => unauthOnConfirmClick(false)}
                     disabled={submitting || !hasChanged}>Next</WideWhiteButton>
        {unauthRsvpMode === UnauthRsvpModes.PHONE && <TextPermissionsDisclaimer>
            By signing up with your phone number you agree to receive texts when the host makes an updates or sends
            a reminder.
        </TextPermissionsDisclaimer>}
      </ButtonFooter>
    </RSVPForm>;
  } else {
    modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
      <ModalContentContainer>
        <ModalHeader>
          <BackArrowContainer onClick={onUnauthBackArrowClick}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>{modalTitle}</ModalTitle>
        </ModalHeader>
        { event?.hasWaitlist ? nameInput : null}
        {rsvpDetails}
      </ModalContentContainer>
      <ButtonFooter>
        <WideWhiteButton $invert={true} type="button" onClick={() => unauthOnConfirmClick(true)}
                     disabled={submitting || !hasChanged}>Accept</WideWhiteButton>
      </ButtonFooter>
    </RSVPForm>;
  }

  return (
    <>
      <DesktopShow>
        <Overlay onMouseDown={onClose}>
          <ModalContainer onMouseDown={onModalClick}>
            {modalContent}
          </ModalContainer>
        </Overlay>
      </DesktopShow>
      <MobileShow>
        <TransparentOverlayBottomModal onClick={onClose}>
          <BottomModalContainer onClick={onModalClick}>
            {modalContent}
          </BottomModalContainer>
        </TransparentOverlayBottomModal>
      </MobileShow>
    </>
  );
};

const RSVPForm = styled.form`
  margin: 5px;
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  //gap: 30px;
`;

const ModalContentContainer = styled.div`
  //display: flex;
`;

const ButtonFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const TextPermissionsDisclaimer = styled.div`
  color: rgba(var(--sp-text-rgb), 0.60);
  font-size: 12px;
  font-weight: 400;
`;

export default RSVPYesModal;
