import styled from 'styled-components';

import { DeviceQueries } from 'Common/src/components/styled';

export const WhiteButton = styled.button<{ $noMin?: boolean, $invert?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans, sans-serif;
  width: fit-content;
  cursor: pointer;
  ${({ $noMin = false }) => $noMin ? '' : ' min-width: 150px;'};
  font-size: 16px;
  gap: 10px;
  padding: 6px 12px;
  line-height: 24px;
  ${({ $invert = false }) => $invert ?
    'background: var(--sp-text-color); color: var(--sp-bg-color); &:hover:not([disabled]) {background-color: rgba(var(--sp-text-rgb), 0.8);}' :
    'background: var(--sp-bg-color); color: var(--sp-text-color); &:hover:not([disabled]) {background-color: rgba(var(--sp-bg-rgb), 0.8);}'}
  white-space: nowrap;

  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  &[disabled] {
    color: rgba(var(--sp-text-rgb), 0.80);
    background: rgba(var(--sp-bg-rgb), 0.80);
    border: 1px solid var(--sp-border-color);
  }
`;

export const MobileWideWhiteButton = styled(WhiteButton)`
  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;

export const WideWhiteButton = styled(WhiteButton)`
  width: 100%;
`;

export const WhiteFormButton = styled(WhiteButton)`
  height: 39px;
  padding: 6px 15px;
  min-width: auto;
`;

export const SmallWhiteButton = styled(WhiteButton)`
  font-size: 12px;
  gap: 6px;
  padding: 3px 7px;
  line-height: 16px;
  height: 34px;
  min-width: fit-content; // Optional: adjust if you want to keep the min-width logic
  box-sizing: border-box;
`;
