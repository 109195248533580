import React from 'react';

const ComputerOutline = ({ color = 'currentColor' }: {
  color?: string
}) => {
  return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="DesktopComputerOutline">
      <path id="Vector"
            d="M9.75 17.5L9 20.5L8 21.5H16L15 20.5L14.25 17.5M3 13.5H21M5 17.5H19C19.5304 17.5 20.0391 17.2893 20.4142 16.9142C20.7893 16.5391 21 16.0304 21 15.5V5.5C21 4.96957 20.7893 4.46086 20.4142 4.08579C20.0391 3.71071 19.5304 3.5 19 3.5H5C4.46957 3.5 3.96086 3.71071 3.58579 4.08579C3.21071 4.46086 3 4.96957 3 5.5V15.5C3 16.0304 3.21071 16.5391 3.58579 16.9142C3.96086 17.2893 4.46957 17.5 5 17.5Z"
            stroke={color} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>;
};

export default ComputerOutline;

