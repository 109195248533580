import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logEvent } from 'firebase/analytics';

import { BasePageContent } from './BasePage';
import { ColorScheme, DEFAULT_COLORS, useBackgroundContext } from '../contexts/BackgroundContext';
import PersonCropCircle from '../components/icons/PersonCropCircle';
import { useUserContext } from '../contexts/UserContext';
import { WideWhiteButton } from '../components/buttons/WhiteButton';
import { WideOutlineButton } from '../components/buttons/OutlineButton';
import ImageUploader from '../components/ImageUploader';
import LogoutModal from '../components/modals/LogoutModal';
import { useModalContext } from '../contexts/ModalContext';
import OptOutModal from '../components/modals/OptOutModal';
import { getNormalizedPhotoUrl, uploadToFirebase } from '../lib/images';
import { updateElkUser } from '../api/ElkEventService';
import BasePageHeader, { UPageType } from './BasePageHeader';
import FooterLinks from '../components/FooterLinks';
import ExpandingTextInput from '../components/ExpandingTextInput';
import { firebaseAnalytics } from '../core/libs/Firebase';
import { ActionMenuLabel } from '../components/EventForm/ActionMenuCommon';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import Checkbox from 'Common/src/components/Checkbox';
import { DeviceQueries } from 'Common/src/components/styled';

import { TAppElkTextPermissionStatus } from 'TProtocol/prototypes/events/messages';

const ProfileEditPage = () => {
  const backgroundContext = useBackgroundContext();
  const navigate = useNavigate();
  const userContext = useUserContext();
  const modalContext = useModalContext();
  const imgRef = useRef<HTMLImageElement>(null);

  const [name, setName] = useState(userContext.name);
  const [photoUrl, setPhotoUrl] = useState(userContext.photoUrl);
  const [hideSaveButton, setHideSaveButton] = useState(false);
  const [grantedTextPermission, setGrantedTextPermission] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    document.title = 'Shine Parties - Profile Edit';
    logEvent(firebaseAnalytics, 'page_view', {
      page_title: 'Profile Edit - Analytics'
    });
    backgroundContext.setColors({ colors: DEFAULT_COLORS });
  }, []);

  useEffect(() => {
    backgroundContext.setScheme({ scheme: ColorScheme.Welcome });
  }, []);

  useEffect(() => {
    setPhotoUrl(userContext.photoUrl);
  }, [userContext.photoUrl]);

  useEffect(() => {
    setName(userContext.name);
  }, [userContext.name]);

  useEffect(() => {
    setGrantedTextPermission(userContext.grantedTextPermission ?? false);
  }, [userContext.grantedTextPermission]);

  const handleNameChange = (name: string) => {
    setName(name);
  };

  const onImageSelect = (imgSrc: string) => {
    if (imgRef.current) {
      imgRef.current.src = imgSrc;
    }
    setPhotoUrl(imgSrc);
  };

  const onSaveClick = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    // If a new field is added to this page, it needs to be added here, and also in UserContext.generateElkUser
    const user = userContext.generateElkUser({
      name,
      photoUrl,
      grantedTextPermission,
      textPermissionStatus: grantedTextPermission ? TAppElkTextPermissionStatus.GRANTED : TAppElkTextPermissionStatus.REJECTED
    });
    if (user && (imgRef.current?.src || name !== '')) {
      if (imgRef.current?.src) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.INFO,
          userId: userContext.id,
          tag: ULogTag.UserAction,
          message: '[ProfileEditPage] Uploading new profile image to Firebase'
        });
        try {
          const firebaseUrl = await uploadToFirebase(imgRef.current.src, 'profile');
          user.photoUrl = await getNormalizedPhotoUrl(firebaseUrl);
          userContext.setPhotoUrl(user.photoUrl);
        } catch (e) {
          void logSumoEvent({
            app: ULogApplication.ELK,
            severity: ULogSeverity.ERROR,
            userId: userContext.id,
            tag: ULogTag.UserAction,
            message: `[ProfileEditPage] Error during Firebase image upload or normalization: ${JSON.stringify(e)}`
          });
        }
      }
      userContext.setGrantedTextPermissions(grantedTextPermission);
      user.grantedTextPermission = grantedTextPermission;
      await updateElkUser(userContext.getAuthHeader(), user);
      navigate('/events');
    }
  };

  const onLogoutClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ProfileEditPage] Clicked on logout link'
    });

    modalContext.show({
      contents: <LogoutModal close={() => modalContext.hide()}/>
    });
  };

  const onOptOutClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[ProfileEditPage] Clicked on opt out link'
    });

    modalContext.show({
      contents: <OptOutModal close={() => modalContext.hide()}/>
    });
  };

  const handleCheckboxChange = (checked: boolean) => {
    setGrantedTextPermission(checked);
  };

  return <>
    <BasePageHeader page={UPageType.ProfileEdit}/>
    <BasePageContent $scrolling={false}>
      <CenteredContainer>
        <ProfileContainer>
          <ImageContainer>
            <div>
              <ImageUploader circular={true} onImageCropAvailable={onImageSelect}
                             onPopupOpen={() => setHideSaveButton(true)} onPopupClose={() => setHideSaveButton(false)}>
                <EmptyPhotoContainer>
                  {
                    photoUrl ? <PhotoImg ref={imgRef} src={photoUrl} width={94} alt="Profile photo"/> :
                      <PersonCropCircle size={94} color="#afb0b4"/>
                  }
                </EmptyPhotoContainer>
                <EditLink>
                  Change picture
                </EditLink>
              </ImageUploader>
            </div>
          </ImageContainer>
          <ActionMenuLabel>Name</ActionMenuLabel>
          <ExpandingTextInput
            placeholder={'Name'}
            value={name}
            textColor="currentColor"
            onChange={handleNameChange}
            noVertMargin={true}
            isForSideBar={true}
            excludeBackground={true}
          />
          <PermissionContainer>
            <Checkbox
              id="text-permission"
              checked={grantedTextPermission}
              onInputChange={handleCheckboxChange}
              $size="medium"
              $labelFontSize="13px"
              $dark={true}
            >
              Please send me texts for invitations, updates responses and reminders.
              Message and data rates may apply. To opt-out, reply STOP.
            </Checkbox>
          </PermissionContainer>
          <ButtonContainer>
            <WideWhiteButton $invert={true} type="submit" onClick={onSaveClick} hidden={hideSaveButton}
                             disabled={submitting || name === ''}>
              Save
            </WideWhiteButton>
          </ButtonContainer>
          <ButtonContainer>
            <WideOutlineButton type="submit" onClick={onLogoutClick} hidden={hideSaveButton}
                               disabled={submitting || name === ''}>
              Log out
            </WideOutlineButton>
          </ButtonContainer>
        </ProfileContainer>
        <div style={{ color: 'red', fontWeight: '500', cursor: 'pointer' }} onClick={onOptOutClick}>Delete Account</div>
      </CenteredContainer>
      <FooterLinks/>
    </BasePageContent>
  </>;
};

const Title = styled.div`
  text-align: center;
  font-size: 30pt;
`;

const ImageContainer = styled.div`
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  border: 2px solid var(--sp-border-color);
  padding: 20px;
  border-radius: 16px;
  width: 470px;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    box-shadow: none;
  }
`;

const ProfileSpacer = styled.div`
  flex-grow: 1;
`;

const PermissionContainer = styled.div`
  font-size: 13px;
  color: rgba(var(--bg-text-rgb), 0.7);
`;

const EditLink = styled.div`
  cursor: pointer;
  color: rgba(var(--bg-text-rgb), 0.3);
  font-size: 13px;
  font-weight: 500;
`;

const PhotoImg = styled.img`
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const EmptyPhotoContainer = styled.div`
  border: 1px solid transparent;
  border-radius: 50%;
  min-width: 94px;
  height: 94px;
`;

const ButtonContainer = styled.div`
  margin: 13px 0 8px;
`;

export default ProfileEditPage;
