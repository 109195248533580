import { JewishMonth, toGregorianDate } from 'jewish-date';

export const HOLIDAY_OFFSET = 4; // stop showing a holiday 4 days before it happens

const now = new Date();
const thisYear = now.getFullYear();

export const nextDate = (month: number, day: number, offsetDays?: number): Date => {
  const dayThisYear = new Date(thisYear, month - 1, day - (offsetDays ?? 0));

  if (dayThisYear > now) {
    return dayThisYear;
  }

  return new Date(thisYear + 1, month - 1, day);
};

const easter = (year: number, offsetDays = 0): Date => {
  const C = Math.floor(year / 100);
  const N = year - 19 * Math.floor(year / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor((I / 30));
  I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
  let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const M = 3 + Math.floor((L + 40) / 44);
  const D = L + 28 - 31 * Math.floor(M / 4);

  return new Date(year, M - 1, D - offsetDays);
};

export const nextEaster = (offsetDays?: number) => {
  const dayThisYear = easter(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return easter(thisYear + 1);
};

const mardisGras = (year: number, offsetDays = 0) => {
  const date = easter(year, offsetDays);
  date.setDate(date.getDate() - 47);
  return date;
};

export const nextMardisGras = (offsetDays?: number) => {
  const dayThisYear = mardisGras(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return mardisGras(thisYear + 1, offsetDays);
};

const usaThanksgiving = (year: number, offsetDays?: number): Date => {
  const lastOfNov = new Date(year, 10, 30).getDay();
  const day = (lastOfNov >= 4 ? 34 : 27) - lastOfNov - (offsetDays ?? 0);

  return new Date(year, 10, day);
};

export const nextUsaThanksgiving = (offsetDays?: number): Date => {
  const dayThisYear = usaThanksgiving(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return usaThanksgiving(thisYear + 1, offsetDays);
};

const hanukkah = (gregorianYear: number, offsetDays = 0): Date => {
  const date = toGregorianDate({
    year: gregorianYear + 3761, // Barely works for Hanukkah because the latest it can be is December 27
    monthName: JewishMonth.Kislev,
    day: 25,
  });
  date.setDate(date.getDate() - offsetDays);

  return date;
};

export const nextHanukkah = (offsetDays?: number): Date => {
  const dayThisYear = hanukkah(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return hanukkah(thisYear + 1, offsetDays);
};

const getNewMoons = (date: Date) => {
  const LUNAR_MONTH = 29.5305888531;
  let y = date.getFullYear();
  let m = date.getMonth() + 1;

  if (m <= 2) {
    y -= 1;
    m += 12;
  }

  const a = Math.floor(y / 100);
  return (((2 - a + (Math.floor(a / 4))) + date.getDate() + (Math.floor(365.25 * (y + 4716))) + (Math.floor(
    30.6001 * (m + 1))) - 1524.5) - 2451549.5) / LUNAR_MONTH;
};

const inLunarNewYear = (date: Date) => {
  return Math.floor(getNewMoons(date)) > Math.floor(getNewMoons(new Date(date.getFullYear(), 0, 20))) ? 1 : 0;
};

const lunarNewYear = (gregorianYear: number, offsetDays = 0) => {
  for (let i = 0; i <= 30; ++i) {
    const start = new Date(gregorianYear, 0, 1);
    start.setDate(21 + i);
    if (inLunarNewYear(start)) {
      start.setDate(start.getDate() - offsetDays);
      return start;
    }
  }
  return new Date();
};

export const nextLunarNewYear = (offsetDays?: number): Date => {
  const dayThisYear = lunarNewYear(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return lunarNewYear(thisYear + 1, offsetDays);
};

const holi = (gregorianYear: number, offsetDays = 0): Date => {
  const day = {
    2025: 14,
    2026: 3,
    2027: 22,
    2028: 11,
    2029: 1,
    2030: 20
  }[gregorianYear] ?? 10;

  return new Date(gregorianYear, 2, day - offsetDays);
};

export const nextHoli = (offsetDays?: number): Date => {
  const dayThisYear = holi(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return holi(thisYear + 1, offsetDays);
};

const diwali = (gregorianYear: number, offsetDays = 0): Date => {
  const date = {
    2025: [9, 20],
    2026: [10, 8],
    2027: [9, 28],
    2028: [9, 17],
    2029: [10, 5],
    2030: [9, 26]
  }[gregorianYear] ?? [10, 1];

  return new Date(gregorianYear, date[0], date[1] - offsetDays);
};

export const nextDiwali = (offsetDays?: number): Date => {
  const dayThisYear = diwali(thisYear, offsetDays);

  if (dayThisYear > now) {
    return dayThisYear;
  }
  return diwali(thisYear + 1, offsetDays);
};
