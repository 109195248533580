import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

import { app } from '../core/libs/Firebase';

import { ULogApplication, ULogSeverity, ULogTag, logSumoEvent } from 'Common/src/api/SumoLogicApi';
import { TAppElkImage } from 'TProtocol/prototypes/events/messages';
import { getPalette } from './colorPicker';
import { IUEventColors } from './event';

declare const ELK_APP_STORAGE_BUCKET: string;
declare const REACT_APP_STORAGE_BUCKET: string;

const TO_RADIANS = Math.PI / 180;
const FIREBASE_PREFIX = new RegExp('^https://firebasestorage.googleapis.com(?::443)?/');

function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
}

const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const signedImageCache: { [key: string]: string } = {};

export const uploadToFirebase = async (url: string, eventId: string): Promise<string> => {
  if (url.match(new RegExp(`^https://firebasestorage.googleapis.com(?::443)?/v0/b/(${ELK_APP_STORAGE_BUCKET}|${REACT_APP_STORAGE_BUCKET})/o/`))) {
    // Don't re-upload if it's already in our Firebase bucket
    return getNormalizedPhotoUrl(url);
  }

  const storage = getStorage(app);

  const auth = getAuth(app);
  const user = auth.currentUser;
  if (!user?.uid) {
    throw new Error('Firebase user not present.');
  }
  const fullPath = `events/user_images/${user.uid}/${eventId}-${Date.now()}`;
  const imageRef = ref(storage, fullPath);

  let blob: Blob;
  if (url.match(/^data:/)) {
    blob = dataURItoBlob(url);
  } else {
    try {
      const response = await fetch(url);
      blob = await response.blob();
      if (['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'].indexOf(blob.type) === -1) {
        // Something may have gone wrong during fetch (permission error or such).  Leave it as-is to allow for debugging.
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.WARN,
          userId: user.uid,
          tag: ULogTag.Network,
          message: `[lib/images] Image MIME type is not an image: ${blob.type}`
        });
        return getNormalizedPhotoUrl(url);
      }
    } catch (e) {
      // This may fail due to CORS. If something goes wrong, the safest thing to do is leave it alone.
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: user.uid,
        tag: ULogTag.Network,
        message: `[lib/images] Error during image download for upload: ${JSON.stringify(e)}`
      });
      return getNormalizedPhotoUrl(url);
    }
  }

  delete signedImageCache[eventId];
  const uploadMetadata = { cacheControl: 'public, max-age=86400, s-maxage=86400' };
  const uploadResult = await uploadBytes(imageRef, blob, uploadMetadata);

  return `https://firebasestorage.googleapis.com/v0/b/${ELK_APP_STORAGE_BUCKET}/o/${encodeURIComponent(uploadResult.ref.fullPath)}?alt=media`;
};

// Hack to fix potentially badly formatted photo urls coming in TAppContactLite objects.  If they are fixed, this step
// can be removed.
export const fixBadWrappedPhotoUrl = (photoUrl: string): string => {
  const match = photoUrl.match(
    new RegExp('^https://[^/]+.amazonaws.com/(https%3A/%2Ffirebasestorage.googleapis.com.*alt%3Dmedia)')
  );
  return match ? decodeURIComponent(match[1]) : photoUrl;
};

export const getNormalizedPhotoUrl = (url: string): string => {
  const photoUrl = fixBadWrappedPhotoUrl(url);
  if (!photoUrl.match(FIREBASE_PREFIX)) {
    return photoUrl;
  }

  try {
    const url = new URL(photoUrl);
    const [, prePath, imgPath] = url.pathname.match(/^(.*events)(\/user_images.*)$/) ?? [];
    if (!prePath || !imgPath) {
      return photoUrl;
    }

    url.pathname = prePath + encodeURIComponent(imgPath);
    url.searchParams.set('alt', 'media');
    return url.toString();
  } catch (e) {
    return photoUrl;
  }
};

const downloadUrlCache: Map<string, string> = new Map();
export const getDownloadUrl = async (photoUrl: string): Promise<string> => {
  if (!photoUrl.match(FIREBASE_PREFIX)) {
    return photoUrl;
  }

  if (photoUrl.match(/&token=[a-f0-9-]+$/)) {
    return photoUrl;
  }

  let downloadUrl = downloadUrlCache.get(photoUrl);
  if (downloadUrl !== undefined) {
    return downloadUrl;
  }

  const storage = getStorage(app);
  downloadUrl = await getDownloadURL(ref(storage, photoUrl));
  downloadUrlCache.set(photoUrl, downloadUrl);
  return downloadUrl;
};

export const getPhotoDataUrl = async (photoUrl: string): Promise<string> => {
  if (!photoUrl.match(FIREBASE_PREFIX)) {
    return photoUrl;
  }

  try {
    const downloadUrl = await getDownloadUrl(photoUrl);

    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = downloadUrl;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;
          ctx.drawImage(img, 0, 0);

          resolve(
            canvas.toDataURL('image/png')
          );
          return;
        }
        resolve(photoUrl);
      };
    });
  } catch (e) {
    return photoUrl;
  }
};

export const calculateImagePalette = async (photoUrl: string): Promise<IUEventColors> => {
  const dataUrl = await getPhotoDataUrl(photoUrl);
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = dataUrl;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const colors = getPalette(img);
      if (colors) {
        resolve(colors);
      } else {
        reject();
      }
    };
  });
};

export const findPromptForImageWithURL = (images: TAppElkImage[], photoUrl: string | undefined): string | undefined => {
  const selectedImage = images.find(image => image.imageUrl === photoUrl)
  return selectedImage ? selectedImage.prompt : undefined;
}
