import { useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useUserContext } from '../contexts/UserContext';
import { useEventCacheContext } from '../contexts/EventCacheContext';
import { updateEventAttendance } from '../lib/attendance';
import { b64tos } from '../util/stringutils';
import { updateAttendance, updateAttendanceThroughEmail } from '../api/ElkEventService';
import useInviteeUuidStorage from '../hooks/useInviteeUuidStorage';
import { UpdateAttendanceUserActionState } from './UpdateAttendeeUserAction';
import { useModalContext } from '../contexts/ModalContext';
import { PageEnum } from './Login/LoginPage';
import { IUInvitee } from '../lib/event';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkNotificationPreference,
  TElkUpdateAttendanceResponse
} from 'TProtocol/prototypes/events/messages';

const UpdateAttendanceThroughEmailAction = () => {
  const userContext = useUserContext();
  const eventCacheContext = useEventCacheContext();
  const modalContext = useModalContext();
  const {
    getInviteeUuid,
    getToken,
    getQuestionUuidsAndAnswers,
    setToken,
    setInviteeUuid,
    setEmail,
    setPhone,
    deleteEmail,
    deletePhone
  } = useInviteeUuidStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const params = useParams();

  const eventId: string | undefined = params.eventId;
  let response: string | undefined | null;
  let encodedMessage: string | undefined | null;
  let numGuests: string | undefined | null;
  let notificationPreference: TAppElkNotificationPreference | undefined;
  let encodedName: string | undefined | null;
  let inviteeUuid: string | undefined | null;
  let encodedEmail: string | undefined | null;
  let encodedPhone: string | undefined | null;
  let updateThroughEmail: string | undefined | null;
  const answers = getQuestionUuidsAndAnswers(eventId ?? '');

  const finishingRSVP = location.state?.finishingRSVP;
  const showPhotosPromo = location.state?.showPhotosPromo || finishingRSVP;

  if (queryParams.has('ee')) {
    encodedEmail = queryParams.get('ee');
  }
  if (queryParams.has('ep')) {
    encodedPhone = queryParams.get('ep');
  }
  if (queryParams.has('r')) {
    response = queryParams.get('r');
  }
  if (queryParams.has('em')) {
    encodedMessage = queryParams.get('em');
  }
  if (queryParams.has('ng')) {
    numGuests = queryParams.get('ng');
  }
  if (queryParams.has('en')) {
    encodedName = queryParams.get('en');
  }
  if (queryParams.has('fe')) {
    updateThroughEmail = queryParams.get('fe');
  }

  if (queryParams.has('np')) {
    const notifPrefString = queryParams.get('np');
    if (notifPrefString === 'phone') {
      notificationPreference = TAppElkNotificationPreference.PHONE;
    } else if (notifPrefString === 'email') {
      notificationPreference = TAppElkNotificationPreference.EMAIL;
    } else if (notifPrefString === 'both') {
      notificationPreference = TAppElkNotificationPreference.BOTH;
    } else {
      // Set default notification preference if coming through email
      notificationPreference = TAppElkNotificationPreference.EMAIL;
    }
  }

  if (queryParams.has('iu')) {
    const encodedInviteeUuid = queryParams.get('iu');
    if (encodedInviteeUuid) {
      try {
        inviteeUuid = b64tos(encodedInviteeUuid);
      } catch (e) {
        // EncodedInviteeUuid is not b64 encoded
        inviteeUuid = encodedInviteeUuid;
      }
    } else {
      inviteeUuid = getInviteeUuid(eventId);
    }
  } else {
    inviteeUuid = getInviteeUuid(eventId);
  }
  const token = queryParams.get('token') ?? getToken(eventId);

  let updatedMessage = '';

  if (encodedMessage) {
    let decodedMessage = '';
    try {
      decodedMessage = b64tos(encodedMessage);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceThroughEmailAction] Error during message decoding "${encodedMessage}": ${stringifyError(
          e)}`
      });
    }
    if (decodedMessage === 'm') {
      updatedMessage = '';
    } else if (decodedMessage !== undefined) {
      updatedMessage = decodedMessage;
    }
  }
  let decodedName: string | undefined = undefined;
  if (encodedName) {
    try {
      decodedName = b64tos(encodedName);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceThroughEmailAction] Error during name decoding "${encodedName}": ${stringifyError(
          e)}`
      });
    }
  }

  let decodedEmail: string | undefined = undefined;
  if (encodedEmail) {
    try {
      decodedEmail = b64tos(encodedEmail);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceThroughEmailAction] Error during email decoding "${encodedEmail}": ${stringifyError(
          e)}`
      });
    }
  }

  let decodedPhone: string | undefined = undefined;
  if (encodedPhone) {
    try {
      decodedPhone = b64tos(encodedPhone);
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[UpdateAttendanceThroughEmailAction] Error during phone decoding "${encodedPhone}": ${stringifyError(
          e)}`
      });
    }
  }

  const isThroughEmail = updateThroughEmail === '1';

  useEffect(() => {
    if (eventId !== undefined) {
      if (!userContext.isLoggedIn() || (userContext.name !== undefined && userContext.name.trim() !== '')) {
        void handleRSVP(eventId);
      }
    } else {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: '[UpdateAttendanceThroughEmailAction] eventId is undefined'
      });
      navigate('/');
    }
  }, [eventId, userContext.name]);

  const handleRSVP = async (eventId: string) => {
    let additionalGuests = 0;
    if (numGuests) {
      additionalGuests = parseInt(numGuests);
    }
    let stat: TAppElkAttendeeStatus;
    if (response === 'yes') {
      stat = TAppElkAttendeeStatus.YES;
    } else if (response === 'no') {
      stat = TAppElkAttendeeStatus.NO;
    } else {
      stat = TAppElkAttendeeStatus.SEEN;
    }

    const inviteeId = inviteeUuid || uuidv4();

    const attendee = new IUInvitee({
      inviteeId,
      rsvpStatus: stat,
      role: TAppElkAttendeeRole.ATTENDEE,
      additionalGuestCount: additionalGuests,
      userId: userContext.id,
      photoUrl: userContext.photoUrl,
      name: decodedName,
      notificationPreference,
      answers,
      isNew: inviteeUuid === undefined
    });

    if (decodedEmail !== undefined) {
      attendee.email = decodedEmail;
      setEmail(decodedEmail);
    }

    if (decodedPhone !== undefined) {
      attendee.phone = decodedPhone;
      setPhone(decodedPhone);
    }

    if (updatedMessage) {
      attendee.rsvpMessage = updatedMessage;
    }

    const event = await eventCacheContext.fetchEvent({ eventId, inviteeUuid: inviteeId });

    if (event?.id && token) {
      let updateRes = new TElkUpdateAttendanceResponse();
      if (userContext.isLoggedIn()) {
        attendee.name = userContext.name ?? decodedName;
        attendee.email = userContext.getEmail();
        attendee.phone = userContext.getPhoneNumber();
        deleteEmail();
        deletePhone();

        updateRes = await updateAttendance(userContext, eventId, attendee.toTAppElkInvitee(), undefined, undefined,
          undefined, false);
      } else {
        updateRes = await updateAttendanceThroughEmail(eventId, attendee.toTAppElkInvitee(), undefined, token, false, isThroughEmail);
      }
      modalContext.hide();

      if (updateRes.shouldPromptLogin === true) {
        const loginMethodPage = updateRes.email !== undefined ? PageEnum.Email : PageEnum.Phone;

        if (updateRes.phone !== undefined) {
          setPhone(updateRes.phone);
        }
        if (updateRes.email !== undefined) {
          setEmail(updateRes.email);
        }

        navigate(
          `/event/updateuser/action/${eventId}`,
          {
            state: {
              token,
              showPhotosPromo,
              showRSVP: finishingRSVP !== true,
              hostedBy: event.hostedBy,
              eventTitle: event.title,
              page: loginMethodPage,
              eventId,
              rsvpStatus: stat,
              forceLogin: true,
              inviteeName: attendee.name
            } as UpdateAttendanceUserActionState,
            replace: true
          }
        );
        return;
      }

      const serverInviteeId = updateRes.inviteeId ?? inviteeId;
      setInviteeUuid(eventId, serverInviteeId);
      setToken(eventId, token);
      eventCacheContext.setEvent(updateEventAttendance(event, attendee));

      if (finishingRSVP !== true) {
        navigate(`/event/${eventId}?r=${response}`, { state: { showRSVP: true, nonUpdateRSVPThroughEmail: updateRes.isNonUpdate }, replace: true });
      } else if (response === 'no') {
        navigate(`/event/${eventId}`, { replace: true });
      } else if (userContext.isLoggedIn()) {
        navigate(`/event/${eventId}`, { state: { showPhotoPromo: true }, replace: true });
      } else {
        const loginMethodPage = attendee.email !== undefined && attendee.phone === undefined ? PageEnum.Email : PageEnum.Phone;

        navigate(
          `/event/updateuser/action/${eventId}`,
          {
            state: {
              inviteeUuid: serverInviteeId,
              token,
              showPhotosPromo,
              hostedBy: event.hostedBy,
              eventTitle: event.title,
              eventId,
              page: loginMethodPage
            } as UpdateAttendanceUserActionState,
            replace: true
          }
        );
      }
    } else {
      navigate('/');
    }
  };
  return null;
};

export default UpdateAttendanceThroughEmailAction;
