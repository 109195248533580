import React from 'react';
import styled from 'styled-components';

import { IUEvent } from '../../lib/event';
import { useUserContext } from '../../contexts/UserContext';

import sunshinePhotosPng from 'Common/src/assets/ShineIcon.svg';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { DeviceQueries } from 'Common/src/components/styled';
import PhotoGroup from '../../assets/promos/photoGroup.png';

const PhotosLink = ({ event, isRo = false, useLogo = true }: {
  event: IUEvent,
  isRo?: boolean,
  useLogo?: boolean
}) => {
  const userContext = useUserContext();
  const albumDetails = event.albumDetails;

  const onLinkClick = () => {
    if (!isRo) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.INFO,
        userId: userContext.id,
        tag: ULogTag.UserAction,
        message: '[PhotosLink] Clicked on photos link'
      });
    }
  };

  if (albumDetails === undefined) {
    return null;
  }

  const message = <Text>
    Share photos from <BoldedText>{event.title}</BoldedText> using the <LinkText>Shine app</LinkText>
  </Text>

  const image = useLogo ? <IconImg src={sunshinePhotosPng}/> : <Image src={PhotoGroup}/>

  if (albumDetails.albumUrl !== undefined) {
    return <AlbumLink href={!isRo ? `https://${albumDetails.albumUrl}` : ''} target="_blank" rel="noreferrer"
                      onClick={onLinkClick}>
      {image}
      {message}
    </AlbumLink>;
  } else {
    return <ContentsContainer>
      {image}
      {message}
    </ContentsContainer>;
  }
};

const IconImg = styled.img`
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
`;

const Image = styled.img`
  @media (${DeviceQueries.mobile}) {
    width: 88px;
  }

  @media not (${DeviceQueries.mobile}) {
    height: 100px;
  }
`;

const ContentsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  gap: 16px;

  @media (${DeviceQueries.mobile}) {
    padding: 0;
  }
`;

const AlbumLink = styled.a`
  text-decoration: none;
  color: currentColor;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  gap: 16px;

  @media (${DeviceQueries.mobile}) {
    padding: 0;
  }
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 22px;

  @media (${DeviceQueries.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const BoldedText = styled.span`
  font-weight: 600;
  font-size: 18px;

  @media (${DeviceQueries.mobile}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const LinkText = styled(BoldedText)`
  text-decoration: underline;
`

export default PhotosLink;
