import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TAppElkEventAnswer, } from 'TProtocol/prototypes/events/messages';
import useSpotify, { SpotifyTrack } from '../../lib/spotify';
import CloseIcon from 'Common/src/components/icons/CloseIcon';
import { tAppElkEventAnswerValueToString } from '../../lib/answer';
import EventFormInput from '../EventForm/EventFormInput';

interface Props {
  answer: TAppElkEventAnswer;
  onSongSelect: (song: SpotifyTrack) => void;
  onRemoveSong: () => void;
}

const EditableSongAnswer = ({ answer, onSongSelect, onRemoveSong }: Props) => {
  const spotify = useSpotify();

  const [songResults, setSongResults] = useState<SpotifyTrack[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const menuDivRef = useRef<HTMLDivElement>(null);

  const handleSearchQueryChange = async (value: string) => {
    setSearchQuery(value);
    if (!value || value.trim() === '') {
      setSongResults([]);
    } else {
      const results = await spotify.searchSpotifyTrack(value);
      setSongResults(results);
    }
  };

  const clearSongAnswer = () => {
    onRemoveSong();
    setSongResults([]);
    setSearchQuery('');
  };

  const songSearchInput = <>
    <EventFormInput value={searchQuery}
                    onChange={handleSearchQueryChange}
                    isLabelOutside={true}
                    excludeBackground={true}
                    inModal={true}
                    noVertMargin={true}/>
    <DropdownMenuAnchor>
      {songResults.length > 0 && <DropdownMenu ref={menuDivRef}>
        {
          songResults.map((song) =>
            <SongItem
              key={song.id}
              onClick={() => onSongSelect(song)}>
              <SongImage src={spotify.getSmallestAlbumImage(song.album)?.url}/>
              {song.name} • {song.artists[0].name}
            </SongItem>)
        }
      </DropdownMenu>}
    </DropdownMenuAnchor>
  </>;

  const selectedSong = <SelectedSongCard>
    {tAppElkEventAnswerValueToString(answer.answerValue)}
    <CloseIconContainer><CloseIcon onClick={clearSongAnswer}/></CloseIconContainer>
  </SelectedSongCard>;

  return <DropdownButtonContainer>
    {answer.answerValue.song?.songUri ? selectedSong : songSearchInput}
  </DropdownButtonContainer>;
};

const DropdownMenuAnchor = styled.div`
  height: 0;
  position: relative;
  z-index: 200;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #c9c9c9;
  color: black;
  border-radius: 6px;
  box-shadow: 0 0 24px 0 rgba(var(--sp-text-rgb), 0.5);
`;

const SongItem = styled.div`
  cursor: pointer;
  padding: 6px 10px;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: var(--shine-highlight-color);
    color: var(--shine-system-button-text-color);
  }

  &:first-of-type {
    border-radius: 6px 6px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 6px 6px;
  }

  &:nth-of-type(n + 2) {
    border-top: 1px solid #5A5A5A;
  }
`;

const SongImage = styled.img`
  width: 50px;
  height: 50px;
`;

const DropdownButtonContainer = styled.div`
  width: 100%;
`;

const SelectedSongCard = styled.div`
  display: flex;
  padding: 10px;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  border-radius: 12px;
  background: rgba(var(--sp-bg-rgb), 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
`;

export default EditableSongAnswer;
