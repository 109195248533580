import React, { MouseEvent, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { getEventTintColor } from '../../lib/colorPicker';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  noColor?: boolean;
  outline?: boolean;
  customButtonColor?: string;
  customTextColor?: string;
}

const ColorButton = ({
                       type,
                       children,
                       onClick,
                       disabled,
                       outline,
                       noColor,
                       customButtonColor,
                       customTextColor
                     }: PropsWithChildren<Props>) => {
  const backgroundContext = useBackgroundContext();
  const eventTintColor = getEventTintColor(backgroundContext.colors.primary);

  const outlineButtonBgColor = 'var(--sp-bg-color)';
  const grayButtonBgColor = '#B3B3B3';

  const bgColor = customButtonColor !== undefined ? customButtonColor : outline ? outlineButtonBgColor : noColor ? grayButtonBgColor : eventTintColor;
  const borderColor = customButtonColor !== undefined ? customButtonColor : outline ? 'rgba(var(--sp-text-rgb), 0.20)' : bgColor;
  const textColor = customTextColor !== undefined ? customTextColor : outline ? eventTintColor : 'var(--sp-bg-color)';

  return <Button type={type} $bgColor={bgColor} $borderColor={borderColor} $textColor={textColor} onClick={onClick}
                 disabled={disabled}>
    {children}
  </Button>;
};

const Button = styled.button<{
  $bgColor: string,
  $borderColor: string,
  $textColor: string
}>`
  font-family: Plus Jakarta Sans, sans-serif;
  background: ${({ $bgColor }) => $bgColor};
  color: ${({ $textColor }) => $textColor};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  --button-text-color: ${({ $textColor }) => $textColor};
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;

  &:active {
    filter: brightness(90%);
  }

  &:hover:not([disabled]) {
    filter: brightness(95%);
  }

  &:disabled {
    opacity: .5;
  }
`;

export default ColorButton;
