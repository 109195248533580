// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:100,300,400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* This needs to be imported from an actual css file (not in a styled-components global), due to
 * https://github.com/styled-components/styled-components/issues/2911 */
`, "",{"version":3,"sources":["webpack://./packages/elk-web/src/core/App.css"],"names":[],"mappings":"AAAA;uEACuE","sourcesContent":["/* This needs to be imported from an actual css file (not in a styled-components global), due to\n * https://github.com/styled-components/styled-components/issues/2911 */\n@import url('https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:100,300,400,500,600,700&display=swap');\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
