import React from 'react';
import styled from 'styled-components';
import { DarkMask } from '../../contexts/BackgroundContext';
import { DeviceQueries } from 'Common/src/components/styled';

export const InviteDarkMask = styled(DarkMask)`
  position: absolute;
  top: 0;
  left: 0;
`

export const InviteContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 1;
  cursor: pointer;

  @media (${DeviceQueries.mobile}) {
    gap: 25px;
    flex-direction: column;
  }
`

export const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 37px;
`

export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const EventTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 50.952px;
  font-weight: 600;
  line-height: 60px;
`

export const EventDetails = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
`

export const EventDescription = styled(EventDetails)`
  text-align: left;
`

export const EventImage = styled.img`
  height: 512px;
  width: 512px;

  @media (${DeviceQueries.mobile}) {
    height: 344px;
    width: 344px;
  }
`
