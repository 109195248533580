import React, { MouseEvent, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  outline?: boolean;
  secondary?: boolean;
}

const SystemButton = ({
                        type,
                        children,
                        outline = false,
                        secondary = false,
                        onClick,
                        disabled
                      }: PropsWithChildren<Props>) => {
  return <ButtonInternal type={type} onClick={onClick} disabled={disabled} $outline={outline} $secondary={secondary}>
    {children}
  </ButtonInternal>;
};

export const ButtonInternal = styled.button<{ $outline: boolean, $secondary: boolean }>`
  font-family: Plus Jakarta Sans, sans-serif;
  background-color: ${({ $outline, $secondary }) => $outline ? '#FFF' : $secondary ? '#B3B3B3' : '#000'};
  color: ${({ $outline }) => $outline ? '#000' : '#FFF'};
  border: 1px solid ${({
                         $outline,
                         $secondary
                       }) => $outline ? 'rgba(var(--sp-text-rgb), 0.20)' : $secondary ? '#B3B3B3' : '#000'};
  cursor: pointer;

  display: flex;
  height: 39px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:active {
    filter: brightness(90%);
  }

  &:hover:not(:disabled) {
    filter: brightness(95%);
  }

  &:disabled {
    //opacity: .5;
    background-color: #CDCDCD;
    border-color: #CDCDCD;
  }
`;

export default SystemButton;
