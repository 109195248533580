import { DEFAULT_COLORS } from '../contexts/BackgroundContext';
import { v4 as uuidv4 } from 'uuid';
import PlaceResult = google.maps.places.PlaceResult;

import { uploadToFirebase } from './images';
import { isPast } from './time';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkBackgroundAnimation,
  TAppElkCohostRequestStatus,
  TAppElkDraftEvent,
  TAppElkDraftEventDetails,
  TAppElkDraftEventQuestion,
  TAppElkDraftInvitee,
  TAppElkDraftLocation,
  TAppElkEvent,
  TAppElkEventAnswer,
  TAppElkEventDetails,
  TAppElkEventMetadata,
  TAppElkEventNotification,
  TAppElkEventQuestion,
  TAppElkEventQuestionType,
  TAppElkEventTheme,
  TAppElkImage,
  TAppElkInvitee,
  TAppElkInviteStatus,
  TAppElkLocation,
  TAppElkMessage,
  TAppElkNotificationPreference,
  TAppElkPhotoUploadMode,
  TAppElkReviewStatus,
  TElkGetAlbumDetailsResponse
} from 'TProtocol/prototypes/events/messages';
import { TAppAddress } from 'TProtocol/common/models';

declare const ELK_WEBAPP_URL: string;

export enum EventsFilterType {
  All = 'ALL',
  HostedByYou = 'HOSTED_BY_YOU',
  Upcoming = 'UPCOMING',
  Past = 'PAST',
  Drafts = 'DRAFTS'
}

export class IUEventQuestion {
  constructor({ question, isRequired, questionUuid, questionType }: Partial<IUEventQuestion> = {}) {
    this.question = question ?? '';
    this.isRequired = isRequired ?? false;
    this.questionUuid = questionUuid ?? uuidv4();
    this.questionType = questionType ?? TAppElkEventQuestionType.TEXT;
  }

  public question: string;
  public isRequired: boolean;
  public questionUuid: string;
  public questionType: TAppElkEventQuestionType = TAppElkEventQuestionType.TEXT;

  public toTAppElkEventQuestion = (): TAppElkEventQuestion => {
    return new TAppElkEventQuestion(this);
  };

  public toTAppElkDraftEventQuestion = (): TAppElkDraftEventQuestion => {
    return new TAppElkDraftEventQuestion(this);
  };
}

export class IUInvitee {
  constructor({
                inviteeId,
                name,
                phone,
                email,
                photoUrl,
                userId,
                role,
                inviteStatus,
                rsvpStatus,
                rsvpMessage,
                additionalGuestCount,
                notificationPreference,
                answers,
                waitlistStatus,
                cohostStatus,
                isNew = false
              }: Partial<IUInvitee> = {}) {
    this.inviteeId = inviteeId ?? uuidv4();
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.photoUrl = photoUrl;
    this.userId = userId;
    this.role = role ?? TAppElkAttendeeRole.UNDEFINED;
    this.inviteStatus = inviteStatus;
    this.rsvpStatus = rsvpStatus ?? (this.role === TAppElkAttendeeRole.ORGANIZER ? TAppElkAttendeeStatus.YES : TAppElkAttendeeStatus.INVITED);
    this.rsvpMessage = rsvpMessage;
    this.additionalGuestCount = additionalGuestCount ?? 0;
    this.notificationPreference = notificationPreference;
    this.answers = answers;
    this.waitlistStatus = waitlistStatus;
    this.cohostStatus = cohostStatus;
    this.isNew = isNew;
  }

  public inviteeId: string;
  public name?: string;
  public email?: string;
  public phone?: string;
  public photoUrl?: string;
  public userId?: string;
  public role: TAppElkAttendeeRole;
  public inviteStatus?: TAppElkInviteStatus;
  public rsvpStatus: TAppElkAttendeeStatus;
  public rsvpMessage?: string;
  public additionalGuestCount: number;
  public notificationPreference?: TAppElkNotificationPreference;
  public answers?: Array<TAppElkEventAnswer>;
  public waitlistStatus?: TAppElkReviewStatus;
  public cohostStatus?: TAppElkCohostRequestStatus;
  public isNew: boolean;

  public toTAppElkInvitee = (): TAppElkInvitee => {
    return new TAppElkInvitee({
      ...this,
      answers: this.answers?.map(answer => new TAppElkEventAnswer(answer))
    });
  };

  public toTAppElkDraftInvitee = (): TAppElkDraftInvitee => {
    return new TAppElkDraftInvitee({
      ...this,
      answers: this.answers?.map(answer => new TAppElkEventAnswer(answer))
    });
  };
}

export class IULocation {
  constructor({ displayName, placeName, address, place }: Partial<IULocation> = {}) {
    this.displayName = displayName ?? '';
    this.placeName = placeName;
    this.place = place;
    this.address = address;
  }

  public displayName: string;
  public placeName?: string;
  public place?: PlaceResult;
  public address?: TAppAddress;

  public toTAppElkLocation = (): TAppElkLocation => {
    return new TAppElkLocation({
      displayName: this.displayName,
      address: new TAppAddress({
        ...this.address,
        provenances: new Set()
      }),
      placeName: this.placeName,
      // rawAddress: JSON.stringify(this.place) // TODO: uncomment when raw address storage is ready
    });
  };

  public toTAppElkDraftLocation = (): TAppElkDraftLocation => {
    return new TAppElkDraftLocation({
      displayName: this.displayName,
      address: new TAppAddress({
        ...this.address,
        provenances: new Set()
      }),
      placeName: this.placeName,
      // rawAddress: JSON.stringify(this.place) // TODO: uncomment when raw address storage is ready
    });
  };
}

export class IUEvent {
  constructor({
                isPreview,
                title,
                id,
                organizerUuid,
                lastAutoSaveTs,
                url,
                colors,
                primaryOverrideColor,
                animation,
                photoUrl,
                isHost,
                isCreate,
                isClone,
                isDraft,
                inspirationId,
                isFromQuickCreate,
                isPlaceholderUrl,
                isFinishedWithQuickCreate,
                openInvite,
                description,
                suggestionTags,
                modificationId,
                messageModificationId,
                publicGuestList,
                cancelledTimestamp,
                messages,
                messagesArePersonal,
                hostedBy,
                hostEmail,
                hostNotification,
                communicationPreference,
                hasWaitlist,
                message,
                timeZone,
                remindersEnabled,
                imageFetchError,
                previousImages,
                questions,
                existingAlbumId,
                albumDetails,
                prompt,
                backgroundAnimationSearchQuery,
                backgroundAnimationUrl,
                videoBackgroundDisabled,
                attendees,
                location,
                date,
                dateQuery,
                startTime,
                endTime,
                hostUploadMode,
                loading,
                playlistId,
                errors,
                lastSavedTimestamp,
                viewedEventOptions,
                cohostLink
              }: Partial<IUEvent> = {}) {
    const uuid = uuidv4();
    let urlToPut: string | undefined = undefined;
    if (url) {
      urlToPut = url;
    } else if (id) {
      urlToPut = `https://${ELK_WEBAPP_URL}/event/${id}`;
    } else {
      urlToPut = `https://${ELK_WEBAPP_URL}/event/${uuid}`;
    }
    this.isCreate = isCreate ?? true;
    this.isPreview = isPreview ?? true;
    this.isClone = isClone ?? false;
    this.isDraft = isDraft ?? false;
    this.inspirationId = inspirationId;
    this.isFromQuickCreate = isFromQuickCreate ?? false;
    this.isPlaceholderUrl = isPlaceholderUrl ?? true;
    this.isFinishedWithQuickCreate = isFinishedWithQuickCreate ?? false;
    this.title = title ?? '';
    this.id = id ?? uuid;
    this.organizerUuid = organizerUuid;
    this.lastAutoSaveTs = lastAutoSaveTs;
    this.url = urlToPut;
    this.colors = colors ?? DEFAULT_COLORS;
    this.primaryOverrideColor = primaryOverrideColor;
    this.animation = animation ?? TAppElkBackgroundAnimation.LIGHT_SOURCE;
    this.photoUrl = photoUrl;
    this.imageFetchError = imageFetchError;
    this.previousImages = previousImages;
    this.isHost = isHost ?? false;
    this.openInvite = openInvite ?? true;
    this.publicGuestList = publicGuestList ?? true;
    this.remindersEnabled = remindersEnabled ?? true;
    this.description = description ?? '';
    this.suggestionTags = suggestionTags;
    this.modificationId = modificationId ?? Date.now().toString();
    this.messageModificationId = messageModificationId ?? Date.now().toString();
    this.cancelledTimestamp = cancelledTimestamp;
    this.messages = messages ?? null;
    this.messagesArePersonal = messagesArePersonal ?? false;
    this.hostedBy = hostedBy ?? '';
    this.hostEmail = hostEmail;
    this.hostNotification = hostNotification ?? TAppElkEventNotification.ATTENDEE_RSVP;
    this.communicationPreference = communicationPreference ?? TAppElkNotificationPreference.BOTH;
    this.hasWaitlist = hasWaitlist ?? false;
    this.timeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.message = message ?? '';
    this.questions = questions ?? [];
    this.existingAlbumId = existingAlbumId;
    this.albumDetails = albumDetails;
    this.prompt = prompt;
    this.attendees = attendees ?? [];
    this.location = location ?? new IULocation();
    this.date = date;
    this.dateQuery = dateQuery;
    this.startTime = startTime ?? 0;
    this.endTime = endTime ?? 0;
    this.errors = errors ?? {};
    this.backgroundAnimationSearchQuery = backgroundAnimationSearchQuery;
    this.backgroundAnimationUrl = backgroundAnimationUrl;
    this.videoBackgroundDisabled = videoBackgroundDisabled;
    this.hostUploadMode = hostUploadMode;
    this.loading = loading ?? false;
    this.playlistId = playlistId;
    this.lastSavedTimestamp = lastSavedTimestamp;
    this.viewedEventOptions = viewedEventOptions;
    this.cohostLink = cohostLink;
  }

  isCreate: boolean;
  isPreview: boolean;
  isClone: boolean;
  isDraft: boolean;
  inspirationId?: string;
  isFromQuickCreate: boolean;
  isPlaceholderUrl: boolean;
  isFinishedWithQuickCreate: boolean;
  title: string;
  id: string;
  organizerUuid?: string;
  lastAutoSaveTs?: number;
  url: string;
  colors: IUEventColors;
  primaryOverrideColor?: string;
  animation: TAppElkBackgroundAnimation;
  photoUrl?: string;
  previousPhotoUrl?: string;
  imageFetchError?: boolean;
  previousImages?: TAppElkImage[];
  isHost: boolean;
  openInvite: boolean;
  publicGuestList: boolean;
  description: string;
  suggestionTags?: string[];
  modificationId: string;
  messageModificationId: string;
  cancelledTimestamp?: number;
  messages: TAppElkMessage[] | null;
  messagesArePersonal: boolean;
  hostedBy: string;
  hostEmail?: string;
  hostNotification: TAppElkEventNotification;
  communicationPreference: TAppElkNotificationPreference;
  hasWaitlist: boolean;
  message: string;
  timeZone: string;
  remindersEnabled: boolean;
  questions: IUEventQuestion[];
  existingAlbumId?: string;
  albumDetails?: TElkGetAlbumDetailsResponse;
  prompt?: string;
  backgroundAnimationSearchQuery?: string;
  backgroundAnimationUrl?: string;
  videoBackgroundDisabled?: boolean;
  attendees: IUInvitee[];
  location: IULocation;
  date?: Date;
  dateQuery?: string;
  startTime: number;
  endTime: number;
  errors: IUEventErrors;
  hostUploadMode: TAppElkPhotoUploadMode | undefined;
  loading: boolean;
  playlistId?: string;
  lastSavedTimestamp?: number;
  viewedEventOptions?: boolean;
  cohostLink?: string;

  public convertToTAppElkEvent = async (userName: string): Promise<TAppElkEvent> => {
    const title = this.title;
    const tzID: string = this.timeZone;
    let photoUrl: string | undefined = this.photoUrl;
    if (photoUrl?.match(/^(data|blob):/)) {
      photoUrl = await uploadToFirebase(photoUrl, this.id);
    }

    let hostString: string;
    if (this.hostedBy != '') {
      hostString = this.hostedBy;
    } else {
      hostString = userName;
    }

    return new TAppElkEvent({
      id: this.id,
      url: this.url,
      attendees: this.attendees.map((attendee) => attendee.toTAppElkInvitee()),
      organizerUuid: this.organizerUuid ?? '',
      details: new TAppElkEventDetails({
        title: title.trim(),
        eventDescription: this.description.trim(),
        startTime: this.startTime,
        timezone: tzID,
        endTime: this.endTime,
        location: this.location.toTAppElkLocation(),
        hostedBy: hostString,
        cancelledTimestamp: this.cancelledTimestamp,
        playlistId: this.playlistId
      }),
      theme: new TAppElkEventTheme({
        colors: [this.colors.primary, this.colors.secondary, this.colors.highlightText, this.colors.text, this.colors.buttonTextColor],
        animation: this.animation,
        backgroundAnimationSearchQuery: this.backgroundAnimationSearchQuery,
        backgroundAnimationUrl: this.backgroundAnimationUrl,
        videoBackgroundDisabled: this.videoBackgroundDisabled,
        photoUrl
      }),
      metadata: new TAppElkEventMetadata({
        openInvite: this.openInvite,
        publicGuestList: this.publicGuestList,
        disableReminders: !this.remindersEnabled,
        hostNotifications: [this.hostNotification],
        communicationPreference: this.communicationPreference,
        hasWaitlist: this.hasWaitlist
      }),
      questions: this.questions.map((question) => question.toTAppElkEventQuestion())
    });
  };

  public convertToTAppElkDraftEvent = async (userName: string): Promise<TAppElkDraftEvent> => {
    const title = this.title;
    const tzID: string = this.timeZone;
    let photoUrl: string | undefined = this.photoUrl;
    if (photoUrl?.match(/^(data|blob):/)) {
      photoUrl = await uploadToFirebase(photoUrl, this.id);
    }

    let hostString: string;
    if (this.hostedBy != '') {
      hostString = this.hostedBy;
    } else {
      hostString = userName;
    }

    return new TAppElkDraftEvent({
      id: this.id,
      url: this.url,
      attendees: this.attendees.map((attendee) => attendee.toTAppElkDraftInvitee()),
      organizerUuid: '',
      details: new TAppElkDraftEventDetails({
        title: title,
        eventDescription: this.description,
        startTime: this.startTime,
        timezone: tzID,
        endTime: this.endTime,
        location: this.location.toTAppElkDraftLocation(),
        hostedBy: hostString,
        cancelledTimestamp: this.cancelledTimestamp,
        playlistId: this.playlistId
      }),
      theme: new TAppElkEventTheme({
        colors: [this.colors.primary, this.colors.secondary, this.colors.highlightText, this.colors.text, this.colors.buttonTextColor],
        animation: this.animation,
        backgroundAnimationSearchQuery: this.backgroundAnimationSearchQuery,
        backgroundAnimationUrl: this.backgroundAnimationUrl,
        videoBackgroundDisabled: this.videoBackgroundDisabled,
        photoUrl
      }),
      metadata: new TAppElkEventMetadata({
        openInvite: this.openInvite,
        publicGuestList: this.publicGuestList,
        disableReminders: !this.remindersEnabled,
        hostNotifications: [this.hostNotification],
        communicationPreference: this.communicationPreference,
        hasWaitlist: this.hasWaitlist
      }),
      questions: this.questions.map((question) => question.toTAppElkDraftEventQuestion()),
      lastSavedTimestamp: this.lastSavedTimestamp
    });
  };
}

export const copy = (event?: IUEvent): IUEvent | undefined => {
  if (event === undefined) {
    return undefined;
  }

  return new IUEvent({
    ...event,
    colors: { ...event.colors },
    messages: event.messages?.map((message) => {
      return new TAppElkMessage(message);
    }) ?? null,
    questions: event.questions?.map((question) => {
      return new IUEventQuestion(question);
    }) ?? null,
    albumDetails: event.albumDetails ? new TElkGetAlbumDetailsResponse(event.albumDetails) : undefined,
    attendees: event.attendees.map((attendee) => {
      return new IUInvitee(attendee);
    }),
    location: new IULocation(event.location),
    errors: { ...event.errors }
  });
};

export class IUEventErrors {
  invalidTitle?: boolean;
  invalidStartDate?: boolean;
  invalidDatePair?: boolean;
  invalidDuration?: boolean;
  missingImage?: boolean;
  invalidLocation?: boolean;
}

export interface IUEventColors {
  primary: string;
  secondary: string;
  highlightText: string;
  text: string;
  buttonTextColor: string;
}

const ONE_DAY = 24 * 60 * 60 * 1000;

export const cloneEvent = (event: IUEvent): IUEvent => {
  const newEventId = uuidv4();

  const newEvent = new IUEvent({
    ...event,
    id: newEventId,
    isCreate: true,
    isClone: true,
    isDraft: true,
    url: `https://${ELK_WEBAPP_URL}/event/${newEventId}`,
    startTime: Date.now() + ONE_DAY,
    endTime: 0,
    modificationId: Date.now().toString(),
    messageModificationId: Date.now().toString(),
    cancelledTimestamp: undefined,
    albumDetails: undefined,
    existingAlbumId: undefined,
    messages: []
  });

  newEvent.attendees.forEach((attendee) => {
    attendee.inviteeId = uuidv4();
    if (attendee.role !== TAppElkAttendeeRole.ORGANIZER) {
      attendee.inviteStatus = TAppElkInviteStatus.PENDING;
      attendee.rsvpStatus = TAppElkAttendeeStatus.INVITED;
      attendee.rsvpMessage = undefined;
    }
    attendee.additionalGuestCount = 0;
  });

  return newEvent;
};

export const isHost = (event: IUEvent, userId: string) => event.attendees.find(
  attendee => attendee.role === TAppElkAttendeeRole.ORGANIZER && attendee.userId === userId
);

export type EventsByType = {
  [key in EventsFilterType]: IUEvent[];
};

export const separateEvents = (events: IUEvent[], userId: string): EventsByType => {
  const map: EventsByType = {
    [EventsFilterType.All]: [],
    [EventsFilterType.Past]: [],
    [EventsFilterType.HostedByYou]: [],
    [EventsFilterType.Upcoming]: [],
    [EventsFilterType.Drafts]: []
  };

  events.forEach(event => {
    map[EventsFilterType.All].push(event);

    if (isPast(event)) {
      map[EventsFilterType.Past].push(event);
    } else {
      map[EventsFilterType.Upcoming].push(event);
    }

    if (isHost(event, userId)) {
      map[EventsFilterType.HostedByYou].push(event);
    }
  });

  return map;
};

export const areEqualEvents = (eventA?: IUEvent, eventB?: IUEvent): boolean => {
  if (eventA?.startTime !== undefined && eventB?.startTime !== undefined) {
    if (Math.abs(eventA.startTime - eventB.startTime) > 60000) {
      return false;
    }
  }

  // Can't look at lastSavedTimestamp, otherwise it'll keep triggering a change every time
  // previousImages and prompt are set on IUEvent after event has been fetched, and changes to these
  // will be reflected through change in photoUrl
  const a = {
    ...eventA,
    lastSavedTimestamp: null,
    startTime: null,
    date: null,
    primaryOverrideColor: null,
    previousImages: [],
    prompt: null,
    hostUploadMode: undefined,
    errors: null,
    viewedEventOptions: undefined
  };
  const b = {
    ...eventB,
    lastSavedTimestamp: null,
    startTime: null,
    date: null,
    primaryOverrideColor: null,
    previousImages: [],
    prompt: null,
    hostUploadMode: undefined,
    errors: null,
    viewedEventOptions: undefined
  };
  return JSON.stringify(a) === JSON.stringify(b);
};

export const isEventEmpty = (event: IUEvent): boolean => {
  return event.title === '' &&
    event.description === '' &&
    (event.location.address?.address1 === '' || event.location.address?.address1 === undefined) &&
    (event.photoUrl === '' || event.photoUrl === undefined);
};
