import React from 'react';

const PhotoSolid = ({ size = 19, color = 'currentColor' }: { size?: number, color?: string }) => {
  return <svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="heroicons-solid/photo">
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd"
            d="M1.23047 5.04048C1.23047 4.11688 1.97919 3.36816 2.90278 3.36816H15.1664C16.09 3.36816 16.8387 4.11688 16.8387 5.04048V13.9595C16.8387 14.8831 16.09 15.6318 15.1664 15.6318H2.90278C1.97919 15.6318 1.23047 14.8831 1.23047 13.9595V5.04048ZM2.34534 12.5181V13.9595C2.34534 14.2673 2.59492 14.5169 2.90278 14.5169H15.1664C15.4743 14.5169 15.7238 14.2673 15.7238 13.9595V12.5181L13.725 10.5192C13.2896 10.0838 12.5837 10.0838 12.1483 10.5192L11.4952 11.1723L12.2159 11.893C12.4336 12.1107 12.4336 12.4636 12.2159 12.6813C11.9983 12.899 11.6453 12.899 11.4276 12.6813L7.59318 8.8469C7.15779 8.41151 6.45189 8.41151 6.01651 8.8469L2.34534 12.5181ZM9.87075 6.71279C9.87075 6.25099 10.2451 5.87663 10.7069 5.87663C11.1687 5.87663 11.5431 6.25099 11.5431 6.71279C11.5431 7.17459 11.1687 7.54895 10.7069 7.54895C10.2451 7.54895 9.87075 7.17459 9.87075 6.71279Z"
            fill={color}/>
    </g>
  </svg>;
};

export default PhotoSolid;
