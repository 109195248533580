import React, { ReactNode } from 'react';

const URL_REGEX = /https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

export const parseHyperlinks = (str: string): ReactNode => {
  const nodes: ReactNode[] = [];

  let currentString = '';

  str.split(' ')
    .forEach((part, index) =>
      {
        if (URL_REGEX.test(part)) {
          if (currentString !== '') {
            nodes.push(currentString + ' ');
          }
          currentString = ' ';
          nodes.push(
            <a key={index} href={part} target="_blank" rel="noreferrer">{part}</a>
          );
        } else {
          currentString += (part + ' ');
        }
      }
    );

  if (currentString !== '') {
    nodes.push(currentString);
  }

  return nodes;
};
