import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import { useEditEventContext } from '../../contexts/EditEventContext';
import { endUserEvent, startUserEvent, UserEventType } from '../../lib/performance';
import { isUserHost } from '../../lib/attendance';
import { useUserContext } from '../../contexts/UserContext';
import { useEventCacheContext } from '../../contexts/EventCacheContext';
import { editEventDetails } from '../../api/ElkEventService';
import { UButterBarLevel, useButterBarContext } from '../../contexts/ButterBarContext';
import BackArrow from '../icons/BackArrow';
import { BackArrowContainer } from '../../pages/InvitePage';
import EventFormInput from '../EventForm/EventFormInput';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import { DesktopShow, MobileShow } from '../styled/Common';
import { WideWhiteButton } from '../buttons/WhiteButton';
import { firebaseAnalytics } from '../../core/libs/Firebase';
import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import Toggle from 'Common/src/components/Toggle';
import Radio from 'Common/src/components/Radio';
import { TAppElkEventNotification, TAppElkNotificationPreference } from 'TProtocol/prototypes/events/messages';
import Dropdown, { DropdownItem } from '../Dropdown';
import ChevronDown from '../icons/ChevronDown';
import ChevronUp from '../icons/ChevronUp';

const ShineLink = styled.a`
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const INVITE_GUESTS_OPTIONS: DropdownItem<TAppElkNotificationPreference>[]  = [{
  id: TAppElkNotificationPreference.EMAIL,
  label: 'Email',
  selectedLabel: 'Notify guests via email only'
}, {
  id: TAppElkNotificationPreference.PHONE,
  label: 'Text',
  selectedLabel: 'Notify guests via text only'
}, {
  id: TAppElkNotificationPreference.BOTH,
  label: 'Email and text',
  selectedLabel: 'Notify guests via email and text'
}];

const GuestManagementModal = ({ close }: {
  close: () => void
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const editEventContext = useEditEventContext();
  const eventCacheContext = useEventCacheContext();
  const butterBarContext = useButterBarContext();

  const [submitting, setSubmitting] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const event = editEventContext.event;

  if (!event) {
    return null;
  }

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const saveEvent = async () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.Network,
      message: `[GuestManagementModal] Saving event`
    });

    startUserEvent(UserEventType.SaveEvent);
    setSubmitting(true);

    const event = await editEventContext.generateEvent();
    const editEvent = editEventContext.event;
    if (event !== undefined) {
      if (editEvent !== undefined) {
        editEvent.isHost = isUserHost(editEvent, userContext.id);
        eventCacheContext.setEvent(editEvent);
      }
      try {
        await editEventDetails(userContext, event, editEvent?.hostUploadMode, true, true);
        editEventContext.clearEvent();
        endUserEvent(userContext);
        setSubmitting(false);
        close();
        navigate(`/event/invite/${event.id}`, { replace: true, state: { fromCreate: true } });
      } catch (e) {
        void logSumoEvent({
          app: ULogApplication.ELK,
          severity: ULogSeverity.ERROR,
          userId: userContext.id,
          tag: ULogTag.Network,
          message: `[GuestManagementModal] Error during event save: ${stringifyError(e)}`
        });
        butterBarContext.show({
          level: UButterBarLevel.ERROR,
          contents: 'Sorry, something went wrong saving your party. Please try again. If the problem persists, please contact us at support@sunshine.com.',
          duration: 20000
        });
        setSubmitting(false);
      }
    }
  };

  const generateModalContent = (isMobile: boolean) => {
    return <>
      <CardBody>
        <ModalHeader>
          <BackArrowContainer onClick={close}>
            <BackArrow/>
          </BackArrowContainer>
          <ModalTitle>Party options</ModalTitle>
        </ModalHeader>
        <OptionsContainer>
          <InfoText>
          Get photos from your event! Photo sharing is easy and FREE with the Shine app <br/><ShineLink href={`https://${event.albumDetails?.albumUrl ?? 'photos.sunshine.com'}`}>Shine app</ShineLink>
          </InfoText>

          <Toggle id={'field-add'}
                  checked={editEventContext.event?.openInvite ?? false}
                  onInputChange={(checked) => editEventContext.setIsOpenInvite(checked)}
                  $labelFontSize={'16px'}
                  $dark={true}>
            Allow guests to invite additional people
          </Toggle>

          <Toggle id={'Reminders'}
                  checked={editEventContext.event?.remindersEnabled ?? false}
                  onInputChange={(checked) => editEventContext.setRemindersEnabled(checked)}
                  $labelFontSize={'16px'}
                  $dark={true}>
            Send guests reminders
          </Toggle>

          <Toggle id={'GuestList'}
                  checked={editEventContext.event?.publicGuestList ?? false}
                  onInputChange={(checked) => editEventContext.setShowGuestList(checked)}
                  $labelFontSize={'16px'}
                  $dark={true}>
            Allow guests to view guest list
          </Toggle>

          <HostNameSection>
            <Label>Host name:</Label>
            <EventFormInput
              placeholder={'Host name'}
              value={editEventContext.event?.hostedBy ?? ''}
              onChange={(value) => editEventContext.setHostedBy(value)}
              excludeBackground={true}
              noVertMargin={true}
              isLabelOutside={true}
              inModal={true}/>
          </HostNameSection>

          <AdvancedSection>
            <AdvancedHeader onClick={() => setShowAdvanced(!showAdvanced)}>
              {showAdvanced ? 'Less' : 'More'}
              {showAdvanced ? <ChevronUp /> : <ChevronDown />}
            </AdvancedHeader>
            {showAdvanced && (
              <AdvancedContent>
                <DropdownWrapper>
                  <Dropdown
                    values={INVITE_GUESTS_OPTIONS}
                    value={event.communicationPreference}
                    onChange={(value) => editEventContext.setCommunicationPreference(value)}
                  />
                </DropdownWrapper>

                {(editEventContext.event?.isCreate || !editEventContext.event?.hasWaitlist) && (
                  <Toggle id={'guest-approval'}
                          checked={editEventContext.event?.hasWaitlist ?? false}
                          onInputChange={(checked) => editEventContext.setHasWaitlist(checked)}
                          $labelFontSize={'16px'}
                          $dark={true}>
                    Require guest approval
                  </Toggle>
                )}

          <RsvpUpdatesContainer>
                  <RsvpLabel>Get RSVP updates:</RsvpLabel>
                  <RadioContainer>
                    <Radio
                      id={`modalHostNotificationsRsvp${isMobile ? 'Mobile' : 'Desktop'}`}
                      checked={editEventContext.event?.hostNotification === TAppElkEventNotification.ATTENDEE_RSVP}
                      name={`modalHostNotification${isMobile ? 'Mobile' : 'Desktop'}`}
                      value="rsvp"
                      onInputChange={editEventContext.setHostNotification}
                      $dark={true}
                    >as they arrive</Radio>
                    <Radio
                      id={`modalHostNotificationsDigest${isMobile ? 'Mobile' : 'Desktop'}`}
                      checked={editEventContext.event?.hostNotification === TAppElkEventNotification.DAILY_DIGEST}
                      name={`modalHostNotification${isMobile ? 'Mobile' : 'Desktop'}`}
                      value="digest"
                      onInputChange={editEventContext.setHostNotification}
                      $dark={true}
                    >daily digest</Radio>
                  </RadioContainer>
                </RsvpUpdatesContainer>
              </AdvancedContent>
            )}
          </AdvancedSection>
        </OptionsContainer>
      </CardBody>
      <ButtonWrapper>
        <WideWhiteButton $invert={true} onClick={saveEvent} disabled={submitting}>
          Confirm
        </WideWhiteButton>
      </ButtonWrapper>
    </>;
  };

  return <>
    <DesktopShow>
      <Overlay onMouseDown={close}>
        <ModalContainer onMouseDown={onModalClick}>
          {generateModalContent(false)}
        </ModalContainer>
      </Overlay>
    </DesktopShow>
    <MobileShow>
      <TransparentOverlayBottomModal onClick={close}>
        <BottomModalContainer onClick={onModalClick}>
          {generateModalContent(true)}
        </BottomModalContainer>
      </TransparentOverlayBottomModal>
    </MobileShow>
  </>;
};

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfoText = styled.div`
  font-size: 16px;
  color: #FFF;
  margin-bottom: 8px;
`;

const RsvpUpdatesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const RsvpLabel = styled.div`
  font-size: 16px;
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const AdvancedSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AdvancedHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #FFF;
  
  &:hover {
    opacity: 0.8;
  }
`;

const AdvancedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 8px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const HostNameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export default GuestManagementModal;
