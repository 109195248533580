import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUserContext } from '../contexts/UserContext';
import { startUserEvent, UserEventType } from '../lib/performance';

import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import WelcomePage from './WelcomePage';

type UrlType = {
  EventPageUrlType?: object;
}

declare const WWW_WEBAPP_URL: string; // www.sunshine.com
declare const ELK_COOKIE_DOMAIN: string; // sunshine.com

const RootPage = () => {
  const userContext = useUserContext();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Shine';

    if (window.location.host === WWW_WEBAPP_URL || window.location.host === ELK_COOKIE_DOMAIN) {
      return;
    }

    const params = queryParams.get('params');
    try {
      if (params !== null) {
        const parsedParams = JSON.parse(atob(params));
        const urlType: string | UrlType | undefined = parsedParams.urlType;
        const eventId: string | undefined = parsedParams.eventId;

        if ((typeof urlType === 'string' && urlType === 'EventPage') ||
          (typeof urlType !== 'string' && urlType?.EventPageUrlType)) {
          if (eventId) {
            startUserEvent(UserEventType.LoadEventFromShortlink);
            navigate(`/event/${eventId}`, { replace: true });
            return;
          }
        }
      }
    } catch (e) {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.Browser,
        message: `[RootPage] Error during params parsing "${params}": ${stringifyError(e)}`
      });
    }

    if (userContext.isLoggedIn()) {
      startUserEvent(UserEventType.LoadEventsFromRoot);
      navigate('/events', { replace: true });
    } else {
      navigate('/welcome', { replace: true });
    }
  }, []);

  if (window.location.host === WWW_WEBAPP_URL || window.location.host === ELK_COOKIE_DOMAIN) {
    return <WelcomePage/>;
  }

  return null;
};

export default RootPage;
