import { logSumoEvent, stringifyError, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { getIsMacOS, getIsWindows } from 'Common/src/utils/Browser';

import { IUEvent } from '../lib/event';
import { IUUserContext } from '../contexts/UserContext';

export enum ShareResult {
  Clipboard = 1,
  Share,
  Error
}

export const shareEvent = async (userContext: IUUserContext, event: IUEvent): Promise<ShareResult> => {
  const isWindows = getIsWindows();
  const isMacOS = getIsMacOS();

  if (event.url) {
    void navigator.clipboard.writeText(event.url);
  }

  if (navigator.share === undefined || isWindows || isMacOS) {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.HangoutAction,
      message: `eventId: ${event.id} - Share (clipboard)`
    });

    return ShareResult.Clipboard;
  }

  void logSumoEvent({
    app: ULogApplication.ELK,
    severity: ULogSeverity.INFO,
    userId: userContext.id,
    tag: ULogTag.HangoutAction,
    message: `eventId: ${event.id} - Share (share sheet)`
  });

  const shareData = {
    title: 'Shine Parties',
    url: event.url,
  };

  try {
    await navigator.share(shareData);

    return ShareResult.Share;
  } catch (e) {
    if (e.name !== 'AbortError') {
      void logSumoEvent({
        app: ULogApplication.ELK,
        severity: ULogSeverity.WARN,
        userId: userContext.id,
        tag: ULogTag.UserError,
        message: `[util/share] Error during user share: ${stringifyError(e)}`
      });
    }

    return ShareResult.Error;
  }
};
