import React from 'react';
import styled from 'styled-components';

const WelcomeBackground = () => {
  return <div>
    <Background/>
  </div>;
};

const Background = styled.div`
  background-color: var(--sp-bg-color);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: 0.5s;
`;

export default WelcomeBackground;
