import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { useQRCode } from 'next-qrcode';

import { ModalClose, ModalContainer, Overlay, Title } from './CommonModal';
import { IUEvent } from '../../lib/event';
import ActionButton from '../buttons/ActionButton';
import Mail from '../icons/Mail';
import PhotosLink from '../../pages/Event/PhotosLink';

import LinkIcon from 'Common/src/components/icons/LinkIcon';
import CloseIcon from 'Common/src/components/icons/CloseIcon';

interface Props {
  event: IUEvent;
  close: () => void;
  onLinkClick: () => void;
  onEmailClick: () => void;
}

const EventCreatedModal = ({ event, close, onLinkClick, onEmailClick }: Props) => {
  const { Canvas } = useQRCode();

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return <Overlay onMouseDown={close}>
    <MyModalContainer $wide={true} $maxWidth="600px" onMouseDown={onModalClick}>
      <MyModalClose onClick={close}><CloseIcon/></MyModalClose>
      <MyTitle>Your party {event.title} has been created!</MyTitle>

      <ButtonContainer>
        <ActionButton onClick={onEmailClick}>
          <ButtonText>
            <Mail size={40}/><span>Invite by email</span>
          </ButtonText>
        </ActionButton>
        <ActionButton onClick={onLinkClick}>
          <ButtonText>
            <LinkIcon/><span>Send a link</span>
          </ButtonText>
        </ActionButton>
      </ButtonContainer>

      <SubTitle>Easy photo sharing for your party!</SubTitle>
      <BodyText>
        Shine created a shared photo album for your party.
      </BodyText>
      <BodyText>
        Your guests will be added when they confirm.
      </BodyText>

      <ContentContainer>
        <AppSection>
          <PhotosLink event={event}/>
        </AppSection>
        {event.albumDetails?.albumUrl !== undefined &&
            <div>
                <Canvas
                    text={event.albumDetails.albumUrl}
                    options={{
                      errorCorrectionLevel: 'M',
                      margin: 3,
                      scale: 4,
                      width: 100,
                      color: {
                        dark: '#000',
                        light: '#FFF',
                      },
                    }}
                />
            </div>
        }
      </ContentContainer>

    </MyModalContainer>
  </Overlay>;
};

const MyModalContainer = styled(ModalContainer)`
  padding: 16px 25px;
`;

const MyModalClose = styled(ModalClose)`
  margin-right: -20px;
`;

const MyTitle = styled(Title)`
  margin-bottom: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 0 20px 20px;
`;

const ButtonText = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const SubTitle = styled.h2`
  margin: 25px 0 5px;
  font-size: 24px;
`;

const BodyText = styled.div`
  font-size: 18px;
`;

const ContentContainer = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px;
  align-items: center;
`;

const AppSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export default EventCreatedModal;
