import React from 'react';

const PencilAlt = ({ color = 'currentColor', opacity = 1, size = 20 }: {
  color?: string,
  opacity?: number,
  size?: number
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
    <path
      d="M16.8617 4.76011L18.5492 3.07261C19.2814 2.34038 20.4686 2.34038 21.2008 3.07261C21.9331 3.80485 21.9331 4.99203 21.2008 5.72426L6.83218 20.0929C6.30351 20.6216 5.65144 21.0102 4.93489 21.2236L2.25 22.0234L3.04978 19.3386C3.26323 18.622 3.65185 17.9699 4.18052 17.4413L16.8617 4.76011ZM16.8617 4.76011L19.5 7.39843"
      stroke={color} strokeOpacity={opacity} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default PencilAlt;
