import React from 'react';

const AddToCal = ({ color = 'currentColor', size = 21 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path
      d="M10.625 10.625C10.625 10.9702 10.3452 11.25 10 11.25C9.65482 11.25 9.375 10.9702 9.375 10.625C9.375 10.2798 9.65482 10 10 10C10.3452 10 10.625 10.2798 10.625 10.625Z"
      fill={color}/>
    <path
      d="M6.25 13.125C6.59518 13.125 6.875 12.8452 6.875 12.5C6.875 12.1548 6.59518 11.875 6.25 11.875C5.90482 11.875 5.625 12.1548 5.625 12.5C5.625 12.8452 5.90482 13.125 6.25 13.125Z"
      fill={color}/>
    <path
      d="M6.875 14.375C6.875 14.7202 6.59518 15 6.25 15C5.90482 15 5.625 14.7202 5.625 14.375C5.625 14.0298 5.90482 13.75 6.25 13.75C6.59518 13.75 6.875 14.0298 6.875 14.375Z"
      fill={color}/>
    <path
      d="M8.125 13.125C8.47018 13.125 8.75 12.8452 8.75 12.5C8.75 12.1548 8.47018 11.875 8.125 11.875C7.77982 11.875 7.5 12.1548 7.5 12.5C7.5 12.8452 7.77982 13.125 8.125 13.125Z"
      fill={color}/>
    <path
      d="M8.75 14.375C8.75 14.7202 8.47018 15 8.125 15C7.77982 15 7.5 14.7202 7.5 14.375C7.5 14.0298 7.77982 13.75 8.125 13.75C8.47018 13.75 8.75 14.0298 8.75 14.375Z"
      fill={color}/>
    <path
      d="M10 13.125C10.3452 13.125 10.625 12.8452 10.625 12.5C10.625 12.1548 10.3452 11.875 10 11.875C9.65482 11.875 9.375 12.1548 9.375 12.5C9.375 12.8452 9.65482 13.125 10 13.125Z"
      fill={color}/>
    <path
      d="M10.625 14.375C10.625 14.7202 10.3452 15 10 15C9.65482 15 9.375 14.7202 9.375 14.375C9.375 14.0298 9.65482 13.75 10 13.75C10.3452 13.75 10.625 14.0298 10.625 14.375Z"
      fill={color}/>
    <path
      d="M11.875 13.125C12.2202 13.125 12.5 12.8452 12.5 12.5C12.5 12.1548 12.2202 11.875 11.875 11.875C11.5298 11.875 11.25 12.1548 11.25 12.5C11.25 12.8452 11.5298 13.125 11.875 13.125Z"
      fill={color}/>
    <path
      d="M12.5 14.375C12.5 14.7202 12.2202 15 11.875 15C11.5298 15 11.25 14.7202 11.25 14.375C11.25 14.0298 11.5298 13.75 11.875 13.75C12.2202 13.75 12.5 14.0298 12.5 14.375Z"
      fill={color}/>
    <path
      d="M13.75 13.125C14.0952 13.125 14.375 12.8452 14.375 12.5C14.375 12.1548 14.0952 11.875 13.75 11.875C13.4048 11.875 13.125 12.1548 13.125 12.5C13.125 12.8452 13.4048 13.125 13.75 13.125Z"
      fill={color}/>
    <path
      d="M12.5 10.625C12.5 10.9702 12.2202 11.25 11.875 11.25C11.5298 11.25 11.25 10.9702 11.25 10.625C11.25 10.2798 11.5298 10 11.875 10C12.2202 10 12.5 10.2798 12.5 10.625Z"
      fill={color}/>
    <path
      d="M13.75 11.25C14.0952 11.25 14.375 10.9702 14.375 10.625C14.375 10.2798 14.0952 10 13.75 10C13.4048 10 13.125 10.2798 13.125 10.625C13.125 10.9702 13.4048 11.25 13.75 11.25Z"
      fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5.625 1.875C5.97018 1.875 6.25 2.15482 6.25 2.5V3.75H13.75V2.5C13.75 2.15482 14.0298 1.875 14.375 1.875C14.7202 1.875 15 2.15482 15 2.5V3.75H15.625C17.0057 3.75 18.125 4.86929 18.125 6.25V15.625C18.125 17.0057 17.0057 18.125 15.625 18.125H4.375C2.99429 18.125 1.875 17.0057 1.875 15.625V6.25C1.875 4.86929 2.99429 3.75 4.375 3.75H5V2.5C5 2.15482 5.27982 1.875 5.625 1.875ZM16.875 9.375C16.875 8.68464 16.3154 8.125 15.625 8.125H4.375C3.68464 8.125 3.125 8.68464 3.125 9.375V15.625C3.125 16.3154 3.68464 16.875 4.375 16.875H15.625C16.3154 16.875 16.875 16.3154 16.875 15.625V9.375Z"
          fill={color}/>
  </svg>;
};

export default AddToCal;
