import React, { ChangeEvent, PropsWithChildren, RefObject } from 'react';
import styled from 'styled-components';

type Size = 'small' | 'medium' | 'large';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: RefObject<HTMLInputElement>;
  $size?: Size;
  onInputChange: (value: string) => void;
  $labelFontSize?: string;
  $dark?: boolean;
}

const Radio = (props: PropsWithChildren<Props>) => {
  const { inputRef, id, checked = false, onInputChange, $dark = false, children } = props;

  const inputProps: Partial<Props> = { ...props };
  delete inputProps.children;
  delete inputProps.onInputChange;
  delete inputProps.checked;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.currentTarget.value);
  };

  return <div>
    <RadioInput
      type="radio"
      ref={inputRef}
      {...inputProps}
      onChange={onChange}
      defaultChecked={checked}
      $dark={$dark}
    />
    <label htmlFor={id}>
      <InnerLabel>{children}</InnerLabel>
    </label>
  </div>;
};

const calculateSize = (size: Size) => {
  if (size === 'small') {
    return 16;
  } else if (size === 'medium') {
    return 20;
  } else {
    return 24;
  }
};

const calculateDotSize = (size: Size) => {
  if (size === 'small') {
    return 6;
  } else if (size === 'medium') {
    return 8;
  } else {
    return 10;
  }
};

const calculateMarginSize = (size: Size, dark: boolean) => {
  if (size === 'small') {
    return 5;
  } else if (size === 'medium' && !dark) {
    return 6;
  } else {
    return 7;
  }
};

const RadioInput = styled.input<{
  $size?: Size;
  $labelFontSize?: string;
  $dark: boolean;
}>`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  & + label {
    position: relative;
    font-size: ${({ $labelFontSize }) => $labelFontSize ?? '16px'};
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    color: currentColor;
  }

  & + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    border-radius: 100px;
    background: ${({ $dark }) => $dark ? '#000' : '#FFF'};
    ${({ $dark }) => $dark && 'border: 1px solid #FFF;'}

    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:checked + label::before {
    background-color: ${({ $dark }) => $dark ? '#FFF' : '#000'};
  }

  &:disabled + label::before {
    background-color: #9EA5AD;
  }

  &:focus + label::before {
    box-shadow: 0 0 0 4px rgba(252, 101, 16, 0.16), 0 2px 5px 0 rgba(252, 101, 16, 0.08), 0 0 0 1px rgba(252, 101, 16, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover:not(:disabled) + label::before {
    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.64), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover:checked:not(:disabled) + label::before {
    box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.24), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  &:checked + label::after {
    content: " ";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    text-align: center;
    background-color: transparent;
    font-size: 10px;
    margin: 3px 3px 4px 2px;
    background-size: cover;
  }

  & + label::before {
    min-width: ${({ $size = 'medium' }) => calculateSize($size)}px;
    height: ${({ $size = 'medium' }) => calculateSize($size)}px;
  }

  &:checked + label::after {
    min-width: ${({ $size = 'medium' }) => calculateDotSize($size)}px;
    height: ${({ $size = 'medium' }) => calculateDotSize($size)}px;
    margin: ${({ $size = 'medium', $dark }) => calculateMarginSize($size, $dark)}px;
    background-image: url('data:image/svg+xml,<svg width="${({ $size = 'medium' }) => calculateDotSize(
      $size)}" height="${({ $size = 'medium' }) => calculateDotSize(
      $size)}" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5" cy="5" r="5" fill="%23${
      ({ $dark }) => $dark ? '000000' : 'ffffff'}"/> </svg>');
  }

  &:checked:disabled + label::after {
    background-image: url('data:image/svg+xml,<svg width="${({ $size = 'medium' }) => calculateDotSize(
      $size)}" height="${({ $size = 'medium' }) => calculateDotSize(
      $size)}" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5" cy="5" r="5" fill="%23${
  ({ $dark }) => $dark ? '607070' : 'ced2d6'}"/> </svg>');
  }

`;

const InnerLabel = styled.span`
  margin-left: 6px;
  font-size: 16px;
`;

export default Radio;
