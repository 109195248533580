import React, { FormEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { generateAutoCompleteItems, IUAutoCompleteContact } from '../../util/contact';
import { emailExists, parseSingleEmail, validateEmail } from '../../util/email';
import { IUEvent, IUInvitee } from '../../lib/event';
import { useUserContext } from '../../contexts/UserContext';
import InviteAutocompleteRow from './InviteAutocompleteRow';
import InviteCreateContact from './InviteCreateContact';
import { WhiteFormButton } from '../buttons/WhiteButton';
import InviteInput from './InviteInput';

import { DeviceQueries } from 'Common/src/components/styled';
import AutocompleteDropdown, {
  DropdownContainer,
  IUAutoCompleteItem
} from 'Common/src/components/AutocompleteDropdown/AutocompleteDropdown';

import { TAppElkAttendeeRole, TAppElkAttendeeStatus, TAppElkInviteStatus } from 'TProtocol/prototypes/events/messages';

interface Props {
  placeholder: string;
  event?: IUEvent;
  includeAttendees?: boolean;
  usersOnly?: boolean;
  onCreate: (invite: IUInvitee) => void;
}

enum ContactAddStatus {
  None = 1,
  AddByName,
  AddByEmail
}

const InviteForm = ({ placeholder, event, includeAttendees, usersOnly, onCreate }: Props) => {
  const userContext = useUserContext();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const [contactAddStatus, setContactAddStatus] = useState(ContactAddStatus.None);

  const [autocompletions, setAutocompletions] = useState<IUAutoCompleteItem<IUAutoCompleteContact>[]>([]);

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search !== '') {
      onSearchChange(search);
    }
  }, [userContext.contacts, userContext.mutualMembers]);

  const onAutocompleteSelect = (value: IUAutoCompleteItem<IUAutoCompleteContact>) => {
    createInvitee(value.value);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (contactAddStatus === ContactAddStatus.None) {
      const { name, email } = parseSingleEmail(search) ?? {};
      if (name && email) {
        createInvitee({ name, email });
      } else if (!usersOnly) {
        onCreateContactClick();
      }
      return;
    }

    createInvitee({ name, email });
  };

  const createInvitee = ({ name, email, userId, invitee }: {
    name: string,
    email?: string,
    userId?: string,
    invitee?: IUInvitee
  }) => {
    const trimmedEmail = email?.trim();

    if (name === '') {
      setError('Please enter a name.');
      return;
    }

    if (userId === undefined && trimmedEmail !== undefined && !validateEmail(trimmedEmail)) {
      setError('Please enter a valid email.');
      return;
    }

    if (!usersOnly && emailExists(trimmedEmail, event)) {
      setError('Email has already been invited.');
      return;
    }

    onCreate(new IUInvitee({
      inviteeId: invitee?.inviteeId ?? uuidv4(),
      userId,
      email: trimmedEmail,
      name,
      role: TAppElkAttendeeRole.ATTENDEE,
      rsvpStatus: TAppElkAttendeeStatus.INVITED,
      inviteStatus: TAppElkInviteStatus.PENDING,
      additionalGuestCount: 0,
      isNew: invitee === undefined
    }));

    setSearch('');
    setError('');
    setName('');
    setEmail('');
    setContactAddStatus(ContactAddStatus.None);

    searchRef.current?.focus();
  };

  const onSearchChange = (search: string) => {
    setSearch(search);
    setError('');

    if (userContext.contacts !== undefined || userContext.mutualMembers !== undefined) {
      if (search === '') {
        setAutocompletions([]);
        return;
      }

      setAutocompletions(
        generateAutoCompleteItems({ userContext, event, search, usersOnly, includeAttendees })
      );
    }
  };

  const onCreateCancel = () => {
    setSearch('');
    setName('');
    setEmail('');
    setContactAddStatus(ContactAddStatus.None);
  };

  const onCreateContactClick = () => {
    if (search.indexOf('@') > -1) {
      setEmail(search);
    } else {
      setName(search);
    }
    setContactAddStatus(ContactAddStatus.AddByName);
  };

  let contactInputs: ReactNode;
  if (contactAddStatus === ContactAddStatus.AddByName) {
    contactInputs = <>
      <InviteInput width={131} value={name} onChange={setName} label="Name" onCancel={onCreateCancel}
                   autoFocus={name === ''}/>
      <InviteInput width={291} value={email} onChange={setEmail} label="Email" onCancel={onCreateCancel}
                   autoFocus={email === ''}/>
    </>;
  } else if (contactAddStatus === ContactAddStatus.AddByEmail) {
    contactInputs = <>
      <InviteInput width={291} value={email} onChange={setEmail} label="Email" onCancel={onCreateCancel}
                   autoFocus={email === ''}/>
      <InviteInput width={131} value={name} onChange={setName} label="Name" onCancel={onCreateCancel}
                   autoFocus={name === ''}/>
    </>;
  } else {
    contactInputs = <AutocompleteDropdown
      values={autocompletions}
      onSelect={onAutocompleteSelect}
      onCancel={() => setAutocompletions([])}
      Format={InviteAutocompleteRow}
    >
      <FormInput
        ref={searchRef}
        placeholder={placeholder}
        value={search}
        onChange={(e) => onSearchChange(e.currentTarget.value)}
        onBlur={() => setAutocompletions([])}
        maxLength={128}
      />
      {!usersOnly && search !== '' && autocompletions.length === 0 && error === '' &&
          <InviteCreateContact search={search} onClick={onCreateContactClick}/>}
    </AutocompleteDropdown>;
  }

  return <FormContainer>
    <FormRow onSubmit={onSubmit}>
      {contactInputs}

      {!usersOnly &&
        <InputGroupButtonWrapper>
          <WhiteFormButton type="submit" onClick={onSubmit} $invert={true}>
            Add
          </WhiteFormButton>
        </InputGroupButtonWrapper>
      }
    </FormRow>
    {error === '' ? null : <InviteError>{error}</InviteError>}
  </FormContainer>;
};

const FormContainer = styled.div``;

const FormRow = styled.form`
  display: flex;
  width: auto;
  align-items: flex-start;
  gap: 10px;
  margin: 2px;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;

    ${DropdownContainer} {
      flex-grow: 1;
      width: 100%;
    }
  }
`;

const FormInput = styled.input`
  width: 529px;
  height: 39px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 12px;
  border: 1px solid #E6E6E6;
  background: rgba(var(--sp-bg-rgb), 0.60);
  color: var(--sp-text-color);
  font-family: Plus Jakarta Sans, sans-serif;

  &::placeholder {
    color: rgba(var(--sp-text-rgb), 0.40);
  }

  @media (${DeviceQueries.mobile}) {
    width: calc(100% - 35px);
  }
`;

const InviteError = styled.div`
  color: var(--shine-system-error-color);
  border-radius: 6px;
  padding: 5px 10px;
  grid-column-start: 1;
  grid-column-end: span 3;
`;

const InputGroupButtonWrapper = styled.div`

  @media (${DeviceQueries.mobile}) {
    min-width: 100%;
    margin-bottom: 10px;

    ${WhiteFormButton} {
      width: 100%;
      justify-content: center;
    }
  }
`;

export default InviteForm;
