import React, { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useUserContext } from '../../contexts/UserContext';
import { UEventFormField, useEditEventContext } from '../../contexts/EditEventContext';
// import { useCoinModalContext } from '../../contexts/CoinModalContext';
// import { useCoinContext } from '../../contexts/CoinContext';
import ExpandingTextInput from '../../components/ExpandingTextInput';
import { IUEvent } from '../../lib/event';
import { ErrorContainer } from '../../components/forms/EventForm';
import { ShadedBox } from '../BasePage';
import EventImageHistoryModal, { ImagesTab } from '../../components/modals/EventImageHistoryModal';
import { useModalContext } from '../../contexts/ModalContext';
import ImageStyleDropdownButton from '../../components/buttons/ImageStyleDropdownButton';
import { getEventTintColor } from '../../lib/colorPicker';
import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { ActionMenuLabel } from '../../components/EventForm/ActionMenuCommon';
import Sparkles from '../../components/icons/Sparkles';
import { DesktopShow } from '../../components/styled/Common';
import { WideWhiteButton } from '../../components/buttons/WhiteButton';
import { PillInputActionButton } from '../../components/EventForm/Common';
import PaginatedImages from '../../components/PaginatedImages';
import InspirationGalleryModal from '../../components/modals/InspirationGalleryModal';

import { DeviceQueries } from 'Common/src/components/styled';
import { TimeoutError } from 'Common/src/utils/PromiseTimeout';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import { TAppElkImageStyle } from 'TProtocol/prototypes/events/messages';
import ImageUploader from '../../components/ImageUploader';
import PhotoSolid from '../../components/icons/PhotoSolid';
import { ActionMenuTab } from '../../components/EventForm/ActionMenu';
import { Tooltip } from 'react-tooltip';
import { ShareTooltipContainer } from '../../components/styled/CopyLinkTooltip';

declare const ELK_COINS_ENABLED: boolean;
declare const APP_ENV: string;
declare const SHOULD_SHOW_AI_MODULES: boolean;

const GeneratedImageSelection = ({ event, excludeImage, onImageGenerate, onAddImage, setOpenTab }: {
  event: IUEvent,
  excludeImage: boolean,
  onImageGenerate?: () => void;
  onAddImage?: () => void;
  setOpenTab?: (tab: ActionMenuTab) => void;
}) => {
  const userContext = useUserContext();
  const backgroundContext = useBackgroundContext();
  const editEventContext = useEditEventContext();
  // const coinContext = useCoinContext();
  // const coinModalContext = useCoinModalContext();
  const modalContext = useModalContext();

  const imagePlaceholderRef = useRef<HTMLDivElement>(null);
  const loading = event.loading;
  const [timedOut, setTimedOut] = useState(false);
  const [showNoPromptMessage, setShowNoPromptMessage] = useState(false);
  const [style, setStyle] = useState<TAppElkImageStyle | undefined>();
  const [showCopiedPhotoUrl, setShowCopiedPhotoUrl] = useState<boolean>(false);

  const mounted = useRef(true);
  const promptInputModIdRef = useRef<number>(Date.now());
  const inStageEnv = APP_ENV === 'DEV' || APP_ENV === 'STAGE';

  useEffect(() => {
    void editEventContext.fetchPreviousImages();

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (showCopiedPhotoUrl) {
      const timerId = setTimeout(() => {
        setShowCopiedPhotoUrl(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
    return () => {
      // noop
    };
  }, [showCopiedPhotoUrl]);

  const copyPhotoUrl = () => {
    if (event.photoUrl) {
      void navigator.clipboard.writeText(event.photoUrl);
      setShowCopiedPhotoUrl(true);
    }
  };

  const onImageClick = (imageUrl: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[GeneratedImageSelection] Selected image'
    });

    editEventContext.setPhotoUrl(imageUrl);
    promptInputModIdRef.current = Date.now();
  };

  const showUploadImagesModal = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[GeneratedImageSelection] Show uploaded images modal'
    });

    modalContext.show({
      contents: <EventImageHistoryModal close={modalContext.hide} promptInputModIdRef={promptInputModIdRef}/>
    });
  };

  const showImageHistoryModal = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[GeneratedImageSelection] Show image history modal'
    });

    modalContext.show({
      contents: <EventImageHistoryModal close={modalContext.hide} promptInputModIdRef={promptInputModIdRef}
                                        initialTab={ImagesTab.Generated}/>
    });
  };

  const showInspirationGalleryModal = () => {
    modalContext.show({
      contents: <InspirationGalleryModal close={modalContext.hide} event={event}/>
    });
  };

  const onRegenerate = async (e?: MouseEvent) => {
    e?.preventDefault();

    void editEventContext.fetchEventInspirations();

    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[GeneratedImageSelection] Clicked on generate'
    });

    if (loading) {
      return;
    }

    // if (ELK_COINS_ENABLED && coinContext.getBalance() < 3) {
    //   if (!userContext.isLoggedIn()) {
    //     userContext.login();
    //     return;
    //   }
    //
    //   coinModalContext.showMenuModal({ needed: 3 });
    //   return;
    // }

    if (!event.title && !event.prompt) {
      setShowNoPromptMessage(true);
      return;
    }

    if (onImageGenerate) {
      onImageGenerate();
    }

    setTimedOut(false);
    setShowNoPromptMessage(false);

    try {
      // imagePlaceholderRef.current?.scrollIntoView({ behavior: 'smooth' });

      const actualStyle = (style !== undefined && +style === TAppElkImageStyle.DEFAULT) ? undefined : style;
      await editEventContext.fetchEventSuggestion(actualStyle);
    } catch (e) {
      if (e instanceof TimeoutError) {
        setTimedOut(true);
      }
    }
  };

  const onImageCropDone = (imgSrc: string) => {
    editEventContext.setPhotoUrl(imgSrc);
  };

  const isPhotoEmpty = event.photoUrl === undefined || event.photoUrl === '';

  let topContent: ReactNode;
  if (isPhotoEmpty && loading) {
    topContent = <>
      <BigImagePlaceholderContainer>
        <BigImagePlaceholder>
          <Loader>
            <div className={'loader'}></div>
          </Loader>
          <LoaderText>{'Working on it! '}</LoaderText>
          <LoaderText>{'(typically takes 10 seconds)'}</LoaderText>
        </BigImagePlaceholder>
      </BigImagePlaceholderContainer>
    </>;
  } else if (isPhotoEmpty) {
    topContent = <BigImagePlaceholderContainer>
      {loading ?
        <BigImagePlaceholder>
          <LoaderWrapper>
            <Loader>
              <div className={'loader'}></div>
            </Loader>
            <LoaderText>{'Working on it! '}</LoaderText>
            <LoaderText>{'(typically takes 10 seconds)'}</LoaderText>
          </LoaderWrapper>
        </BigImagePlaceholder>
        : <ImageUploader circular={false} onImageCropAvailable={onImageCropDone} onPopupOpen={() => null}
                         onPopupClose={() => null} fullWidth={true}>
          <BigImagePlaceholder>
            {
              SHOULD_SHOW_AI_MODULES ? <CustomPillInputButton $fontSize={18}
                                                              onClick={(e: MouseEvent) => {
                                                                e.stopPropagation();
                                                                setOpenTab && setOpenTab(ActionMenuTab.IMAGE_GEN);
                                                              }}>
                <Sparkles/> Generate an image
              </CustomPillInputButton> : <></>
            }
            <CustomPillInputButton $fontSize={18}>
              <PhotoSolid/> Upload an image
            </CustomPillInputButton>
          </BigImagePlaceholder>
        </ImageUploader>}
    </BigImagePlaceholderContainer>;
  } else {
    topContent = <ImageContainer ref={imagePlaceholderRef}>
      <BigImage src={event.photoUrl}></BigImage>
    </ImageContainer>;
  }

  let errorMessage: ReactNode = null;
  if (showNoPromptMessage) {
    errorMessage = <WarningMessage>
      Please give your party a title or supply a manual prompt before generating images.
    </WarningMessage>;
  } else if (timedOut) {
    errorMessage = <WarningMessage>
      Sorry, the image generation request timed out. Please try again.
    </WarningMessage>;
  } else if (event?.imageFetchError) {
    errorMessage = <WarningMessage>
      Sorry, something went wrong generating the images. Please try again. You can also try changing the prompt.
    </WarningMessage>;
  }

  const generatedImageUrls: string[] = [];
  event.previousImages?.map((image) => {
    generatedImageUrls.push(image.imageUrl);
  });

  return <Wrapper>
    {event.errors.missingImage ?
      <ShadedBox><ImageErrorContainer>Image is required</ImageErrorContainer></ShadedBox> : undefined}
    {excludeImage ? <></> : topContent}
    {excludeImage && inStageEnv ? <>
      <ShareTooltipContainer>
        <Tooltip anchorSelect="#copyPhotoUrl" place="top" className="copiedTooltip"
                 noArrow={true} isOpen={showCopiedPhotoUrl}>
          Copied photo URL!
        </Tooltip>
        <ActionMenuLabel id={'copyPhotoUrl'}
                         onClick={copyPhotoUrl}
                         style={{ cursor: 'pointer' }}>
          Current photo URL:<br/>{event.photoUrl}
        </ActionMenuLabel>
      </ShareTooltipContainer>
      {SHOULD_SHOW_AI_MODULES && <ActionMenuLabel>
        Generate cover with AI
      </ActionMenuLabel>}
    </> : <></>}
    {errorMessage}
    {excludeImage ? <>
      <ButtonWrapper>
        {SHOULD_SHOW_AI_MODULES && <>
          <ExpandingTextInput
            placeholder="What vibe or theme? Let AI create the perfect party card!"
            value={event.prompt ?? ''}
            onChange={(value) => editEventContext.setPrompt(value)}
            onSubmit={() => onRegenerate()}
            minHeight={160.5}
            isForSideBar={true}
            noVertMargin={true}
            modId={promptInputModIdRef.current}
          />

          <ImageStyleDropdownButton disabled={loading} style={style ?? TAppElkImageStyle.DEFAULT}
                                    onStyleChange={setStyle}/>
        </>
        }
        {loading ? <LoaderWrapper>
          <Loader>
            <div className={'loader'}></div>
          </Loader>
          <LoaderText>{'Working on it! '}</LoaderText>
          <LoaderText>{'(typically takes 10 seconds)'}</LoaderText>
        </LoaderWrapper> : null}

        {
          (SHOULD_SHOW_AI_MODULES && excludeImage) ? <WideWhiteButton $invert={true} onClick={onRegenerate}>
            <Sparkles size={20}/>
            Make it shine
          </WideWhiteButton> : null
        }
        {
          excludeImage ? <ExtraImageActionContainer
            onClick={showUploadImagesModal}
            ref={(div) => editEventContext.setFormErrorDiv(UEventFormField.Image, div)}>
            {
              excludeImage ? <ImageActionLabel>
                {SHOULD_SHOW_AI_MODULES ? 'Or upload/choose from gallery' : 'Upload/choose from gallery'}
              </ImageActionLabel> : null
            }
          </ExtraImageActionContainer> : <LoaderText/>
        }
      </ButtonWrapper>
      <DesktopShow>
        {
          (event.previousImages?.length ?? 0) > 0 &&
          <PaginatedImages
            title="Previous covers"
            imageUrls={generatedImageUrls}
            onImageClick={onImageClick}
            onAllClick={showImageHistoryModal}
          />
        }
      </DesktopShow>
    </> : <></>}
  </Wrapper>;

};

export const ImageContainer = styled.div`
`;

export const BigImage = styled.img<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) => $fullWidth ? 'width: 100%;' : 'width: 548px; height: 548px;'};
  border: #5e6575 1pt;
  border-radius: 20px;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    height: auto;
    //max-width: 350px;
    //max-height: 350px;
    aspect-ratio: 1;
  }
`;

export const WarningMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  width: 400px;
  max-width: 100%;
  color: var(--shine-system-error-color);
  text-align: center;
`;

export const BigImagePlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const BigImagePlaceholder = styled.div<{ $cursorPointer?: boolean, $fullWidth?: boolean }>`
  ${({ $fullWidth }) => $fullWidth ? 'width: 100%; aspect-ratio: 1;' : 'width: 548px; height: 548px;'};
  border-radius: 30px;
  background: rgba(var(--sp-bg-rgb), 0.20);
  backdrop-filter: blur(40px);

  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ $cursorPointer }) => $cursorPointer && 'cursor: pointer;'};

  gap: 15px;

  .image-upload-drag-active & {
    background-color: rgba(var(--sp-bg-rgb), 0.50);
  }

  @media (${DeviceQueries.mobile}) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`;

export const LoaderText = styled.div`
  z-index: 100;
  margin: 10px 10px 0;
  height: 10px;
`;

export const LoaderWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Loader = styled.div`
  z-index: 100;


  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid gray;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  text-align: left;
  gap: 12px;
`;

export const ExtraImageActionContainer = styled.div`
`;

export const ImageActionLabel = styled.div<{
  $color?: string
}>`
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  color: var(--sp-text-color);
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 300;
`;

export const ImageErrorContainer = styled(ErrorContainer)`
  margin: 5px 0;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CustomPillInputButton = styled(PillInputActionButton)`
  padding: 9.91px 17.838px;
  width: 250px;
  justify-content: center;
`;

export default GeneratedImageSelection;
