import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { useUserContext } from '../../contexts/UserContext';
import EventFormInput, { InputType } from '../EventForm/EventFormInput';
import {
  BottomModalContainer,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Overlay,
  TransparentOverlayBottomModal
} from './CommonModal';
import { stob64 } from '../../util/stringutils';
import useInviteeUuidStorage from '../../hooks/useInviteeUuidStorage';
import { IUEvent } from '../../lib/event';
import { BackArrowContainer } from '../../pages/InvitePage';
import BackArrow from '../icons/BackArrow';
import { DesktopShow, MobileShow } from '../styled/Common';
import { WideWhiteButton } from '../buttons/WhiteButton';
import { findCurrentInvitee } from '../../util/attendee';
import { ExternalLabel } from '../forms/Input';
import { TallerWhiteInput } from '../forms/WhiteInput';
import { ErrorContainer, LoginInput } from '../login/SinglePhoneEmailInput';
import ErrorIcon from '../icons/ErrorIcon';

import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkInvitee,
  TAppElkNotificationPreference
} from 'TProtocol/prototypes/events/messages';
import { NotYouActionText, NotYouText } from '../rsvp/Common';

const RSVPNoModal = ({ close, event, inviteeInput, eventId, nonUpdateRSVPThroughEmail }: {
  close: () => void,
  event: IUEvent | undefined,
  inviteeInput?: TAppElkInvitee | undefined,
  eventId: string | undefined,
  nonUpdateRSVPThroughEmail?: boolean
}) => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const formRef = useRef<HTMLFormElement>(null);

  const [invitee, setInvitee] = useState<TAppElkInvitee | undefined>(inviteeInput);
  const [invalidName, setInvalidName] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [hasResetInvitee, setHasResetInvitee] = useState<boolean>(false);


  const { getInviteeUuid, getPhone, getEmail, clearInviteeUuidStorageForEvent, getToken } = useInviteeUuidStorage();

  useEffect(() => {
    const phone = userContext.getPhoneNumber() ?? getPhone() ?? '';
    const email = userContext.getEmail() ?? getEmail() ?? '';
    const currInvitee = findCurrentInvitee(getInviteeUuid(eventId), userContext.id, event, userContext.name, true,
      [phone], [email]);
    if (currInvitee.notificationPreference === undefined) {
      if (phone.trim() !== '' && email) {
        currInvitee.notificationPreference = TAppElkNotificationPreference.BOTH;
      } else if (email) {
        currInvitee.notificationPreference = TAppElkNotificationPreference.EMAIL;
      } else {
        currInvitee.notificationPreference = TAppElkNotificationPreference.PHONE;
      }
    }
    setInvitee(new TAppElkInvitee(currInvitee));
    setHasChanged(true);
  }, []);

  if (invitee === undefined) {
    return null;
  }

  const additionalGuestCount = invitee.additionalGuestCount;

  const logUserAction = (action: string) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.DEBUG,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: `[RSVPNoModal] ${action}`
    });
  };

  const handleImOut = async () => {
    logUserAction('Handle I\'m out');

    const encodedMessage = stob64(invitee.rsvpMessage ?? '');
    const encodedName = stob64(invitee.name ?? '');
    const encodedEmail = stob64(invitee.email ?? '');
    const encodedTitle = stob64(event?.title ?? '');

    let notificationPreferenceString;
    if (invitee.notificationPreference === TAppElkNotificationPreference.EMAIL) {
      notificationPreferenceString = 'email';
    } else if (invitee.notificationPreference === TAppElkNotificationPreference.BOTH) {
      notificationPreferenceString = 'both';
    } else {
      notificationPreferenceString = 'phone';
    }

    setSubmitting(true);

    if (!userContext.isLoggedIn()) {
      if (getToken(eventId) !== undefined) {
        navigate(
          `/event/updateemail/action/${eventId}?r=no&em=${encodedMessage}&et=${encodedTitle}&en=${encodedName}&iu=${invitee.inviteeId}&ee=${encodedEmail}&iu=${invitee.inviteeId}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      } else {
        navigate(
          `/event/unauthrsvp/action/${eventId}?r=no&em=${encodedMessage}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}`,
          {
            replace: true,
            state: {
              finishingRSVP: true
            }
          });
      }
    } else {
      navigate(
        `/event/rsvp/action/${eventId}?r=no&em=${encodedMessage}&ng=${additionalGuestCount}&et=${encodedTitle}&en=${encodedName}&np=${notificationPreferenceString}&iu=${invitee.inviteeId}&ee=${encodedEmail}`,
        {
          replace: true,
          state: {
            finishingRSVP: true
          }
        });
    }

  };

  const validateForm = () => {
    const isNameInvalid = invitee.name === undefined || invitee.name.trim() === '';
    setInvalidName(isNameInvalid);
    if (invalidName) {
      logUserAction(`Invalid name: "${invitee.name}"`);
    }
    return !isNameInvalid;
  };

  const onModalClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onMessageChange = (message: string) => {
    invitee.rsvpMessage = message;
    setInvitee(new TAppElkInvitee(invitee));
    setHasChanged(true);
  };

  const onConfirmClick = () => {
    logUserAction('On confirm click');

    const validForm = validateForm();
    const validHtmlForm = formRef.current?.reportValidity();
    if (!validForm || !validHtmlForm) {
      logUserAction('Invalid form');
      return;
    }

    void handleImOut();
  };

  const onNameChange = (name: string) => {
    invitee.name = name;
    setInvitee(new TAppElkInvitee(invitee));
    setHasChanged(true);
    setInvalidName(false);
    setHasResetInvitee(true);
  };

  const onClose = () => {
    logUserAction('Closed modal');

    close();
  };

  const showResetInviteeAction = getToken(eventId) !== undefined && !userContext.isLoggedIn() && !hasResetInvitee;

  const resetInvitee = () => {
    logUserAction('Clear and update details');
    if (eventId) {
      clearInviteeUuidStorageForEvent(eventId);
    }
    const newInvitee = new TAppElkInvitee({
      inviteeId: uuidv4(),
      role: TAppElkAttendeeRole.ATTENDEE,
      rsvpStatus: TAppElkAttendeeStatus.NO,
      additionalGuestCount: 0
    });
    setInvitee(newInvitee);
    setHasResetInvitee(true);
  }

  const modalContent = <RSVPForm ref={formRef} onSubmit={(e) => e.preventDefault()}>
    <ModalContentContainer>
      <ModalHeader>
        <BackArrowContainer onClick={onClose}>
          <BackArrow/>
        </BackArrowContainer>
        <ModalTitle>Decline invitation</ModalTitle>
      </ModalHeader>

      <LoginInput $includeMargin={true}>
        <ExternalLabel>Who will not be attending?</ExternalLabel>
        <TallerWhiteInput
          autoFocus={false}
          placeholder="Taylor Swift"
          $isInvalid={invalidName}
          disabled={nonUpdateRSVPThroughEmail && !hasResetInvitee}
          value={invitee.name ?? ''}
          onChange={(e) => onNameChange(e.currentTarget.value)}
        />
        {invalidName && <ErrorContainer><ErrorIcon/><span>Please provide a valid name.</span></ErrorContainer>}
        {showResetInviteeAction &&
            <NotYouText>Not {!invitee.name || invitee.name.trim() === '' ? invitee.email : invitee.name}? <NotYouActionText
                onClick={resetInvitee}>Clear and update
                details</NotYouActionText></NotYouText>}
      </LoginInput>
      <EventFormInput
        placeholder="Type message here"
        event={event}
        label="Message to the host"
        value={invitee.rsvpMessage ?? ''}
        maxLength={1024}
        onChange={(value) => onMessageChange(value)}
        inputType={InputType.Description}
        optionNumber={0}
        isLabelOutside={true}
        excludeBackground={true}
        inModal={true}
      />
    </ModalContentContainer>
    <ButtonFooter>
      <WideWhiteButton $invert={true} type="button" onClick={onConfirmClick}
                   disabled={submitting || !hasChanged}>Decline</WideWhiteButton>
    </ButtonFooter>
  </RSVPForm>;

  return (
    <>
      <DesktopShow>
        <Overlay onMouseDown={onClose}>
          <ModalContainer onMouseDown={onModalClick}>
            {modalContent}
          </ModalContainer>
        </Overlay>
      </DesktopShow>
      <MobileShow>
        <TransparentOverlayBottomModal onClick={onClose}>
          <BottomModalContainer onClick={onModalClick}>
            {modalContent}
          </BottomModalContainer>
        </TransparentOverlayBottomModal>
      </MobileShow>
    </>
  );
};

const RSVPForm = styled.form`
  margin: 5px;
  width: calc(100% - 10px);
`;

const ButtonFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ModalContentContainer = styled.div`
`;

export default RSVPNoModal;
