import React from 'react';

const Share = ({ color = 'currentColor', size = 25 }: {
  color?: string,
  size?: number
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path
      d="M15.7381 5.67188C15.7381 4.01502 17.0812 2.67188 18.7381 2.67188C20.3949 2.67188 21.7381 4.01502 21.7381 5.67188C21.7381 7.32873 20.3949 8.67188 18.7381 8.67188C17.8913 8.67188 17.1264 8.32102 16.5809 7.75674L8.50069 11.7969C8.5253 11.9516 8.53809 12.1102 8.53809 12.2719C8.53809 12.4336 8.5253 12.5922 8.50067 12.747L16.5809 16.7871C17.1263 16.2228 17.8912 15.8719 18.7381 15.8719C20.3949 15.8719 21.7381 17.215 21.7381 18.8719C21.7381 20.5287 20.3949 21.8719 18.7381 21.8719C17.0812 21.8719 15.7381 20.5287 15.7381 18.8719C15.7381 18.7102 15.7509 18.5516 15.7755 18.3969L7.69525 14.3567C7.14977 14.921 6.3849 15.2719 5.53809 15.2719C3.88123 15.2719 2.53809 13.9287 2.53809 12.2719C2.53809 10.615 3.88123 9.27188 5.53809 9.27188C6.38494 9.27188 7.14983 9.62276 7.69532 10.1871L15.7755 6.14699C15.7509 5.99224 15.7381 5.83355 15.7381 5.67188Z"
      fill={color}/>
  </svg>;
};

export default Share;
