import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import OrangeGradientBackgroundDesktop from '../../components/backgrounds/OrangeGradientBackgroundDesktop.svg';
import OrangeGradientBackgroundMobile from '../../components/backgrounds/OrangeGradientBackgroundMobile.svg';
import AppStoreDownload from '../../components/icons/AppStoreDownload';
import AppStoreDownloadWhite from '../../components/icons/AppStoreDownloadWhite';
import GooglePlayOutline from '../../assets/GooglePlayOutline.png';
import PhotoCollage from '../../assets/promos/photoCollage.png';

import { DeviceQueries } from 'Common/src/components/styled';

const PhotosAppPromo = ({ dark, children }: PropsWithChildren<{ dark?: boolean }>) => {
  const handleDownloadShineiOSClick = () => {
    window.open('https://apps.apple.com/us/app/sunshine-photos/id6469663904?itsct=apps_box_badge&amp;itscg=30200',
      '_blank');
  };

  const handleDownloadShineAndroidClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.lumilabs.android.shine.ga', '_blank');
  };

  return <>
    <AppPromoContainer>
      <PromoContent>
        <PromoText>
          {children}
        </PromoText>
        <ButtonRow>
          <AppLinkContainer onClick={handleDownloadShineiOSClick}>
            { dark ? <AppStoreDownloadWhite/> : <AppStoreDownload/> }
          </AppLinkContainer>
          <AppLinkContainer onClick={handleDownloadShineAndroidClick}>
            <img alt="Get it on Google Play" src={GooglePlayOutline} width={236} height={70}/>
          </AppLinkContainer>
        </ButtonRow>
      </PromoContent>
      <AppPromoImage src={PhotoCollage}/>
    </AppPromoContainer>
  </>;
};

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  z-index: 0; // Below the content
  border: 1px solid #E5E5E5;
  overflow: hidden;
  background-image: url(${OrangeGradientBackgroundDesktop});
  background-repeat: no-repeat;
  background-position: center;

  @media (${DeviceQueries.mobile}) {
    background-image: url(${OrangeGradientBackgroundMobile});
  }
`;

const AppPromoContainer = styled.div`
  position: relative;
  display: flex;
  gap: 30px;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column-reverse;
    padding: 0;
    justify-content: center;
  }
`;

const PromoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  overflow: hidden;
  z-index: 1;

  @media (${DeviceQueries.mobile}) {
    padding: 0 20px;
    gap: 10px;
  }
`;

const PromoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${DeviceQueries.mobile}) {
    gap: 10px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  gap: 0 25px;
  flex-wrap: wrap;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
`;

export const AppLinkContainer = styled.div`
  cursor: pointer;
`;

export const AppPromoImage = styled.img`
  width: 38%;
  max-width: 500px;
  height: fit-content;

  @media (${DeviceQueries.mobile}) {
    width: 100%;
  }
`;
export default PhotosAppPromo;
