import styled from 'styled-components';
import { InputContainer } from '../forms/Input';

export const ActionMenuLabel = styled.label`
  font-family: Plus Jakarta Sans, sans-serif;
  color: var(--sp-text-color);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
`;

export const ActionMenuSubtext = styled.div`
  font-family: Plus Jakarta Sans, sans-serif;
  color: var(--sp-text-color);
  font-size: 12px;
  margin: 6px 0;
`;

export const ContentTitleWrapper = styled.div<{
  $withMarginBottom: boolean
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  ${({ $withMarginBottom }) => $withMarginBottom && 'margin-bottom: 20px'};
`;

export const ContentTitle = styled.div`
  color: var(--sp-text-color2);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ActionMenuInput = styled(InputContainer)`
  padding: 8px 10px;
  border-radius: 12px;
  background: rgba(var(--sp-bg-rgb), 0.30);
  box-shadow: 0 2px 5px 0 rgba(103, 110, 118, 0.08), 0 0 0 1px rgba(103, 110, 118, 0.16), 0 1px 1px 0 rgba(var(--sp-text-rgb), 0.12);
  box-sizing: border-box;
  justify-content: space-between;
  border: 2px solid var(--sp-border-color);
  font-size: 16px;
`;
