import React from 'react';
import styled from 'styled-components';

const VideoAnimation = ({ url, asBackground, colorOverride }: { url: string, asBackground: boolean, colorOverride?: string }) => {

  return <>
    <ColorOverlay $color={colorOverride}/>
    <VideoBackgroundContainer $fixedPosition={asBackground}>
      <video autoPlay muted loop playsInline key={url}>
        <source src={url} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </VideoBackgroundContainer>
  </>;
};

const ColorOverlay = styled.div<{ $color?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  z-index: 0; // Below the content
  background-color: ${({ $color }) => $color ? $color : 'var(--shine-bg-blur-overlay-color)'};
  backdrop-filter: blur(13px); // Blur effect
  -webkit-backdrop-filter: blur(13px); // Safari
`;

const VideoBackgroundContainer = styled.div<{ $fixedPosition: boolean }>`
    // ${({ $fixedPosition }) => $fixedPosition && 'position: fixed;'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover; // This will cover the whole area without stretching the video
  }
`;

export default VideoAnimation;
