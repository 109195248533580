import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { IUUserContext } from '../contexts/UserContext';

const logRpc = (userContext: IUUserContext | null, method: string, complete = false) => {
  void logSumoEvent({
    app: ULogApplication.ELK,
    severity: ULogSeverity.INFO,
    userId: userContext?.id,
    tag: ULogTag.Network,
    message: JSON.stringify({
      type: complete ? 'rpcComplete' : 'rpcCall',
      method
    })
  });
};

export default logRpc;
