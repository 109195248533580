import React, { useRef } from 'react';
import styled from 'styled-components';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { ModalContainer, Overlay } from './modals/CommonModal';
import { WideWhiteButton } from './buttons/WhiteButton';
import { WideOutlineButton } from './buttons/OutlineButton';

import { DeviceQueries } from 'Common/src/components/styled';
import { ORANGE, WHITE } from 'Common/src/lib/Colors';

const ImageCropper = ({ image, circular, whenDone, close }: {
  image: string | ArrayBuffer,
  circular?: boolean
  whenDone(dataUrl: string): void
  close: () => void
}) => {
  const editorRef = useRef<ReactCropperElement>(null);

  const handleSave = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.cropper.getCroppedCanvas(
        { maxWidth: 1024, maxHeight: 1024, imageSmoothingQuality: 'high' });
      const dataURL = canvasScaled.toDataURL();
      whenDone(dataURL);
      close();
    }
  };

  const resetCropper = () => {
    if (editorRef.current) {
      editorRef.current.cropper.reset();
    }
  };

  return <Overlay>
    <CustomModalContainer>
      <ImageContainer $circular={circular ?? false}>
        <Cropper
          ref={editorRef}
          src={image as any}
          style={{ width: '100%', height: '100%', borderRadius: '12px' }}
          initialAspectRatio={1}
          aspectRatio={1}
          viewMode={1}
          minCropBoxHeight={250}
          minCropBoxWidth={250}
          wheelZoomRatio={0.2}
          autoCrop={true}
          dragMode={'move'}
          background={false}
          responsive={false}
          toggleDragModeOnDblclick={false}
          checkOrientation={false}
          guides={!circular}
          autoCropArea={1}
        />
      </ImageContainer>

      <ButtonsRow>
        <WideOutlineButton type="button" onClick={resetCropper}>
          Reset
        </WideOutlineButton>
        <WideOutlineButton type="button" onClick={close}>
          Cancel
        </WideOutlineButton>
        <WideWhiteButton $invert={true} type="submit" onClick={handleSave}>
          Done
        </WideWhiteButton>
      </ButtonsRow>
    </CustomModalContainer>
  </Overlay>;
};

const CustomModalContainer = styled(ModalContainer)`
  max-width: fit-content;
`

const ImageContainer = styled.div<{
  $circular: boolean
}>`
  object-fit: contain;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(calc(100vh - 100px) * .7);
  width: 100vw;

  .cropper-view-box {
    border-radius: ${({ $circular }) => $circular ? '50%' : '12px'};
  }

  .cropper-drag-box {
    border-radius: 12px;
  }

  .cropper-modal {
    background: none;
    opacity: 1;
  }

  @media (${DeviceQueries.laptop}) {
    width: 80vh;
  }
`;

const ButtonsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  gap: 20px;
  margin: 0 25px;
  justify-content: center;
  --shine-bg-color: ${WHITE};
  color: ${ORANGE};
`;

export default ImageCropper;
