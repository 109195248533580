import { IUUserContext } from '../contexts/UserContext';
import { createUnauthHeader } from '../lib/performance';
import logRpc from '../util/logRpc';

import Net from 'Common/src/lib/Net';
import { augmentService } from 'Common/src/lib/ThriftServiceAugmentor';

import { TElkEventService } from 'PProtocol/prototypes/events';
import { TAuthHeader } from 'TProtocol/common/models';
import {
  TAppElkAttendeeStatus,
  TAppElkCohostRequestStatus,
  TAppElkDraftEvent,
  TAppElkEvent,
  TAppElkEventId,
  TAppElkInvitee,
  TAppElkMessage,
  TAppElkPhotoUploadMode, TAppElkReviewStatus,
  TAppElkTextPermissionStatus,
  TAppElkUser,
  TElkAddCohostRequest,
  TElkCancelEventRequest,
  TElkCancelEventResponse,
  TElkCreateEventDraftRequest,
  TElkCreateEventDraftResponse,
  TElkCreateEventRequest,
  TElkCreateEventResponse,
  TElkCreateUserRequest,
  TElkCreateUserResponse,
  TElkDeleteDraftRequest,
  TElkDeleteDraftResponse,
  TElkDeleteEventRequest,
  TElkDeletePendingInviteRequest,
  TElkEditEventDetailsRequest,
  TElkEditEventDetailsResponse,
  TElkEditEventDraftRequest,
  TElkEditEventDraftResponse,
  TElkEditInviteeRequest,
  TElkFetchAlbumRequest,
  TElkFetchContactsRequest,
  TElkFetchDraftRequest,
  TElkFetchDraftResponse,
  TElkFetchDraftsRequest,
  TElkFetchDraftsResponse,
  TElkFetchEventsRequest,
  TElkFetchEventsResponse,
  TElkFetchMutualMembersRequest,
  TElkGetAlbumDetailsRequest,
  TElkGetCohostLinkRequest,
  TElkGetEventPreviewRequest,
  TElkGetEventRequest,
  TElkGetEventShortLinkRequest,
  TElkGetEventShortLinkResponse,
  TElkGetHasCompletedProfileRequest,
  TElkGetHasCompletedProfileResponse,
  TElkGetHasProfileRequest,
  TElkGetHasProfileResponse,
  TElkGetImageHistoryRequest,
  TElkGetMessageHistoryRequest,
  TElkGetUserRequest,
  TElkGetUserResponse,
  TElkInvalidateCohostLinkRequest,
  TElkQueueInviteRequest, TElkReviewAttendeeRequest,
  TElkSendCommentRequest,
  TElkSendCommentResponse,
  TElkSendInviteRequest,
  TElkSendTemplateEmailRequest,
  TElkSendTemplateEmailResponse,
  TElkUpdateAttendanceRequest,
  TElkUpdateAttendanceResponse,
  TElkUpdateCohostStatusRequest,
  TElkUpdateUserRequest,
  TElkUpdateUserResponse,
} from 'TProtocol/prototypes/events/messages';

const createService = (): TElkEventService.Client => {
  return augmentService(Net.createService(TElkEventService, '/json/ElkEventService'));
};

export const createElkUser = async (
  header: TAuthHeader,
  user: TAppElkUser,
  coinsSpent?: number
): Promise<TElkCreateUserResponse> => {
  logRpc(null, 'createElkUser');
  const request: TElkCreateUserRequest = new TElkCreateUserRequest({
    elkUser: user,
    coinsSpent
  });
  const client: TElkEventService.Client = createService();
  const response = await client.createElkUser(header, request);
  logRpc(null, 'createElkUser', true);
  return response;
};

export const getElkUser = async (header: TAuthHeader): Promise<TElkGetUserResponse> => {
  logRpc(null, 'getElkUser');
  const request: TElkGetUserRequest = new TElkGetUserRequest({ userId: header.userId });
  const client: TElkEventService.Client = createService();
  const response = await client.getElkUser(header, request);
  logRpc(null, 'getElkUser', true);
  return response;
};

export const updateElkUser = async (header: TAuthHeader, elkUser: TAppElkUser): Promise<TElkUpdateUserResponse> => {
  logRpc(null, 'updateElkUser');
  const request: TElkUpdateUserRequest = new TElkUpdateUserRequest({ elkUser });
  const client: TElkEventService.Client = createService();
  const response = await client.updateElkUser(header, request);
  logRpc(null, 'updateElkUser', true);
  return response;
};

export const fetchContacts = async (userContext: IUUserContext, pageToken?: string) => {
  logRpc(userContext, 'fetchContacts');
  const request = new TElkFetchContactsRequest({
    pageToken
  });
  const client: TElkEventService.Client = createService();
  const response = await client.fetchContacts(userContext.getAuthHeader(), request);
  logRpc(userContext, 'fetchContacts', true);
  return response;
};

export const fetchMutualMembers = async (userContext: IUUserContext, pageToken = '0') => {
  logRpc(userContext, 'fetchMutualMembers');
  const request = new TElkFetchMutualMembersRequest({
    pageToken
  });
  const client: TElkEventService.Client = createService();
  const response = await client.fetchMutualMembers(userContext.getAuthHeader(), request);
  logRpc(userContext, 'fetchMutualMembers', true);
  return response;
};

export const createEvent = async (userContext: IUUserContext,
                                  event: TAppElkEvent, photoUploadMode: TAppElkPhotoUploadMode | undefined,
                                  isBlocking: boolean, albumId?: string): Promise<TElkCreateEventResponse> => {
  logRpc(userContext, 'createEvent');
  const request: TElkCreateEventRequest = new TElkCreateEventRequest({
    event: event,
    albumId
  });
  const client: TElkEventService.Client = createService();
  const response = await client.createEvent(userContext.getAuthHeader(isBlocking), request);
  logRpc(userContext, 'createEvent', true);
  return response;
};

export const editEventDetails = async (userContext: IUUserContext,
                                       event: TAppElkEvent, hostUploadMode: TAppElkPhotoUploadMode | undefined,
                                       disableNotification: boolean,
                                       isBlocking: boolean): Promise<TElkEditEventDetailsResponse> => {
  logRpc(userContext, 'editEventDetails');
  const request: TElkEditEventDetailsRequest = new TElkEditEventDetailsRequest(
    {
      event,
      eventId: event.id,
      uploadMode: undefined,
      disableNotification
    }
  );
  const client: TElkEventService.Client = createService();
  const response = await client.editEventDetails(userContext.getAuthHeader(isBlocking), request);
  logRpc(userContext, 'editEventDetails', true);
  return response;
};

export const fetchEvents = async (userContext: IUUserContext,
                                  isBlocking: boolean): Promise<TElkFetchEventsResponse> => {
  logRpc(userContext, 'fetchEvents');
  const request: TElkFetchEventsRequest = new TElkFetchEventsRequest();
  const client: TElkEventService.Client = createService();
  const response = await client.fetchEvents(userContext.getAuthHeader(isBlocking), request);
  logRpc(userContext, 'fetchEvents', true);
  return response;
};

export const getEvent = async (userContext: IUUserContext, eventId: string, inviteeUuid: string | undefined,
                               isBlocking: boolean): Promise<TAppElkEvent> => {
  logRpc(userContext, 'getEvent');
  const request: TElkGetEventRequest = new TElkGetEventRequest({
    eventId,
    inviteeUuid
  });
  const client: TElkEventService.Client = createService();

  const event = (await client.getEvent(userContext.getAuthHeader(isBlocking), request)).event;
  logRpc(userContext, 'getEvent', true);
  return event;
};

export const getEventPreview = async (eventId: string, inviteeUuid: string | undefined,
                                      isBlocking: boolean): Promise<TAppElkEvent> => {
  logRpc(null, 'getEventPreview');
  const request: TElkGetEventPreviewRequest = new TElkGetEventPreviewRequest({
    eventId,
    inviteeUuid
  });
  const client: TElkEventService.Client = createService();

  const event = (await client.getEventPreview(createUnauthHeader(isBlocking), request)).event;
  logRpc(null, 'getEventPreview', true);
  return event;
};

export const updateAttendance = async (userContext: IUUserContext, eventId: string, invitee: TAppElkInvitee,
                                       textPermissionStatusUpdate: TAppElkTextPermissionStatus | undefined,
                                       token: string | undefined, photoUploadMode: TAppElkPhotoUploadMode | undefined,
                                       isBlocking: boolean): Promise<TElkUpdateAttendanceResponse> => {
  logRpc(userContext, 'updateAttendance');
  const request: TElkUpdateAttendanceRequest = new TElkUpdateAttendanceRequest(
    { eventId, invitee, textPermissionStatusUpdate, token, photoUploadMode });
  const client: TElkEventService.Client = createService();
  const response = await client.updateAttendance(userContext.getAuthHeader(isBlocking), request);
  logRpc(userContext, 'updateAttendance', true);
  return response;
};

export const reviewAttendee = async (userContext: IUUserContext, eventId: string, inviteeUuid: string,
                                       waitlistStatus: TAppElkReviewStatus,
                                       isBlocking: boolean): Promise<TElkUpdateAttendanceResponse> => {
  logRpc(userContext, 'reviewAttendee');
  const request = new TElkReviewAttendeeRequest(
    { eventId, inviteeUuid, waitlistStatus });
  const client: TElkEventService.Client = createService();
  const response = await client.reviewAttendee(userContext.getAuthHeader(isBlocking), request);
  logRpc(userContext, 'reviewAttendee', true);
  return response;
};

export const updateAttendanceThroughEmail = async (eventId: string, invitee: TAppElkInvitee,
                                                   textPermissionStatusUpdate: TAppElkTextPermissionStatus | undefined,
                                                   token: string,
                                                   isBlocking: boolean,
                                                   isThroughEmail: boolean): Promise<TElkUpdateAttendanceResponse> => {
  logRpc(null, 'updateAttendanceThroughEmail');
  const request: TElkUpdateAttendanceRequest = new TElkUpdateAttendanceRequest(
    { eventId, invitee, textPermissionStatusUpdate, token, isThroughEmail });
  const client: TElkEventService.Client = createService();
  const response = await client.updateAttendanceThroughEmail(createUnauthHeader(isBlocking), request);
  logRpc(null, 'updateAttendanceThroughEmail', true);
  return response;
};

export const getHasCompletedElkProfile = async (phoneNumber: string,
                                                isBlocking: boolean): Promise<TElkGetHasCompletedProfileResponse> => {
  logRpc(null, 'getHasCompletedElkProfile');
  const request: TElkGetHasCompletedProfileRequest = new TElkGetHasCompletedProfileRequest({
    phoneNumber
  });
  const client: TElkEventService.Client = createService();

  const response = await client.getHasCompletedElkProfile(createUnauthHeader(isBlocking), request);
  logRpc(null, 'getHasCompletedElkProfile', true);
  return response;
};

export const getHasElkProfile = async (userContext: IUUserContext):
  Promise<TElkGetHasProfileResponse> => {
  logRpc(null, 'getHasElkProfile');
  const request = new TElkGetHasProfileRequest();
  const client: TElkEventService.Client = createService();

  const response = await client.getHasElkProfile(userContext.getAuthHeader(), request);
  logRpc(null, 'getHasElkProfile', true);
  return response;
};

export const getEventShortLink = async (userContext: IUUserContext,
                                        eventId: string): Promise<TElkGetEventShortLinkResponse> => {
  logRpc(userContext, 'getEventShortLink');
  const request: TElkGetEventShortLinkRequest = new TElkGetEventShortLinkRequest({
    eventId
  });
  const client: TElkEventService.Client = createService();

  const response = await client.getEventShortLink(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'getEventShortLink', true);
  return response;
};

export const cancelEvent = async (userContext: IUUserContext, eventId: string): Promise<TElkCancelEventResponse> => {
  logRpc(userContext, 'cancelEvent');
  const request: TElkCancelEventRequest = new TElkCancelEventRequest({
    eventId
  });
  const client: TElkEventService.Client = createService();

  const response = await client.cancelEvent(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'cancelEvent', true);
  return response;
};

export const sendEventComment = async ({ userContext, eventId, messageId, message, sendToStatuses, email, inviteeId }: {
  userContext: IUUserContext,
  eventId: string,
  messageId: string,
  message: string,
  sendToStatuses?: TAppElkAttendeeStatus[],
  email?: string,
  inviteeId?: string
}): Promise<TElkSendCommentResponse> => {
  logRpc(userContext, 'sendEventComment');
  const request: TElkSendCommentRequest = new TElkSendCommentRequest({
    eventId,
    messageId,
    message,
    isTextBlast: inviteeId === undefined,
    sendToStatuses,
    email,
    receiverInviteeUuid: inviteeId
  });

  const client: TElkEventService.Client = createService();
  const response = await client.sendEventComment(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'sendEventComment', true);
  return response;
};

export const getEventMessageHistory = async (userContext: IUUserContext,
                                             eventId: string, inviteeUuid?: string, token?: string): Promise<TAppElkMessage[]> => {
  logRpc(userContext, 'getEventMessageHistory');
  const request: TElkGetMessageHistoryRequest = new TElkGetMessageHistoryRequest({
    eventId,
    inviteeUuid,
    token
  });

  const client: TElkEventService.Client = createService();
  const messages = (await client.getEventMessageHistory(createUnauthHeader(false), request)).messages;
  logRpc(userContext, 'getEventMessageHistory', true);
  return messages;
};

export const getUserEventMessageHistory = async (userContext: IUUserContext,
                                                 eventId: string, inviteeUuid?: string): Promise<TAppElkMessage[]> => {
  logRpc(userContext, 'getUserEventMessageHistory');
  const request: TElkGetMessageHistoryRequest = new TElkGetMessageHistoryRequest({
    eventId,
    inviteeUuid
  });

  const client: TElkEventService.Client = createService();
  const messages = (await client.getUserEventMessageHistory(userContext.getAuthHeader(), request)).messages;
  logRpc(userContext, 'getUserEventMessageHistory', true);
  return messages;
};

export const sendTemplateEmail = async (userContext: IUUserContext, eventId: string,
                                        emailAddress: string): Promise<TElkSendTemplateEmailResponse> => {
  logRpc(userContext, 'sendTemplateEmail');
  const request: TElkSendTemplateEmailRequest = new TElkSendTemplateEmailRequest({
    eventId,
    emailAddress
  });

  const client: TElkEventService.Client = createService();
  const response = await client.sendTemplateEmail(userContext.getAuthHeader(), request);
  logRpc(userContext, 'sendTemplateEmail', true);
  return response;
};
export const deleteEvent = async (userContext: IUUserContext, eventId: string) => {
  logRpc(userContext, 'deleteEvent');
  const request: TElkDeleteEventRequest = new TElkDeleteEventRequest({
    eventId
  });
  const client: TElkEventService.Client = createService();

  const response = await client.deleteEvent(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'deleteEvent', true);
  return response;
};

export const queueInvites = async (userContext: IUUserContext, eventId: string, invitees: TAppElkInvitee[]) => {
  logRpc(userContext, 'queueInvites');
  const request: TElkQueueInviteRequest = new TElkQueueInviteRequest({
    eventId,
    invitees
  });
  const client: TElkEventService.Client = createService();

  const response = await client.queueInvites(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'queueInvites', true);
  return response;
};

export const sendInvites = async (userContext: IUUserContext, eventId: string, inviteIds: string[], email?: string) => {
  logRpc(userContext, 'sendInvites');
  const request: TElkSendInviteRequest = new TElkSendInviteRequest({
    eventId,
    inviteIds,
    email
  });
  const client: TElkEventService.Client = createService();

  const response = await client.sendInvites(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'sendInvites', true);
  return response;
};

export const deletePendingInvites = async (userContext: IUUserContext, eventId: string, inviteIds: string[]) => {
  logRpc(userContext, 'deletePendingInvites');
  const request: TElkDeletePendingInviteRequest = new TElkDeletePendingInviteRequest({
    eventId,
    inviteIds
  });
  const client: TElkEventService.Client = createService();

  const response = client.deletePendingInvites(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'deletePendingInvites', true);
  return response;
};

export const getEventImageHistory = async (userContext: IUUserContext, eventId?: string) => {
  logRpc(userContext, 'getEventImageHistory');
  const request = new TElkGetImageHistoryRequest({
    eventId
  });

  const client: TElkEventService.Client = createService();

  const response = await client.getEventImageHistory(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'getEventImageHistory', true);
  return response;
};

export const getAlbumDetails = async (userContext: IUUserContext, eventId: string, inviteeUuid?: string) => {
  logRpc(userContext, 'getAlbumDetails');
  const request = new TElkGetAlbumDetailsRequest({
    eventId,
    inviteeUuid
  });

  const client: TElkEventService.Client = createService();

  const response = await client.getAlbumDetails(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'getAlbumDetails', true);
  return response;
};

export const getAlbumForElk = async (userContext: IUUserContext, albumId: string) => {
  logRpc(userContext, 'getAlbumForElk');
  const request = new TElkFetchAlbumRequest({
    albumId
  });

  const client: TElkEventService.Client = createService();

  const response = client.getAlbumForElk(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'getAlbumForElk', true);
  return response;
};

export const getPreviewAlbumDetails = async (eventId: string, token?: string) => {
  logRpc(null, 'getPreviewAlbumDetails');
  const request = new TElkGetAlbumDetailsRequest({
    eventId,
    token
  });

  const client: TElkEventService.Client = createService();

  const response = await client.getPreviewAlbumDetails(createUnauthHeader(false), request);
  logRpc(null, 'getPreviewAlbumDetails', true);
  return response;
};

export const editInvitee = async (userContext: IUUserContext, eventId: string, invitee: TAppElkInvitee) => {
  logRpc(userContext, 'editInvitee');
  const request = new TElkEditInviteeRequest({
    eventId,
    invitee
  });

  const client: TElkEventService.Client = createService();

  const response = await client.editInvitee(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'editInvitee', true);
  return response;
};

export const createDraftEvent = async (userContext: IUUserContext,
                                       event: TAppElkDraftEvent): Promise<TElkCreateEventDraftResponse> => {
  logRpc(userContext, 'createDraftEvent');
  const request = new TElkCreateEventDraftRequest({
    event
  });
  const client: TElkEventService.Client = createService();

  const response = await client.createDraftEvent(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'createDraftEvent', true);
  return response;
};

export const editEventDraft = async (userContext: IUUserContext,
                                     event: TAppElkDraftEvent): Promise<TElkEditEventDraftResponse> => {
  logRpc(userContext, 'editEventDraft');
  const request = new TElkEditEventDraftRequest({
    eventId: event.id,
    event
  });
  const client: TElkEventService.Client = createService();

  const response = await client.editEventDraft(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'editEventDraft', true);
  return response;
};

export const fetchDraft = async (userContext: IUUserContext, eventUuid: string): Promise<TElkFetchDraftResponse> => {
  logRpc(userContext, 'fetchDraft');
  const request = new TElkFetchDraftRequest({
    eventUuid
  });
  const client: TElkEventService.Client = createService();

  const response = await client.fetchDraft(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'fetchDraft', true);
  return response;
};

export const fetchDrafts = async (userContext: IUUserContext): Promise<TElkFetchDraftsResponse> => {
  logRpc(userContext, 'fetchDrafts');
  const request = new TElkFetchDraftsRequest();
  const client: TElkEventService.Client = createService();

  const response = await client.fetchDrafts(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'fetchDrafts', true);
  return response;
};

export const deleteDraft = async (userContext: IUUserContext, eventUuid: string): Promise<TElkDeleteDraftResponse> => {
  logRpc(userContext, 'deleteDraft');
  const request = new TElkDeleteDraftRequest({
    eventUuid
  });
  const client: TElkEventService.Client = createService();

  const response = await client.deleteDraft(userContext.getAuthHeader(false), request);
  logRpc(userContext, 'deleteDraft', true);
  return response;
};

export const updateAttendanceByLink = async (eventId: string, invitee: TAppElkInvitee,
                                             textPermissionStatusUpdate: TAppElkTextPermissionStatus | undefined,
                                             token: string | undefined,
                                             isBlocking: boolean): Promise<TElkUpdateAttendanceResponse> => {
  logRpc(null, 'updateAttendanceByLink');
  const request: TElkUpdateAttendanceRequest = new TElkUpdateAttendanceRequest(
    { eventId, invitee, textPermissionStatusUpdate, token });
  const client: TElkEventService.Client = createService();
  const response = await client.updateAttendanceByLink(createUnauthHeader(isBlocking), request);
  logRpc(null, 'updateAttendanceByLink', true);
  return response;
};

export const addCohost = async (userContext: IUUserContext,
                                cohost: TAppElkInvitee,
                                eventId: TAppElkEventId) => {
  logRpc(userContext, 'addCohost');
  const request = new TElkAddCohostRequest(
    { cohost, eventId });
  const client: TElkEventService.Client = createService();
  const response = await client.addCohost(userContext.getAuthHeader(true), request);
  logRpc(userContext, 'addCohost', true);
  return response;
};

export const updateCohostStatus = async (userContext: IUUserContext,
                                         eventId: TAppElkEventId,
                                         cohost: TAppElkInvitee,
                                         status: TAppElkCohostRequestStatus,
                                         linkUuid?: string) => {
  logRpc(userContext, 'updateCohostStatus');
  const request = new TElkUpdateCohostStatusRequest(
    { eventId, cohost, status, linkUuid });
  const client: TElkEventService.Client = createService();
  const response = await client.updateCohostStatus(userContext.getAuthHeader(true), request);
  logRpc(userContext, 'updateCohostStatus', true);
  return response;
};

export const getCohostLink = async (userContext: IUUserContext,
                                    eventId: TAppElkEventId) => {
  logRpc(userContext, 'getCohostLink');
  const request = new TElkGetCohostLinkRequest(
    { eventId });
  const client: TElkEventService.Client = createService();
  const response = await client.getCohostLink(userContext.getAuthHeader(true), request);
  logRpc(userContext, 'getCohostLink', true);
  return response;
};

export const invalidateCohostLink = async (userContext: IUUserContext,
                                           eventId: TAppElkEventId) => {
  logRpc(userContext, 'invalidateCohostLink');
  const request = new TElkInvalidateCohostLinkRequest(
    { eventId });
  const client: TElkEventService.Client = createService();
  const response = await client.invalidateCohostLink(userContext.getAuthHeader(true), request);
  logRpc(userContext, 'invalidateCohostLink', true);
  return response;
};
