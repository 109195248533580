import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logEvent } from 'firebase/analytics';

import { ColorScheme, useBackgroundContext } from '../contexts/BackgroundContext';
import BasePageHeader, { UPageType } from './BasePageHeader';
import { BasePageContent } from './BasePage';
import { DesktopShow, MobileShow } from '../components/styled/Common';
import { getUpcoming } from '../components/seasonal/sampleEvents';
import SeasonalInvite from '../components/seasonal/SeasonalInvite';
import { firebaseAnalytics } from '../core/libs/Firebase';
import WelcomeFooter from './Welcome/WelcomeFooter';
import Slideshow, { SlideshowDatum } from '../components/Slideshow';
import ShineLogo from '../components/icons/ShineLogo';
import { ContentContainer, HeadlineMainText, HeadlineRow, PromoTitle } from './WelcomePage';
import PhotosAppPromo from './Welcome/PhotosAppPromo';
import SparkleButton from '../components/buttons/SparkleButton';
import { useUserContext } from '../contexts/UserContext';
import image0 from '../assets/welcome/image-12.png';
import image1 from '../assets/welcome/image-1-1.png';
import image2 from '../assets/welcome/image-2-1.png';
import image3 from '../assets/welcome/image-3-1.png';
import photosHero from '../assets/welcome/Hero.png';
import AppStoreDownload from '../components/icons/AppStoreDownload';
import GooglePlayStoreDownload from '../components/icons/GooglePlayStoreDownload';

import { DeviceQueries } from 'Common/src/components/styled';
import { logSumoEvent, ULogApplication, ULogSeverity, ULogTag } from 'Common/src/api/SumoLogicApi';
import { InspirationMainCategory, InspirationTheme } from '../lib/inspiration';
import PillRow from './Welcome/PillRow';

const WelcomePageSunshine = () => {
  const backgroundContext = useBackgroundContext();
  const userContext = useUserContext();
  const navigate = useNavigate();

  const [openMainCategory, setOpenMainCategory] = useState<InspirationMainCategory | undefined>();

  useEffect(() => {
    document.title = 'Sunshine: Bright shiny parties, easy photo sharing';
    logEvent(firebaseAnalytics, 'page_view', {
      page_title: 'Welcome - Analytics'
    });
    backgroundContext.setScheme({ scheme: ColorScheme.Inverted });
  }, []);

  const onContentLeave = () => {
    setOpenMainCategory(undefined);
  };

  const onCreateClick = () => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[WelcomePage] Clicked on create invite button'
    });

    navigate('/instant');
  };

  const viewGallery = (theme?: InspirationTheme) => {
    void logSumoEvent({
      app: ULogApplication.ELK,
      severity: ULogSeverity.INFO,
      userId: userContext.id,
      tag: ULogTag.UserAction,
      message: '[WelcomePage] Clicked on view gallery pill'
    });

    navigate(`/instant?theme=${theme}`);
  };

  const handleDownloadShineiOSClick = () => {
    window.open('https://apps.apple.com/us/app/sunshine-photos/id6469663904?itsct=apps_box_badge&amp;itscg=30200',
      '_blank');
  };

  const handleDownloadShineAndroidClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.lumilabs.android.shine.ga', '_blank');
  };

  const slideshowEvents: SlideshowDatum[] = getUpcoming().map(event => {
    return {
      content: <SeasonalInvite key={event.event.id} event={event.event}/>,
      delay: 15000
    };
  });

  const photosAppPromo = {
    content: <PhotosAppPromo>
      <PromoTitle>Photo sharing made smart and easy <PromoSmallEm>with the power of AI</PromoSmallEm></PromoTitle>
      <PromoSmallSubtitle>Perfect for trips, parties, families, teams, clubs, and more.</PromoSmallSubtitle>
      <PromoSmallSubtitle>Try the Shine photo sharing app!</PromoSmallSubtitle>
    </PhotosAppPromo>,
    delay: 30000
  };

  const slideshowData = [...slideshowEvents, photosAppPromo];

  return <>
    <BasePageHeader page={UPageType.Welcome}/>
    <BasePageContent $wide={true} $flush={true} $full={true}>
      <ContentContainer>
        <HeadlineRow>
          <HeadlineTextWithLogo>
            <HeadlineMainText>
              Try <MobileShow><LogoWrapper><ShineLogo opacity={1} size={100}/></LogoWrapper></MobileShow>
              <DesktopShow><LogoWrapper><ShineLogo opacity={1} size={150}/></LogoWrapper></DesktopShow>
              for photos and parties</HeadlineMainText>
          </HeadlineTextWithLogo>
        </HeadlineRow>
        <PillRow openMainCategory={openMainCategory} setOpenMainCategory={setOpenMainCategory} onThemeClick={viewGallery} onMouseLeave={onContentLeave}/>
        <Slideshow slidesData={slideshowData}/>
        <BottomPromoRowWrapper>
          <EventsPromoRow>
            <EventsPromoContent>
              <BottomPromoTitle>Parties</BottomPromoTitle>
              <BottomPromoHeader>
                Stunning events supercharged by AI.
              </BottomPromoHeader>
              <BottomPromoText>
                Generative AI images have a way of capturing people’s imaginations. Shine Parties uses it for clever, stunningly
                gorgeous invites
              </BottomPromoText>
              <div>
                <SparkleButton buttonText="Create your invite" disabled={false} onClick={onCreateClick} fullWidth={false}
                               buttonColor={'var(--sp-text-color)'} textColor={'var(--sp-bg-color)'}/>
              </div>
            </EventsPromoContent>
            <BottomPromoPhotos>
              <BottomPromoPhoto src={image0}/>
              <BottomPromoPhoto src={image1}/>
              <BottomPromoPhoto src={image2}/>
              <BottomPromoPhoto src={image3}/>
            </BottomPromoPhotos>
          </EventsPromoRow>
        </BottomPromoRowWrapper>
        <BottomPromoRowWrapper>
          <PhotosPromoContent>
            <PhotosPromoText>
              <BottomPromoTitle>Shine</BottomPromoTitle>
              <BottomPromoHeader>
                Never miss a photo
              </BottomPromoHeader>
              <BottomPromoText>
                Shine uses AI to effortlessly share your best photos with loved ones. With facial recognition and smart
                curation, the right memories reach the right people at the right time. Never miss a moment again.
              </BottomPromoText>
              <BottomIconsContainer>
                <AppLinkContainer onClick={handleDownloadShineiOSClick}>
                  <AppStoreDownload/>
                </AppLinkContainer>
                <AppLinkContainer onClick={handleDownloadShineAndroidClick}>
                  <GooglePlayStoreDownload/>
                </AppLinkContainer>
              </BottomIconsContainer>
            </PhotosPromoText>
          </PhotosPromoContent>
        </BottomPromoRowWrapper>
      </ContentContainer>
      <WelcomeFooter isRootDomain={true}/>
    </BasePageContent>
  </>;
};

const HeadlineTextWithLogo = styled.div`
  display: flex;
  gap: 10px;
  align-content: baseline;
  align-items: center;
`;

const PromoSmallSubtitle = styled.div`
  color: var(--sp-text-color);
  font-size: 32px;
  font-weight: 400;

  @media (${DeviceQueries.mobile}) {
    font-size: 20px;
    line-height: 32px; /* 160% */
  }
`;

const PromoSmallEm = styled.span`
  font-style: italic;;
`;

const LogoWrapper = styled.span`
  padding-right: 15px;

  @media (${DeviceQueries.mobile}) {
    padding-right: 10px;
  }
`;

const BottomPromoRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const EventsPromoRow = styled.div`
  padding: 20px 0 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1424px;
  gap: 20px;

  @media (${DeviceQueries.mobile}) {
    flex-wrap: wrap;
  }
`;

const EventsPromoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

const BottomPromoTitle = styled.div`
  color: #FFD41C;
  font-size: 38px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 54px;
  letter-spacing: 0.13em;
`;

const BottomPromoHeader = styled.div`
  color: #181726;
  font-size: 63px;
  font-weight: 600;
  line-height: 75px;
  letter-spacing: -0.02em;
  width: 100%;
  word-break: break-word;
`;

const BottomPromoText = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
`;

const BottomPromoPhotos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
`;

const BottomPromoPhoto = styled.img`
  max-width: 100%;
  height: auto;
`;

const PhotosPromoContent = styled.div`
  background-image: url(${photosHero});
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  max-width: 1512px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (${DeviceQueries.mobile}) {
    background: none;
  }
`;

const PhotosPromoText = styled.div`
  min-height: 700px;
  width: 100%;
  max-width: 650px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const BottomIconsContainer = styled.div`
  display: flex;
  gap: 25px;
  justify-content: center;
  flex-wrap: wrap;
`;

const AppLinkContainer = styled.div`
  cursor: pointer;
`;

export default WelcomePageSunshine;

