import React, { MouseEvent, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useBackgroundContext } from '../../contexts/BackgroundContext';
import { calculateButtonColors } from '../../lib/buttonColors';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  outline?: boolean;
  inverse?: boolean;
  hidden?: boolean;
  overrideColor?: boolean;
  buttonColor?: string;
  lessPadding?: boolean;
  maxHeight?: number;
  noMarginTop?: boolean;
  bgColor?: string;
}

const ActionButton = ({
                        type,
                        loading,
                        children,
                        onClick,
                        disabled,
                        outline,
                        inverse,
                        hidden = false,
                        overrideColor,
                        buttonColor,
                        lessPadding,
                        maxHeight,
                        noMarginTop,
                        bgColor = 'var(--sp-text-color)'
                      }: PropsWithChildren<Props>) => {
  const backgroundContext = useBackgroundContext();

  const { textColor } = calculateButtonColors({
    color: buttonColor ?? backgroundContext.colors.highlightText,
    outline,
    inverse,
    loading,
    overrideColor
  });

  return <Button type={type} $bgColor={bgColor} $textColor={textColor} onClick={onClick}
                 disabled={disabled} hidden={hidden} $lessPadding={lessPadding} $maxHeight={maxHeight}
                 $noMarginTop={noMarginTop}>
    {children}
  </Button>;
};

const Button = styled.button<{
  $bgColor?: string,
  $textColor: string,
  $lessPadding?: boolean,
  $maxHeight?: number,
  $noMarginTop?: boolean
}>`
  font-family: Plus Jakarta Sans, sans-serif;
  background: ${({ $bgColor }) => $bgColor};
  color: ${({ $textColor }) => $textColor};
  border-radius: 10px;
  border: none;
  padding: ${({ $lessPadding }) => $lessPadding ? '10px' : '10px 20px'};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
  ${({ $noMarginTop }) => !$noMarginTop && 'margin-top: 13px;'};
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  --button-text-color: ${({ $textColor }) => $textColor};
  align-items: center;
  ${({ $maxHeight }) => $maxHeight !== undefined && `max-height: ${$maxHeight}px`};
  gap: 10px;

  &:active {
    filter: brightness(90%);
  }

  &:hover:not([disabled]) {
    filter: brightness(95%);
  }

  &:disabled {
    opacity: .5;
  }
`;

export default ActionButton;
