import React from 'react';

const AddList = ({ color = 'currentColor', size = 20 }: { color?: string, size?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
    <path
      d="M9.36182 12H13.1118M9.36182 15H13.1118M9.36182 18H13.1118M16.1118 18.75H18.3618C19.6045 18.75 20.6118 17.7426 20.6118 16.5V6.10822C20.6118 4.97324 19.7668 4.01015 18.6357 3.91627C18.2624 3.88529 17.888 3.85858 17.5123 3.83619M11.7113 3.83619C11.6466 4.04602 11.6118 4.26894 11.6118 4.5C11.6118 4.91421 11.9476 5.25 12.3618 5.25H16.8618C17.276 5.25 17.6118 4.91421 17.6118 4.5C17.6118 4.26894 17.577 4.04602 17.5123 3.83619M11.7113 3.83619C11.9946 2.91757 12.8502 2.25 13.8618 2.25H15.3618C16.3734 2.25 17.2291 2.91757 17.5123 3.83619M11.7113 3.83619C11.3357 3.85858 10.9612 3.88529 10.5879 3.91627C9.45681 4.01015 8.61182 4.97324 8.61182 6.10822V8.25M8.61182 8.25H5.23682C4.6155 8.25 4.11182 8.75368 4.11182 9.375V20.625C4.11182 21.2463 4.6155 21.75 5.23682 21.75H14.9868C15.6081 21.75 16.1118 21.2463 16.1118 20.625V9.375C16.1118 8.75368 15.6081 8.25 14.9868 8.25H8.61182ZM7.11182 12H7.11932V12.0075H7.11182V12ZM7.11182 15H7.11932V15.0075H7.11182V15ZM7.11182 18H7.11932V18.0075H7.11182V18Z"
      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default AddList;
