import React from 'react';
import styled from 'styled-components';

import WelcomePageSunshine from './WelcomePageSunshine';
import WelcomePageShine from './WelcomePageShine';

import { DeviceQueries } from 'Common/src/components/styled';

declare const ELK_COOKIE_DOMAIN: string; // sunshine.com

const WelcomePage = () => {
  const isRootDomain = window.location.host === ELK_COOKIE_DOMAIN;

  if (isRootDomain) {
    return <WelcomePageSunshine/>;
  } else {
    return <WelcomePageShine/>;
  }
};

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100svw;
  padding: 0 40px 45px 40px;
  box-sizing: border-box;

  @media (${DeviceQueries.mobile}) {
    padding: 0 16px 50px 16px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;

  @media (${DeviceQueries.mobile}) {
    flex-direction: column;
    gap: 0;
  }
`;

export const HeadlineRow = styled(Row)`
  align-items: center;
  justify-content: space-between;

  @media (${DeviceQueries.mobile}) {
    align-items: flex-start;
  }
`;

export const HeadlineMainText = styled.div`
  color: var(--sp-text-color);
  font-size: 48px;
  font-weight: 500;
  line-height: 80px;
  margin-left: -3px;

  @media (${DeviceQueries.mobile}) {
    font-size: 32px;
    line-height: 50px;
    margin: 0;
  }
`;

export const PromoTitle = styled.div`
  color: var(--sp-text-color);
  font-size: 54px;
  font-weight: 600;
  line-height: 60px;

  @media (${DeviceQueries.mobile}) {
    font-size: 32px;
    letter-spacing: -0.96px;
    line-height: 38px;
  }
`;


export default WelcomePage;

