import { IUEvent, IUInvitee } from '../lib/event';
import { v4 as uuidv4 } from 'uuid';
import { getAttendeeIndex } from '../lib/attendance';
import { IUAutoCompleteContact } from './contact';

import {
  TAppElkAttendeeRole,
  TAppElkAttendeeStatus,
  TAppElkCohostRequestStatus,
  TAppElkInviteStatus,
  TAppElkProfile,
  TAppElkReviewStatus,
  TAppElkUser
} from 'TProtocol/prototypes/events/messages';
import { TAppPii } from 'TProtocol/authentication/messages';

const Colors = [
  '#EAAF41', '#EF6623', '#ED1B2E', '#A3238E',
  '#7A469B', '#0359AA', '#02ABDD', '#4BB749'
];

export enum FilterType {
  All = 'ALL',
  Unsent = 'UNSENT',
  Going = 'GOING',
  NotGoing = 'NOT_GOING',
  Awaiting = 'AWAITING'
}

export const calculateColor = (attendee?: IUInvitee | TAppElkProfile | TAppElkUser | IUAutoCompleteContact) => {
  if (attendee === undefined) {
    return 'transparent';
  }

  const NameRegex = /^([^ ]*) (.*)$/;
  const names = (attendee?.name ?? '').match(NameRegex) ?? [attendee.name, attendee.name, ''];

  const id = (('userId' in attendee && typeof attendee.userId === 'string' && attendee.userId) ||
    (names[0] !== ' ' && names[0]) ||
    ('email' in attendee && typeof attendee.email === 'string' && attendee.email) ||
    '');

  let total = 0;
  for (let i = 0; i < id.length; i++) {
    total += id.charCodeAt(i);
  }

  return Colors[total % 8];
};

export const formatStatus = (invitee?: IUInvitee) => {
  if (invitee === undefined) {
    return '';
  }

  if (invitee.role === TAppElkAttendeeRole.ORGANIZER) {
    return 'Host';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.INVITED) {
    switch (invitee.inviteStatus) {
      case undefined:
        return '';
      case TAppElkInviteStatus.PENDING:
        return 'Pending';
      case TAppElkInviteStatus.TRIGGERED:
        return 'Preparing to Send';
      case TAppElkInviteStatus.SUCCESS:
        return 'Sent Successfully';
      case TAppElkInviteStatus.OPENED:
      case TAppElkInviteStatus.CLICKED:
        return 'Opened Invite';
      case TAppElkInviteStatus.FAILED:
        return 'Send Failure';
      default:
        const exhaustiveCheck: never = invitee.inviteStatus;
        throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
    }
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.SEEN) {
    return 'Seen Party';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.YES) {
    return 'Attending';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.NO) {
    return 'Declined';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.HIDDEN) {
    return 'Hidden';
  } else {
    const exhaustiveCheck: never = invitee.rsvpStatus;
    throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
  }
};

export const formatShortStatus = (invitee?: IUInvitee) => {
  if (invitee === undefined) {
    return '';
  }

  if (invitee.role === TAppElkAttendeeRole.ORGANIZER) {
    return 'HOST';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.INVITED) {
    switch (invitee.inviteStatus) {
      case undefined:
        return '';
      case TAppElkInviteStatus.PENDING:
        return 'Send invite';
      case TAppElkInviteStatus.FAILED:
        return 'FAILED';
      case TAppElkInviteStatus.TRIGGERED:
      case TAppElkInviteStatus.SUCCESS:
      case TAppElkInviteStatus.OPENED:
      case TAppElkInviteStatus.CLICKED:
        return 'SENT';
      default:
        const exhaustiveCheck: never = invitee.inviteStatus;
        throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
    }
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.SEEN) {
    return 'SEEN';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.YES) {
    return 'YES';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.NO) {
    return 'NO';
  } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.HIDDEN) {
    return '';
  } else {
    const exhaustiveCheck: never = invitee.rsvpStatus;
    throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
  }
};

export const formatCohostStatus = (invitee?: IUInvitee) => {
  if (invitee === undefined) {
    return '';
  }

  if (invitee.role === TAppElkAttendeeRole.ORGANIZER) {
    return 'HOST';
  } else if (invitee.cohostStatus === undefined) {
    return '';
  } else if (invitee.cohostStatus === TAppElkCohostRequestStatus.PENDING) {
    return 'PENDING';
  } else if (invitee.cohostStatus === TAppElkCohostRequestStatus.FAILED) {
    return 'FAILED';
  } else if (invitee.cohostStatus === TAppElkCohostRequestStatus.SUCCESS) {
    return 'SENT';
  } else if (invitee.cohostStatus === TAppElkCohostRequestStatus.ACCEPTED) {
    return 'ACCEPTED';
  } else if (invitee.cohostStatus === TAppElkCohostRequestStatus.DECLINED) {
    return 'DECLINED';
  } else {
    const exhaustiveCheck: never = invitee.cohostStatus;
    throw new Error(`Unhandled cohost status: ${exhaustiveCheck}`);
  }
};

export const sortInvitees = (invitees: IUInvitee[]): IUInvitee[] => {
  const pending: IUInvitee[] = [];
  const other: IUInvitee[] = [];

  for (const invitee of invitees) {
    if (invitee.cohostStatus !== undefined && invitee.role !== TAppElkAttendeeRole.ORGANIZER) {
      continue;
    }
    if (invitee.inviteStatus === TAppElkInviteStatus.PENDING) {
      pending.push(invitee);
    } else {
      other.push(invitee);
    }
  }

  return [...pending, ...other.sort(sortByName)];
};

export const sortByName = (a: IUInvitee, b: IUInvitee): number => {
  return (a?.name?.toLowerCase() ?? '') > (b?.name?.toLowerCase() ?? '') ? 1 : -1;
};

const NameRegex = /^([^ ]*) ([^ ]*)(?: (.*))?$/;
const ands = ['&', 'and', '和', 'और', 'y', 'و', 'et', 'এবং', 'e', 'и', 'اور', 'dan', 'und', 'と'];

export const parseName = (name?: string): [string, string] => {
  if (!name) {
    return ['', ''];
  }
  const match = name.match(NameRegex);
  if (!match) {
    return [name, ''];
  }
  const lastName = (match[2] && (ands.indexOf(match[2].toLowerCase()) === -1)) ? match[2] : (match[3] ?? '');
  return [match[1], lastName];
};

export const removeDuplicateInvitees = (invitees: IUInvitee[], event?: IUEvent): IUInvitee[] => {
  const allInvitees = new Set<string>();

  if (event !== undefined) {
    for (const invitee of event.attendees) {
      if (invitee.email !== undefined) {
        allInvitees.add(invitee.email);
      }
    }
  }

  const newInvitees: IUInvitee[] = [];
  for (const invitee of invitees) {
    if (invitee.email !== undefined && !allInvitees.has(invitee.email)) {
      allInvitees.add(invitee.email);
      newInvitees.push(invitee);
    }
  }

  return newInvitees;
};

export type InviteesByType = {
  [key in FilterType]: IUInvitee[];
};

export const filterInvitees = (invitees: IUInvitee[], filterTypes: Set<FilterType>): IUInvitee[] => {
  if (filterTypes.has(FilterType.All)) {
    return [...invitees];
  }

  const filteredInvitees: IUInvitee[] = [];

  invitees.forEach(invitee => {
    if (invitee.rsvpStatus === TAppElkAttendeeStatus.INVITED) {
      switch (invitee.inviteStatus) {
        case undefined:
        case TAppElkInviteStatus.PENDING:
        case TAppElkInviteStatus.FAILED:
          if (filterTypes.has(FilterType.Unsent)) {
            filteredInvitees.push(invitee);
          }
          return;
        case TAppElkInviteStatus.TRIGGERED:
        case TAppElkInviteStatus.SUCCESS:
        case TAppElkInviteStatus.OPENED:
        case TAppElkInviteStatus.CLICKED:
          if (filterTypes.has(FilterType.Awaiting)) {
            filteredInvitees.push(invitee);
          }
          return;
        default:
          const exhaustiveCheck: never = invitee.inviteStatus;
          throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
      }
    } else if (
      (invitee.rsvpStatus === TAppElkAttendeeStatus.SEEN && filterTypes.has(FilterType.Awaiting)) ||
      (invitee.rsvpStatus === TAppElkAttendeeStatus.YES && filterTypes.has(FilterType.Going)) ||
      (invitee.rsvpStatus === TAppElkAttendeeStatus.NO && filterTypes.has(FilterType.NotGoing))
    ) {
      filteredInvitees.push(invitee);
    }
  });

  return filteredInvitees;
};

export const separateInvitees = (invitees: IUInvitee[]): InviteesByType => {
  const map: InviteesByType = {
    [FilterType.All]: [],
    [FilterType.Unsent]: [],
    [FilterType.Going]: [],
    [FilterType.NotGoing]: [],
    [FilterType.Awaiting]: [],
  };

  invitees.forEach(invitee => {
    if (invitee.waitlistStatus !== TAppElkReviewStatus.APPROVED) {
      return;
    }

    map[FilterType.All].push(invitee);

    if (invitee.rsvpStatus === TAppElkAttendeeStatus.INVITED) {
      switch (invitee.inviteStatus) {
        case undefined:
        case TAppElkInviteStatus.PENDING:
        case TAppElkInviteStatus.FAILED:
          map[FilterType.Unsent].push(invitee);
          return;
        case TAppElkInviteStatus.TRIGGERED:
        case TAppElkInviteStatus.SUCCESS:
        case TAppElkInviteStatus.OPENED:
        case TAppElkInviteStatus.CLICKED:
          map[FilterType.Awaiting].push(invitee);
          return;
        default:
          const exhaustiveCheck: never = invitee.inviteStatus;
          throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
      }
    } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.SEEN) {
      map[FilterType.Awaiting].push(invitee);
    } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.YES) {
      map[FilterType.Going].push(invitee);
    } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.NO) {
      map[FilterType.NotGoing].push(invitee);
    } else if (invitee.rsvpStatus === TAppElkAttendeeStatus.HIDDEN) {
      // Do nothing
    } else {
      const exhaustiveCheck: never = invitee.rsvpStatus;
      throw new Error(`Unhandled invite status: ${exhaustiveCheck}`);
    }
  });

  return map;
};

export const findCurrentInvitee = (inviteeUuid: string | undefined, userId: string | undefined,
                                   event: IUEvent | undefined, userName: string | undefined,
                                   clickedYes: boolean | undefined, phones: string[] | undefined,
                                   emails: string[] | undefined) => {
  let inviteeToSet: undefined | IUInvitee;

  const currInviteeIndex = getAttendeeIndex(event?.attendees, userId, inviteeUuid, phones, emails);
  if (currInviteeIndex !== -1 && event?.attendees) {
    inviteeToSet = event?.attendees[currInviteeIndex];
  }

  if (inviteeToSet !== undefined) {
    if (userName !== '' && (inviteeToSet.name === undefined || inviteeToSet.name === '')) {
      inviteeToSet.name = userName;
    }
    return new IUInvitee(inviteeToSet);
  } else {
    // This should only happen if the user is joining an open invite
    return (new IUInvitee({
      inviteeId: uuidv4(),
      name: userName,
      rsvpMessage: '',
      additionalGuestCount: 0,
      role: TAppElkAttendeeRole.ATTENDEE,
      rsvpStatus: clickedYes ? TAppElkAttendeeStatus.YES : TAppElkAttendeeStatus.NO,
      phone: phones?.[0],
      email: emails?.[0],
      isNew: true
    }));
  }
};

export const inviteeHasNewPiiForUser = (invitee?: IUInvitee, user?: TAppElkUser): TAppPii | undefined => {
  if (invitee === undefined || user === undefined) {
    return undefined;
  }
  if (invitee.email && (user?.emails ?? []).indexOf(invitee.email) === -1) {
    return new TAppPii({ email: invitee.email });
  }
  if (invitee.phone && user.phoneNumber !== invitee.phone) {
    return new TAppPii({ phone: invitee.phone });
  }
  return undefined;
}
