import React, { ReactNode, memo } from 'react';
import styled from 'styled-components';

import EventOption from '../../components/EventOption';
import CreateEventPromo from '../../components/promo/CreateEventPromo';
import CreateEventRow from './CreateEventRow';
import { IUEvent } from '../../lib/event';

import { DeviceQueries } from 'Common/src/components/styled';

const EventRow = ({ event, onClick, showAttendees, showCreate, showPlus }: {
  event?: IUEvent,
  onClick: (event: IUEvent) => void,
  showAttendees: boolean,
  showCreate?: boolean,
  showPlus?: boolean,
}) => {
  const handleRowClick = () => {
    if (event !== undefined) {
      onClick(event);
    }
  };

  let content: ReactNode;
  if (event === undefined && showPlus) {
    content = <CreateEventRow/>;
  } else if (event === undefined && showCreate) {
    content = <CreateEventPromo/>;
  } else if (event === undefined) {
    content = <EventOption
      event={event}
      showAttendees={showAttendees}
      isCreatePrompt={false}
      onImageClick={handleRowClick}
    />;
  } else {
    content = <EventOption
      event={event}
      showAttendees={showAttendees}
      isCreatePrompt={false}
      onImageClick={handleRowClick}
    />;
  }

  return <EventRowInner $loading={!showCreate && event === undefined}>
    {content}
  </EventRowInner>;
};

export default memo(EventRow, (prevProps, props) => {
  // onClick changes on every render because it's a lambda, so don't check that
  return prevProps.event === props.event &&
    prevProps.showAttendees === props.showAttendees &&
    prevProps.showCreate === props.showCreate &&
    prevProps.showPlus === props.showPlus;
});

const EventRowInner = styled.div<{ $loading: boolean }>`
  width: 311px;
  border-radius: 10px;

  @media (${DeviceQueries.mobile}) {
    max-width: 100%;
    margin: 0 0 20px;
    scroll-snap-align: start;
  }
`;
